import { memoize } from 'lodash';

import type Store from './base';
import CookieStore from './cookie';
import LocalStorageStore from './localStorage';

const createDefaultStore = memoize((): Store => {
  try {
    return new LocalStorageStore();
  } catch {
    return new CookieStore();
  }
});

export { default as MemoryStore } from './memory';

export type { Store };
export { createDefaultStore, CookieStore, LocalStorageStore };
