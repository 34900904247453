import { ApolloLink } from '@apollo/client';

import NaptimeRestLink from './NaptimeRestLink';
import NaptimeTransformLink from './NaptimeTransformLink';
import catchLink from './catchLink';

function NaptimeLink(options) {
  const naptimeRestLink = new NaptimeRestLink(options);
  const naptimeTransformLink = new NaptimeTransformLink();
  return ApolloLink.from([naptimeTransformLink, catchLink, naptimeRestLink]);
}

export default NaptimeLink;
