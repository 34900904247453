export const defaultKeywords = [
  'Coursera',
  'online',
  'learning',
  'education',
  'free',
  'courses',
  'mooc',
  'specializations',
  'certificates',
];
export const blackListedUrlParameters = ['authMode', 'isNewUser', 'skipBrowseRedirect', 'isPreview'];
export const blackListedSubdomains = ['www-origin', 'www-cloudfront-alias'];
export const defaultImageHref = 'https://s3.amazonaws.com/coursera/media/Grid_Coursera_Partners_updated.png';
export const BEST_PRODUCT_RATING = 5;
