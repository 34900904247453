import { css } from '@emotion/react';

import { breakpoints } from '@coursera/cds-common';

import generateEmotionClasses from '@core/utils/generateEmotionClasses';

import type { DialogWidth } from './types';

export const classes = generateEmotionClasses('Dialog', ['dialog']);

const getDialogWidthCss = (width: DialogWidth) => {
  if (width === 'medium') {
    return css`
      width: 568px;
    `;
  }

  return css`
    width: 60vw;

    ${breakpoints.only('md')} {
      width: 70vw;
    }

    ${breakpoints.only('sm')} {
      width: 568px;
    }
  `;
};

export const getDialogCss = ({ width }: { width: DialogWidth }) => {
  return css`
    .${classes.dialog} {
      background-color: var(--cds-color-neutral-background-primary);
      border-radius: var(--cds-border-radius-100);
      min-height: 300px;
      max-height: 80vh;
      display: flex;
      flex-direction: column;
      overflow-y: hidden;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);

      ${getDialogWidthCss(width)}

      ${breakpoints.down('xs')} {
        border-radius: 0;
        min-height: unset;
        max-height: unset;
        height: 100%;
        width: 100%;
      }
    }
  `;
};
