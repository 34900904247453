import * as cookie from 'js/lib/cookie';

import Store from './base';

/**
 * Name of the index key used to store the list of all keys in the cookie store.
 */
const INDEX = '__index__';

/**
 * A store implementation that uses cookies to persist key-value pairs.
 *
 * The data is stored in cookies with a specified prefix.
 */
class CookieStore extends Store {
  /**
   * The prefix used for all keys stored in the cookie store.
   *
   * @static
   */
  static prefix = 'tracking';

  /**
   * Clear the store by removing all keys. These keys are stored in the index.
   *
   * This method retrieves the list of keys from the index and removes each key
   * from the cookies. It then clears the index itself.
   */
  clear(): void {
    const index = this.get<string[]>(INDEX) ?? [];

    index.forEach((key) => this.remove(key));
  }

  /**
   * Get the value for the given key.
   *
   * This method retrieves the value associated with the given key from the
   * cookies. If the key does not exist or the value cannot be parsed, it
   * returns undefined.
   *
   * @param key - The key whose value needs to be retrieved.
   * @returns The value associated with the key, or undefined if the key does not exist or cannot be parsed.
   */
  get<TData>(key: string): TData | undefined {
    try {
      return JSON.parse(cookie.get(`${CookieStore.prefix}.${key}`) ?? 'undefined');
    } catch {
      return undefined;
    }
  }

  /**
   * Set the value for the given key. Store the key in the index.
   *
   * This method stores the given value in the cookies under the specified key.
   * It also updates the index to include the new key.
   *
   * @param key - The key for which the value needs to be set.
   * @param value - The value to be set for the given key.
   */
  set<TData>(key: string, value: TData): void {
    const index = this.get<string[]>(INDEX) ?? [];

    cookie.set(`${CookieStore.prefix}.${INDEX}`, JSON.stringify([...index, key]), { days: 365 });
    cookie.set(`${CookieStore.prefix}.${key}`, value, { days: 365 });
  }

  /**
   * Remove the value for the given key. Remove the key from the index.
   *
   * This method removes the value associated with the given key from the
   * cookies. It also updates the index to remove the key.
   *
   * @param key - The key whose value needs to be removed.
   */
  remove(key: string): void {
    const index = this.get<string[]>(INDEX) ?? [];

    cookie.set(`${CookieStore.prefix}.${INDEX}`, JSON.stringify(index.filter((item) => item !== key)), { days: 365 });
    cookie.remove(`${CookieStore.prefix}.${key}`);
  }
}

export default CookieStore;
