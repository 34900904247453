import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';

import generateEmotionClasses from '@core/utils/generateEmotionClasses';

export const classes = generateEmotionClasses('CommonCard', [
  'bodyContent',
  'dotSeparator',
  'previewImage',
  'watchNow',
  'watchNowIconLabel',
  'title',
  'titleLink',
  'linkFocusVisible',
  'creditType',
  'metadata',
  'enrollmentDate',
  'avatar',
  'ratings',
  'category',
  'clickArea',
  'interactiveArea',
  'cta',
]);

const getCommonCardCss = (): SerializedStyles =>
  css`
    --card-padding: calc(var(--cds-spacing-100));
    --card-border-radius: var(--cds-spacing-200);
    --card-box-shadow: 0 0 40px -8px rgb(0 0 0 / 16%),
      0 0 24px -16px rgb(0 0 0 / 16%);
    --card-hover-transform: scale3d(1.03, 1.03, 1.08)
      translate3d(0.1rem, -0.25rem, 20rem);
    --card-title-line-clamp: 3;
    --card-body-line-clamp: 3;
    --card-metadata-line-clamp: 2;
    --preview-aspect-ratio: 16 / 9;
    --preview-border-radius: var(--cds-spacing-100);

    .${classes.bodyContent} {
      display: -webkit-box;
      -webkit-line-clamp: var(--card-body-line-clamp);
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .${classes.title} {
      display: -webkit-box;
      -webkit-line-clamp: var(--card-title-line-clamp);
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .${classes.dotSeparator} {
      margin: 0 var(--cds-spacing-50);
    }

    .${classes.previewImage} {
      aspect-ratio: var(--preview-aspect-ratio);
      overflow: hidden;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    .${classes.clickArea} {
      position: relative;
      height: auto;
      display: flex;
      flex-direction: column;
      flex: 1;

      /* overlay entire click area with the title link */
      .${classes.titleLink} {
        &::before {
          content: '';
          cursor: inherit;
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 0;
          width: 100%;
          height: 100%;
        }
      }
    }

    .${classes.interactiveArea} {
      z-index: 1;
      position: relative;
    }

    .${classes.watchNow} {
      display: flex;
      gap: 4px;

      .${classes.watchNowIconLabel} {
        display: flex;
        gap: 4px;
        color: var(--cds-color-interactive-primary);
      }
    }

    .${classes.titleLink} {
      color: var(--cds-color-neutral-primary);
      text-decoration: none;

      /* 
       this is needed for custom tracking where an extra DIV
       wraps this <a> tag and breaks the styles. See ACCESS-3423. 
      */
      display: block;
      height: inherit;

      &:focus,
      &:hover {
        text-decoration: none;
        outline: none;
      }

      &.${classes.linkFocusVisible} {
        outline: 1px solid transparent;
        outline-offset: 2px;
        border-radius: var(--cds-border-radius-50);
        box-shadow: 0 0 0 1px var(--cds-color-interactive-stroke-primary-focus),
          0 0 0 2px var(--cds-color-interactive-background-primary-hover-weak);
      }
    }

    .${classes.creditType} {
      display: flex;
      gap: 8px;
    }

    .${classes.metadata} {
      display: -webkit-box;
      -webkit-line-clamp: var(--card-metadata-line-clamp);
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .${classes.avatar} {
      height: 24px;
      width: 24px;
      border-radius: var(--cds-border-radius-25);
      padding: 2px;
      border: 1px solid var(--cds-color-neutral-stroke-primary-weak);
    }
  `;

export default getCommonCardCss;
