import React from 'react';

import type { ForwardRefComponent } from '@coursera/cds-common';

import { BaseHeadingGroup } from '@core/dialogs/common/BaseHeadingGroup';
import type { BaseHeadingGroupProps } from '@core/dialogs/common/BaseHeadingGroup';

import { useDialogContext } from './DialogContextProvider';
import { getHeadingGroupCss } from './styles';

type HeadingGroupType = ForwardRefComponent<HTMLDivElement, Props>;

type BaseProps = Omit<BaseHeadingGroupProps, 'color' | 'heading'>;

export type Props = BaseProps & {
  /**
   * The text to display as the dialog heading
   */
  children: React.ReactText;
};

const HeadingGroup: HeadingGroupType = React.forwardRef(function HeadingGroup(
  props: Props,
  ref: React.Ref<HTMLDivElement>
) {
  const { children, id, ...rest } = props;

  const { invert, color, variant, headingGroupId } = useDialogContext();

  return (
    <BaseHeadingGroup
      ref={ref}
      color={invert ? 'invertBody' : 'body'}
      css={getHeadingGroupCss({ color, variant })}
      heading={children}
      id={id || headingGroupId}
      {...rest}
    />
  );
});

export default HeadingGroup;
