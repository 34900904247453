import type { MutableRefObject } from 'react';
import * as React from 'react';

import type { DialogProps } from '@coursera/cds-core';
import { Dialog as CDSDialog } from '@coursera/cds-core';

import type { TrackingProps } from './BaseComponent';
import useVisibilityTracker from './useVisibilityTracker';

type OwnProps = Omit<TrackingProps<'view_modal'>, 'trackingOptions'>;
type Props = OwnProps & Omit<DialogProps, keyof OwnProps>;

/**
 * Dialog is a wrapper around CDS Dialog that tracks view modal events.
 */
const Dialog = ({ trackingData, ...props }: Props) => {
  const ref: MutableRefObject<HTMLDivElement | null> = useVisibilityTracker('view_modal', trackingData);

  return <CDSDialog {...props} ref={ref} />;
};

Dialog.Actions = CDSDialog.Actions;
Dialog.Content = CDSDialog.Content;
Dialog.HeadingGroup = CDSDialog.HeadingGroup;

export type { Props };

export default Dialog;
