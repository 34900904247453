export const ProfessionalCertificateS12n = 'ProfessionalCertificateS12n';
export const NormalS12n = 'NormalS12n';
/**
 * This S12n is an hybrid specifically for (ISC)2 certificate
 * Learners finish the Coursera program and then take the exam through (ISC)2 to gain the Certificate through (ISC)2.
 * Since this program aligns to an external certificate, it's considered a "Professional Certificate".
 * However, (ISC)2 does not want it to say "Professional Certificate" on the title.
 * https://coursera.slack.com/archives/C04PHNQAL/p1648067641940959?thread_ts=1648058204.861219&cid=C04PHNQAL
 * */
export const ExternalCertificateS12n = 'ExternalCertificateS12n';
export const GoogleCertificateS12n = 'GoogleCertificateS12n';

export type S12nProductVariant =
  | typeof NormalS12n
  | typeof ProfessionalCertificateS12n
  | typeof ExternalCertificateS12n
  | typeof GoogleCertificateS12n;

export const s12nProductVariants = [
  NormalS12n,
  ProfessionalCertificateS12n,
  ExternalCertificateS12n,
  GoogleCertificateS12n,
];

export default {
  ProfessionalCertificateS12n,
  NormalS12n,
  ExternalCertificateS12n,
  GoogleCertificateS12n,
};
