import { buildDegreeUrlRelative, degreeUrlRoot } from 'bundles/common/utils/urlUtils';
import {
  DEGREES_SECTION_TYPE,
  DUAL_COLUMN_CONTENT_TYPE,
  SECTION_VIEW_ALL_BUTTON_TYPE,
} from 'bundles/megamenu/constants/constants';

import _t from 'i18n!nls/megamenu';

export const getMockedMoreDegreesData = () => ({
  sectionName: _t('More Degrees'),
  sectionType: DEGREES_SECTION_TYPE,
  contentType: DUAL_COLUMN_CONTENT_TYPE,
  sectionItems: [
    {
      url: buildDegreeUrlRelative('public-health'),
      name: _t('Public Health'),
    },
    {
      url: buildDegreeUrlRelative('bachelors'),
      name: _t("Bachelor's Degrees"),
    },
    {
      url: buildDegreeUrlRelative('computer-science'),
      name: _t('Engineering'),
    },
    {
      url: buildDegreeUrlRelative('masters'),
      name: _t("Master's Degrees"),
    },
    {
      url: buildDegreeUrlRelative('social-sciences'),
      name: _t('Social Sciences'),
    },
  ],
  viewAllText: _t('View all degrees'),
  viewAllUrl: `/${degreeUrlRoot}`,
  viewAllType: SECTION_VIEW_ALL_BUTTON_TYPE,
});

// curated from https://docs.google.com/spreadsheets/d/1XD3F0MVGK9t-uEz71HfJzmi5Xu13LHJ76LHRVR3gKJA/edit#gid=0
export const degreeViewAllLinkData: { [key: string]: string | null } = {
  'data-science': buildDegreeUrlRelative('data-science'),
  business: buildDegreeUrlRelative('business'),
  'computer-science': buildDegreeUrlRelative('computer-science'),
  'information-technology': buildDegreeUrlRelative('computer-science'),
  'language-learning': null,
  'life-sciences': buildDegreeUrlRelative('public-health'),
  'personal-development': null,
  'physical-science-and-engineering': buildDegreeUrlRelative('computer-science'),
  'social-sciences': buildDegreeUrlRelative('social-sciences'),
  'arts-and-humanities': null,
  'math-and-logic': null,
};

export const mastertrackSlugUpsellOnDegreesTabData = ['big-data-asu', 'innovation-management-entrepreneurship-hec'];
