import { classes } from '@core/cards/ProductCard/getProductCardCss';

type Props = {
  children: NonNullable<React.ReactNode>;
};

const CardFooter = ({ children }: Props) => {
  return <div className={classes.footer}>{children}</div>;
};

export default CardFooter;
