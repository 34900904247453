import base64 from 'base-64';

import rebrandLogoSvg from 'bundles/page/assets/rebrandLogoSvg';

const FILL_REGEXP = /fill="(#[a-f0-9]+)"/;
const FILL_REGEXP_GLOBAL = /fill="(#[A-Fa-f0-9]+)"/g;
const DEFAULT_HEX_COLOR_CODE = FILL_REGEXP.exec(rebrandLogoSvg)?.[1];

/**
 * @param  {String} [hexColorCode]
 * @return {String} base64 encoding of the logo
 */

export const getBase64 = (hexColorCode: string, svgLogo?: string): string => {
  const changeColor = hexColorCode && hexColorCode !== DEFAULT_HEX_COLOR_CODE;
  const logoSvg = svgLogo || rebrandLogoSvg;
  let svg = changeColor ? logoSvg.replace(FILL_REGEXP_GLOBAL, `fill="${hexColorCode}"`) : logoSvg;

  if (svgLogo) {
    // match any a-z or 0-9 case insensitive character inside of fill="" with an optional # at the beginning
    // allows us to catch fill="none"` or `fill="purple", for instance
    const EXPANSIVE_FILL_REGEXP_GLOBAL = /fill="(#?[a-z0-9]+)"/gi;
    svg = logoSvg.replace(EXPANSIVE_FILL_REGEXP_GLOBAL, `fill="${hexColorCode}"`);
  }

  return base64.encode(svg);
};

export default { getBase64 };
