import React from 'react';

import clsx from 'clsx';

import FocusTrap from '@core/Modal/FocusTrap';

import type { Props as FocusTrapProps } from './FocusTrap';
import { classes as modalClasses } from './modalCss';

type BaseProps = FocusTrapProps & React.ComponentPropsWithRef<'div'>;

export type Props = BaseProps & {
  onBackdropClick: React.MouseEventHandler;
};

const ModalContainer = React.forwardRef(function AriaModal(
  props: Props,
  ref: React.Ref<HTMLDivElement>
) {
  // suppress MUI injected tabIndex to allow text selection inside FocusLock
  const {
    children,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    tabIndex,
    initialFocusRef,
    returnFocusRef,
    disableFocusLock,
    className,
    onBackdropClick,
    ...rest
  } = props;

  const backdropClickHandler: React.MouseEventHandler = (event) => {
    const textSelection = window.getSelection()?.toString();

    if (event.currentTarget === event.target && textSelection === '') {
      onBackdropClick(event);
    }
  };

  return (
    <div
      ref={ref}
      className={clsx(className, modalClasses.container)}
      onClick={backdropClickHandler}
      {...rest}
    >
      <FocusTrap
        disableFocusLock={disableFocusLock}
        initialFocusRef={initialFocusRef}
        returnFocusRef={returnFocusRef}
      >
        {children}
      </FocusTrap>
    </div>
  );
});

export default ModalContainer;
