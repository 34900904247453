import React from 'react';

import EmotionCacheProvider from '@common/components/EmotionCacheProvider/EmotionCacheProvider';
import createEmotionCache from '@common/theme/createEmotionCache';

type Props = {
  children?: React.ReactNode;
  direction?: 'ltr' | 'rtl';
};

const ClientSideEmotionCacheProvider = ({ direction, children }: Props) => {
  if (typeof window === 'undefined') {
    return <React.Fragment>{children}</React.Fragment>;
  }

  const { cache } = createEmotionCache(direction);
  return <EmotionCacheProvider value={cache}>{children}</EmotionCacheProvider>;
};

export default ClientSideEmotionCacheProvider;
