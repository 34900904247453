// For more info about supported currencies: https://coursera.atlassian.net/wiki/spaces/GROW/pages/282984479/Geo-Pricing+and+Local+Currency
// Currency decimal places defined in ISO 4217: https://en.wikipedia.org/wiki/ISO_4217
export const currencies = [
  {
    id: 36,
    code: 'AUD',
    symbol: 'A$',
    decimalPlaces: 2,
  },
  {
    id: 124,
    code: 'CAD',
    symbol: '$',
    decimalPlaces: 2,
  },
  {
    id: 156,
    code: 'CNY',
    symbol: '¥',
    decimalPlaces: 2,
  },
  {
    id: 208,
    code: 'DKK',
    symbol: 'kr',
    decimalPlaces: 2,
  },
  {
    id: 344,
    code: 'HKD',
    symbol: '$',
    decimalPlaces: 2,
  },
  {
    id: 356,
    code: 'INR',
    symbol: '₹',
    decimalPlaces: 2,
  },
  {
    id: 360,
    code: 'IDR',
    symbol: 'Rp',
    decimalPlaces: 2,
  },
  {
    id: 376,
    code: 'ILS',
    symbol: '₪',
    decimalPlaces: 2,
  },
  {
    id: 392,
    code: 'JPY',
    symbol: '¥',
    decimalPlaces: 0,
  },
  {
    id: 484,
    code: 'MXN',
    symbol: '$',
    decimalPlaces: 2,
  },
  {
    id: 554,
    code: 'NZD',
    symbol: '$',
    decimalPlaces: 2,
  },
  {
    id: 578,
    code: 'NOK',
    symbol: 'kr',
    decimalPlaces: 2,
  },
  {
    id: 643,
    code: 'RUB',
    symbol: '₽',
    decimalPlaces: 2,
  },
  {
    id: 682,
    code: 'SAR',
    symbol: '﷼',
    decimalPlaces: 2,
  },
  {
    id: 702,
    code: 'SGD',
    symbol: 'S$',
    decimalPlaces: 2,
  },
  {
    id: 710,
    code: 'ZAR',
    symbol: 'R',
    decimalPlaces: 2,
  },
  {
    id: 752,
    code: 'SEK',
    symbol: 'kr',
    decimalPlaces: 2,
  },
  {
    id: 756,
    code: 'CHF',
    symbol: '',
    decimalPlaces: 2,
  },
  {
    id: 784,
    code: 'AED',
    symbol: 'د.إ',
    decimalPlaces: 2,
  },
  {
    id: 826,
    code: 'GBP',
    symbol: '£',
    decimalPlaces: 2,
  },
  {
    id: 840,
    code: 'USD',
    symbol: '$',
    decimalPlaces: 2,
  },
  {
    id: 901,
    code: 'TWD',
    symbol: 'NT$',
    decimalPlaces: 2,
  },
  {
    id: 949,
    code: 'TRY',
    symbol: 'TL',
    decimalPlaces: 2,
  },
  {
    id: 978,
    code: 'EUR',
    symbol: '€',
    decimalPlaces: 2,
  },
  {
    id: 986,
    code: 'BRL',
    symbol: 'R$',
    decimalPlaces: 2,
  },
  {
    id: 818,
    code: 'EGP',
    symbol: '£',
    decimalPlaces: 2,
  },
  {
    id: 50,
    code: 'BDT',
    symbol: '৳',
    decimalPlaces: 2,
  },
  {
    id: 975,
    code: 'BGN',
    symbol: 'лв',
    decimalPlaces: 2,
  },
  {
    id: 68,
    code: 'BOB',
    symbol: 'Bs.',
    decimalPlaces: 2,
  },
  {
    id: 152,
    code: 'CLP',
    symbol: '$',
    decimalPlaces: 0,
  },
  {
    id: 170,
    code: 'COP',
    symbol: '$',
    decimalPlaces: 2,
  },
  {
    id: 188,
    code: 'CRC',
    symbol: '₡',
    decimalPlaces: 2,
  },
  {
    id: 203,
    code: 'CZK',
    symbol: 'Kč',
    decimalPlaces: 2,
  },
  {
    id: 12,
    code: 'DZD',
    symbol: 'د.ج',
    decimalPlaces: 2,
  },
  {
    id: 936,
    code: 'GHS',
    symbol: '₵',
    decimalPlaces: 2,
  },
  {
    id: 191,
    code: 'HRK',
    symbol: 'Kn',
    decimalPlaces: 2,
  },
  {
    id: 348,
    code: 'HUF',
    symbol: 'Ft',
    decimalPlaces: 2,
  },
  {
    id: 368,
    code: 'IQD',
    symbol: 'ع.د',
    decimalPlaces: 3,
  },
  {
    id: 400,
    code: 'JOD',
    symbol: 'د.ا',
    decimalPlaces: 3,
  },
  {
    id: 404,
    code: 'KES',
    symbol: 'Sh',
    decimalPlaces: 2,
  },
  {
    id: 410,
    code: 'KRW',
    symbol: '₩',
    decimalPlaces: 0,
  },
  {
    id: 398,
    code: 'KZT',
    symbol: '〒',
    decimalPlaces: 2,
  },
  {
    id: 144,
    code: 'LKR',
    symbol: 'Rs',
    decimalPlaces: 2,
  },
  {
    id: 504,
    code: 'MAD',
    symbol: 'د.م.',
    decimalPlaces: 2,
  },
  {
    id: 104,
    code: 'MMK',
    symbol: 'K',
    decimalPlaces: 0,
  },
  {
    id: 446,
    code: 'MOP',
    symbol: 'P',
    decimalPlaces: 2,
  },
  {
    id: 458,
    code: 'MYR',
    symbol: 'RM',
    decimalPlaces: 2,
  },
  {
    id: 566,
    code: 'NGN',
    symbol: '₦',
    decimalPlaces: 2,
  },
  {
    id: 604,
    code: 'PEN',
    symbol: 'S/.',
    decimalPlaces: 2,
  },
  {
    id: 608,
    code: 'PHP',
    symbol: '₱',
    decimalPlaces: 2,
  },
  {
    id: 586,
    code: 'PKR',
    symbol: '₨',
    decimalPlaces: 2,
  },
  {
    id: 985,
    code: 'PLN',
    symbol: 'zł',
    decimalPlaces: 2,
  },
  {
    id: 600,
    code: 'PYG',
    symbol: '₲',
    decimalPlaces: 0,
  },
  {
    id: 634,
    code: 'QAR',
    symbol: 'ر.ق',
    decimalPlaces: 2,
  },
  {
    id: 946,
    code: 'RON',
    symbol: 'L',
    decimalPlaces: 2,
  },
  {
    id: 941,
    code: 'RSD',
    symbol: 'din',
    decimalPlaces: 2,
  },
  {
    id: 764,
    code: 'THB',
    symbol: '฿',
    decimalPlaces: 2,
  },
  {
    id: 834,
    code: 'TZS',
    symbol: 'Sh',
    decimalPlaces: 2,
  },
  {
    id: 980,
    code: 'UAH',
    symbol: '₴',
    decimalPlaces: 2,
  },
  {
    id: 704,
    code: 'VND',
    symbol: '₫',
    decimalPlaces: 0,
  },
];

type Currency = {
  id: number;
  code: string;
  symbol: string;
  decimalPlaces: number;
};

export const currencyIdMap = currencies.reduce<{ [id: number]: Currency }>((map, currency) => {
  map[currency.id] = currency;
  return map;
}, {});

export const currencyCodeMap = currencies.reduce<{ [code: string]: Currency }>((map, currency) => {
  map[currency.code] = currency;
  return map;
}, {});
