export const TYPENAME_PREFIX = 'Naptime';

export const MUTATIONS = Object.freeze({
  create: 'POST',
  update: 'PUT',
  patch: 'PATCH',
  delete: 'DELETE',
  action: 'POST',
});

export const SINGLE_ELEMENT_ACTIONS = {
  get: true,
  ...MUTATIONS,
};
