import CardPreview from '@core/cards/common/CardPreview';
import { classes } from '@core/cards/ProductCard/getProductCardCss';
import type { ProductCardImage } from '@core/cards/ProductCard/types';

import CardStatusTags from './CardStatusTags';

type Props = {
  statusTags?: string[];
} & ProductCardImage;

/**
 * Renders the preview + optional tags overlayed on top of the preview
 */
const CardPreviewGrid = (props: Props) => {
  const { imageSrc, statusTags, renderImage } = props;
  const hasImage = !!imageSrc || !!renderImage;

  return (
    <div className={classes.gridPreviewContainer}>
      <CardPreview renderImage={renderImage} src={imageSrc} />

      {statusTags && (
        <CardStatusTags shouldOverlay={hasImage} tags={statusTags} />
      )}
    </div>
  );
};
export default CardPreviewGrid;
