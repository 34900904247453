import { graphql } from 'react-apollo';
import type { OptionProps } from 'react-apollo';

import { compose } from 'recompose';

import type { Domain } from 'bundles/browse/components/types/MegaMenu';
import type { ProfessionalCertificateElement } from 'bundles/browse/components/types/ProfessionalCertificate';
import MegaMenuQuery from 'bundles/page-header/components/queries/MegaMenu';
import type { MegaMenuQuery as MegaMenuQueryType } from 'bundles/page-header/components/queries/__generated__/MegaMenuQuery';
import { parseDomains, parseMegaMenuData, parseProfessionalCertificates } from 'bundles/page/utils/requestUtils';

type QueryData = {
  data?: MegaMenuQueryType;
  renderMenuOnServer: boolean;
};

type PropsToComponent = {
  domains: Array<Domain>;
  professionalCertificates: Array<ProfessionalCertificateElement>;
};

const withHeaderQuery = compose<PropsToComponent, {}>(
  graphql(MegaMenuQuery, {
    options: ({ renderMenuOnServer }) => ({
      fetchPolicy: 'cache-first',
      ssr: renderMenuOnServer,
    }),
    props: ({ data }: OptionProps<QueryData>) => {
      return {
        domains: data && parseDomains(data.DomainsV1Resource),
        professionalCertificates: data && parseProfessionalCertificates(data.ProfessionalCertsV1Resource),
        megaMenuAPIData:
          data && parseMegaMenuData(data.ExternallyAccessibleNostosV1Resource, data.MegamenuPopularSkills),
      };
    },
  })
);

export default withHeaderQuery;
