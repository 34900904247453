import * as React from 'react';
import { Query } from 'react-apollo';

import type { CountryCode, LanguageCode } from '__generated__/graphql-types';
import { print } from 'graphql/language/printer';

import getLocalizedDataQuery from 'bundles/internationalization-lib/api/LocalizedDataQuery.graphql';
import type {
  GetLocalizedDataQuery,
  GetLocalizedDataQueryVariables,
} from 'bundles/internationalization-lib/api/__generated__/LocalizedDataQuery';
import ErrorMessage from 'bundles/internationalization-lib/components/ErrorMessage';
import { getContentfulQuery } from 'bundles/internationalization-lib/utils/getContenfulQuery';
import subdomainCountryCode from 'bundles/internationalization-lib/utils/subdomainCountryCode';

type Props<DataOutputType> = {
  isPreview: boolean;
  contentType: string;
  fragmentDoc: $TSFixMe; // this type should be a AST document from graphql, TS is supported in V3 of apollo but does not seem to be in v2
  fragmentFieldsName: string;
  children: (props: { loading: boolean; data?: DataOutputType | null }) => JSX.Element | null;
  fallbackComponent?: () => JSX.Element;
  countryCode?: CountryCode;
  languageCode?: LanguageCode;
  filters?: Record<string, string>; // this is used to make the query more specific.
  contentfulSpace?: string;
};

export const getLocalizedQueryVariables = ({
  isPreview,
  contentType,
  fragmentDoc,
  fragmentFieldsName,
  countryCode = (subdomainCountryCode.get()?.toUpperCase() || 'global') as CountryCode,
  languageCode = 'global',
  filters,
  contentfulSpace,
}: Omit<Props<{}>, 'children'>) => {
  // gets the country code from the url
  const queryDocument = getContentfulQuery(contentType, fragmentDoc, fragmentFieldsName, filters);

  if (!queryDocument) {
    return undefined;
  }

  const query = print(queryDocument); // the queryDocument is an AST document, the print methods turns it into a string with the fragments so that it can be sent in the url;
  return {
    contentfulRequest: { query, variables: { isPreview, countryCode, languageCode, contentType }, contentfulSpace },
  };
};

export const getLocalizedQueryOption = (Props: Omit<Props<{}>, 'children'>) => ({
  query: getLocalizedDataQuery,
  variables: getLocalizedQueryVariables(Props),
  context: { clientName: 'gatewayGql' },
});

export const WithLocalizedContent = <DataOutputType extends {}>({
  children,
  fallbackComponent = () => <ErrorMessage />,
  ...rest
}: Props<DataOutputType>) => {
  const localizedQueryOptions = getLocalizedQueryOption(rest);
  if (!localizedQueryOptions) {
    return fallbackComponent();
  }

  return (
    <Query<GetLocalizedDataQuery, GetLocalizedDataQueryVariables> {...localizedQueryOptions}>
      {({ loading, data, error }) => {
        // return loading response if still loading
        if (loading) {
          return children({ loading: true, data: null });
        }

        // return error component if error is returned or if there is no data to parse
        const contentData = data?.LocalizedData?.getLocalizedData?.contentData;
        if (error || !contentData) {
          return fallbackComponent();
        }

        // return the data if valid
        return children({
          data: JSON.parse(contentData) as DataOutputType,
          loading: false,
        });
      }}
    </Query>
  );
};
