import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';

export type IconColor =
  | 'default'
  | 'support'
  | 'invert'
  | 'interactive'
  | 'warning'
  | 'error'
  | 'success';

export const getIconColor = (color: IconColor): string | undefined => {
  const colorMap: Record<IconColor, string | undefined> = {
    default: 'var(--cds-color-neutral-primary)',
    support: 'var(--cds-color-neutral-primary-weak)',
    invert: 'var(--cds-color-neutral-primary-invert)',
    interactive: 'var(--cds-color-interactive-primary)',
    warning: 'var(--cds-color-feedback-warning)',
    error: 'var(--cds-color-feedback-error)',
    success: 'var(--cds-color-feedback-success)',
  };

  return colorMap[color];
};

export const transformForRtlCss = (flipForRTL: boolean) => css`
  transform: ${flipForRTL ? 'scale(-1, 1)' : 'none'};
`;

export default (color: IconColor): SerializedStyles => css`
  color: ${getIconColor(color)};
`;
