import { CardTitle } from '@core/cards/common';
import { classes } from '@core/cards/ProductCard/getProductCardCss';

import CardBody from './card/CardBody';
import CardContent from './card/CardContent';
import CardFooter from './card/CardFooter';
import CardHeader from './card/CardHeader';
import CardPartnerInfo from './card/CardPartnerInfo';
import CardPreviewGrid from './card/CardPreviewGrid';
import type { ProductCardProps } from './types';

type Props = {
  'aria-label': string;
  titleId: string;
  partnerInfoId?: string;
} & ProductCardProps;

/**
 * Grid variant of the ProductCard
 */
const ProductGridCard = (props: Props) => {
  const {
    previewImageSrc,
    renderPreviewImage,
    statusTags,
    partners,
    title,
    'aria-label': ariaLabel,
    'aria-labelledby': ariaLabelledby,
    body,
    footer,
    titleId,
    partnerInfoId,
  } = props;

  const shouldShowPreview = previewImageSrc || renderPreviewImage || statusTags;

  return (
    <div className={classes.gridCard}>
      {shouldShowPreview && (
        <CardPreviewGrid
          imageSrc={previewImageSrc}
          renderImage={renderPreviewImage}
          statusTags={statusTags}
        />
      )}
      <CardContent>
        <CardHeader>
          {partners && (
            <CardPartnerInfo id={partnerInfoId} partners={partners} />
          )}
          {title && (
            <CardTitle
              aria-label={ariaLabel}
              aria-labelledby={ariaLabelledby}
              component={title?.component}
              customLinkProps={title.customLinkProps}
              id={titleId}
              linkProps={title.linkProps}
              name={title.name}
            />
          )}
        </CardHeader>
        {body && <CardBody>{body}</CardBody>}
        {footer && <CardFooter>{footer}</CardFooter>}
      </CardContent>
    </div>
  );
};

export default ProductGridCard;
