import gql from 'graphql-tag';

export const MegaMenuQuery = gql`
  query MegaMenuQuery {
    DomainsV1Resource {
      domains: getAll {
        elements {
          id
          slug
          name
          description
          backgroundImageUrl
          subdomains {
            elements {
              id
              slug
              name
              domainId
              description
            }
          }
        }
      }
    }
    ProfessionalCertsV1Resource {
      getAll {
        elements {
          id
          name
          slug
          tagline
          partnerSquareLogo
          thumbnail
        }
      }
    }
    ExternallyAccessibleNostosV1Resource {
      getAllProperties(job_name: "megamenu_nostos_job", keys: "megamenu_nostos_key") {
        elements {
          id
          content
        }
      }
    }
    MegamenuPopularSkills: ExternallyAccessibleNostosV1Resource {
      getAllProperties(
        job_name: "megamenu_popular_terms_by_domain"
        keys: """
        data-science,business,computer-science,information-technology,
        language-learning,life-sciences,personal-development,physical-science-and-engineering,
        social-sciences,arts-and-humanities,math-and-logic
        """
      ) {
        elements {
          id
          content
        }
      }
    }
  }
`;

export default MegaMenuQuery;
