import type * as React from 'react';
import { graphql } from 'react-apollo';
import type { QueryOpts } from 'react-apollo';

import type { FetchPolicy } from 'apollo-client';

import { apolloFetchPolicy } from 'js/utils/apolloFetchPolicy';

import type {
  PremiumProductCollectionsQuery as GraphqlData,
  PremiumProductCollectionsQueryVariables as GraphqlVariables,
  PremiumProductCollections_LearningProductFragment as LearningProduct,
  PremiumProductCollections_LearningProductPartnerFragment as LearningProductPartner,
} from 'bundles/browse/components/Domain/queries/__generated__/getPremiumProductCollectionsByDomainQuery';
import getPremiumProductCollectionsByDomain from 'bundles/browse/components/Domain/queries/getPremiumProductCollectionsByDomainQuery.graphql';
import { PRODUCT_TYPE_NAMES, ProductVariantNames } from 'bundles/browse/constants';
import type { DegreeCardDegreeProps, DegreeProductMember, Partner } from 'bundles/browse/types/degree-list';
import {
  buildCertificatesUrlAbsolute,
  buildDegreeUrlAbsolute,
  buildMastertrackUrlAbsolute,
  buildS12nsUrlAbsolute,
} from 'bundles/common/utils/urlUtils';
import DegreesDiscoveryExperiments from 'bundles/epic/clients/DegreesDiscovery';

export type PremiumProductProps = DegreeProductMember & DegreeCardDegreeProps;

export type ChildProps = {
  degreeListFromPremiumProductsCollection?: PremiumProductProps[];
  mastertrackListFromPremiumProductsCollection?: PremiumProductProps[];
  universityCertificateListFromPremiumProductsCollection?: PremiumProductProps[];
  premiumProductsCollectionLoading?: boolean;
};

type GraphqlChildProps = ChildProps;

export const allProductVariants = [
  ProductVariantNames.BachelorsDegree,
  ProductVariantNames.MastersDegree,
  ProductVariantNames.Mastertrack,
  ProductVariantNames.GraduateCertificate,
  ProductVariantNames.UniversityCertificate,
];

export const degreeProductVariants = [ProductVariantNames.BachelorsDegree, ProductVariantNames.MastersDegree];

export const defaultVariables = {
  domains: null,
  productVariants: allProductVariants,
};

export const defaultOptions = {
  ...apolloFetchPolicy({ cacheForLoggedOutOnly: false }),
  query: getPremiumProductCollectionsByDomain,
  variables: defaultVariables,
  errorPolicy: 'all' as const,
  context: { clientName: 'gatewayGql' },
};

export const buildProgramAbsoluteUrl = (slug: string, programType: string): string => {
  switch (programType) {
    case PRODUCT_TYPE_NAMES.DEGREE:
      return buildDegreeUrlAbsolute(slug);
    case PRODUCT_TYPE_NAMES.CERTIFICATE:
      return buildCertificatesUrlAbsolute(slug);
    case PRODUCT_TYPE_NAMES.SPECIALIZATION:
      return buildS12nsUrlAbsolute(slug);
    default:
      return buildMastertrackUrlAbsolute(slug);
  }
};

export function filterNullOrUndefined<T>(x: T | null | undefined): x is T {
  return !!x;
}

function learningProductPartnerToDegreeListPartner(partner: LearningProductPartner): Partner {
  return {
    ...partner,
    squareLogo: partner.logo,
  };
}

export const getDegreeProductWithLinkAndPartners = (
  degreeProduct: LearningProduct,
  productType: string
): PremiumProductProps => {
  const primaryPartner = degreeProduct.partners?.[0];
  return {
    ...degreeProduct,
    marketingName: degreeProduct.name,
    partner: primaryPartner ? learningProductPartnerToDegreeListPartner(primaryPartner) : primaryPartner,
    partners: degreeProduct.partners
      ? {
          elements: degreeProduct.partners.filter(filterNullOrUndefined).map(learningProductPartnerToDegreeListPartner),
        }
      : undefined,
    type: productType,
    link: buildProgramAbsoluteUrl(degreeProduct.slug, productType),
    bannerImage: degreeProduct.imageUrl,
    partnerIds: [],
  };
};

const isDegree = (product: LearningProduct) => {
  // In the epic there are a list of Postgraduate Diploma we want to show
  const postgraduateDiplomaList = DegreesDiscoveryExperiments.get('pgdipsMarketedAsDegrees');
  return (
    product.productVariant === ProductVariantNames.BachelorsDegree ||
    product.productVariant === ProductVariantNames.MastersDegree ||
    (product.productVariant === ProductVariantNames.PostgraduateDiploma &&
      postgraduateDiplomaList.includes(product.slug))
  );
};

const isMastertrack = (product: LearningProduct) => product.productVariant === ProductVariantNames.Mastertrack;
const isUniversityCertificate = (product: LearningProduct) =>
  product.productVariant === ProductVariantNames.UniversityCertificate;

export const getPremiumProductCollectionsQueryOption = (overrideOptions: QueryOpts<GraphqlVariables>) => ({
  ...defaultOptions,
  query: getPremiumProductCollectionsByDomain,
  ...overrideOptions,
});

const withPremiumProductCollections =
  <P extends Record<string, unknown>>(
    getVariablesFromProps: (props: P) => GraphqlVariables & { ssr?: boolean; fetchPolicy?: FetchPolicy }
  ) =>
  (BaseComponent: React.ComponentType<ChildProps & P>) =>
    graphql<P, GraphqlData, GraphqlVariables, GraphqlChildProps>(getPremiumProductCollectionsByDomain, {
      options: (props) => {
        const { domains, productVariants, ssr, fetchPolicy } = getVariablesFromProps(props);

        const returnedOptions = {
          ...defaultOptions,
          variables: {
            domains: domains ?? defaultVariables.domains,
            productVariants: productVariants ?? defaultVariables.productVariants,
          },
          fetchPolicy,
          // Allows us to serve data immediately on SSR, which helps web crawlers rank our pages for SEO.
          // e.g. on Browse and the Front Page.
          ssr: ssr ?? false,
        };

        return returnedOptions;
      },
      props: ({ data }): ChildProps => {
        const premiumProductCollection = data?.PremiumProductCollections?.queryCollection;

        const premiumProductsRaw = premiumProductCollection || [];
        const premiumProducts: LearningProduct[] = premiumProductsRaw.filter(filterNullOrUndefined);

        const degreeList: Array<PremiumProductProps> = premiumProducts
          .filter((product) => isDegree(product))
          .map((product) => getDegreeProductWithLinkAndPartners(product, PRODUCT_TYPE_NAMES.DEGREE));

        const mastertrackList: DegreeProductMember[] = premiumProducts
          .filter((product) => isMastertrack(product))
          .map((product) => getDegreeProductWithLinkAndPartners(product, PRODUCT_TYPE_NAMES.MASTERTRACK));

        const universityCertificateList: DegreeProductMember[] = premiumProducts
          .filter((product) => isUniversityCertificate(product))
          .map((product) => getDegreeProductWithLinkAndPartners(product, PRODUCT_TYPE_NAMES.CERTIFICATE));

        const isLoading = data?.loading;
        return {
          degreeListFromPremiumProductsCollection: degreeList,
          mastertrackListFromPremiumProductsCollection: mastertrackList,
          universityCertificateListFromPremiumProductsCollection: universityCertificateList,
          premiumProductsCollectionLoading: isLoading,
        };
      },
    })(BaseComponent);

export default withPremiumProductCollections;
