import { css } from '@emotion/react';

import { typography } from '@coursera/cds-common';

import type { TypographyProps } from '@core/Typography';
import generateEmotionClasses from '@core/utils/generateEmotionClasses';

type TypographyColor = NonNullable<TypographyProps['color']>;

export const classes = generateEmotionClasses('Typography', ['base']);

export const getColor = (typographyColor: TypographyColor = 'body'): string => {
  const colors: Record<TypographyColor, string> = {
    inherit: 'inherit',
    body: 'var(--cds-color-neutral-primary)',
    invertBody: 'var(--cds-color-neutral-primary-invert)',
    supportText: 'var(--cds-color-neutral-primary-weak)',
    primaryHeadline: 'var(--cds-color-callouts-secondary)',
    error: 'var(--cds-color-feedback-error)',
    success: 'var(--cds-color-feedback-success)',
    highlightBlue: 'var(--cds-color-callouts-tertiary)',
    highlightPurple: 'var(--cds-color-callouts-secondary)',
    eyebrowYellow: 'var(--cds-color-callouts-secondary-invert)',
    eyebrowAqua: 'var(--cds-color-callouts-tertiary-invert)',
    eyebrowPurple: 'var(--cds-color-callouts-secondary)',
  };

  return colors[typographyColor];
};

export default ({ variant = 'body1', color = 'body' }: TypographyProps) => css`
  color: ${getColor(color)};
  ${typography[variant]};

  strong {
    /* stylelint-disable-next-line cds-stylelint/cds-design-tokens */
    font-weight: var(--cds-font-weight-700);
  }
`;
