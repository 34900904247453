/* eslint-disable @typescript-eslint/no-use-before-define, no-bitwise */

declare global {
  interface Window {
    msCrypto?: Crypto;
  }
}

export const randomUUID = (): string => {
  const crypto = window.crypto ?? window.msCrypto;
  if (typeof crypto === 'undefined') {
    throw new Error('crypto is not available.');
  }

  if ('randomUUID' in crypto) {
    return crypto.randomUUID();
  }

  // Older browsers don't have the convenience API, but may have the underlying CSPRNG.
  return randomUUIDPolyfill();
};

export const randomUUIDPolyfill = (): string => {
  const crypto = window.crypto ?? window.msCrypto;
  if (typeof crypto === 'undefined') {
    throw new Error('crypto is not available.');
  }

  // Little polyfill based on the spec.
  // https://w3c.github.io/webcrypto/#Crypto-method-randomUUID
  if (!('getRandomValues' in crypto)) {
    throw new Error('crypto.getRandomValues() is not available.');
  }

  // The randomUUID method generates a new version 4 UUID and returns its namespace specific string representation as
  // described in section 3 of [RFC4122]. To generate a random UUID:

  // Let bytes be a byte sequence of length 16.
  const bytes = new Uint8Array(16);

  // Fill bytes with cryptographically secure random bytes.
  crypto.getRandomValues(bytes);

  // Set the 4 most significant bits of bytes[6], which represent the UUID version, to 0100.
  bytes[6] = (bytes[6] & 0b00001111) | 0b01000000;

  // Set the 2 most significant bits of bytes[8], which represent the UUID variant, to 10.
  bytes[8] = (bytes[8] & 0b00111111) | 0b10000000;

  // Return the string concatenation of «
  // hexadecimal representation of bytes[0], hexadecimal representation of bytes[1], hexadecimal representation of
  //   bytes[2], hexadecimal representation of bytes[3],
  // "-",
  // hexadecimal representation of bytes[4], hexadecimal representation of bytes[5],
  // "-",
  // hexadecimal representation of bytes[6], hexadecimal representation of bytes[7],
  // "-",
  // hexadecimal representation of bytes[8], hexadecimal representation of bytes[9],
  // "-",
  // hexadecimal representation of bytes[10], hexadecimal representation of bytes[11], hexadecimal representation of
  //   bytes[12], hexadecimal representation of bytes[13], hexadecimal representation of bytes[14], hexadecimal
  //   representation of bytes[15]
  return (
    toHex(bytes[0]) +
    toHex(bytes[1]) +
    toHex(bytes[2]) +
    toHex(bytes[3]) +
    '-' +
    toHex(bytes[4]) +
    toHex(bytes[5]) +
    '-' +
    toHex(bytes[6]) +
    toHex(bytes[7]) +
    '-' +
    toHex(bytes[8]) +
    toHex(bytes[9]) +
    '-' +
    toHex(bytes[10]) +
    toHex(bytes[11]) +
    toHex(bytes[12]) +
    toHex(bytes[13]) +
    toHex(bytes[14]) +
    toHex(bytes[15])
  );

  // For the steps described in the algorithm to generate a random UUID, the hexadecimal representation of a byte value
  // is the two-character string created by expressing value in hexadecimal using ASCII lower hex digits, left-padded
  // with "0" to reach two ASCII lower hex digits.
  function toHex(value: number) {
    return ('0' + value.toString(16)).slice(-2);
  }
};
