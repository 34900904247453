import { stringKeyToTuple } from 'js/lib/stringKeyTuple';

export const courseraPlusProductVariants = {
  ANNUAL_NO_FREE_TRIAL: '-0etFmEeipcQojwuFOIA',
  ANNUAL_SPECIAL_DISCOUNTED_PRICING_NO_FREE_TRIAL: 'GMM31Io6RjODN9SKOuYz_A',
  ANNUAL_MIDCYCLE_UPGRADE: '45W7fykPRZCgF0_pP_uK3g',
  ANNUAL_SEVEN_DAY_FREE_TRIAL: '6kTHyIDqEeqBu_61xj9e0Q',
  MONTHLY_WITH_FREE_TRIAL: 'LbAwD6QzEeqeDWZSF5SmzA',
  MONTHLY_UPSELL_UPGRADE: 'rfUDqMi1T8OXIAi9tk2RTw',
  MONTHLY_MIDCYCLE_UPSELL_UPGRADE: 'NTRiMDM0OTEtMWIyNWRjZGY',
  MONTHLY_GATEWAY_UPSELL_49: 'rfUDqMi1T8OXIAi9tk2RTw',
  MONTHLY_GATEWAY_UPSELL_59: 'isehEwYeR3CUaB2NtBaHDQ',
} as const;

export const CourseraTierProductType = {
  COURSERA_TIER_LITE: 'COURSERA_TIER_LITE',
};

export const courseraLiteProductVariants = {
  MONTHLY_WITH_FREE_TRIAL: 'BcioFh8tQUakR39Fb0LKyQ',
} as const;

const cycleValue = {
  MONTHLY: 'monthly',
  ANNUAL: 'annual',
} as const;

type CourseraPlusProductVariantDetail = {
  cycle: (typeof cycleValue)[keyof typeof cycleValue];
  hasFreeTrial: boolean;
};

export const subscriptionTiersProductVariantDetails = (
  productVariant: SubscriptionTiersProductVariant
): CourseraPlusProductVariantDetail | null => {
  switch (productVariant) {
    case courseraPlusProductVariants.ANNUAL_NO_FREE_TRIAL:
    case courseraPlusProductVariants.ANNUAL_SPECIAL_DISCOUNTED_PRICING_NO_FREE_TRIAL:
    case courseraPlusProductVariants.ANNUAL_MIDCYCLE_UPGRADE:
      return { cycle: cycleValue.ANNUAL, hasFreeTrial: false };
    case courseraPlusProductVariants.ANNUAL_SEVEN_DAY_FREE_TRIAL:
      return { cycle: cycleValue.ANNUAL, hasFreeTrial: true };
    case courseraPlusProductVariants.MONTHLY_WITH_FREE_TRIAL:
    case courseraPlusProductVariants.MONTHLY_UPSELL_UPGRADE:
      return { cycle: cycleValue.MONTHLY, hasFreeTrial: true };
    case courseraPlusProductVariants.MONTHLY_GATEWAY_UPSELL_49:
    case courseraPlusProductVariants.MONTHLY_GATEWAY_UPSELL_59:
    case courseraPlusProductVariants.MONTHLY_MIDCYCLE_UPSELL_UPGRADE:
    case courseraLiteProductVariants.MONTHLY_WITH_FREE_TRIAL: // <--- Coursera Lite
      return { cycle: cycleValue.MONTHLY, hasFreeTrial: true };
    default:
      return null;
  }
};

export default courseraPlusProductVariants;

export type CourseraPlusProductVariant = (typeof courseraPlusProductVariants)[keyof typeof courseraPlusProductVariants];
export type CourseraLiteProductVariant = (typeof courseraLiteProductVariants)[keyof typeof courseraLiteProductVariants];

export type SubscriptionTiersProductVariant = CourseraPlusProductVariant | CourseraLiteProductVariant;

// Use this for contentful product variants only
export const isMonthlyWithFreeTrial = (itemId: SubscriptionTiersProductVariant): boolean => {
  return (
    itemId === courseraPlusProductVariants.MONTHLY_WITH_FREE_TRIAL ||
    itemId === courseraLiteProductVariants.MONTHLY_WITH_FREE_TRIAL
  );
};

export const isAnnualSevenDayFreeTrial = (itemId: CourseraPlusProductVariant): boolean => {
  return itemId === courseraPlusProductVariants.ANNUAL_SEVEN_DAY_FREE_TRIAL;
};

// the sku looks like  : CourseraPlusSubscription~LbAwD6QzEeqeDWZSF5SmzA
// we need the tail part only
export const getProductIdFromSku = (sku: string): CourseraPlusProductVariant | undefined => {
  return (stringKeyToTuple(sku)[1] as CourseraPlusProductVariant) ?? undefined;
};

export const isMonthly = (productSku: string): boolean => {
  const itemId = getProductIdFromSku(productSku);
  return itemId ? subscriptionTiersProductVariantDetails(itemId)?.cycle === cycleValue.MONTHLY : false;
};

export const isYearly = (productSku: string): boolean => {
  const itemId = getProductIdFromSku(productSku);
  return itemId ? subscriptionTiersProductVariantDetails(itemId)?.cycle === cycleValue.ANNUAL : false;
};

export const isMonthlyByVariantId = (id: SubscriptionTiersProductVariant): boolean => {
  return subscriptionTiersProductVariantDetails(id)?.cycle === cycleValue.MONTHLY;
};

export const isAnnualByVariantId = (id: SubscriptionTiersProductVariant): boolean => {
  return subscriptionTiersProductVariantDetails(id)?.cycle === cycleValue.ANNUAL;
};

export const hasFreeTrialByVariantId = (id: SubscriptionTiersProductVariant): boolean => {
  return subscriptionTiersProductVariantDetails(id)?.hasFreeTrial ?? false;
};
