import React from 'react';

import type { ForwardRefComponent } from '@coursera/cds-common';

import type { CommonContentProps } from '@core/dialogs/common/types';

import { useDialogContext } from './DialogContextProvider';
import { getContentCss } from './styles';

type ContentType = ForwardRefComponent<HTMLDivElement, Props>;

export type Props = CommonContentProps;

const Content: ContentType = React.forwardRef(function Content(
  props: Props,
  ref: React.Ref<HTMLDivElement>
) {
  const { children, ...rest } = props;

  const { color } = useDialogContext();

  return (
    <div ref={ref} css={getContentCss({ color })} {...rest}>
      {children}
    </div>
  );
});

export default Content;
