import clsx from 'clsx';

import type { BaseComponentProps } from '@coursera/cds-common';

import useHasImageLoadError from '@core/Avatar/useHasImageLoadError';

import getAvatarCss, { classes } from './getAvatarCss';

type Size = 24 | 36 | 64 | 120 | 200;

type ImageProps = {
  renderImage?: (imgProps: Props['imgProps']) => React.ReactElement;
  imgProps?: BaseComponentProps<'img'>;
};

const Image = ({ renderImage, imgProps }: ImageProps) => {
  const props = {
    ...imgProps,
    // manually set an empty alt because images must always have alt defined (at least empty)
    alt: imgProps?.alt || '',
  };

  if (renderImage) {
    return renderImage(props);
  }

  return <img {...props} />;
};

type InitialProps = {
  initial?: string;
};

const Initial = ({ initial = 'C' }: InitialProps) => {
  return (
    <div aria-hidden="true" className={classes.initial}>
      <span className={classes.initialText}>
        {/* even though we request 1 character for `initial` we should still slice it for safety */}
        {initial.trim().slice(0, 1)}
      </span>
    </div>
  );
};

export type Props = {
  /**
   * Whether this avatar represents a user or an organization.
   */
  variant: 'user' | 'organization';

  /**
   * Size of the avatar, used for both width and height dimensions.
   * @default 36
   */
  size?: Size;

  /**
   * A single uppercase character, that is used as a fallback when the
   * avatar image is either not provided or fails to load.
   *
   * @default 'C' (stands for Coursera)
   */
  initial?: string;

  /**
   * Whether the avatar should have a border.
   * @default false
   */
  showBorder?: boolean;

  /**
   * Props for the underlying DOM element. Provide an `alt` when necessary to
   * describe the image.
   */
  imgProps?: ImageProps['imgProps'];

  /**
   * Custom renderer for when the preview image has a custom need
   * such as lazy loading, or tracking. Note that we cannot handle the error fallback
   * for custom images and this needs to be handled by the implementation team.
   * See `useHasImageLoadError` hook we use here for how to do this.
   *
   * Accessibility: `aria-hidden=true` or an empty `alt` must be added for
   * custom images to hide decorative images from screenreaders
   * (https://www.w3.org/WAI/tutorials/images/decorative/)
   *
   * @example renderImage={(imgProps) => <LazyImg src={src} loading={isLoading} alt="" {...imgProps} />}
   */
  renderImage?: ImageProps['renderImage'];
} & BaseComponentProps<'div'>;

/**
 * An avatar is a visual representation of an user (circular thumbnail) or an organization (square thumbnail).
 *
 * See [Props](__storybookUrl__/components-data-display-avatar--default#props)
 */
const Avatar = (props: Props) => {
  const {
    showBorder,
    initial,
    size = 36,
    variant,
    renderImage,
    imgProps,
    className,
    ...rest
  } = props;

  const hasImgLoadError = useHasImageLoadError({
    src: imgProps?.src,
    srcSet: imgProps?.srcSet,
  });

  const showImage = !hasImgLoadError || !!renderImage;

  return (
    <div
      className={clsx(className, classes.base, {
        [classes.user]: variant === 'user',
        [classes.organization]: variant === 'organization',
        [classes.showBorder]: showBorder,
      })}
      css={getAvatarCss({ size })}
      {...rest}
    >
      {showImage && <Image imgProps={imgProps} renderImage={renderImage} />}

      {!showImage && <Initial initial={initial} />}
    </div>
  );
};

export default Avatar;
