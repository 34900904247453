import getNaptimeResourceMetadata from './getNaptimeResourceMetadata';

// Iterate through query doc and finds all occurencies of @naptime directive.
// If it found, add it's path to the result.
// Returns the array of found nodes with their path, resource, version and action.
// Result is sorted with deepest elements first.
// It is needed to know which nodes will be needed to be transformed when we receive
// response.
const findPatchedNodes = (node, path = []) => {
  let patchedNodes = [];
  const currentPath = node.kind === 'Field' ? [...path, node.name.value] : path;
  if (node.selectionSet) {
    patchedNodes = node.selectionSet.selections.reduce((r, n) => r.concat(findPatchedNodes(n, currentPath)), []);
  }
  const { resource, version, action } = getNaptimeResourceMetadata(node);
  if (resource) {
    patchedNodes.unshift({
      path: currentPath,
      resource,
      version,
      action,
    });
  }
  return patchedNodes;
};

export default findPatchedNodes;
