import React from 'react';

import { duration as muiDuration } from '@material-ui/core/styles';

type TransitionDuration = { enter: number; exit: number };

export const defaultDuration: TransitionDuration = {
  enter: muiDuration.enteringScreen,
  exit: muiDuration.leavingScreen,
};

/**
 * Controls the transition duration to use for the modal. If it is initially
 * rendered as `open`, the enter transition is disabled to prevent the modal from
 * transitioning in on page re-load or during hydration after SSR.
 *
 * If disabled, the enter transition will be restored when modal is closed.
 *
 */
export const useTransitionDuration = ({
  open,
}: {
  open: boolean;
}): TransitionDuration => {
  const [duration, setDuration] = React.useState(
    open ? { enter: 0, exit: defaultDuration.exit } : defaultDuration
  );

  React.useEffect(() => {
    if (duration.enter === 0 && !open) {
      setDuration(defaultDuration);
    }
  }, [duration.enter, open]);

  return duration;
};
