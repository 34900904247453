import type Envelope from '../envelope';

type Options = {
  batchUrl?: string;
  url?: string;
};

const DEFAULT_BATCH_URL = '/api/rest/v1/eventing/infobatch';
const DEFAULT_URL = '/api/rest/v1/eventing/info';

/**
 * Abstract base class for creating transport mechanisms to send events.
 * Subclasses are required to implement the specific method of sending events.
 */
abstract class BaseTransport {
  /**
   * The URL to send batched events to.
   *
   * @protected
   */
  protected declare batchUrl: string;

  /**
   * The URL to send events to.
   *
   * @protected
   */
  protected declare url: string;

  constructor(options: Options = {}) {
    this.batchUrl = options.batchUrl ?? DEFAULT_BATCH_URL;
    this.url = options.url ?? DEFAULT_URL;
  }

  /**
   * Send the event to the server.
   * Subclasses must implement this method to define the specific transport mechanism.
   *
   * @param data - The data to be sent to the server.
   * @returns A promise that resolves to the server response.
   */
  abstract request<TData extends Record<string, unknown>>(data: TData): Promise<Response>;

  /**
   * Unwrap the envelope and request the event to the server.
   *
   * @param envelope - The envelope containing the event data.
   * @returns A promise that resolves when the event has been queued.
   * @throws Will throw an error if the server response is not ok or if the request fails.
   */
  async send(envelope: Envelope): Promise<void> {
    await this.request(envelope.toJSON());
  }
}

export type { Options };
export { DEFAULT_BATCH_URL, DEFAULT_URL };
export default BaseTransport;
