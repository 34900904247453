import storage from 'bundles/common/utils/localStorageEx';

import Store from './base';

/**
 * Name of the index key used to store the list of all keys in the local storage.
 */
const INDEX = '__index__';

/**
 * A store implementation that uses local storage to persist key-value pairs.
 *
 * This store is useful for persisting data that needs to be available across
 * different sessions and page reloads. The data is stored in the browser's
 * local storage with a specified prefix.
 */
class LocalStorageStore extends Store {
  /**
   * The prefix used for all keys stored in the local storage.
   *
   * @static
   */
  static prefix = 'tracking';

  /**
   * Constructor for the LocalStorageStore.
   *
   * Throws an error if local storage is not available.
   */
  constructor() {
    if (!storage.isAvailable()) {
      throw new Error('[LocalStorageStore] LocalStorage is not available');
    }

    super();
  }

  /**
   * Clear the store by removing all keys. These keys are stored in the index.
   *
   * This method retrieves the list of keys from the index and removes each key
   * from the local storage. It then clears the index itself.
   */
  clear(): void {
    const index = this.get<string[]>(INDEX) ?? [];

    index.forEach((key) => this.remove(key));
  }

  /**
   * Get the value for the given key.
   *
   * This method retrieves the value associated with the given key from the
   * local storage. If the key does not exist, it returns undefined.
   *
   * @param key - The key whose value needs to be retrieved.
   * @returns The value associated with the key, or undefined if the key does not exist.
   */
  get<TData>(key: string): TData | undefined {
    return storage.getItem(`${LocalStorageStore.prefix}.${key}`, JSON.parse, undefined);
  }

  /**
   * Set the value for the given key. Store the key in the index.
   *
   * This method stores the given value in the local storage under the specified key.
   * It also updates the index to include the new key.
   *
   * @param key - The key for which the value needs to be set.
   * @param value - The value to be set for the given key.
   */
  set<TData>(key: string, value: TData): void {
    const index = this.get<string[]>(INDEX) ?? [];

    storage.setItem(`${LocalStorageStore.prefix}.${INDEX}`, [...new Set([...index, key])], JSON.stringify);
    storage.setItem(`${LocalStorageStore.prefix}.${key}`, value, JSON.stringify);
  }

  /**
   * Remove the value for the given key. Remove the key from the index.
   *
   * This method removes the value associated with the given key from the
   * local storage. It also updates the index to remove the key.
   *
   * @param key - The key whose value needs to be removed.
   */
  remove(key: string): void {
    const index = this.get<string[]>(INDEX) ?? [];

    storage.setItem(
      `${LocalStorageStore.prefix}.${INDEX}`,
      index.filter((item) => item !== key),
      JSON.stringify
    );
    storage.removeItem(`${LocalStorageStore.prefix}.${key}`);
  }
}

export default LocalStorageStore;
