const generateEmotionClasses = <T extends string>(
  componentName: string,
  ids: Array<T>
) => {
  const classes = {} as Record<T, string>;

  for (const id of ids) {
    classes[id] = `cds-${componentName}-${id}`;
  }

  return classes;
};

export default generateEmotionClasses;
