import config from 'js/app/config';

const exported = {
  config,
  timeout: 500,
  signup: 'signup',
  enroll: 'enroll',

  googleTagManager: {
    id: 'GTM-5JKLVK',
    dataLayerName: 'dataLayer',
    gtagName: 'gtag',
    url: 'https://www.googletagmanager.com/gtm.js?id=GTM-5JKLVK&l=dataLayer',
    // This is used for Meta Pixel testing only
    copyContainerUrl: 'https://www.googletagmanager.com/gtm.js?id=GTM-5TWWKN76&l=dataLayer',
  } as const,
};

export default exported;
export { config };

export const { timeout, signup, enroll, googleTagManager } = exported;
