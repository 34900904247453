import * as React from 'react';
import { IndexRoute, Redirect, Route } from 'react-router';

import user from 'js/lib/user';

import { Route as CourseraRoute } from '@coursera/react-router';

import loadOnRoute from 'bundles/common/components/loadOnRoute';
import FrontPageApplication from 'bundles/front-page/components/FrontPageApplication';
import NotFound from 'bundles/phoenix/components/NotFound';

const FrontPageWrapper = loadOnRoute(() => import('bundles/front-page/components/FrontPageWrapper'));

const routes = (
  <CourseraRoute
    handle={{
      tracking: {
        data: () => ({
          page: { segment: 'consumer' },
        }),
      },
    }}
  >
    <CourseraRoute
      path="/frontpage"
      component={FrontPageApplication}
      handle={{
        tracking: {
          data: () => ({
            page: { type: 'lohp' },
          }),
        },
      }}
    >
      <IndexRoute getComponent={FrontPageWrapper} />
    </CourseraRoute>

    <CourseraRoute
      path="/"
      component={FrontPageApplication}
      handle={{
        tracking: {
          data: () => ({
            page: { type: user.isAuthenticatedUser() ? 'lihp' : 'lohp' },
          }),
        },
      }}
    >
      <IndexRoute getComponent={FrontPageWrapper} />
      {/* <Route name="front-page" path="/frontpage" getComponent={FrontPageWrapper} /> */}
      <Route path="/in" getComponent={FrontPageWrapper} />
      <Route path="/en-IN" getComponent={FrontPageWrapper} />
      <Redirect from="/accomplishments" to="/" />
      <Redirect from="/notifications" to="/" />
      <Redirect from="/recommendations" to="/" />
      <Redirect from="/account-settings" to="/" />
      <Route path="*" component={NotFound} />
    </CourseraRoute>
  </CourseraRoute>
);

export default routes;
