import { omitBy } from 'lodash';

import * as user from 'js/lib/user';
import { randomUUID as uuid } from 'js/lib/uuid';

import type Client from './client';
import type Device from './device';
import type Events from './events';
import type Session from './session';

type App = string;

type Options<K extends keyof Events> = {
  app: App;
  client: Client;
  device: Device;
  key: K;
  session: Session;
  value: Events[K];
};

type EnvelopeJSON = {
  app: App;
  clientTimestamp: number;
  clientType: string;
  clientVersion: string;
  deviceId: string;
  guid: string;
  initialReferrer?: string;
  key: string;
  referrerUrl?: string;
  screenSize: string;
  sequence: number;
  url: string;
  userId?: string;
  value: unknown;
  visitId: string;
};

/**
 * Class to wrap the data sent to the server.
 *
 * This class encapsulates the event data that is sent to the server, including
 * information about the app, client, device, session, and event value. It also
 * provides a method to serialize the data to JSON format.
 */
class Envelope<K extends keyof Events = keyof Events> {
  /**
   * The app name.
   */
  declare app: App;

  /**
   * The client that sent the event.
   */
  declare client: Client;

  /**
   * The device that sent the event.
   */
  declare device: Device;

  /**
   * The key of the event.
   */
  declare key: K;

  /**
   * The sequence of the event.
   * @private
   */
  private declare readonly sequence: number;

  /**
   * The session of the event.
   */
  declare session: Session;

  /**
   * The value of the event.
   */
  declare value: Events[K];

  /**
   * Constructor for the Envelope class.
   *
   * @param options - An object containing the app, client, device, event key, session, and event value.
   */
  constructor(options: Options<K>) {
    this.app = options.app;
    this.client = options.client;
    this.device = options.device;
    this.key = options.key;
    // We generate the sequence in the constructor so that it is always
    // incremented when the envelope is created.
    this.sequence = options.session.getSequence();
    this.session = options.session;
    this.value = options.value;
  }

  /**
   * Serialize the envelope to JSON.
   *
   * This method converts the envelope data to a JSON object, removing any undefined or empty values.
   *
   * @returns A JSON representation of the envelope.
   */
  toJSON(): EnvelopeJSON {
    // Remove undefined or empty values
    return omitBy(
      {
        app: this.app,
        clientTimestamp: Date.now(),
        clientType: this.client.type,
        clientVersion: this.client.version,
        deviceId: this.device.uuid,
        guid: uuid(),
        initialReferrer: this.session.initialReferrer,
        // We introduce the `eventingv3.` prefix to the key for API compatibility reasons.
        key: `eventingv3.${this.key}`,
        referrerUrl: this.session.referrer,
        screenSize: this.device.screenSize,
        sequence: this.sequence,
        url: this.session.url,
        userId: user.get()?.id?.toString(),
        value: this.value,
        viewportSize: this.device.viewportSize,
        visitId: this.session.uuid,
      },
      (value: unknown) => !value
    ) as EnvelopeJSON;
  }
}

export default Envelope;
