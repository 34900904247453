export type RequestUrlInfo = {
  hostname: string;
  protocol: string;
};

export default function requestUrlInfo(): RequestUrlInfo {
  const { hostname, protocol } = window.location;
  return {
    hostname,
    protocol,
  };
}
