import * as React from 'react';

import ComplexMenuItem from 'bundles/megamenu/components/goal-item/ComplexMenuItem';
import SimpleMenuItem from 'bundles/megamenu/components/goal-item/SimpleMenuItem';
import type { MenuItem as MenuItemType } from 'bundles/megamenu/types/MenuItem';

const MegaMenuGoalItem: React.FunctionComponent<MenuItemType> = ({
  item,
  hitPosition,
  hideAnchorColor,
  isMobile,
  tabName,
  tabId,
  sectionName,
}) => {
  const { name, url, partnerLogo } = item;

  // Invalid Link Data
  if (!name || !url) return null;

  // Simple Link
  if (!partnerLogo)
    return (
      <SimpleMenuItem
        item={item}
        hideAnchorColor={hideAnchorColor}
        hitPosition={hitPosition}
        isMobile={isMobile}
        tabName={tabName}
        tabId={tabId}
        sectionName={sectionName}
      />
    );

  // Complicated Degree/Cert item
  return (
    <ComplexMenuItem
      item={item}
      hitPosition={hitPosition}
      tabName={tabName}
      tabId={tabId}
      sectionName={sectionName}
      isMobile={isMobile}
    />
  );
};

export default MegaMenuGoalItem;
