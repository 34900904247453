import cloneDeep from 'lodash/cloneDeep';

import { MUTATIONS, SINGLE_ELEMENT_ACTIONS } from './constants';
import getNaptimeResourceMetadata from './getNaptimeResourceMetadata';

// Generate @rest directive
const restDirective = (resourceTypename, path, action, isMutation) => {
  const result = {
    kind: 'Directive',
    name: {
      kind: 'Name',
      value: 'rest',
    },
    arguments: [
      {
        kind: 'Argument',
        name: {
          kind: 'Name',
          value: 'type',
        },
        value: {
          kind: 'StringValue',
          value: `${resourceTypename}Resource`,
          block: false,
        },
      },
      {
        kind: 'Argument',
        name: {
          kind: 'Name',
          value: 'path',
        },
        value: {
          kind: 'StringValue',
          value: path,
          block: false,
        },
      },
    ],
  };
  if ((action && MUTATIONS[action]) || isMutation) {
    result.arguments.push({
      kind: 'Argument',
      name: {
        kind: 'Name',
        value: 'method',
      },
      value: {
        block: false,
        kind: 'StringValue',
        value: MUTATIONS[action] || 'POST',
      },
    });
  }
  return result;
};

const filterFields = (node) =>
  node.name.value !== '__typename' && !(node.directives && node.directives.find((d) => d.name.value === 'naptime'));

// Collect fields from query to include them in `fields` param for Naptime API call.
const findFields = (actionNode) => {
  const fields = [];
  if (actionNode.selectionSet) {
    const { selections } = actionNode.selectionSet;
    const elementsNode = selections.find((s) => s.name.value === 'elements');
    if (elementsNode) {
      fields.push(...elementsNode.selectionSet.selections.filter(filterFields).map((s) => s.name.value));
    }
    const linkedNode = selections.find((s) => s.name.value === 'linked');
    if (linkedNode) {
      const linkedResources = linkedNode.selectionSet.selections.filter(filterFields);
      if (linkedResources) {
        const linkedFields = linkedResources.map((resourceNode) => {
          const resourceName = resourceNode.name.value.replace(/V(\d+)/, '.v$1');
          const resourceFields = resourceNode.selectionSet.selections.filter(filterFields).map((s) => s.name.value);
          return `${resourceName}(${resourceFields.join(',')})`;
        });
        fields.push(...linkedFields);
      }
    }
  }
  return fields;
};

/**
 * This function first converts all the action node arguments to rest link args and filter the input arg
 * it is used for the custom action mutation case because we do not want to include input in the url parameter
 * @param actionNode actionNode from getNaptimeResourceMetadata
 * @returns returns an array of arguments in the rest link args format, for exmaple "id={args.id}", without the "input" argument
 */
const convertActionNodeToRestArgsWithoutInput = (actionNode) => {
  const argumentsStrings = actionNode.arguments.map((argument) => argument.name.value);
  const argumentsStringsWithoutInput = argumentsStrings.filter((arg) => arg !== 'input');
  return argumentsStringsWithoutInput.map((arg) => `${arg}={args.${arg}}`);
};

// Recursively find nodes with @naptime directive and replace it with corresponding @rest
// directive.
export const patchNaptimeDirective = (originalNode, operationType) => {
  let node = originalNode;
  const { actionNode, resourceTypename, resource, version, action } = getNaptimeResourceMetadata(node);
  if (resource) {
    node = cloneDeep(originalNode);
    const isMutation = operationType === 'mutation';
    // Generate path for @rest directive. It includes list of fields from query.
    let path = `${resource}.v${version}`;
    const params = [];
    if (!MUTATIONS[action] && !isMutation) {
      params.push('{args}');
    }
    if (action !== 'create' && action !== 'multiGet') {
      if (SINGLE_ELEMENT_ACTIONS[action]) {
        path += '/{args.id}';
      } else if (isMutation) {
        params.push(`action=${action}`);
        convertActionNodeToRestArgsWithoutInput(actionNode).forEach((arg) => params.push(arg));
      } else {
        params.push(`q=${action}`);
      }
    }
    const fields = findFields(actionNode);
    if (fields.length > 0) {
      params.push(`fields=${fields.join(',')}`);
    }
    path += `?${params.join('&')}`;

    // Update original query. Move arguments and add @rest directive to node
    node.arguments = actionNode.arguments;
    node.directives[node.directives.findIndex((d) => d.name.value === 'naptime')] = restDirective(
      resourceTypename,
      path,
      action,
      isMutation
    );
    node.selectionSet = actionNode.selectionSet;
  }
  if (node.selectionSet) {
    return {
      ...node,
      selectionSet: {
        ...node.selectionSet,
        selections: node.selectionSet.selections.map((selection) =>
          patchNaptimeDirective(selection, node.kind === 'OperationDefinition' ? node.operation : operationType)
        ),
      },
    };
  }
  return node;
};

const transformNaptimeToRestQuery = (query) => ({
  ...query,
  definitions: query.definitions.map((definition) => patchNaptimeDirective(definition)),
});

export default transformNaptimeToRestQuery;
