import BaseTransport from './base';

/**
 * Transport that uses the Beacon API to send events. It does not support batching.
 *
 * The Beacon API is only available in modern browsers.
 */
class BeaconTransport extends BaseTransport {
  /**
   * Send data to the server.
   *
   * @param data
   */
  async request<TData extends Record<string, unknown>>(data: TData): Promise<Response> {
    if (!navigator.sendBeacon) {
      throw new Error('[BeaconTransport] Beacon API is not available');
    }

    // API only supports www-url-encoded format. Additionally, nested objects
    // must be stringified.
    const payload = Object.entries(data).map(([key, value]) => [
      key,
      typeof value !== 'string' ? JSON.stringify(value) : value,
    ]);

    const enqueued = navigator.sendBeacon(this.url, new URLSearchParams(payload));

    return new Response(undefined, { status: enqueued ? 200 : 500 });
  }
}

export default BeaconTransport;
