/* eslint-disable @typescript-eslint/no-explicit-any, camelcase */

/**
 * This file is autogenerated, please don't edit it manually.
 * Use this command to regenerate it if you modified Epic experiments:
 * yarn epic:types
 */

/**
 * Epic experiments namespaces with their possible keys.
 * Autogenerated from static/bundles/epic/data/defaults/
 */
import client from 'bundles/epic/client';
import siteMisc from 'bundles/epic/data/defaults/siteMisc.json';
import type { Tags } from 'bundles/epic/lib/EpicTypes';

type Namespace = {
  profilesV2: boolean;
  facebookPluginEnabled: boolean;
  applePluginEnabled: boolean;
  googlePluginEnabled: boolean;
  googleOneTapPluginEnabled: boolean;
  twitterPluginEnabled: boolean;
  gplusPluginEnabled: boolean;
  enableTimezoneMismatchNotification: boolean;
  asyncRenderedApps: any;
  SSRDebug: any;
  graphqlGatewayQueryLinkOverride: any;
  graphqlGatewayQueryLinkOverrideSecondary: any;
  enableEventPulsePageViews: boolean;
};

const NAMESPACE = 'siteMisc';

// Adding safe optional chaining to avoid massive changes in unit tests.
client.addDefaults?.([siteMisc as $TSFixMe]);

const siteMiscEpicClient = {
  get<K extends keyof Namespace>(key: K, tags?: Tags): Namespace[K] {
    return client.get(NAMESPACE, key, tags);
  },

  preview<K extends keyof Namespace>(key: K, tags?: Tags, defaults?: Namespace): Namespace[K] {
    return client.preview(NAMESPACE, key, tags);
  },
};

export default siteMiscEpicClient;
