/* eslint-disable no-restricted-syntax */
import config from 'js/app/config';

import { SEARCH_FILTERS_FOR_URL_SYNC } from 'bundles/search-common/constants/filter';
import type { SearchProductType } from 'bundles/search-common/providers/searchTypes';
import type { SearchSortType } from 'bundles/search-common/types';

import _t from 'i18n!nls/search-common';

export const RECENT_SEARCHES_LOCAL_STORAGE_NAME = 'recent-searches';
export const RECENTLY_VIEWED_LOCAL_STORAGE_NAME = 'recently-viewed';
export const RECENT_ITEM_NUMBER_OF_DAYS_TO_LAST = 7;

export const TABLET_SCREEN_WIDTH = 992;
// unfortunately bound to a width defined in a styl file in another team's bundle.
// see static/bundles/page-header/components/__styles__/PageHeader.styl
export const MAX_SCREEN_WIDTH_FOR_MOBILE_SEARCH_BAR = 1060;
export const NUM_NO_RESULTS_RECS_PER_PRODUCT_TYPE = 12;
export const NUM_NO_RESULTS_TO_SHOW = 24;

export const NUMBER_OF_RESULTS_PER_PAGE = 12;
export const NUMBER_OF_RESULTS_PER_COLLECTION = 3;

export const THRESHOLD_FOR_FILTER_GROUP_BECOMING_SEARCHABLE = 12;

export const PRODUCT_TYPES: Record<string, SearchProductType> = {
  Course: 'COURSE',
  GuidedProject: 'GUIDED_PROJECT',
  Specialization: 'SPECIALIZATION',
  Project: 'PROJECT',
  ProfessionalCertificate: 'PROFESSIONAL_CERTIFICATE',
  Degree: 'DEGREE',
  UniversityCertificate: 'UNIVERSITY_CERTIFICATE',
  Mastertrack: 'MASTERTRACK',
  PostgraduateDiploma: 'POSTGRADUATE_DIPLOMA',
  GraduateCertificate: 'GRADUATE_CERTIFICATE',
  Video: 'VIDEO',
  Lesson: 'LESSON',
} as const;

/**
 * @deprecated: use PRODUCT_TYPES instead
 */
export const LEARNING_PRODUCTS = {
  Course: 'COURSE',
  GuidedProject: 'GUIDED PROJECT',
  Specialization: 'SPECIALIZATION',
  Project: 'PROJECT',
  ProfessionalCertificate: 'PROFESSIONAL CERTIFICATE',
  Degree: 'DEGREE',
  UniversityCertificate: 'UNIVERSITY CERTIFICATE',
  Mastertrack: 'MASTERTRACK',
  PostgraduateDiploma: 'POSTGRADUATE DIPLOMA',
  GraduateCertificate: 'GRADUATE CERTIFICATE',
  Video: 'VIDEO',
  Lesson: 'LESSON',
  LearningPath: 'LEARNING PATH',
} as const;

export const LEARNING_PRODUCTS_ENUM_MAP: Record<string, (typeof LEARNING_PRODUCTS)[keyof typeof LEARNING_PRODUCTS]> = {
  COURSE: 'COURSE',
  GUIDED_PROJECT: 'GUIDED PROJECT',
  SPECIALIZATION: 'SPECIALIZATION',
  PROJECT: 'PROJECT',
  PROFESSIONAL_CERTIFICATE: 'PROFESSIONAL CERTIFICATE',
  DEGREE: 'DEGREE',
  UNIVERSITY_CERTIFICATE: 'UNIVERSITY CERTIFICATE',
  MASTERTRACK: 'MASTERTRACK',
  POSTGRADUATE_DIPLOMA: 'POSTGRADUATE DIPLOMA',
  GRADUATE_CERTIFICATE: 'GRADUATE CERTIFICATE',
  VIDEO: 'VIDEO',
  LESSON: 'LESSON',
} as const;

export const getTranslatedProductName = (productName?: string) => {
  switch (productName) {
    case 'Course':
      return _t('Course');
    case 'Guided Project':
      return _t('Guided Project');
    case 'Project':
      return _t('Project');
    case 'Specialization':
      return _t('Specialization');
    case 'Professional Certificate':
      return _t('Professional Certificate');
    case 'Mastertrack':
      return _t('Mastertrack');
    case 'MasterTrack':
      return _t('MasterTrack');
    case 'Degree':
      return _t('Degree');
    case 'Post Graduate':
      return _t('Post Graduate');
    case 'Postgraduate Diploma':
      return _t('Postgraduate Diploma');
    case 'Graduate Certificate':
      return _t('Graduate Certificate');
    case 'University Certificate':
      return _t('University Certificate');
    case 'Video':
      return _t('Video');
    case 'Lesson':
      return _t('Lesson');
    default:
      return productName;
  }
};

export const NUMBER_OF_FILTER_ITEMS_TO_SHOW = 4;

export const PRODUCT_DIFFICULTY_LEVELS = {
  Beginner: 'Beginner',
  Intermediate: 'Intermediate',
  Advanced: 'Advanced',
  Mixed: 'Mixed', // Mixed represents a learning product that covers multiple difficulty levels.
} as const;
export const PRODUCT_DIFFICULTY_LEVELS_ENUM_MAP = Object.keys(PRODUCT_DIFFICULTY_LEVELS);
export const getTranslatedDifficulty = (difficulty: string): string => {
  switch (difficulty) {
    case 'Beginner':
    case 'BEGINNER':
      return _t('Beginner');
    case 'Intermediate':
    case 'INTERMEDIATE':
      return _t('Intermediate');
    case 'Advanced':
    case 'ADVANCED':
      return _t('Advanced');
    case 'Mixed':
    case 'MIXED':
      return _t('Mixed');
    default:
      return '';
  }
};

export const PRODUCT_DURATIONS = [
  'Under 60 Minutes', // UNDER_SIXTY_MINUTES
  'Less Than 2 Hours', // LESS_THAN_TWO_HOURS
  '1 - 3 Months', // ONE_TO_THREE_MONTHS
  '1 - 4 Weeks', // ONE_TO_FOUR_WEEKS
  '3 - 6 Months', // THREE_TO_SIX_MONTHS
  '6 - 12 Months', // SIX_TO_TWELVE_MONTHS
  '1 - 4 Years', // ONE_TO_FOUR_YEARS
];
export const PRODUCT_DURATIONS_STRING: Record<string, string> = {
  UNDER_SIXTY_MINUTES: 'Under 60 Minutes',
  LESS_THAN_TWO_HOURS: 'Less Than 2 Hours',
  ONE_TO_THREE_MONTHS: '1 - 3 Months',
  ONE_TO_FOUR_WEEKS: '1 - 4 Weeks',
  THREE_TO_SIX_MONTHS: '3 - 6 Months',
  SIX_TO_TWELVE_MONTHS: '6 - 12 Months',
  ONE_TO_FOUR_YEARS: '1 - 4 Years',
};
export const getTranslatedDurations = (productDuration: string) => {
  switch (productDuration) {
    case 'Under 60 Minutes':
      return _t('Under 60 Minutes');
    case 'Less Than 2 Hours':
      return _t('Less Than 2 Hours');
    case '1 - 3 Months':
      return _t('1 - 3 Months');
    case '1 - 4 Weeks':
      return _t('1 - 4 Weeks');
    case '3 - 6 Months':
      return _t('3 - 6 Months');
    case '6 - 12 Months':
      return _t('6 - 12 Months');
    case '1 - 4 Years':
      return _t('1 - 4 Years');
    default:
      return undefined;
  }
};

export const SEARCH_BAR_LOCATION = {
  SearchPage: 'SearchPage',
  SEOEntityPage: 'SEOEntityPage',
  FrontPage: 'FrontPage',
  FrontPageBanner: 'FrontPageBanner',
} as const;
export type SearchBarLocation = (typeof SEARCH_BAR_LOCATION)[keyof typeof SEARCH_BAR_LOCATION];

/* START ALGOLIA SEARCH RESULTS PROVIDER CONSTANTS */
export const INDEX_TYPE_TO_NAME_EXPERIMENT_PARAMETER_NAME = 'algoliaIndexTypeToIndexNameMap';

export const SUGGESTION_INDEX_TYPE = 'suggestions' as const;
export const ARTICLES_INDEX_TYPE = 'articles' as const;
export const ALL_PRODUCTS_INDEX_TYPE = 'all' as const;
export const COURSES_AND_SPECIALIZATIONS_INDEX_TYPE = 'products' as const;
export const ENTERPRISE_COURSES_AND_SPECIALIZATIONS_INDEX_TYPE = 'enterprise_products' as const;
export const C4CB_COURSES_AND_PROJECTS_INDEX_TYPE = 'enterprise_products' as const;

export const ALGOLIA_APP_ID = 'LUA9B20G37';

// This is a search only API key that is safe to check in.
export const ALGOLIA_API_KEY = 'dcc55281ffd7ba6f24c3a9b18288499b';

// NOTE: This index name MUST exist in our Algolia application, we are using an empty index
// as placeholder for this purpose rather than using an existing index in case we delete them later
// given we are using multi-index
/**
 * @deprecated: do not use placeholder index
 */
export const ALGOLIA_PLACEHOLDER_INDEX_NAME = 'DO_NOT_DELETE_PLACEHOLDER_INDEX_NAME';
export const ALGOLIA_PROD_RESULTS_INDEX = 'prod_all_launched_products_term_optimization';

export const CONSUMER_SEARCH_ROUTE = 'search';
export const EQP_ROUTE = 'courses';

export const ON_SITE_SEARCH_PATH = `/${CONSUMER_SEARCH_ROUTE}`;

export const SEO_ENTITY_SEARCH_PATH = `/${EQP_ROUTE}`;

// Experiment route used for searh V2 in development.
export const DEV_ON_SITE_SEARCH_PATH = '/search-v2';

// On /courses, /search, /logged-in-home, /, and /programs/{programName} sync URL with search state.
export const ROUTES_TO_SYNC_SEARCH_STATE =
  /^(\/courses\/?|\/search\/?|\/search-v2\/?|\/logged-in-home\/?|\/?|\/programs\/.+)/;

export const SEARCH_STATE_URL_PARAMS = [...SEARCH_FILTERS_FOR_URL_SYNC, 'query', 'page', 'sortBy'];

export const SEARCH_MAIN_INDEX_ID = 'search-results-all';
export const SEARCH_AUTOCOMPLETE_MAIN_INDEX_ID = 'autocomplete-results-all';
export const SEARCH_NEW_INDEX_ID = 'search-results-new';
export const SEARCH_ENTERPRISE_AUTOCOMPLETE_CLIPS_INDEX_ID = 'search-results-clips';
export const SEARCH_ENTERPRISE_AUTOCOMPLETE_COURSES_S12N_INDEX_ID = 'search-results-courses-s12n';

export const BROWSE_IN_SEARCH_MOST_POPULAR_INDEX_ID = 'browse-in-search-most-popular';
export const BROWSE_IN_SEARCH_BEGINNER_INDEX_ID = 'browse-in-search-beginner';
export const BROWSE_IN_SEARCH_PARTNERS_INDEX_ID = 'browse-in-search-partners';
export const SEARCH_FILTERS_INDEX_ID = 'search-filters';
export const SEARCH_SUGGESTIONS_INDEX_ID = 'suggestions';
export const AUTOCOMPLETE_DIRECT_MATCH_INDEX_ID = 'direct-match';
export const SEARCH_ARTICLES_INDEX_ID = 'articles';

export const DEFAULT_SORTED_VALUE: SearchSortType = 'BEST_MATCH';
export const NEW_SORTED_VALUE: SearchSortType = 'NEW';

export const SEARCH_INDEX_ID_BY_SORT_VALUE: Record<SearchSortType, string> = {
  BEST_MATCH: 'search-results-all',
  NEW: 'search-results-new',
};
export const DEFAULT_PAGE_NUMBER = 1;
export const SORT_BY_FILTER = 'sortBy';

export const VALID_SORTED_VALUES = [DEFAULT_SORTED_VALUE, NEW_SORTED_VALUE];

export const ENTERPRISE_COLLECTION_ENTITIES = [
  'Short Form Content Video',
  'Courses',
  'Specializations',
  'Projects',
  'Guided Projects',
  'Professional Certificates',
];

export const SEARCHABLE_FILTER_GROUP_KEYS = ['partners', 'skills'];

export const COURSERA_PLUS_POPULAR_DOMAINS_MAPPING = [
  { id: 'business', name: 'Business' },
  { id: 'computer-science', name: 'Computer Science' },
  { id: 'data-science', name: 'Data Science' },
  { id: 'life-sciences', name: 'Health' },
  { id: 'information-technology', name: 'Information Technology' },
  { id: 'arts-and-humanities', name: 'Arts and Humanities' },
];

export const SHORT_FORM_CONTENT_ENTITY_TYPES = ['VIDEO', 'LESSON'];

// TODO(htran) remove after UCI APM Contentful migration is done in GNG-1259
export const UCI_APM_OBJECT_ID = 's12n~kLWnFWsyEeeVdhKUpvOPZg';
export const UCI_APM_IMAGE_URL = `${config.url.resource_assets}search/assets/uci_apm.png`;

/**
 * @deprecated: no need render delay for the new provider, only being used for the old algolia components
 */
export const SEARCH_RENDER_DELAY = 1200; // 1200 milliseconds

export { DEFAULT_SEARCH_FILTERS } from 'bundles/search-common/constants/filter';

export const OLD_TO_NEW_PARAM_MAP: Record<string, string> = {
  entityTypeDescription: 'productTypeDescription',
  productDurationEnum: 'productDuration',
};

export const BROWSE_IN_SEARCH_MOST_POPULAR_FACETFILTER_VALUES = [
  ['productTypeDescription:Specializations', 'productTypeDescription:Professional Certificates'],
];

export const BROWSE_IN_SEARCH_BEGINNER_FACETFILTER_VALUES = [['productDifficultyLevel:Beginner']];

export const MAX_NUMBER_OF_SPOTLIGHT_COLLECTION_PRODUCTS = 2;
