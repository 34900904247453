/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { FormattedMessage } from 'js/lib/coursera.react-intl';

import { Link, Typography, Typography2 } from '@coursera/cds-core';

import { NotFound } from 'bundles/common/components/withNotFound';

import _t from 'i18n!nls/internationalization-lib';

const styles = {
  title: css`
    margin-bottom: var(--cds-spacing-300);
  `,
};

export const ErrorMessage = (): React.ReactElement => {
  return (
    <NotFound
      description={
        <React.Fragment>
          <Typography variant="d2semibold" component="h1" css={styles.title}>
            {_t('Oops! Something went wrong')}
          </Typography>
          <Typography2 component="p">
            <FormattedMessage
              message={_t(
                'This page has encountered an error. Our team is on it. Please try again later. If the issue persists, {supportLink}.'
              )}
              supportLink={<Link href="https://www.coursera.support/">{_t('contact help support')}</Link>}
            />
          </Typography2>
        </React.Fragment>
      }
    />
  );
};

export default ErrorMessage;
