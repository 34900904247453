/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useRef } from 'react';

import MegaMenuSection from 'bundles/megamenu/components/sections/MegaMenuSection';
import useArrowKeys from 'bundles/megamenu/components/sections/useArrowKeys';
import type { MegaMenuSections as MegaMenuSectionsType } from 'bundles/megamenu/types/MegaMenuSections';

const styles = {
  sectionsContainer: () => css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: calc(100% + 28px);
    margin: 0 -24px;
  `,
};

const MegaMenuSections: React.FunctionComponent<MegaMenuSectionsType> = ({
  sectionItems,
  isMobile,
  tabName,
  tabId,
}) => {
  const sectionsWithoutBottom = sectionItems.filter((section) => section.contentType !== 'bottom');
  const bottomSections = sectionItems.filter((section) => section.contentType === 'bottom');
  const divRef = useRef<HTMLDivElement>(null);
  useArrowKeys(divRef, sectionItems, isMobile);

  return (
    <div className="rc-MegaMenuSections" css={styles.sectionsContainer} ref={divRef}>
      {sectionsWithoutBottom.map((section) => (
        <MegaMenuSection
          key={section.sectionName}
          section={section}
          isMobile={isMobile}
          tabName={tabName}
          tabId={tabId}
          hideAnchorColor={section.hideAnchorColor}
        />
      ))}
      {bottomSections.map((section) => (
        <MegaMenuSection
          key={section.sectionName}
          doubleColumn={true}
          fullWidth={true}
          section={section}
          isMobile={isMobile}
          tabName={tabName}
          tabId={tabId}
          hideAnchorColor={section.hideAnchorColor}
        />
      ))}
    </div>
  );
};

export default MegaMenuSections;
