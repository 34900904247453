// REF https://github.com/webedx-spark/infra-services/blob/main/libs/models/src/main/pegasus/org/coursera/enrollment/EnrollmentChoiceType.courier
export const BULKPAY_FULL_SPECIALIZATION = 'BULKPAY_FULL_SPECIALIZATION';

export const BULKPAY_REMAINING_SPECIALIZATION_COURSES = 'BULKPAY_REMAINING_SPECIALIZATION_COURSES';

export const ENROLL_THROUGH_S12N_PREPAID = 'ENROLL_THROUGH_S12N_PREPAID';

export const ENROLL_THROUGH_S12N_SUBSCRIPTION = 'ENROLL_THROUGH_S12N_SUBSCRIPTION';

export const ENROLL_THROUGH_S12N_SUBSCRIPTION_TRIAL = 'ENROLL_THROUGH_S12N_SUBSCRIPTION_TRIAL';

export const PURCHASE_SINGLE_COURSE = 'PURCHASE_SINGLE_COURSE';

export const ENROLL_COURSE = 'ENROLL_COURSE';

export const ENROLL_COURSE_WITH_FULL_DISCOUNT = 'ENROLL_COURSE_WITH_FULL_DISCOUNT';

export const AUDIT_COURSE = 'AUDIT_COURSE';

export const ENROLL_THROUGH_PROGRAM = 'ENROLL_THROUGH_PROGRAM';

export const ENROLL_THROUGH_PROGRAM_INVITATION = 'ENROLL_THROUGH_PROGRAM_INVITATION';

export const ENROLL_THROUGH_GROUP = 'ENROLL_THROUGH_GROUP';

export const PURCHASE_COURSERA_TIER_LITE = 'PURCHASE_COURSERA_TIER_LITE';

export const SUBSCRIBE_TO_COURSERA_PLUS = 'SUBSCRIBE_TO_COURSERA_PLUS';

export const PRE_ENROLLMENT_ELIGIBLE = 'PRE_ENROLLMENT_ELIGIBLE';

// This is a purely FE construct - this choice type doesn't actually exist on the BE
export const PURCHASE_THROUGH_COURSERA_PLUS = 'PURCHASE_THROUGH_COURSERA_PLUS';

export const ENROLL_THROUGH_COURSERA_PLUS = 'ENROLL_THROUGH_COURSERA_PLUS';

// This is a purely FE construct - this choice type doesn't actually exist on the BE
export const ENROLL_THROUGH_CREDENTIALTRACK_SUBSCRIPTION = 'ENROLL_THROUGH_CREDENTIALTRACK_SUBSCRIPTION';

// This is a purely FE construct - this choice type doesn't actually exist on the BE
export const ENROLL_THROUGH_CREDENTIALTRACK = 'ENROLL_THROUGH_CREDENTIALTRACK';

// This is a purely FE construct - this choice type doesn't actually exist on the BE
export const ENROLL_GUIDED_PROJECT = 'ENROLL_GUIDED_PROJECT';

// This is a purely FE construct - this choice type doesn't actually exist on the BE
export const ENROLL_PROJECT = 'ENROLL_PROJECT';

const EnrollmentChoiceTypes = {
  BULKPAY_FULL_SPECIALIZATION,
  BULKPAY_REMAINING_SPECIALIZATION_COURSES,
  ENROLL_THROUGH_S12N_PREPAID,
  ENROLL_THROUGH_S12N_SUBSCRIPTION,
  ENROLL_THROUGH_S12N_SUBSCRIPTION_TRIAL,
  PURCHASE_SINGLE_COURSE,
  ENROLL_COURSE,
  AUDIT_COURSE,
  ENROLL_THROUGH_PROGRAM,
  ENROLL_THROUGH_PROGRAM_INVITATION,
  ENROLL_THROUGH_GROUP,
  PURCHASE_COURSERA_TIER_LITE,
  SUBSCRIBE_TO_COURSERA_PLUS,
  PRE_ENROLLMENT_ELIGIBLE,
  PURCHASE_THROUGH_COURSERA_PLUS,
  ENROLL_THROUGH_COURSERA_PLUS,
  ENROLL_COURSE_WITH_FULL_DISCOUNT,
  ENROLL_THROUGH_CREDENTIALTRACK_SUBSCRIPTION,
  ENROLL_THROUGH_CREDENTIALTRACK,
  ENROLL_GUIDED_PROJECT,
  ENROLL_PROJECT,
} as const;

export type EnrollmentChoiceTypesValues = typeof EnrollmentChoiceTypes[keyof typeof EnrollmentChoiceTypes];

export default EnrollmentChoiceTypes;
