/** @jsx jsx */
import { jsx } from '@emotion/react';

import * as React from 'react';

import FocusTrap from 'focus-trap-react';
import { flatMap } from 'lodash';
import PropTypes from 'prop-types';
import type { LegacyContextType } from 'types/legacy-context-types';

import Retracked from 'js/app/retracked';

import { Button, Typography2, VisuallyHidden } from '@coursera/cds-core';
import { track } from '@coursera/event-pulse/sdk';

import { getRootItem } from 'bundles/browse/components/PageHeader/constants';
import MegaMenuMainItem from 'bundles/megamenu/components/MegaMenuMainItem';
import styles from 'bundles/megamenu/components/__styles__/MegaMenuMain';
import type { MegaMenuData, MenuItem } from 'bundles/megamenu/types/MenuData';
import { focusMenuItem, getNextItem, getPreviousItem } from 'bundles/megamenu/utils/MegaMenuUtils';
import TrackedDiv from 'bundles/page/components/TrackedDiv';
import { TrackedLink2 } from 'bundles/page/components/TrackedLink2';

import _t from 'i18n!nls/megamenu';

type Props = {
  megaMenuData: MegaMenuData[];
  selectedDomain?: MenuItem;
  closeMenu: () => void;
  setCursorDomain: (domain: MenuItem) => void;
  handleMenuItemSelection: (domain: MenuItem) => void;
  menuIsOpen: boolean;
  isUsingKeyboard?: boolean;
  setIsUsingArrowKeys: () => void;
};

type computedNextOrPrevItemId = Record<string, { previousItemId?: string; nextItemId?: string }>;

type State = {
  computedNextOrPrevItemId: computedNextOrPrevItemId;
};

class MegaMenuMain extends React.Component<Props, State> {
  static contextTypes = {
    _eventData: PropTypes.object,
  };

  declare context: LegacyContextType<typeof MegaMenuMain.contextTypes>;

  state: State = {
    computedNextOrPrevItemId: {},
  };

  constructor(props: Props) {
    super(props);

    const { megaMenuData } = props;
    // because vanila flatmap is not supported by current jest setup (node 10)
    const allMenuItems = flatMap(megaMenuData, (section) => section.sectionMenus);
    const computedNextOrPrevItemId: computedNextOrPrevItemId = {};
    allMenuItems.forEach((menuItem) => {
      if (menuItem?.id) {
        computedNextOrPrevItemId[menuItem.id] = {
          previousItemId: getPreviousItem(allMenuItems, menuItem).id,
          nextItemId: getNextItem(allMenuItems, menuItem).id,
        };
      }
    });
    this.state = { computedNextOrPrevItemId };
  }

  escapeKeyHandler = (event: KeyboardEvent) => {
    if (event.key === 'Escape' && this.props.menuIsOpen) {
      this.props.closeMenu();
    }
  };

  componentDidUpdate(prevProps: Props) {
    // this tracking logic is needed due to CSS hiding technique refrain us from using retracked visibility tracking
    const { menuIsOpen } = this.props;
    const { _eventData } = this.context;
    // should only fired once when menuIsOpen switch to true from false
    if (menuIsOpen && prevProps.menuIsOpen === false) {
      Retracked.trackComponent(_eventData, { menuIsOpen }, 'mega_menu_main_panel', 'view');
    }
  }

  componentDidMount() {
    document.addEventListener('keydown', this.escapeKeyHandler);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.escapeKeyHandler);
  }

  render() {
    const {
      selectedDomain,
      handleMenuItemSelection,
      megaMenuData,
      setCursorDomain,
      closeMenu,
      menuIsOpen,
      isUsingKeyboard,
      setIsUsingArrowKeys,
    } = this.props;
    const { computedNextOrPrevItemId } = this.state;
    const rootDomainId = getRootItem().id;
    const shouldFocusOnMainMenu = menuIsOpen && selectedDomain?.id === rootDomainId;

    return (
      <div className="rc-MegaMenuMain" css={styles.megaMenuMain}>
        <TrackedDiv
          trackingName="mega_menu_main_panel"
          withVisibilityTracking={false}
          trackClicks={false}
          trackMouseEnters={true}
        >
          <VisuallyHidden id="section-description" component="p" className="rc-A11yScreenReaderOnly">
            {_t('Press the up or down arrow key to navigate')}
          </VisuallyHidden>
          <FocusTrap
            active={shouldFocusOnMainMenu && isUsingKeyboard}
            focusTrapOptions={{ returnFocusOnDeactivate: false }}
          >
            <div css={styles.ulWrapper} aria-label={_t('Explore menu')} aria-describedby="section-description">
              {megaMenuData?.map(({ sectionName, sectionMenus }) => {
                const firstNonRootMenuId = sectionMenus.find((menu) => menu.id !== rootDomainId)?.id;

                return (
                  <div key={sectionName}>
                    <Typography2
                      variant="titleSmall"
                      component="h2"
                      className="section-title"
                      css={styles.sectionTitle}
                    >
                      {sectionName}
                    </Typography2>
                    <ul css={styles.menuList}>
                      {sectionMenus.map((menuItem: MenuItem) => {
                        const { previousItemId = undefined, nextItemId = undefined } =
                          (menuItem.id && computedNextOrPrevItemId[menuItem.id]) || {};
                        const isRoot = menuItem?.id === rootDomainId;
                        return isRoot ? (
                          <li
                            className="rc-MegaMenuMainItem"
                            css={[styles.menuItem, styles.menuItemWrapperHidden, styles.listItem]}
                            key={menuItem.id}
                            aria-describedby="section-title"
                            aria-hidden="true"
                          />
                        ) : (
                          <li css={styles.listItem} key={menuItem.id}>
                            <MegaMenuMainItem
                              key={menuItem.id}
                              item={menuItem}
                              closeMenu={closeMenu}
                              setCursorDomain={setCursorDomain}
                              selected={menuItem.id === selectedDomain?.id}
                              selectedDomain={selectedDomain}
                              selectMenuItem={() => handleMenuItemSelection(menuItem)}
                              focusNextMenuItem={() => focusMenuItem(nextItemId)}
                              focusPreviousMenuItem={() => focusMenuItem(previousItemId)}
                              href={menuItem.link}
                              isFirst={menuItem.id === firstNonRootMenuId}
                              sectionName={sectionName}
                              renderItemContent={menuItem.menuItemRenderer}
                              setIsUsingArrowKeys={setIsUsingArrowKeys}
                            />
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                );
              })}
              <div css={styles.exploreAllWrapper}>
                <Button
                  variant="secondary"
                  size="small"
                  component={TrackedLink2}
                  trackingName="browse_all_subjects_button"
                  href="/browse"
                  onMouseDown={(event: React.MouseEvent) => event.preventDefault()}
                  onKeyDown={(evt: React.KeyboardEvent<HTMLElement>) => {
                    if (evt.key === 'Escape') closeMenu();
                  }}
                  onClick={() => {
                    track('click_megamenu_item', {
                      megamenuItemName: 'browse',
                      megamenuSection: 'subjects',
                      megamenuItemType: 'view_all_link',
                      megamenuItemLink: '/browse',
                    });
                  }}
                  data-e2e="browse-all-subjects-button"
                  aria-label={_t('Browse all subjects')}
                  css={styles.exploreAllButton}
                >
                  {_t('Browse all subjects')}
                </Button>
              </div>
            </div>
          </FocusTrap>
        </TrackedDiv>
      </div>
    );
  }
}

export default MegaMenuMain;
