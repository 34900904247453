import * as React from 'react';
import { createContext } from 'react';

import appInfo from 'js/lib/appInfo';
import type UserAgentInfo from 'js/lib/useragent';

type UserVerificationData = {
  first_name: string;
  middle_name: string;
  verified_by: string;
  last_name: string;
};

type UserData = {
  id?: number | null;
  authenticated: boolean;
  fullName?: string | null;
  full_name?: string | null;
  is_staff?: boolean;
  is_superuser?: boolean;
  id_verification?: UserVerificationData;
};

export type AppInfo = {
  csrfToken?: string | null;
  requestCountryCode?: string | null;
  userAgent?: UserAgentInfo;
  userData?: UserData;
  appName?: string;
};

const AppInfoContext = createContext<AppInfo>({});

export const AppInfoProvider = ({ children }: { children: React.ReactNode }) => {
  const value = appInfo.get();
  return <AppInfoContext.Provider value={value}>{children}</AppInfoContext.Provider>;
};

export default AppInfoContext;
