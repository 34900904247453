/**
 * Restricts a number within the inclusive lower and upper bound range.
 * @param num number to be clamped
 * @param min lower bound
 * @param max upper bound
 */
export const clamp = (num: number, min = 0, max = 100): number =>
  Math.max(min, Math.min(num, max));

/**
 * whether a number is a floating point number
 * @param num number to check for
 */
export const isFloatNumber = (num: number): boolean =>
  Number(num) === num && num % 1 !== 0;
