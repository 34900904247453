/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { Colors, Typography2, typography, typography2 } from '@coursera/cds-core';
import { useTracker } from '@coursera/event-pulse/react';

import { domainToCategoryMapping } from 'bundles/browse/constants';
import MegaMenuGoalItem from 'bundles/megamenu/components/goal-item/MegaMenuGoalItem';
import {
  DEGREES_SECTION_TYPE,
  DUAL_COLUMN_CONTENT_TYPE,
  SECTION_VIEW_ALL_BUTTON_TYPE,
  SECTION_VIEW_ALL_LINK_TYPE,
} from 'bundles/megamenu/constants/constants';
import type { MegaMenuSection as MegaMenuSectionType } from 'bundles/megamenu/types/MegaMenuSection';
import TrackedDiv from 'bundles/page/components/TrackedDiv';
import { TrackedA } from 'bundles/page/components/TrackedLink2';

import _t from 'i18n!nls/megamenu';

const styles = {
  viewAllButton: () => css`
    &:hover,
    &:focus {
      background: ${Colors.blue[600]};
      color: ${Colors.white};
      text-decoration: none;
    }

    ${typography2.subtitleMedium}
    border-radius: 2px;
    min-height: 36px;
    text-align: center;
    vertical-align: middle;
    background: ${Colors.white};
    color: ${Colors.blue[600]};
    border: 1px solid ${Colors.blue[600]};
    padding: var(--cds-spacing-100) var(--cds-spacing-200);
    display: inline-block;
    margin: 20px auto 0;
    white-space: normal;
    margin-top: 20px;

    @media screen and (max-width: 768px) {
      width: 100%;
      margin-top: 0;
    }
  `,
  sectionContainer: () => css`
    width: 50%;
    padding: 0 24px;
    margin-bottom: 44px;
  `,
  mobileSectionContainer: () => css`
    width: 100vw;
    padding: 0 24px;
  `,
  fullWidthSectionContainer: () => css`
    width: 100%;
  `,
  sectionTitle: css`
    ${typography.h2semibold}
    display: block;
    border-bottom: 1px solid #c8c8c8;
    font-size: 16px;
    font-weight: bold;
    line-height: 28px;
    margin-bottom: 18px;
    width: 100%;
    height: fit-content;
  `,
  sectionSubTitle: () => css`
    color: #5e5e5e;
    padding-bottom: 15px;
    font-size: 12px;
  `,
  mobileSectionTitle: css`
    ${typography.h2semibold}
    display: block;
    border-top: 1px solid #c8c8c8;
    font-size: 16px;
    font-weight: bold;
    line-height: 28px;
    margin-bottom: 18px;
    width: 100%;
    padding-top: 20px;
  `,
  viewAllLink: () => css`
    padding-left: 2px;
  `,
  itemList: () => css`
    list-style: none;
    padding: 0;
    width: 100%;
  `,
  itemListDoubleColumn: () => css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  `,
  item: () => css`
    display: flex;
    flex: 6;
    flex-basis: 50%;
    max-width: 50%;
  `,
};

const MegaMenuSection: React.FunctionComponent<MegaMenuSectionType> = ({
  section,
  fullWidth,
  doubleColumn,
  hideAnchorColor,
  isMobile,
  tabName,
  tabId,
}) => {
  const track = useTracker();

  const {
    sectionName,
    sectionSubTitle,
    sectionType,
    sectionItems,
    domainSlug,
    viewAllText,
    viewAllUrl,
    viewAllType,
    contentType,
  } = section;

  if (!sectionItems || sectionItems?.length < 1) return null;

  const EARN_A_DEGREE_TAB_ID = 'earn-a-degree';
  const SOCIAL_SCIENCE_TAB_ID = 'social-sciences';
  const SOCIAL_SCIENCE_VIEW_ALL_URL = '/search?entityTypeDescription=Degrees&topic=Social Sciences';

  const isEarnADegreeTab = tabId === EARN_A_DEGREE_TAB_ID;
  const isSocialScienceTab = tabId === SOCIAL_SCIENCE_TAB_ID;
  const isSocialScienceDegrees = isSocialScienceTab && sectionType === DEGREES_SECTION_TYPE;

  const viewAllCalculatedUrl = viewAllUrl || (domainSlug ? domainToCategoryMapping[domainSlug] : undefined);
  const isDoubleColumn = contentType === DUAL_COLUMN_CONTENT_TYPE || doubleColumn;

  let viewAllMessage: string | undefined;
  let trackingItemName: string | undefined;
  if (viewAllCalculatedUrl && sectionType && sectionName) {
    viewAllMessage = _t('View all #{domain} #{sectionType}', { domain: sectionName, sectionType });
    trackingItemName = `View all ${sectionName} ${sectionType}`.toLowerCase().replace(/\s+/g, '-');
  }
  if (viewAllText) {
    viewAllMessage = _t(viewAllText); // Explicitly set view all text always overrides computed view more text
    trackingItemName = viewAllText.toLowerCase().replace(/\s+/g, '-');
  }

  return (
    <TrackedDiv
      css={[
        styles.sectionContainer(),
        isMobile ? styles.mobileSectionContainer() : styles.sectionContainer,
        fullWidth && styles.fullWidthSectionContainer(),
      ]}
      className="rc-MegaMenuSection"
      trackingName="mega_menu_section"
      trackClicks={false}
      trackMouseEnters={true}
      data={{
        sectionName,
        sectionType,
        domainSlug,
        viewAllText,
        viewAllUrl,
        contentType,
        sectionItemsLength: sectionItems.length,
        fullWidth,
        doubleColumn,
        hideAnchorColor,
      }}
    >
      <Typography2
        variant="subtitleMedium"
        component="p"
        id={`${tabName}-tab-${sectionName}-title`.replace(/\s+/g, '-')}
        className="megaMenuSection-title"
        css={isMobile ? styles.mobileSectionTitle : styles.sectionTitle}
      >
        {sectionName}
      </Typography2>
      {sectionSubTitle && <div css={styles.sectionSubTitle}>{sectionSubTitle}</div>}
      {/* with list-style none with VoiceOver we need to re-add list semantics https://www.scottohara.me/blog/2019/01/12/lists-and-safari.html */}
      {/* eslint-disable-next-line jsx-a11y/no-redundant-roles */}
      <ul
        css={[styles.itemList, isDoubleColumn && !isMobile && styles.itemListDoubleColumn]}
        aria-labelledby={`${tabName}-tab-${sectionName}-title`.replace(/\s+/g, '-')}
        role="list"
      >
        {sectionItems?.map(
          (item, index) =>
            item &&
            !(isEarnADegreeTab && item.name === 'Social Sciences') && (
              // eslint-disable-next-line jsx-a11y/no-redundant-roles
              <li css={isDoubleColumn && styles.item} key={`${sectionName}~${item.slug || item.name}`} role="listitem">
                <MegaMenuGoalItem
                  item={item}
                  key={item.id}
                  hitPosition={index}
                  hideAnchorColor={hideAnchorColor}
                  isMobile={isMobile}
                  tabName={tabName}
                  tabId={tabId}
                  sectionName={sectionName}
                />
              </li>
            )
        )}
        {viewAllMessage && viewAllType === SECTION_VIEW_ALL_LINK_TYPE && viewAllCalculatedUrl && (
          // eslint-disable-next-line jsx-a11y/no-redundant-roles
          <li role="listitem">
            <TrackedA
              href={viewAllCalculatedUrl}
              css={styles.viewAllLink}
              trackingName={isMobile ? 'mobile_section_view_all_button' : 'section_view_all_button'}
              data={{
                sectionName,
                sectionType,
                sectionSubTitle,
                domainSlug,
                viewAllText,
                viewAllUrl,
                contentType,
                sectionItemsLength: sectionItems.length,
              }}
              tabIndex={isMobile ? 0 : -1}
              onClick={() => {
                track('click_megamenu_item', {
                  megamenuItemLink: viewAllCalculatedUrl,
                  megamenuItemName: trackingItemName as string,
                  megamenuSection: tabId ?? '',
                  megamenuItemType: 'view_all_link',
                });
              }}
            >
              {viewAllMessage}
            </TrackedA>
          </li>
        )}
        {viewAllMessage && viewAllType === SECTION_VIEW_ALL_BUTTON_TYPE && viewAllCalculatedUrl && (
          /* eslint-disable-next-line jsx-a11y/no-redundant-roles */
          <li role="listitem" style={{ width: '100%' }}>
            <TrackedA
              href={isSocialScienceDegrees ? SOCIAL_SCIENCE_VIEW_ALL_URL : viewAllCalculatedUrl}
              trackingName={isMobile ? 'mobile_section_view_all_button' : 'section_view_all_button'}
              css={styles.viewAllButton}
              data={{
                sectionName,
                sectionType,
                sectionSubTitle,
                domainSlug,
                viewAllText,
                viewAllUrl: isSocialScienceDegrees ? SOCIAL_SCIENCE_VIEW_ALL_URL : viewAllUrl,
                contentType,
                sectionItemsLength: sectionItems.length,
              }}
              tabIndex={isMobile ? 0 : -1}
              onClick={() => {
                track('click_megamenu_item', {
                  megamenuItemLink: viewAllCalculatedUrl,
                  megamenuItemName: trackingItemName as string,
                  megamenuSection: tabId ?? '',
                  megamenuItemType: 'view_all_link',
                });
              }}
            >
              {viewAllMessage}
            </TrackedA>
          </li>
        )}
      </ul>
    </TrackedDiv>
  );
};

export default MegaMenuSection;
