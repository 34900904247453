import { css } from '@emotion/react';

export const styles = {
  megaMenuSubPanel: () => css`
    background: white;
    display: inline-block;
    box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
  `,
  sectionWrapper: () => css`
    background: white;
    display: inline-block;
    height: 100%;
  `,
  isHidden: () => css`
    /* https://css-tricks.com/snippets/css/accessibilityseo-friendly-css-hiding/ */

    /* visually hiding subpanels in css, and using FocusTrap and aria-hidden to hide them from screen readers */
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    padding: 0;
    margin: -1px;
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    word-wrap: normal !important;
    z-index: 100000;
  `,
};

export default styles;
