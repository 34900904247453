import logger from 'js/app/loggerSingleton';

import BaseTransport from '@coursera/event-pulse/core/transport/base';

/**
 * Transport that uses the client console log to record the event.
 *
 * This transport provides a simple way to debug the events that are being sent
 * by logging them to the console.
 */
class LoggerTransport extends BaseTransport {
  /**
   * Logs the event data to the client console.
   *
   * @param data - The data to be logged to the console.
   * @returns A promise that resolves to a Response object indicating the result of the log operation.
   */
  async request<TData extends Record<string, unknown>>(data: TData): Promise<Response> {
    logger.info('[Eventing] - send:', data);

    return new Response(undefined, { status: 200 });
  }
}

export default LoggerTransport;
