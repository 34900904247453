import cloneDeep from 'lodash/cloneDeep';
import upperFirst from 'lodash/upperFirst';

import { TYPENAME_PREFIX } from './constants';

// apollo-cache-inmemory gets mad if it does not see __typename for objects in result, so we generate one for it.
// Previously, we generated null type names, but Apollo V3 doesn't like these, so try a bit to generate something
// sensible.
//
// TODO(igor): obey @type(name: "ResourceName") directive
const injectEmptyTypename = (object, syntheticTypeName) => {
  if (object == null || typeof object !== 'object') {
    return object;
  }

  let __typename = object.__typename ?? syntheticTypeName;
  if (object.typeName) {
    __typename += `_${object.typeName}`;
  }

  if (Array.isArray(object)) {
    return object.map((element) => injectEmptyTypename(element, __typename));
  }

  return Object.keys(object).reduce(
    (result, key) => ({
      ...result,
      [key]: injectEmptyTypename(object[key], `${__typename}_${key}`),
    }),
    { __typename }
  );
};

// Transform response:
// * Surround result with method name (get, multiGet, or other finder).
// * Inject __typename: NaptimeResourceNameV1Resource, NaptimeResourceNameV1Connection,
//   NaptimeResourceNameV1. Resource typenames have Naptime prefix to avoid conflicts with
//   Assembler types.
// * Inject __typename to linked resources.
// * Inject empty __typename's for nested objects.
const responseTransformer = (response, resource, version, action) => {
  if (response) {
    const resourceName = `${TYPENAME_PREFIX}${upperFirst(resource)}V${version}`;

    const elements = (response.elements || []).map((element) => ({
      ...element,
      __typename: resourceName,
    }));
    const linked = Object.keys(response.linked || {}).reduce((acc, key) => {
      return {
        ...acc,
        [key]: response.linked[key].map((e) => ({
          ...e,
          __typename: `${TYPENAME_PREFIX}${upperFirst(key)}`,
        })),
      };
    }, {});

    return {
      [action]: injectEmptyTypename({
        ...response,
        elements,
        ...(Object.keys(linked).length > 0 && { linked }),
        __typename: `${resourceName}Connection`,
      }),
      __typename: `${resourceName}Resource`,
    };
  }
  return response;
};

// Find @naptime nodes on result and apply responseTransformer for them.
const patchNode = (node, path, resource, version, action) => {
  if (path.length === 1) {
    if (node[path[0]]) {
      // eslint-disable-next-line no-param-reassign
      node[path[0]] = responseTransformer(node[path[0]], resource, version, action);
    }
  } else {
    const [pathItem, ...restPath] = path;
    if (node[pathItem]) {
      if (Array.isArray(node[pathItem])) {
        Object.keys(node[pathItem]).forEach((key) =>
          patchNode(node[pathItem][key], restPath, resource, version, action)
        );
      } else {
        patchNode(node[pathItem], restPath, resource, version, action);
      }
    }
  }
};

const transformRestToNaptimeResponse = (patchedNodes) => (originalData) => {
  const data = cloneDeep(originalData);
  patchedNodes.forEach(({ path, resource, version, action }) => {
    patchNode(data.data, path, resource, version, action);
  });
  return data;
};

export default transformRestToNaptimeResponse;
