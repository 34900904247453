/**
 * This utility function simplifies the value of location.query.someKey, which has an actual type defined as of
 * @types/history version 3, to simply be a string or undefined. String arrays are concatenated into a single string,
 * using the optional `joinWith` parameter (or a comma by default), and `null` becomes `undefined`.
 *
 * @param value - the value of a query parameter, as fetched by the `useLocation` Hook (or equivalent), of type
 *                `string | string[] | null | undefined`
 * @param joinWith - an optional string to join string arrays with
 *
 * @return - the simplified value of the query parameter, of type `string | undefined`
 */
export function simplifyQueryValue(
  value: string | string[] | null | undefined,
  joinWith: string = ','
): string | undefined {
  if (Array.isArray(value)) {
    return value.join(joinWith);
  }
  return value ?? undefined;
}
