import { css } from '@emotion/react';

import { breakpoints } from '@coursera/cds-common';

export const getActionsCss = () => {
  return css`
    background-color: inherit;
    padding: var(--cds-spacing-600);

    ${breakpoints.down('xs')} {
      padding: var(--cds-spacing-400) var(--cds-spacing-200)
        var(--cds-spacing-400) var(--cds-spacing-200);
    }
  `;
};
