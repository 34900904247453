import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';

import { breakpoints } from '@coursera/cds-common';

import { classes as commonCardClasses } from '@core/cards/common/getCommonCardCss';
import generateEmotionClasses from '@core/utils/generateEmotionClasses';

import type { Props as ProductCardProps } from './ProductCard';

export const classes = generateEmotionClasses('ProductCard', [
  'base',
  'grid',
  'list',
  'gridCard',
  'listCard',
  'listContentContainer',
  'listMain',
  'content',
  'header',
  'body',
  'footer',
  'gridPreviewContainer',
  'listPreviewImage',
  'adminContent',
  'statusTags',
  'statusTagsSimple',
  'statusTagsOverlay',
  'partnerInfo',
  'partners',
  'partnerLogos',
  'partnerNames',
  'avatar',
  'gridSkeleton',
  'gridSkeletonPreview',
  'gridSkeletonHeader',
  'gridSkeletonBody',
  'gridSkeletonFooter',
  'listSkeleton',
  'listSkeletonPreview',
  'listSkeletonPreviewContainer',
  'listSkeletonSection',
  'listSkeletonContent',
  'listSkeletonFooter',
  'alwaysSmallImage',
]);

const getProductCardCss = (props: ProductCardProps): SerializedStyles =>
  css`
    --grid-max-width: 470px;
    --grid-min-width: 230px;
    --grid-border: 2px;
    --list-max-width: 100%;
    --list-min-width: 320px;
    --list-img-size: 80px;
    --list-img-size-xs: 64px;
    --list-img-size-lg: 100%;

    &.${classes.base} {
      border-radius: var(--card-border-radius);
      background: var(--cds-color-neutral-background-primary);
      transition: all 0.3s cubic-bezier(0, 0, 0.5, 1);
      position: relative;
      transform: translateZ(0);

      &:hover {
        box-shadow: var(--card-box-shadow);
        transform: var(--card-hover-transform);
      }
    }

    &.${classes.grid} {
      border: var(--grid-border) solid
        var(--cds-color-neutral-stroke-primary-weak);
      min-width: var(--grid-min-width);
      max-width: calc(
        var(--grid-max-width) - (var(--card-padding) * 2) -
          (var(--grid-border) * 2)
      );
      width: ${props.width || '100%'};
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .${classes.content} {
        gap: var(--cds-spacing-200);
        padding: var(--cds-spacing-100);
      }

      .${classes.statusTagsSimple} {
        margin-bottom: var(--cds-spacing-100);
        padding-left: var(--cds-spacing-100);
      }

      .${classes.adminContent} {
        padding: var(--cds-spacing-100) var(--cds-spacing-200);
      }
    }

    &.${classes.list} {
      min-width: var(--list-min-width);
      max-width: var(--list-max-width);
      padding: var(--card-padding);
      width: ${props.width || 'auto'};

      .${classes.content} {
        gap: 8px;
      }

      .${classes.adminContent} {
        padding: var(--cds-spacing-200) 0 0 0;
      }
    }

    .${classes.gridCard} {
      display: flex;
      flex-direction: column;
      flex: 1;
      padding: var(--card-padding);
    }

    .${classes.listCard} {
      display: flex;
      flex: 1;
      flex-direction: column;
      gap: 8px;
    }

    .${classes.listContentContainer} {
      display: flex;
      flex-direction: row;
      gap: 16px;

      ${breakpoints.down('xs')} {
        flex-direction: row-reverse;
      }
    }

    .${classes.body} {
      flex: 1;
    }

    .${classes.content} {
      display: flex;
      flex-direction: column;
      flex: 1;
    }

    .${classes.gridPreviewContainer} {
      position: relative;

      .${commonCardClasses.previewImage} {
        border-radius: var(--preview-border-radius);
        margin-bottom: var(--cds-spacing-100);
      }
    }

    .${classes.listPreviewImage} {
      position: relative;
      background-color: var(--cds-color-neutral-disabled);
      border-radius: var(--preview-border-radius);
      aspect-ratio: 1 / 1;
      max-height: var(--list-img-size);
      overflow: hidden;

      ${breakpoints.up('lg')} {
        aspect-ratio: 16 / 9;
        max-height: var(--list-img-size-lg);
        height: max-content;
        flex: 1;
      }

      ${breakpoints.down('xs')} {
        max-height: var(--list-img-size-xs);
      }

      img {
        height: var(--list-img-size);
        width: var(--list-img-size);
        object-fit: cover;

        ${breakpoints.up('lg')} {
          height: var(--list-img-size-lg);
          width: var(--list-img-size-lg);
        }

        ${breakpoints.down('xs')} {
          height: var(--list-img-size-xs);
          width: var(--list-img-size-xs);
        }
      }
    }

    .${classes.statusTags} {
      display: flex;
      flex-wrap: wrap;
      gap: 4px;
    }

    .${classes.statusTagsOverlay} {
      z-index: 1;
      position: absolute;
      top: var(--cds-spacing-100);
      left: var(--cds-spacing-100);
      padding-right: var(--cds-spacing-100);
    }

    .${classes.header} {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    .${classes.partnerInfo} {
      display: flex;
    }

    .${classes.partners} {
      display: flex;
      align-items: center;
      gap: 4px;
    }

    .${classes.partnerLogos} {
      display: flex;
      gap: 4px;
    }

    .${classes.partnerNames} {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .${classes.footer} {
      display: flex;
      flex-direction: column;
      gap: 4px;
      flex: 1;
      justify-content: end;
    }

    .${classes.gridSkeleton} {
      cursor: not-allowed;
      padding: var(--cds-spacing-100);

      .${classes.gridSkeletonPreview} {
        height: auto;
        width: 100%;
        border-radius: var(--preview-border-radius);
        aspect-ratio: var(--preview-aspect-ratio);
        max-height: 270px;
      }

      .${classes.gridSkeletonHeader} {
        display: flex;
        flex-direction: column;
        margin: 8px 0 16px 4px;
        gap: 8px;
      }

      .${classes.gridSkeletonBody} {
        display: flex;
        flex-direction: column;
        margin: 8px 0 8px 4px;
        gap: 4px;
      }

      .${classes.gridSkeletonFooter} {
        display: flex;
        margin-left: 4px;
        gap: 8px;
      }
    }

    .${classes.listSkeleton} {
      cursor: not-allowed;
      display: flex;
      gap: 16px;
      flex-direction: row;

      ${breakpoints.down('xs')} {
        flex-direction: row-reverse;
      }

      .${classes.listSkeletonPreviewContainer} {
        ${breakpoints.up('lg')} {
          flex: 1;
        }
      }

      .${classes.listSkeletonPreview} {
        border-radius: var(--preview-border-radius);
        height: var(--list-img-size);
        width: var(--list-img-size);

        ${breakpoints.up('lg')} {
          aspect-ratio: 16 / 9;
          height: var(--list-img-size-lg);
          width: var(--list-img-size-lg);
        }

        ${breakpoints.down('xs')} {
          height: var(--list-img-size-xs);
          width: var(--list-img-size-xs);
        }
      }

      .${classes.listSkeletonContent} {
        flex-direction: column;
        flex: 1;
      }

      .${classes.listSkeletonSection} {
        margin-bottom: var(--cds-spacing-100);
      }

      .${classes.listSkeletonFooter} {
        display: flex;
        gap: 8px;
      }
    }

    /**
      * Overriding above classes to make sure that, with alwaysSmallImage set,
      * that the image size in the List product card is always small even at
      * larger breakpoints.
      */
    .${classes.alwaysSmallImage} {
      .${classes.listPreviewImage} {
        ${breakpoints.up('lg')} {
          aspect-ratio: 1 / 1;
          max-height: var(--list-img-size);
          height: auto;
          flex: none;
        }

        img {
          ${breakpoints.up('lg')} {
            height: var(--list-img-size);
            width: var(--list-img-size);
          }
        }
      }
      &.${classes.listSkeleton} {
        .${classes.listSkeletonPreviewContainer} {
          ${breakpoints.up('lg')} {
            flex: none;
          }
        }
        .${classes.listSkeletonPreview} {
          ${breakpoints.up('lg')} {
            aspect-ratio: 1 / 1;
            height: var(--list-img-size);
            width: var(--list-img-size);
          }
        }
      }
    }
  `;

export default getProductCardCss;
