import { css } from '@emotion/react';

const styles = {
  svgContainer: css`
    place-items: center;
    margin: 0 auto;

    mask {
      mask-type: alpha;
    }

    /* ✨ SPARKLES ✨ */
    .sparkle {
      animation: sparkle-animation 1s linear infinite;
      opacity: 0;
    }
    #large-blue-shine {
      transform-origin: 92% 23%;
      animation-delay: 0.5s;
    }
    #small-blue-shine {
      transform-origin: 29% 16%;
      animation-delay: 1.2s;
    }
    #small-pink-shine {
      transform-origin: 90% 50%;
      animation-delay: 0.8s;
    }
    #med-pink-shine-1 {
      transform-origin: 7% 70%;
      animation-delay: 0.3s;
    }
    #med-pink-shine-2 {
      transform-origin: 32% 90%;
      animation-delay: 1.7s;
    }
    @keyframes sparkle-animation {
      0% {
        transform: scale(0, 0);
        opacity: 1;
      }
      29% {
        opacity: 1;
      }
      50% {
        transform: scale(1, 1);
        opacity: 0;
      }
      100% {
        opacity: 0;
      }
    }

    /* 🪜 LADDER 🪜 */
    #Ladder {
      transform-origin: 50% 85%;
      transform: translate(-100px, 230px);
      animation: ladder-animation 1s cubic-bezier(0, 0.31, 0.43, 1.01) 0.3s forwards;
    }
    @keyframes ladder-animation {
      0% {
        transform: translate(-100px, 230px);
      }
      100% {
        transform: translate(0, 0);
      }
    }

    /* 🌿 PLANT 🌿 */
    #stem-and-leaves {
      transform-origin: 8% 5%;
      animation: plant-sway 3s ease-in-out infinite alternate-reverse;
    }
    @keyframes plant-sway {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(-13deg);
      }
    }
    .leaf {
      animation: leaf-sway 3s ease-in-out infinite alternate-reverse;
    }
    #leaf-l1,
    #leaf-r1 {
      transform-origin: 10% 13%;
    }
    #leaf-l2,
    #leaf-r2 {
      transform-origin: 11% 16%;
    }
    #leaf-l3,
    #leaf-r3 {
      transform-origin: 11% 20%;
    }
    #leaf-r4 {
      transform-origin: 11% 21%;
    }
    #leaf-l4 {
      transform-origin: 11% 24%;
    }
    @keyframes leaf-sway {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(-8deg);
      }
    }
  `,
};

export default styles;
