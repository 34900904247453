import { TYPENAME_PREFIX } from './constants';

// Check if query node has @naptime directive and parse it to return resource name,
// version and action.
const getNaptimeResourceMetadata = (node) => {
  if (node.directives && node.directives.find((d) => d.name.value === 'naptime')) {
    const match = node.name.value.match(/^(\w+)V(\d+)$/);
    if (match) {
      const actionNode = node.selectionSet.selections[0];
      return {
        actionNode,
        resourceTypename: `${TYPENAME_PREFIX}${match[1]}V${match[2]}`,
        resource: match[1].charAt(0).toLowerCase() + match[1].slice(1),
        version: match[2],
        action: actionNode.name.value,
      };
    } else {
      throw new Error(`Invalid resource name '${node.name.value}'`);
    }
  }
  return {};
};

export default getNaptimeResourceMetadata;
