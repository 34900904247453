import type { ChangeEvent } from 'react';
import React, { useEffect } from 'react';

import { Tooltip as MuiTooltip } from '@material-ui/core';

import { ClassNames } from '@emotion/react';

import clsx from 'clsx';

import getTooltipCss, { classes } from './getTooltipCss';

export type Props = {
  title: string;

  open?: boolean;

  children: React.ReactElement;
  /**
   * Enable inverted styling
   */
  invert?: boolean;

  placement?: 'top' | 'bottom';

  arrow?: boolean;

  onOpen?: (event: ChangeEvent<Record<string, unknown>>) => void;
  onClose?: (
    event: ChangeEvent<Record<string, unknown>> | KeyboardEvent
  ) => void;
};

const Tooltip = React.forwardRef<HTMLDivElement, Props>(function Tooltip(
  props,
  ref
) {
  const { invert, onOpen, open, onClose, ...rest } = props;

  useEffect(
    function keyboardEffect() {
      if (!open) {
        return undefined;
      }

      function handleKeyDown(nativeEvent: KeyboardEvent) {
        if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
          onClose?.(nativeEvent);
        }
      }

      document.addEventListener('keydown', handleKeyDown);

      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    },
    [onClose, open]
  );

  return (
    <ClassNames>
      {({ css: getClassName }) => {
        return (
          <MuiTooltip
            open={open}
            onClose={onClose}
            onOpen={onOpen}
            {...rest}
            ref={ref}
            TransitionProps={{
              timeout: {
                exit: 0,
              },
            }}
            classes={{
              popper: clsx(getClassName(getTooltipCss), {
                [classes.inverted]: invert,
              }),
              tooltip: classes.tooltip,
              arrow: classes.arrow,
              tooltipPlacementBottom: classes.placementBottom,
              tooltipPlacementTop: classes.placementTop,
            }}
            css={getTooltipCss}
            enterDelay={200}
          />
        );
      }}
    </ClassNames>
  );
});

export default Tooltip;
