/* eslint-disable no-restricted-syntax */
import * as React from 'react';

import type { Seo_MegaMenu as SeoMegaMenu } from '__generated__/graphql-types';

import {
  getCertificateItem,
  getDegreeItem,
  getFindYourNewCareer,
  getFreeItem,
  getRootItem,
} from 'bundles/browse/components/PageHeader/constants';
import type { Domain } from 'bundles/browse/components/types/MegaMenu';
import type { ProfessionalCertificateProps } from 'bundles/browse/components/types/ProfessionalCertificate';
import type { DegreeProductMember } from 'bundles/browse/types/degree-list';
import certificateMetadata from 'bundles/megamenu/constants/certificateMetadata';
import {
  BOTTOM_CONTENT_SECTION_TYPE,
  CERTIFICATE_SECTION_TYPE,
  COLUMN_CONTENT_SECTION_TYPE,
  DEGREES_SECTION_TYPE,
  DOMAIN_TOPICS_WITH_NO_PROFESSIONAL_CERTIFICATES,
  GET_STARTED_SECTION_TYPE,
  MASTERTRACK_SECTION_TYPE,
  MEGAMENU_SECTION_ID_CERTIFICATE_PROGRAMS,
  MEGAMENU_SECTION_ID_GET_STARTED,
  MEGAMENU_SECTION_ID_POPULAR_SKILLS,
  POPULAR_SKILLS_SECTION_TYPE,
  SECTION_VIEW_ALL_BUTTON_TYPE,
  SECTION_VIEW_ALL_LINK_TYPE,
  domainIdOrderFromKittHeuristic,
} from 'bundles/megamenu/constants/constants';
import {
  certificateViewAllLinkByDomain,
  getMockedMoreCertificateData,
} from 'bundles/megamenu/mockData/certificateData';
import {
  degreeViewAllLinkData,
  getMockedMoreDegreesData,
  mastertrackSlugUpsellOnDegreesTabData,
} from 'bundles/megamenu/mockData/degreeData';
import type {
  MegaMenuData,
  MenuItem,
  mappedMegaMenuAPIData,
  megaMenuAPIData as megaMenuAPIDataType,
} from 'bundles/megamenu/types/MenuData';
import {
  getCareerAcademyLink,
  getFindYourNewCareerMegaMenuItemTitle,
} from 'bundles/page/components/shared/utils/careerAcademyUtils';
import { getAllTabIndexName } from 'bundles/search-common/utils/utils';

import _t from 'i18n!nls/megamenu';

function domainIdToCategoryId(domainId?: string) {
  return domainId?.toUpperCase().split('-').join('_');
}

const rootItemId = getRootItem().id;

export function removeWWWFromUrl(url: string) {
  return url
    .replace('https://www.coursera.org/', '/')
    .replace('http://www.coursera.org/', '/')
    .replace('www.coursera.org/', '/')
    .replace('coursera.org/', '/');
}

export const getPreviousItem = (items: Array<MenuItem>, item: MenuItem): MenuItem => {
  const idx = items.indexOf(item);
  const tempPreviousDomain = items[idx - 1 >= 0 ? idx - 1 : items.length - 1];
  const previousDomain =
    tempPreviousDomain.id !== rootItemId ? tempPreviousDomain : getPreviousItem(items, tempPreviousDomain);
  return previousDomain;
};
export const getNextItem = (items: Array<MenuItem>, item: MenuItem): MenuItem => {
  const idx = items.indexOf(item);
  const tempNextDomain = items[idx + 1 <= items.length - 1 ? idx + 1 : 0];
  const nextDomain = tempNextDomain.id !== rootItemId ? tempNextDomain : getNextItem(items, tempNextDomain);
  return nextDomain;
};
export const focusMenuItem = (domainId?: string) => {
  const menuItem = document.getElementById(`${domainId}~menu-item`);
  menuItem?.focus();
};

export const getSeoOverrideItems = ({
  megaMenuSeoData,
  domainId,
  sectionId,
}: {
  megaMenuSeoData?: SeoMegaMenu;
  domainId: string;
  sectionId: string;
}) => {
  const seoOverrideItems = megaMenuSeoData?.categories
    ?.find(({ header }) => header === domainId)
    ?.subcategories?.find(({ header }) => header === sectionId)
    ?.links?.map((link) => ({
      domainId,
      sectionId,
      name: link.label,
      title: link.label,
      url: link.href,
    }));
  return seoOverrideItems;
};

export const getDomainGetStartedSectionData = (
  domainId: string,
  getStartedSectionData?: Record<string, megaMenuAPIDataType[]>,
  megaMenuSeoData?: SeoMegaMenu
) => {
  const seoOverrideItems = getSeoOverrideItems({
    megaMenuSeoData,
    domainId,
    sectionId: MEGAMENU_SECTION_ID_GET_STARTED,
  });
  const getStartedItems = getStartedSectionData?.[domainId]?.map((link) => ({ ...link, name: link.title }));

  return {
    sectionName: _t('Get started'),
    contentType: COLUMN_CONTENT_SECTION_TYPE,
    sectionType: GET_STARTED_SECTION_TYPE,
    sectionItems: seoOverrideItems?.length ? seoOverrideItems : getStartedItems,
  };
};

export const getDomainPopularSkillsSectionData = (
  domainId: string,
  popularSkillsSectionData?: Record<string, megaMenuAPIDataType[]>,
  megaMenuSeoData?: SeoMegaMenu
) => {
  const seoOverrideItems = getSeoOverrideItems({
    megaMenuSeoData,
    domainId,
    sectionId: MEGAMENU_SECTION_ID_POPULAR_SKILLS,
  });
  const popularSkillsItems = popularSkillsSectionData?.[domainId]?.map((link) => ({ ...link, name: link.title }));

  return {
    sectionName: _t('Popular skills'),
    contentType: COLUMN_CONTENT_SECTION_TYPE,
    sectionType: POPULAR_SKILLS_SECTION_TYPE,
    viewAllText: _t('View all skills'),
    viewAllUrl: `/browse/${domainId || ''}`,
    viewAllType: SECTION_VIEW_ALL_BUTTON_TYPE,
    sectionItems: seoOverrideItems?.length ? seoOverrideItems : popularSkillsItems,
  };
};

export const removeMastertrackCertificateFromName = (name: string) => {
  return name.replace(' MasterTrack™ Certificate', '');
};

export const getCertificateSectionItemData = (
  professionalCertificates: ProfessionalCertificateProps[],
  slug?: string
) => {
  const certificateData = professionalCertificates.find((c) => c.slug === slug);
  if (certificateData) {
    return {
      ...certificateData,
      url: removeWWWFromUrl(certificateData?.link),
      partnerLogo: certificateData?.partnerSquareLogo,
      type: 'ProfessionalCertsV1',
      value: certificateMetadata(slug),
    };
  } else {
    return undefined;
  }
};

export const getMastertrackSectionItemData = (mastertrackList: DegreeProductMember[], slug?: string) => {
  const mastertrackData = mastertrackList?.find((m) => m.slug === slug);
  return (
    mastertrackData && {
      ...mastertrackData,
      name: removeMastertrackCertificateFromName(mastertrackData.name),
      url: removeWWWFromUrl(mastertrackData?.link || ''),
      partnerLogo: mastertrackData.partner?.squareLogo,
      type: 'ProfessionalCertsV1',
      value: certificateMetadata(slug),
    }
  );
};

export const getSearchUrl = (domain: Domain | undefined) => {
  if (!domain?.topic) {
    return '';
  }

  return `/search?index=${getAllTabIndexName()}${
    DOMAIN_TOPICS_WITH_NO_PROFESSIONAL_CERTIFICATES.includes(domain.id)
      ? ''
      : '&entityTypeDescription=Professional%20Certificates'
  }&entityTypeDescription=MasterTrack®%20Certificates&topic=${domain.topic}`;
};

export const getDomainCertificatesSectionData = (
  domain: Domain,
  professionalCertificates: ProfessionalCertificateProps[],
  mastertrackList: DegreeProductMember[],
  certificatesSectionData?: Record<string, megaMenuAPIDataType[]>
) => {
  const filteredCertificates = certificatesSectionData?.[domain.id];
  if (!filteredCertificates || filteredCertificates.length < 1) return null;

  const sectionItems = filteredCertificates
    .map((certificate) => {
      const certificateData = getCertificateSectionItemData(professionalCertificates, certificate.slug);
      if (certificateData) {
        return certificateData;
      } else {
        return getMastertrackSectionItemData(mastertrackList, certificate.slug);
      }
    })
    .filter(Boolean);

  if (!sectionItems || sectionItems.length < 1) return null;

  const viewAllUrl = certificateViewAllLinkByDomain[domain.id] || getSearchUrl(domain);

  return {
    sectionName: _t('Certificate programs'),
    sectionSubTitle: _t(
      'In 3-9 months, gain the skills to break into a new career or take your career to the next level.'
    ),
    sectionType: CERTIFICATE_SECTION_TYPE,
    contentType: COLUMN_CONTENT_SECTION_TYPE,
    viewAllText: _t('View all Certificates'),
    viewAllUrl,
    viewAllType: SECTION_VIEW_ALL_BUTTON_TYPE,
    sectionItems,
  };
};

export const mapDegreeSectionItems = (degrees: DegreeProductMember[]) =>
  degrees?.map((degree) => ({
    ...degree,
    url: removeWWWFromUrl(degree.link || ''),
    partnerName: degree.partner?.name,
    partnerLogo: degree.partner?.squareLogo,
    type: 'PRODUCT_TYPE~DEGREE',
  }));

export const getDomainDegreesSectionData = (domain: Domain, degreeList: DegreeProductMember[]) => {
  // note: we already sort the order based on DRE and we want only top 6
  const filteredDegrees = degreeList?.filter((degree) => degree?.domainIds?.includes(domain.id)).slice(0, 6);
  if (!filteredDegrees || filteredDegrees.length < 1) return null;
  const curatedDegreeViewAllUrl = degreeViewAllLinkData[domain?.id || ''];

  const degreeData = {
    sectionName: _t('Degrees'),
    sectionSubTitle: _t('Breakthrough pricing on 100% online degrees designed to fit into your life.'),
    sectionType: DEGREES_SECTION_TYPE,
    contentType: COLUMN_CONTENT_SECTION_TYPE,
    sectionItems: mapDegreeSectionItems(filteredDegrees)?.filter(Boolean),
  };

  // For degrees that have only one program, the "View all" button is misleading / not helpful
  if (filteredDegrees.length === 1) {
    return degreeData;
  } else {
    return {
      ...degreeData,
      viewAllText: _t('View all degrees'),
      viewAllUrl:
        curatedDegreeViewAllUrl ||
        (domain?.topic
          ? `/search?index=${getAllTabIndexName()}&entityTypeDescription=Degrees&topic=${domain.topic}`
          : ''),
      viewAllType: SECTION_VIEW_ALL_BUTTON_TYPE,
    };
  }
};

export const reorderDomains = (domains: Domain[]): Domain[] => {
  return domainIdOrderFromKittHeuristic.map((id) => domains.find((domain) => domain.id === id)).filter(Boolean);
};

const getDegreeTabData = (
  degreeList: DegreeProductMember[],
  domains: Domain[],
  mastertrackList: DegreeProductMember[]
) => {
  const top3DomainId = domainIdOrderFromKittHeuristic.slice(0, 3);

  const topDomainSections = top3DomainId.map((id) => {
    const domain = domains.find((d) => d.id === id);
    const categoryId = domainIdToCategoryId(domain?.id) || '';
    const filteredDegrees = degreeList?.filter((degree) => degree?.categoryIds?.includes(categoryId)).slice(0, 6);

    const curatedDegreeViewAllUrl = degreeViewAllLinkData[id];

    return {
      sectionName: domain?.name,
      sectionType: DEGREES_SECTION_TYPE,
      contentType: COLUMN_CONTENT_SECTION_TYPE,
      domainSlug: domain?.id,
      viewAllText: _t('View all #{domainName} degrees', { domainName: domain?.name }),
      viewAllUrl:
        curatedDegreeViewAllUrl ||
        (domain?.topic
          ? `/search?index=${getAllTabIndexName()}&entityTypeDescription=Degrees&topic=${domain.topic}`
          : ''),
      viewAllType: SECTION_VIEW_ALL_LINK_TYPE,
      sectionItems: mapDegreeSectionItems(filteredDegrees)?.filter(Boolean),
    };
  });

  const mastertrackUpsellItems = mastertrackSlugUpsellOnDegreesTabData.map((slug) =>
    getMastertrackSectionItemData(mastertrackList, slug)
  );
  const mastertrackUpsellSection = {
    sectionName: _t("Earn credit toward a master's"),
    sectionType: MASTERTRACK_SECTION_TYPE,
    contentType: BOTTOM_CONTENT_SECTION_TYPE,
    viewAllText: _t('View all MasterTrack Certificates'),
    viewAllUrl: `/mastertrack`,
    viewAllType: SECTION_VIEW_ALL_LINK_TYPE,
    sectionItems: mastertrackUpsellItems,
  };

  return {
    ...getDegreeItem(),
    subMenuData: {
      title: getDegreeItem().menuName,
      subTitle: _t('Breakthrough pricing on 100% online degrees designed to fit into your life.'),
      sections: [...topDomainSections, getMockedMoreDegreesData(), mastertrackUpsellSection],
    },
  };
};

const getCertificateTabData = (
  domains: Domain[],
  mastertrackList: DegreeProductMember[],
  professionalCertificates: ProfessionalCertificateProps[],
  certificatesSectionData?: Record<string, megaMenuAPIDataType[]>
) => {
  const byDomainSections = domainIdOrderFromKittHeuristic
    .map((domainId) => {
      const domain = domains.find((d) => d.id === domainId);
      const certificatesByDomain = certificatesSectionData?.[domainId];

      const sectionItems = certificatesByDomain
        ?.map((certificate) => {
          const certificateData = getCertificateSectionItemData(professionalCertificates, certificate.slug);
          if (certificateData) {
            return certificateData;
          } else {
            return getMastertrackSectionItemData(mastertrackList, certificate.slug);
          }
        })
        ?.filter(Boolean);
      if (!sectionItems || sectionItems.length < 1) return null;

      const viewAllUrl = certificateViewAllLinkByDomain[domainId] || getSearchUrl(domain);

      return {
        sectionName: domain?.name,
        sectionType: CERTIFICATE_SECTION_TYPE,
        contentType: COLUMN_CONTENT_SECTION_TYPE,
        domainSlug: domain?.slug,
        viewAllText: _t('View all #{domainName} Certificates', { domainName: domain?.name }),
        viewAllUrl,
        viewAllType: SECTION_VIEW_ALL_LINK_TYPE,
        sectionItems,
      };
    })
    ?.filter(Boolean)
    .slice(0, 3);

  return {
    ...getCertificateItem(),
    subMenuData: {
      title: getCertificateItem().menuName,
      subTitle: _t('In 3-9 months, gain the skills to break into a new career or take your career to the next level.'),
      sections: [...byDomainSections, getMockedMoreCertificateData()],
    },
  };
};

const getCareerAcademyData = () => {
  const menuName = getFindYourNewCareerMegaMenuItemTitle();
  return {
    ...getFindYourNewCareer(),
    menuName,
    link: getCareerAcademyLink('megamenu'),
    menuItemRenderer: () => {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ marginRight: '8px' }}>{menuName}</span>
        </div>
      );
    },
  };
};

type getMegaMenuSectionDataParams = {
  domains: Domain[];
  degreeList: DegreeProductMember[];
  mastertrackList: DegreeProductMember[];
  professionalCertificates: ProfessionalCertificateProps[];
  megaMenuAPIData?: mappedMegaMenuAPIData;
  megaMenuSeoData?: SeoMegaMenu;
};

export const getMegaMenuSectionData = (params: getMegaMenuSectionDataParams): MegaMenuData[] => {
  const { domains, degreeList, mastertrackList, professionalCertificates, megaMenuAPIData, megaMenuSeoData } = params;
  const reorderedDomainsFromKittHeuristic = reorderDomains(domains);

  const subjectSectionMenus = reorderedDomainsFromKittHeuristic.map((domain) => ({
    menuName: domain.name,
    id: domain.id,
    slug: domain.id,
    subMenuData: {
      title: domain.name,
      sections: [
        getDomainGetStartedSectionData(domain.id, megaMenuAPIData?.[MEGAMENU_SECTION_ID_GET_STARTED], megaMenuSeoData),
        getDomainPopularSkillsSectionData(
          domain.id,
          megaMenuAPIData?.[MEGAMENU_SECTION_ID_POPULAR_SKILLS],
          megaMenuSeoData
        ),
        getDomainDegreesSectionData(domain, degreeList),
        getDomainCertificatesSectionData(
          domain,
          professionalCertificates,
          mastertrackList,
          megaMenuAPIData?.[MEGAMENU_SECTION_ID_CERTIFICATE_PROGRAMS]
        ),
      ].filter(Boolean),
    },
  }));

  const goalSectionMenus = [
    getRootItem(),
    getFreeItem(),
    getDegreeTabData(degreeList, domains, mastertrackList),
    getCertificateTabData(
      domains,
      mastertrackList,
      professionalCertificates,
      megaMenuAPIData?.[MEGAMENU_SECTION_ID_CERTIFICATE_PROGRAMS]
    ),
    getCareerAcademyData(),
  ];

  return [
    {
      sectionName: _t('Goals'),
      sectionMenus: goalSectionMenus,
    },
    {
      sectionName: _t('Subjects'),
      sectionMenus: subjectSectionMenus,
    },
  ];
};
