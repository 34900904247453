const MESSAGE = `


            learnlea
        learnlearnlearn
      learnlearnlearnlear
      learnl        ear
    learnl
    learn                    Passionate about education? Come work at Coursera!
    learn                    https://careers.coursera.com
    learnl
      learnl        ear
      learnlearnlearnlea
        learnlearnlear
            learnle



`;

export const notifyConsole = () => {
  const isCsr = typeof window !== 'undefined';
  const shouldLog =
    isCsr &&
    window.console &&
    // eslint-disable-next-line no-console
    typeof console.log === 'function' &&
    // eslint-disable-next-line no-restricted-syntax
    window.location.hostname !== 'mock.dev-coursera.org';

  if (!shouldLog) {
    return;
  }

  // eslint-disable-next-line no-console
  console.log(MESSAGE);
};
