import { getAdvanceYourCareerMegaMenuItemTitle } from 'bundles/page/components/shared/utils/careerAcademyUtils';

import _t from 'i18n!nls/page-header';

export const getFreeItem = () => ({
  id: 'free-course',
  menuName: _t('Take a free course'),
  link: '/courses?query=free',
});

export const getFindYourNewCareer = () => ({
  id: 'career-academy',
  menuName: '',
});

export const getDegreeItem = () => ({
  id: 'earn-a-degree',
  menuName: _t('Earn a Degree'),
  slug: 'degrees',
});

export const getCertificateItem = () => ({
  id: 'earn-a-certificate',
  menuName: _t('Earn a Certificate'),
  slug: 'certificates',
});

export const getCareerLearningPathsItem = () => ({
  id: 'learning-paths',
  menuName: getAdvanceYourCareerMegaMenuItemTitle(),
  slug: 'learning_paths',
});

export const getRootItem = () => ({
  id: 'rootDomain',
  menuName: 'root',
});

// the item above are used for Megamenu Redesign
// the domain below are used for the legacy Megamenu

export const getDegreeDomain = () => ({
  name: _t('Degrees'),
  id: 'degrees',
  slug: 'degrees',
});

export const getCertificateDomain = () => ({
  name: _t('Certificates'),
  id: 'certificates',
  slug: 'certificates',
});

export const getRootDomain = () => ({
  id: 'rootDomain',
  name: 'root',
  subdomains: { elements: [] },
});
