/**
 * Load up React-Intl and also add the localization definitions
 * for the locales that we support.
 *
 * When we support many languages, we may want to load these dynamically
 * but for now fixing these in only adds 2.4KB and ensures they are not
 * requested repeatedly.
 *
 * Locale data is taken from node_modules/react-intl/dist/locale-data.
 */
import ReactIntl from 'react-intl';

/* eslint-disable */
ReactIntl.__addLocaleData({
  locale: 'en',
  pluralRuleFunction: function (n, ord) {
    var s = String(n).split('.'),
      v0 = !s[1],
      t0 = Number(s[0]) == n,
      n10 = t0 && s[0].slice(-1),
      n100 = t0 && s[0].slice(-2);
    if (ord)
      return n10 == 1 && n100 != 11 ? 'one' : n10 == 2 && n100 != 12 ? 'two' : n10 == 3 && n100 != 13 ? 'few' : 'other';
    return n == 1 && v0 ? 'one' : 'other';
  },
  fields: {
    year: {
      displayName: 'Year',
      relative: { 0: 'this year', 1: 'next year', '-1': 'last year' },
      relativeTime: {
        future: { one: 'in {0} year', other: 'in {0} years' },
        past: { one: '{0} year ago', other: '{0} years ago' },
      },
    },
    month: {
      displayName: 'Month',
      relative: { 0: 'this month', 1: 'next month', '-1': 'last month' },
      relativeTime: {
        future: { one: 'in {0} month', other: 'in {0} months' },
        past: { one: '{0} month ago', other: '{0} months ago' },
      },
    },
    day: {
      displayName: 'Day',
      relative: { 0: 'today', 1: 'tomorrow', '-1': 'yesterday' },
      relativeTime: {
        future: { one: 'in {0} day', other: 'in {0} days' },
        past: { one: '{0} day ago', other: '{0} days ago' },
      },
    },
    hour: {
      displayName: 'Hour',
      relativeTime: {
        future: { one: 'in {0} hour', other: 'in {0} hours' },
        past: { one: '{0} hour ago', other: '{0} hours ago' },
      },
    },
    minute: {
      displayName: 'Minute',
      relativeTime: {
        future: { one: 'in {0} minute', other: 'in {0} minutes' },
        past: { one: '{0} minute ago', other: '{0} minutes ago' },
      },
    },
    second: {
      displayName: 'Second',
      relative: { 0: 'now' },
      relativeTime: {
        future: { one: 'in {0} second', other: 'in {0} seconds' },
        past: { one: '{0} second ago', other: '{0} seconds ago' },
      },
    },
  },
});
ReactIntl.__addLocaleData({
  locale: 'ar',
  pluralRuleFunction: function (n, ord) {
    var s = String(n).split('.'),
      t0 = Number(s[0]) == n,
      n100 = t0 && s[0].slice(-2);
    if (ord) return 'other';
    return n == 0
      ? 'zero'
      : n == 1
      ? 'one'
      : n == 2
      ? 'two'
      : n100 >= 3 && n100 <= 10
      ? 'few'
      : n100 >= 11 && n100 <= 99
      ? 'many'
      : 'other';
  },
  fields: {
    year: {
      displayName: 'السنة',
      relative: { 0: 'السنة الحالية', 1: 'السنة التالية', '-1': 'السنة الماضية' },
      relativeTime: {
        future: {
          zero: 'خلال {0} من السنوات',
          one: 'خلال {0} من السنوات',
          two: 'خلال سنتين',
          few: 'خلال {0} سنوات',
          many: 'خلال {0} سنة',
          other: 'خلال {0} من السنوات',
        },
        past: {
          zero: 'قبل {0} من السنوات',
          one: 'قبل {0} من السنوات',
          two: 'قبل سنتين',
          few: 'قبل {0} سنوات',
          many: 'قبل {0} سنة',
          other: 'قبل {0} من السنوات',
        },
      },
    },
    month: {
      displayName: 'الشهر',
      relative: { 0: 'هذا الشهر', 1: 'الشهر التالي', '-1': 'الشهر الماضي' },
      relativeTime: {
        future: {
          zero: 'خلال {0} من الشهور',
          one: 'خلال {0} من الشهور',
          two: 'خلال شهرين',
          few: 'خلال {0} شهور',
          many: 'خلال {0} شهرًا',
          other: 'خلال {0} من الشهور',
        },
        past: {
          zero: 'قبل {0} من الشهور',
          one: 'قبل {0} من الشهور',
          two: 'قبل شهرين',
          few: 'قبل {0} أشهر',
          many: 'قبل {0} شهرًا',
          other: 'قبل {0} من الشهور',
        },
      },
    },
    day: {
      displayName: 'يوم',
      relative: { 0: 'اليوم', 1: 'غدًا', 2: 'بعد الغد', '-1': 'أمس', '-2': 'أول أمس' },
      relativeTime: {
        future: {
          zero: 'خلال {0} من الأيام',
          one: 'خلال {0} من الأيام',
          two: 'خلال يومين',
          few: 'خلال {0} أيام',
          many: 'خلال {0} يومًا',
          other: 'خلال {0} من الأيام',
        },
        past: {
          zero: 'قبل {0} من الأيام',
          one: 'قبل {0} من الأيام',
          two: 'قبل يومين',
          few: 'قبل {0} أيام',
          many: 'قبل {0} يومًا',
          other: 'قبل {0} من الأيام',
        },
      },
    },
    hour: {
      displayName: 'الساعات',
      relativeTime: {
        future: {
          zero: 'خلال {0} من الساعات',
          one: 'خلال {0} من الساعات',
          two: 'خلال ساعتين',
          few: 'خلال {0} ساعات',
          many: 'خلال {0} ساعة',
          other: 'خلال {0} من الساعات',
        },
        past: {
          zero: 'قبل {0} من الساعات',
          one: 'قبل {0} من الساعات',
          two: 'قبل ساعتين',
          few: 'قبل {0} ساعات',
          many: 'قبل {0} ساعة',
          other: 'قبل {0} من الساعات',
        },
      },
    },
    minute: {
      displayName: 'الدقائق',
      relativeTime: {
        future: {
          zero: 'خلال {0} من الدقائق',
          one: 'خلال {0} من الدقائق',
          two: 'خلال دقيقتين',
          few: 'خلال {0} دقائق',
          many: 'خلال {0} دقيقة',
          other: 'خلال {0} من الدقائق',
        },
        past: {
          zero: 'قبل {0} من الدقائق',
          one: 'قبل {0} من الدقائق',
          two: 'قبل دقيقتين',
          few: 'قبل {0} دقائق',
          many: 'قبل {0} دقيقة',
          other: 'قبل {0} من الدقائق',
        },
      },
    },
    second: {
      displayName: 'الثواني',
      relative: { 0: 'الآن' },
      relativeTime: {
        future: {
          zero: 'خلال {0} من الثواني',
          one: 'خلال {0} من الثواني',
          two: 'خلال ثانيتين',
          few: 'خلال {0} ثوانِ',
          many: 'خلال {0} ثانية',
          other: 'خلال {0} من الثواني',
        },
        past: {
          zero: 'قبل {0} من الثواني',
          one: 'قبل {0} من الثواني',
          two: 'قبل ثانيتين',
          few: 'قبل {0} ثوانِ',
          many: 'قبل {0} ثانية',
          other: 'قبل {0} من الثواني',
        },
      },
    },
  },
});
ReactIntl.__addLocaleData({
  locale: 'de',
  pluralRuleFunction: function (n, ord) {
    var s = String(n).split('.'),
      v0 = !s[1];
    if (ord) return 'other';
    return n == 1 && v0 ? 'one' : 'other';
  },
  fields: {
    year: {
      displayName: 'Jahr',
      relative: { 0: 'dieses Jahr', 1: 'nächstes Jahr', '-1': 'letztes Jahr' },
      relativeTime: {
        future: { one: 'in {0} Jahr', other: 'in {0} Jahren' },
        past: { one: 'vor {0} Jahr', other: 'vor {0} Jahren' },
      },
    },
    month: {
      displayName: 'Monat',
      relative: { 0: 'diesen Monat', 1: 'nächsten Monat', '-1': 'letzten Monat' },
      relativeTime: {
        future: { one: 'in {0} Monat', other: 'in {0} Monaten' },
        past: { one: 'vor {0} Monat', other: 'vor {0} Monaten' },
      },
    },
    day: {
      displayName: 'Tag',
      relative: { 0: 'heute', 1: 'morgen', 2: 'übermorgen', '-1': 'gestern', '-2': 'vorgestern' },
      relativeTime: {
        future: { one: 'in {0} Tag', other: 'in {0} Tagen' },
        past: { one: 'vor {0} Tag', other: 'vor {0} Tagen' },
      },
    },
    hour: {
      displayName: 'Stunde',
      relativeTime: {
        future: { one: 'in {0} Stunde', other: 'in {0} Stunden' },
        past: { one: 'vor {0} Stunde', other: 'vor {0} Stunden' },
      },
    },
    minute: {
      displayName: 'Minute',
      relativeTime: {
        future: { one: 'in {0} Minute', other: 'in {0} Minuten' },
        past: { one: 'vor {0} Minute', other: 'vor {0} Minuten' },
      },
    },
    second: {
      displayName: 'Sekunde',
      relative: { 0: 'jetzt' },
      relativeTime: {
        future: { one: 'in {0} Sekunde', other: 'in {0} Sekunden' },
        past: { one: 'vor {0} Sekunde', other: 'vor {0} Sekunden' },
      },
    },
  },
});
ReactIntl.__addLocaleData({
  locale: 'es',
  pluralRuleFunction: function (n, ord) {
    if (ord) return 'other';
    return n == 1 ? 'one' : 'other';
  },
  fields: {
    year: {
      displayName: 'Año',
      relative: { 0: 'este año', 1: 'el próximo año', '-1': 'el año pasado' },
      relativeTime: {
        future: { one: 'dentro de {0} año', other: 'dentro de {0} años' },
        past: { one: 'hace {0} año', other: 'hace {0} años' },
      },
    },
    month: {
      displayName: 'Mes',
      relative: { 0: 'este mes', 1: 'el próximo mes', '-1': 'el mes pasado' },
      relativeTime: {
        future: { one: 'dentro de {0} mes', other: 'dentro de {0} meses' },
        past: { one: 'hace {0} mes', other: 'hace {0} meses' },
      },
    },
    day: {
      displayName: 'Día',
      relative: { 0: 'hoy', 1: 'mañana', 2: 'pasado mañana', '-1': 'ayer', '-2': 'antes de ayer' },
      relativeTime: {
        future: { one: 'dentro de {0} día', other: 'dentro de {0} días' },
        past: { one: 'hace {0} día', other: 'hace {0} días' },
      },
    },
    hour: {
      displayName: 'Hora',
      relativeTime: {
        future: { one: 'dentro de {0} hora', other: 'dentro de {0} horas' },
        past: { one: 'hace {0} hora', other: 'hace {0} horas' },
      },
    },
    minute: {
      displayName: 'Minuto',
      relativeTime: {
        future: { one: 'dentro de {0} minuto', other: 'dentro de {0} minutos' },
        past: { one: 'hace {0} minuto', other: 'hace {0} minutos' },
      },
    },
    second: {
      displayName: 'Segundo',
      relative: { 0: 'ahora' },
      relativeTime: {
        future: { one: 'dentro de {0} segundo', other: 'dentro de {0} segundos' },
        past: { one: 'hace {0} segundo', other: 'hace {0} segundos' },
      },
    },
  },
});
ReactIntl.__addLocaleData({
  locale: 'fr',
  pluralRuleFunction: function (n, ord) {
    if (ord) return n == 1 ? 'one' : 'other';
    return n >= 0 && n < 2 ? 'one' : 'other';
  },
  fields: {
    year: {
      displayName: 'année',
      relative: { 0: 'cette année', 1: 'l’année prochaine', '-1': 'l’année dernière' },
      relativeTime: {
        future: { one: 'dans {0} an', other: 'dans {0} ans' },
        past: { one: 'il y a {0} an', other: 'il y a {0} ans' },
      },
    },
    month: {
      displayName: 'mois',
      relative: { 0: 'ce mois-ci', 1: 'le mois prochain', '-1': 'le mois dernier' },
      relativeTime: {
        future: { one: 'dans {0} mois', other: 'dans {0} mois' },
        past: { one: 'il y a {0} mois', other: 'il y a {0} mois' },
      },
    },
    day: {
      displayName: 'jour',
      relative: { 0: 'aujourd’hui', 1: 'demain', 2: 'après-demain', '-1': 'hier', '-2': 'avant-hier' },
      relativeTime: {
        future: { one: 'dans {0} jour', other: 'dans {0} jours' },
        past: { one: 'il y a {0} jour', other: 'il y a {0} jours' },
      },
    },
    hour: {
      displayName: 'heure',
      relativeTime: {
        future: { one: 'dans {0} heure', other: 'dans {0} heures' },
        past: { one: 'il y a {0} heure', other: 'il y a {0} heures' },
      },
    },
    minute: {
      displayName: 'minute',
      relativeTime: {
        future: { one: 'dans {0} minute', other: 'dans {0} minutes' },
        past: { one: 'il y a {0} minute', other: 'il y a {0} minutes' },
      },
    },
    second: {
      displayName: 'seconde',
      relative: { 0: 'maintenant' },
      relativeTime: {
        future: { one: 'dans {0} seconde', other: 'dans {0} secondes' },
        past: { one: 'il y a {0} seconde', other: 'il y a {0} secondes' },
      },
    },
  },
});
ReactIntl.__addLocaleData({
  locale: 'id',
  pluralRuleFunction: function (n, ord) {
    if (ord) return 'other';
    return 'other';
  },
  fields: {
    year: {
      displayName: 'Tahun',
      relative: { 0: 'tahun ini', 1: 'tahun depan', '-1': 'tahun lalu' },
      relativeTime: { future: { other: 'Dalam {0} tahun' }, past: { other: '{0} tahun yang lalu' } },
    },
    month: {
      displayName: 'Bulan',
      relative: { 0: 'bulan ini', 1: 'Bulan berikutnya', '-1': 'bulan lalu' },
      relativeTime: { future: { other: 'Dalam {0} bulan' }, past: { other: '{0} bulan yang lalu' } },
    },
    day: {
      displayName: 'Hari',
      relative: { 0: 'hari ini', 1: 'besok', 2: 'lusa', '-1': 'kemarin', '-2': 'kemarin lusa' },
      relativeTime: { future: { other: 'Dalam {0} hari' }, past: { other: '{0} hari yang lalu' } },
    },
    hour: {
      displayName: 'Jam',
      relativeTime: { future: { other: 'Dalam {0} jam' }, past: { other: '{0} jam yang lalu' } },
    },
    minute: {
      displayName: 'Menit',
      relativeTime: { future: { other: 'Dalam {0} menit' }, past: { other: '{0} menit yang lalu' } },
    },
    second: {
      displayName: 'Detik',
      relative: { 0: 'sekarang' },
      relativeTime: { future: { other: 'Dalam {0} detik' }, past: { other: '{0} detik yang lalu' } },
    },
  },
});
ReactIntl.__addLocaleData({
  locale: 'ja',
  pluralRuleFunction: function (n, ord) {
    if (ord) return 'other';
    return 'other';
  },
  fields: {
    year: {
      displayName: '年',
      relative: { 0: '今年', 1: '翌年', '-1': '昨年' },
      relativeTime: { future: { other: '{0} 年後' }, past: { other: '{0} 年前' } },
    },
    month: {
      displayName: '月',
      relative: { 0: '今月', 1: '翌月', '-1': '先月' },
      relativeTime: { future: { other: '{0} か月後' }, past: { other: '{0} か月前' } },
    },
    day: {
      displayName: '日',
      relative: { 0: '今日', 1: '明日', 2: '明後日', '-1': '昨日', '-2': '一昨日' },
      relativeTime: { future: { other: '{0} 日後' }, past: { other: '{0} 日前' } },
    },
    hour: { displayName: '時', relativeTime: { future: { other: '{0} 時間後' }, past: { other: '{0} 時間前' } } },
    minute: { displayName: '分', relativeTime: { future: { other: '{0} 分後' }, past: { other: '{0} 分前' } } },
    second: {
      displayName: '秒',
      relative: { 0: '今すぐ' },
      relativeTime: { future: { other: '{0} 秒後' }, past: { other: '{0} 秒前' } },
    },
  },
});
ReactIntl.__addLocaleData({
  locale: 'kk',
  pluralRuleFunction: function (n, ord) {
    var s = String(n).split('.'),
      t0 = Number(s[0]) == n,
      n10 = t0 && s[0].slice(-1);
    if (ord) return n10 == 6 || n10 == 9 || (t0 && n10 == 0 && n != 0) ? 'many' : 'other';
    return n == 1 ? 'one' : 'other';
  },
  fields: {
    year: {
      displayName: 'Жыл',
      relative: { 0: 'биылғы жыл', 1: 'келесі жыл', '-1': 'былтырғы жыл' },
      relativeTime: {
        future: { one: '{0} жылдан кейін', other: '{0} жылдан кейін' },
        past: { one: '{0} жыл бұрын', other: '{0} жыл бұрын' },
      },
    },
    month: {
      displayName: 'Ай',
      relative: { 0: 'осы ай', 1: 'келесі ай', '-1': 'өткен ай' },
      relativeTime: {
        future: { one: '{0} айдан кейін', other: '{0} айдан кейін' },
        past: { one: '{0} ай бұрын', other: '{0} ай бұрын' },
      },
    },
    day: {
      displayName: 'күн',
      relative: { 0: 'бүгін', 1: 'ертең', 2: 'арғы күні', '-1': 'кеше', '-2': 'алдыңғы күні' },
      relativeTime: {
        future: { one: '{0} күннен кейін', other: '{0} күннен кейін' },
        past: { one: '{0} күн бұрын', other: '{0} күн бұрын' },
      },
    },
    hour: {
      displayName: 'Сағат',
      relativeTime: {
        future: { one: '{0} сағаттан кейін', other: '{0} сағаттан кейін' },
        past: { one: '{0} сағат бұрын', other: '{0} сағат бұрын' },
      },
    },
    minute: {
      displayName: 'Минут',
      relativeTime: {
        future: { one: '{0} минуттан кейін', other: '{0} минуттан кейін' },
        past: { one: '{0} минут бұрын', other: '{0} минут бұрын' },
      },
    },
    second: {
      displayName: 'Секунд',
      relative: { 0: 'қазір' },
      relativeTime: {
        future: { one: '{0} секундтан кейін', other: '{0} секундтан кейін' },
        past: { one: '{0} секунд бұрын', other: '{0} секунд бұрын' },
      },
    },
  },
});
ReactIntl.__addLocaleData({
  locale: 'ko',
  pluralRuleFunction: function (n, ord) {
    if (ord) return 'other';
    return 'other';
  },
  fields: {
    year: {
      displayName: '년',
      relative: { 0: '올해', 1: '내년', '-1': '작년' },
      relativeTime: { future: { other: '{0}년 후' }, past: { other: '{0}년 전' } },
    },
    month: {
      displayName: '월',
      relative: { 0: '이번 달', 1: '다음 달', '-1': '지난달' },
      relativeTime: { future: { other: '{0}개월 후' }, past: { other: '{0}개월 전' } },
    },
    day: {
      displayName: '일',
      relative: { 0: '오늘', 1: '내일', 2: '모레', '-1': '어제', '-2': '그저께' },
      relativeTime: { future: { other: '{0}일 후' }, past: { other: '{0}일 전' } },
    },
    hour: { displayName: '시', relativeTime: { future: { other: '{0}시간 후' }, past: { other: '{0}시간 전' } } },
    minute: { displayName: '분', relativeTime: { future: { other: '{0}분 후' }, past: { other: '{0}분 전' } } },
    second: {
      displayName: '초',
      relative: { 0: '지금' },
      relativeTime: { future: { other: '{0}초 후' }, past: { other: '{0}초 전' } },
    },
  },
});
ReactIntl.__addLocaleData({
  locale: 'th',
  pluralRuleFunction: function (n, ord) {
    if (ord) return 'other';
    return 'other';
  },
  fields: {
    year: {
      displayName: 'ปี',
      relative: { 0: 'ปีนี้', 1: 'ปีหน้า', '-1': 'ปีที่แล้ว' },
      relativeTime: { future: { other: 'ในอีก {0} ปี' }, past: { other: '{0} ปีที่แล้ว' } },
    },
    month: {
      displayName: 'เดือน',
      relative: { 0: 'เดือนนี้', 1: 'เดือนหน้า', '-1': 'เดือนที่แล้ว' },
      relativeTime: { future: { other: 'ในอีก {0} เดือน' }, past: { other: '{0} เดือนที่ผ่านมา' } },
    },
    day: {
      displayName: 'วัน',
      relative: { 0: 'วันนี้', 1: 'พรุ่งนี้', 2: 'มะรืนนี้', '-1': 'เมื่อวาน', '-2': 'เมื่อวานซืน' },
      relativeTime: { future: { other: 'ในอีก {0} วัน' }, past: { other: '{0} วันที่ผ่านมา' } },
    },
    hour: {
      displayName: 'ชั่วโมง',
      relativeTime: { future: { other: 'ในอีก {0} ชั่วโมง' }, past: { other: '{0} ชั่วโมงที่ผ่านมา' } },
    },
    minute: {
      displayName: 'นาที',
      relativeTime: { future: { other: 'ในอีก {0} นาที' }, past: { other: '{0} นาทีที่ผ่านมา' } },
    },
    second: {
      displayName: 'วินาที',
      relative: { 0: 'ขณะนี้' },
      relativeTime: { future: { other: 'ในอีก {0} วินาที' }, past: { other: '{0} วินาทีที่ผ่านมา' } },
    },
  },
});
ReactIntl.__addLocaleData({
  locale: 'pt',
  pluralRuleFunction: function (n, ord) {
    var s = String(n).split('.'),
      t0 = Number(s[0]) == n;
    if (ord) return 'other';
    return t0 && n >= 0 && n <= 2 && n != 2 ? 'one' : 'other';
  },
  fields: {
    year: {
      displayName: 'Ano',
      relative: { 0: 'este ano', 1: 'próximo ano', '-1': 'ano passado' },
      relativeTime: {
        future: { one: 'Dentro de {0} ano', other: 'Dentro de {0} anos' },
        past: { one: 'Há {0} ano', other: 'Há {0} anos' },
      },
    },
    month: {
      displayName: 'Mês',
      relative: { 0: 'este mês', 1: 'próximo mês', '-1': 'mês passado' },
      relativeTime: {
        future: { one: 'Dentro de {0} mês', other: 'Dentro de {0} meses' },
        past: { one: 'Há {0} mês', other: 'Há {0} meses' },
      },
    },
    day: {
      displayName: 'Dia',
      relative: { 0: 'hoje', 1: 'amanhã', 2: 'depois de amanhã', '-1': 'ontem', '-2': 'anteontem' },
      relativeTime: {
        future: { one: 'Dentro de {0} dia', other: 'Dentro de {0} dias' },
        past: { one: 'Há {0} dia', other: 'Há {0} dias' },
      },
    },
    hour: {
      displayName: 'Hora',
      relativeTime: {
        future: { one: 'Dentro de {0} hora', other: 'Dentro de {0} horas' },
        past: { one: 'Há {0} hora', other: 'Há {0} horas' },
      },
    },
    minute: {
      displayName: 'Minuto',
      relativeTime: {
        future: { one: 'Dentro de {0} minuto', other: 'Dentro de {0} minutos' },
        past: { one: 'Há {0} minuto', other: 'Há {0} minutos' },
      },
    },
    second: {
      displayName: 'Segundo',
      relative: { 0: 'agora' },
      relativeTime: {
        future: { one: 'Dentro de {0} segundo', other: 'Dentro de {0} segundos' },
        past: { one: 'Há {0} segundo', other: 'Há {0} segundos' },
      },
    },
  },
});
ReactIntl.__addLocaleData({
  locale: 'uk',
  pluralRuleFunction: function (n, ord) {
    var s = String(n).split('.'),
      i = s[0],
      v0 = !s[1],
      t0 = Number(s[0]) == n,
      n10 = t0 && s[0].slice(-1),
      n100 = t0 && s[0].slice(-2),
      i10 = i.slice(-1),
      i100 = i.slice(-2);
    if (ord) return n10 == 3 && n100 != 13 ? 'few' : 'other';
    return v0 && i10 == 1 && i100 != 11
      ? 'one'
      : v0 && i10 >= 2 && i10 <= 4 && (i100 < 12 || i100 > 14)
      ? 'few'
      : (v0 && i10 == 0) || (v0 && i10 >= 5 && i10 <= 9) || (v0 && i100 >= 11 && i100 <= 14)
      ? 'many'
      : 'other';
  },
  fields: {
    year: {
      displayName: 'Рік',
      relative: { 0: 'цього року', 1: 'наступного року', '-1': 'торік' },
      relativeTime: {
        future: { one: 'через {0} рік', few: 'через {0} роки', many: 'через {0} років', other: 'через {0} року' },
        past: { one: '{0} рік тому', few: '{0} роки тому', many: '{0} років тому', other: '{0} року тому' },
      },
    },
    month: {
      displayName: 'Місяць',
      relative: { 0: 'цього місяця', 1: 'наступного місяця', '-1': 'минулого місяця' },
      relativeTime: {
        future: {
          one: 'через {0} місяць',
          few: 'через {0} місяці',
          many: 'через {0} місяців',
          other: 'через {0} місяця',
        },
        past: { one: '{0} місяць тому', few: '{0} місяці тому', many: '{0} місяців тому', other: '{0} місяця тому' },
      },
    },
    day: {
      displayName: 'День',
      relative: { 0: 'сьогодні', 1: 'завтра', 2: 'післязавтра', '-1': 'учора', '-2': 'позавчора' },
      relativeTime: {
        future: { one: 'через {0} день', few: 'через {0} дні', many: 'через {0} днів', other: 'через {0} дня' },
        past: { one: '{0} день тому', few: '{0} дні тому', many: '{0} днів тому', other: '{0} дня тому' },
      },
    },
    hour: {
      displayName: 'Година',
      relativeTime: {
        future: {
          one: 'через {0} годину',
          few: 'через {0} години',
          many: 'через {0} годин',
          other: 'через {0} години',
        },
        past: { one: '{0} годину тому', few: '{0} години тому', many: '{0} годин тому', other: '{0} години тому' },
      },
    },
    minute: {
      displayName: 'Хвилина',
      relativeTime: {
        future: {
          one: 'через {0} хвилину',
          few: 'через {0} хвилини',
          many: 'через {0} хвилин',
          other: 'через {0} хвилини',
        },
        past: { one: '{0} хвилину тому', few: '{0} хвилини тому', many: '{0} хвилин тому', other: '{0} хвилини тому' },
      },
    },
    second: {
      displayName: 'Секунда',
      relative: { 0: 'зараз' },
      relativeTime: {
        future: {
          one: 'через {0} секунду',
          few: 'через {0} секунди',
          many: 'через {0} секунд',
          other: 'через {0} секунди',
        },
        past: { one: '{0} секунду тому', few: '{0} секунди тому', many: '{0} секунд тому', other: '{0} секунди тому' },
      },
    },
  },
});
ReactIntl.__addLocaleData({
  locale: 'ru',
  pluralRuleFunction: function (n, ord) {
    var s = String(n).split('.'),
      i = s[0],
      v0 = !s[1],
      i10 = i.slice(-1),
      i100 = i.slice(-2);
    if (ord) return 'other';
    return v0 && i10 == 1 && i100 != 11
      ? 'one'
      : v0 && i10 >= 2 && i10 <= 4 && (i100 < 12 || i100 > 14)
      ? 'few'
      : (v0 && i10 == 0) || (v0 && i10 >= 5 && i10 <= 9) || (v0 && i100 >= 11 && i100 <= 14)
      ? 'many'
      : 'other';
  },
  fields: {
    year: {
      displayName: 'Год',
      relative: { 0: 'в этому году', 1: 'в следующем году', '-1': 'в прошлом году' },
      relativeTime: {
        future: { one: 'через {0} год', few: 'через {0} года', many: 'через {0} лет', other: 'через {0} года' },
        past: { one: '{0} год назад', few: '{0} года назад', many: '{0} лет назад', other: '{0} года назад' },
      },
    },
    month: {
      displayName: 'Месяц',
      relative: { 0: 'в этом месяце', 1: 'в следующем месяце', '-1': 'в прошлом месяце' },
      relativeTime: {
        future: {
          one: 'через {0} месяц',
          few: 'через {0} месяца',
          many: 'через {0} месяцев',
          other: 'через {0} месяца',
        },
        past: { one: '{0} месяц назад', few: '{0} месяца назад', many: '{0} месяцев назад', other: '{0} месяца назад' },
      },
    },
    day: {
      displayName: 'День',
      relative: { 0: 'сегодня', 1: 'завтра', 2: 'послезавтра', '-1': 'вчера', '-2': 'позавчера' },
      relativeTime: {
        future: { one: 'через {0} день', few: 'через {0} дня', many: 'через {0} дней', other: 'через {0} дней' },
        past: { one: '{0} день назад', few: '{0} дня назад', many: '{0} дней назад', other: '{0} дня назад' },
      },
    },
    hour: {
      displayName: 'Час',
      relativeTime: {
        future: { one: 'через {0} час', few: 'через {0} часа', many: 'через {0} часов', other: 'через {0} часа' },
        past: { one: '{0} час назад', few: '{0} часа назад', many: '{0} часов назад', other: '{0} часа назад' },
      },
    },
    minute: {
      displayName: 'Минута',
      relativeTime: {
        future: {
          one: 'через {0} минуту',
          few: 'через {0} минуты',
          many: 'через {0} минут',
          other: 'через {0} минуты',
        },
        past: { one: '{0} минуту назад', few: '{0} минуты назад', many: '{0} минут назад', other: '{0} минуты назад' },
      },
    },
    second: {
      displayName: 'Секунда',
      relative: { 0: 'сейчас' },
      relativeTime: {
        future: {
          one: 'через {0} секунду',
          few: 'через {0} секунды',
          many: 'через {0} секунд',
          other: 'через {0} секунды',
        },
        past: {
          one: '{0} секунду назад',
          few: '{0} секунды назад',
          many: '{0} секунд назад',
          other: '{0} секунды назад',
        },
      },
    },
  },
});
ReactIntl.__addLocaleData({
  locale: 'zh',
  pluralRuleFunction: function (n, ord) {
    if (ord) return 'other';
    return 'other';
  },
  fields: {
    year: {
      displayName: '年',
      relative: { 0: '今年', 1: '明年', '-1': '去年' },
      relativeTime: { future: { other: '{0}年后' }, past: { other: '{0}年前' } },
    },
    month: {
      displayName: '月',
      relative: { 0: '本月', 1: '下个月', '-1': '上个月' },
      relativeTime: { future: { other: '{0}个月后' }, past: { other: '{0}个月前' } },
    },
    day: {
      displayName: '日',
      relative: { 0: '今天', 1: '明天', 2: '后天', '-1': '昨天', '-2': '前天' },
      relativeTime: { future: { other: '{0}天后' }, past: { other: '{0}天前' } },
    },
    hour: { displayName: '小时', relativeTime: { future: { other: '{0}小时后' }, past: { other: '{0}小时前' } } },
    minute: { displayName: '分钟', relativeTime: { future: { other: '{0}分钟后' }, past: { other: '{0}分钟前' } } },
    second: {
      displayName: '秒钟',
      relative: { 0: '现在' },
      relativeTime: { future: { other: '{0}秒钟后' }, past: { other: '{0}秒钟前' } },
    },
  },
});
ReactIntl.__addLocaleData({
  locale: 'el',
  pluralRuleFunction: function (n, ord) {
    if (ord) return 'other';
    return n == 1 ? 'one' : 'other';
  },
  fields: {
    year: {
      displayName: 'Έτος',
      relative: { 0: 'φέτος', 1: 'επόμενο έτος', '-1': 'προηγούμενο έτος' },
      relativeTime: {
        future: { one: 'σε {0} έτος', other: 'σε {0} έτη' },
        past: { one: 'πριν από {0} έτος', other: 'πριν από {0} έτη' },
      },
    },
    month: {
      displayName: 'Μήνας',
      relative: { 0: 'τρέχων μήνας', 1: 'επόμενος μήνας', '-1': 'προηγούμενος μήνας' },
      relativeTime: {
        future: { one: 'σε {0} μήνα', other: 'σε {0} μήνες' },
        past: { one: 'πριν από {0} μήνα', other: 'πριν από {0} μήνες' },
      },
    },
    day: {
      displayName: 'Ημέρα',
      relative: { 0: 'σήμερα', 1: 'αύριο', 2: 'μεθαύριο', '-1': 'χθες', '-2': 'προχθές' },
      relativeTime: {
        future: { one: 'σε {0} ημέρα', other: 'σε {0} ημέρες' },
        past: { one: 'πριν από {0} ημέρα', other: 'πριν από {0} ημέρες' },
      },
    },
    hour: {
      displayName: 'Ώρα',
      relativeTime: {
        future: { one: 'σε {0} ώρα', other: 'σε {0} ώρες' },
        past: { one: 'πριν από {0} ώρα', other: 'πριν από {0} ώρες' },
      },
    },
    minute: {
      displayName: 'Λεπτό',
      relativeTime: {
        future: { one: 'σε {0} λεπτό', other: 'σε {0} λεπτά' },
        past: { one: 'πριν από {0} λεπτό', other: 'πριν από {0} λεπτά' },
      },
    },
    second: {
      displayName: 'Δευτερόλεπτο',
      relative: { 0: 'τώρα' },
      relativeTime: {
        future: { one: 'σε {0} δευτερόλεπτο', other: 'σε {0} δευτερόλεπτα' },
        past: { one: 'πριν από {0} δευτερόλεπτο', other: 'πριν από {0} δευτερόλεπτα' },
      },
    },
  },
});
ReactIntl.__addLocaleData({
  locale: 'it',
  pluralRuleFunction: function (n, ord) {
    var s = String(n).split('.'),
      v0 = !s[1];
    if (ord) return n == 11 || n == 8 || n == 80 || n == 800 ? 'many' : 'other';
    return n == 1 && v0 ? 'one' : 'other';
  },
  fields: {
    year: {
      displayName: 'Anno',
      relative: { 0: 'quest’anno', 1: 'anno prossimo', '-1': 'anno scorso' },
      relativeTime: {
        future: { one: 'tra {0} anno', other: 'tra {0} anni' },
        past: { one: '{0} anno fa', other: '{0} anni fa' },
      },
    },
    month: {
      displayName: 'Mese',
      relative: { 0: 'questo mese', 1: 'mese prossimo', '-1': 'mese scorso' },
      relativeTime: {
        future: { one: 'tra {0} mese', other: 'tra {0} mesi' },
        past: { one: '{0} mese fa', other: '{0} mesi fa' },
      },
    },
    day: {
      displayName: 'Giorno',
      relative: { 0: 'oggi', 1: 'domani', 2: 'dopodomani', '-1': 'ieri', '-2': 'l’altro ieri' },
      relativeTime: {
        future: { one: 'tra {0} giorno', other: 'tra {0} giorni' },
        past: { one: '{0} giorno fa', other: '{0} giorni fa' },
      },
    },
    hour: {
      displayName: 'Ora',
      relativeTime: {
        future: { one: 'tra {0} ora', other: 'tra {0} ore' },
        past: { one: '{0} ora fa', other: '{0} ore fa' },
      },
    },
    minute: {
      displayName: 'Minuto',
      relativeTime: {
        future: { one: 'tra {0} minuto', other: 'tra {0} minuti' },
        past: { one: '{0} minuto fa', other: '{0} minuti fa' },
      },
    },
    second: {
      displayName: 'Secondo',
      relative: { 0: 'ora' },
      relativeTime: {
        future: { one: 'tra {0} secondo', other: 'tra {0} secondi' },
        past: { one: '{0} secondo fa', other: '{0} secondi fa' },
      },
    },
  },
});
ReactIntl.__addLocaleData({
  locale: 'tr',
  pluralRuleFunction: function (n, ord) {
    if (ord) return 'other';
    return n == 1 ? 'one' : 'other';
  },
  fields: {
    year: {
      displayName: 'Yıl',
      relative: { 0: 'bu yıl', 1: 'gelecek yıl', '-1': 'geçen yıl' },
      relativeTime: {
        future: { one: '{0} yıl sonra', other: '{0} yıl sonra' },
        past: { one: '{0} yıl önce', other: '{0} yıl önce' },
      },
    },
    month: {
      displayName: 'Ay',
      relative: { 0: 'bu ay', 1: 'gelecek ay', '-1': 'geçen ay' },
      relativeTime: {
        future: { one: '{0} ay sonra', other: '{0} ay sonra' },
        past: { one: '{0} ay önce', other: '{0} ay önce' },
      },
    },
    day: {
      displayName: 'Gün',
      relative: { 0: 'bugün', 1: 'yarın', 2: 'öbür gün', '-1': 'dün', '-2': 'evvelsi gün' },
      relativeTime: {
        future: { one: '{0} gün sonra', other: '{0} gün sonra' },
        past: { one: '{0} gün önce', other: '{0} gün önce' },
      },
    },
    hour: {
      displayName: 'Saat',
      relativeTime: {
        future: { one: '{0} saat sonra', other: '{0} saat sonra' },
        past: { one: '{0} saat önce', other: '{0} saat önce' },
      },
    },
    minute: {
      displayName: 'Dakika',
      relativeTime: {
        future: { one: '{0} dakika sonra', other: '{0} dakika sonra' },
        past: { one: '{0} dakika önce', other: '{0} dakika önce' },
      },
    },
    second: {
      displayName: 'Saniye',
      relative: { 0: 'şimdi' },
      relativeTime: {
        future: { one: '{0} saniye sonra', other: '{0} saniye sonra' },
        past: { one: '{0} saniye önce', other: '{0} saniye önce' },
      },
    },
  },
});
ReactIntl.__addLocaleData({
  locale: 'pl',
  pluralRuleFunction: function (n, ord) {
    var s = String(n).split('.'),
      i = s[0],
      v0 = !s[1],
      i10 = i.slice(-1),
      i100 = i.slice(-2);
    if (ord) return 'other';
    return n == 1 && v0
      ? 'one'
      : v0 && i10 >= 2 && i10 <= 4 && (i100 < 12 || i100 > 14)
      ? 'few'
      : (v0 && i != 1 && (i10 == 0 || i10 == 1)) || (v0 && i10 >= 5 && i10 <= 9) || (v0 && i100 >= 12 && i100 <= 14)
      ? 'many'
      : 'other';
  },
  fields: {
    year: {
      displayName: 'rok',
      relative: { 0: 'w tym roku', 1: 'w przyszłym roku', '-1': 'w zeszłym roku' },
      relativeTime: {
        future: { one: 'za {0} rok', few: 'za {0} lata', many: 'za {0} lat', other: 'za {0} roku' },
        past: { one: '{0} rok temu', few: '{0} lata temu', many: '{0} lat temu', other: '{0} roku temu' },
      },
    },
    month: {
      displayName: 'miesiąc',
      relative: { 0: 'w tym miesiącu', 1: 'w przyszłym miesiącu', '-1': 'w zeszłym miesiącu' },
      relativeTime: {
        future: { one: 'za {0} miesiąc', few: 'za {0} miesiące', many: 'za {0} miesięcy', other: 'za {0} miesiąca' },
        past: {
          one: '{0} miesiąc temu',
          few: '{0} miesiące temu',
          many: '{0} miesięcy temu',
          other: '{0} miesiąca temu',
        },
      },
    },
    day: {
      displayName: 'dzień',
      relative: { 0: 'dzisiaj', 1: 'jutro', 2: 'pojutrze', '-1': 'wczoraj', '-2': 'przedwczoraj' },
      relativeTime: {
        future: { one: 'za {0} dzień', few: 'za {0} dni', many: 'za {0} dni', other: 'za {0} dnia' },
        past: { one: '{0} dzień temu', few: '{0} dni temu', many: '{0} dni temu', other: '{0} dnia temu' },
      },
    },
    hour: {
      displayName: 'godzina',
      relativeTime: {
        future: { one: 'za {0} godzinę', few: 'za {0} godziny', many: 'za {0} godzin', other: 'za {0} godziny' },
        past: { one: '{0} godzinę temu', few: '{0} godziny temu', many: '{0} godzin temu', other: '{0} godziny temu' },
      },
    },
    minute: {
      displayName: 'minuta',
      relativeTime: {
        future: { one: 'za {0} minutę', few: 'za {0} minuty', many: 'za {0} minut', other: 'za {0} minuty' },
        past: { one: '{0} minutę temu', few: '{0} minuty temu', many: '{0} minut temu', other: '{0} minuty temu' },
      },
    },
    second: {
      displayName: 'sekunda',
      relative: { 0: 'teraz' },
      relativeTime: {
        future: { one: 'za {0} sekundę', few: 'za {0} sekundy', many: 'za {0} sekund', other: 'za {0} sekundy' },
        past: { one: '{0} sekundę temu', few: '{0} sekundy temu', many: '{0} sekund temu', other: '{0} sekundy temu' },
      },
    },
  },
});
ReactIntl.__addLocaleData({
  locale: 'hu',
  pluralRuleFunction: function (n, ord) {
    if (ord) return n == 1 || n == 5 ? 'one' : 'other';
    return n == 1 ? 'one' : 'other';
  },
  fields: {
    year: {
      displayName: 'év',
      relative: { 0: 'ez az év', 1: 'következő év', '-1': 'előző év' },
      relativeTime: {
        future: { one: '{0} év múlva', other: '{0} év múlva' },
        past: { one: '{0} évvel ezelőtt', other: '{0} évvel ezelőtt' },
      },
    },
    month: {
      displayName: 'hónap',
      relative: { 0: 'ez a hónap', 1: 'következő hónap', '-1': 'előző hónap' },
      relativeTime: {
        future: { one: '{0} hónap múlva', other: '{0} hónap múlva' },
        past: { one: '{0} hónappal ezelőtt', other: '{0} hónappal ezelőtt' },
      },
    },
    day: {
      displayName: 'nap',
      relative: { 0: 'ma', 1: 'holnap', 2: 'holnapután', '-1': 'tegnap', '-2': 'tegnapelőtt' },
      relativeTime: {
        future: { one: '{0} nap múlva', other: '{0} nap múlva' },
        past: { one: '{0} nappal ezelőtt', other: '{0} nappal ezelőtt' },
      },
    },
    hour: {
      displayName: 'óra',
      relativeTime: {
        future: { one: '{0} óra múlva', other: '{0} óra múlva' },
        past: { one: '{0} órával ezelőtt', other: '{0} órával ezelőtt' },
      },
    },
    minute: {
      displayName: 'perc',
      relativeTime: {
        future: { one: '{0} perc múlva', other: '{0} perc múlva' },
        past: { one: '{0} perccel ezelőtt', other: '{0} perccel ezelőtt' },
      },
    },
    second: {
      displayName: 'másodperc',
      relative: { 0: 'most' },
      relativeTime: {
        future: { one: '{0} másodperc múlva', other: '{0} másodperc múlva' },
        past: { one: '{0} másodperccel ezelőtt', other: '{0} másodperccel ezelőtt' },
      },
    },
  },
});
ReactIntl.__addLocaleData({
  locale: 'sv',
  pluralRuleFunction: function (n, ord) {
    var s = String(n).split('.'),
      v0 = !s[1],
      t0 = Number(s[0]) == n,
      n10 = t0 && s[0].slice(-1),
      n100 = t0 && s[0].slice(-2);
    if (ord) return (n10 == 1 || n10 == 2) && n100 != 11 && n100 != 12 ? 'one' : 'other';
    return n == 1 && v0 ? 'one' : 'other';
  },
  fields: {
    year: {
      displayName: 'År',
      relative: { 0: 'i år', 1: 'nästa år', '-1': 'i fjol' },
      relativeTime: {
        future: { one: 'om {0} år', other: 'om {0} år' },
        past: { one: 'för {0} år sedan', other: 'för {0} år sedan' },
      },
    },
    month: {
      displayName: 'Månad',
      relative: { 0: 'denna månad', 1: 'nästa månad', '-1': 'förra månaden' },
      relativeTime: {
        future: { one: 'om {0} månad', other: 'om {0} månader' },
        past: { one: 'för {0} månad sedan', other: 'för {0} månader sedan' },
      },
    },
    day: {
      displayName: 'Dag',
      relative: { 0: 'i dag', 1: 'i morgon', 2: 'i övermorgon', '-1': 'i går', '-2': 'i förrgår' },
      relativeTime: {
        future: { one: 'om {0} dag', other: 'om {0} dagar' },
        past: { one: 'för {0} dag sedan', other: 'för {0} dagar sedan' },
      },
    },
    hour: {
      displayName: 'Timme',
      relativeTime: {
        future: { one: 'om {0} timme', other: 'om {0} timmar' },
        past: { one: 'för {0} timme sedan', other: 'för {0} timmar sedan' },
      },
    },
    minute: {
      displayName: 'Minut',
      relativeTime: {
        future: { one: 'om {0} minut', other: 'om {0} minuter' },
        past: { one: 'för {0} minut sedan', other: 'för {0} minuter sedan' },
      },
    },
    second: {
      displayName: 'Sekund',
      relative: { 0: 'nu' },
      relativeTime: {
        future: { one: 'om {0} sekund', other: 'om {0} sekunder' },
        past: { one: 'för {0} sekund sedan', other: 'för {0} sekunder sedan' },
      },
    },
  },
});
ReactIntl.__addLocaleData({
  locale: 'nl',
  pluralRuleFunction: function (n, ord) {
    var s = String(n).split('.'),
      v0 = !s[1];
    if (ord) return 'other';
    return n == 1 && v0 ? 'one' : 'other';
  },
  fields: {
    year: {
      displayName: 'Jaar',
      relative: { 0: 'dit jaar', 1: 'volgend jaar', '-1': 'vorig jaar' },
      relativeTime: {
        future: { one: 'over {0} jaar', other: 'over {0} jaar' },
        past: { one: '{0} jaar geleden', other: '{0} jaar geleden' },
      },
    },
    month: {
      displayName: 'Maand',
      relative: { 0: 'deze maand', 1: 'volgende maand', '-1': 'vorige maand' },
      relativeTime: {
        future: { one: 'over {0} maand', other: 'over {0} maanden' },
        past: { one: '{0} maand geleden', other: '{0} maanden geleden' },
      },
    },
    day: {
      displayName: 'Dag',
      relative: { 0: 'vandaag', 1: 'morgen', 2: 'overmorgen', '-1': 'gisteren', '-2': 'eergisteren' },
      relativeTime: {
        future: { one: 'over {0} dag', other: 'over {0} dagen' },
        past: { one: '{0} dag geleden', other: '{0} dagen geleden' },
      },
    },
    hour: {
      displayName: 'Uur',
      relativeTime: {
        future: { one: 'over {0} uur', other: 'over {0} uur' },
        past: { one: '{0} uur geleden', other: '{0} uur geleden' },
      },
    },
    minute: {
      displayName: 'Minuut',
      relativeTime: {
        future: { one: 'over {0} minuut', other: 'over {0} minuten' },
        past: { one: '{0} minuut geleden', other: '{0} minuten geleden' },
      },
    },
    second: {
      displayName: 'Seconde',
      relative: { 0: 'nu' },
      relativeTime: {
        future: { one: 'over {0} seconde', other: 'over {0} seconden' },
        past: { one: '{0} seconde geleden', other: '{0} seconden geleden' },
      },
    },
  },
});
ReactIntl.__addLocaleData({
  locale: 'hi',
  pluralRuleFunction: function (n, ord) {
    if (ord) return n == 1 ? 'one' : n == 2 || n == 3 ? 'two' : n == 4 ? 'few' : n == 6 ? 'many' : 'other';
    return n >= 0 && n <= 1 ? 'one' : 'other';
  },
  fields: {
    year: {
      displayName: 'वर्ष',
      relative: { 0: 'इस वर्ष', 1: 'अगला वर्ष', '-1': 'पिछला वर्ष' },
      relativeTime: {
        future: { one: '{0} वर्ष में', other: '{0} वर्ष में' },
        past: { one: '{0} वर्ष पहले', other: '{0} वर्ष पहले' },
      },
    },
    month: {
      displayName: 'माह',
      relative: { 0: 'इस माह', 1: 'अगला माह', '-1': 'पिछला माह' },
      relativeTime: {
        future: { one: '{0} माह में', other: '{0} माह में' },
        past: { one: '{0} माह पहले', other: '{0} माह पहले' },
      },
    },
    day: {
      displayName: 'दिन',
      relative: { 0: 'आज', 1: 'कल', 2: 'परसों', '-1': 'कल', '-2': 'बीता परसों' },
      relativeTime: {
        future: { one: '{0} दिन में', other: '{0} दिन में' },
        past: { one: '{0} दिन पहले', other: '{0} दिन पहले' },
      },
    },
    hour: {
      displayName: 'घंटा',
      relativeTime: {
        future: { one: '{0} घंटे में', other: '{0} घंटे में' },
        past: { one: '{0} घंटे पहले', other: '{0} घंटे पहले' },
      },
    },
    minute: {
      displayName: 'मिनट',
      relativeTime: {
        future: { one: '{0} मिनट में', other: '{0} मिनट में' },
        past: { one: '{0} मिनट पहले', other: '{0} मिनट पहले' },
      },
    },
    second: {
      displayName: 'सेकंड',
      relative: { 0: 'अब' },
      relativeTime: {
        future: { one: '{0} सेकंड में', other: '{0} सेकंड में' },
        past: { one: '{0} सेकंड पहले', other: '{0} सेकंड पहले' },
      },
    },
  },
});

ReactIntl.__addLocaleData({
  locale: 'ps',
  pluralRuleFunction: function (e, t) {
    return t ? 'other' : 1 == e ? 'one' : 'other';
  },
  fields: {
    year: {
      displayName: 'کال',
      relative: { 0: 'سږکال', 1: 'بل کال', '-1': 'پروسږکال' },
      relativeTime: {
        future: { one: 'په {0} کال کې', other: 'په {0} کالونو کې' },
        past: { one: '{0} کال مخکې', other: '{0} کاله مخکې' },
      },
    },
    month: {
      displayName: 'مياشت',
      relative: { 0: 'دا میاشت', 1: 'راتلونکې میاشت', '-1': 'تیره میاشت' },
      relativeTime: {
        future: { one: 'په {0} میاشت کې', other: 'په {0} میاشتو کې' },
        past: { one: '{0} میاشت مخکي', other: '{0} میاشتې مخکې' },
      },
    },
    day: {
      displayName: 'ورځ',
      relative: { 0: 'نن', 1: 'سبا', '-1': 'پرون' },
      relativeTime: {
        future: { one: 'په {0} ورځو کې', other: 'په {0} ورځو کې' },
        past: { one: '{0} ورځ مخکې', other: '{0} ورځې مخکی' },
      },
    },
    hour: {
      displayName: 'ساعت',
      relative: { 0: 'دا ساعت' },
      relativeTime: {
        future: { one: 'په {0} ساعت کې', other: 'په {0} ساعتونو کې' },
        past: { one: '{0} ساعت مخکې', other: '{0} ساعته مخکې' },
      },
    },
    minute: {
      displayName: 'دقيقه',
      relative: { 0: 'دا دقيقه' },
      relativeTime: {
        future: { one: 'په {0} دقیقو کې', other: 'په {0} دقیقو کې' },
        past: { one: '{0} دقیقې مخکې', other: '{0} دقیقې مخکې' },
      },
    },
    second: {
      displayName: 'ثانيه',
      relative: { 0: 'اوس' },
      relativeTime: {
        future: { one: 'په {0} ثانیو کې', other: 'په {0} ثانیو کې' },
        past: { one: '{0} ثانیې مخکې', other: '{0} ثانیې مخکې' },
      },
    },
  },
});

ReactIntl.__addLocaleData({
  locale: 'fa-af',
  pluralRuleFunction: function (n, ord) {
    if (ord) return 'other';
    return n >= 0 && n <= 1 ? 'one' : 'other';
  },
  fields: {
    year: {
      displayName: 'سال',
      relative: { 0: 'امسال', 1: 'سال آینده', '-1': 'سال گذشته' },
      relativeTime: {
        future: { one: '{0} سال بعد', other: '{0} سال بعد' },
        past: { one: '{0} سال پیش', other: '{0} سال پیش' },
      },
    },
    month: {
      displayName: 'ماه',
      relative: { 0: 'این ماه', 1: 'ماه آینده', '-1': 'ماه گذشته' },
      relativeTime: {
        future: { one: '{0} ماه بعد', other: '{0} ماه بعد' },
        past: { one: '{0} ماه پیش', other: '{0} ماه پیش' },
      },
    },
    day: {
      displayName: 'روز',
      relative: { 0: 'امروز', 1: 'فردا', 2: 'پس‌فردا', '-1': 'دیروز', '-2': 'پریروز' },
      relativeTime: {
        future: { one: '{0} روز بعد', other: '{0} روز بعد' },
        past: { one: '{0} روز پیش', other: '{0} روز پیش' },
      },
    },
    hour: {
      displayName: 'ساعت',
      relativeTime: {
        future: { one: '{0} ساعت بعد', other: '{0} ساعت بعد' },
        past: { one: '{0} ساعت پیش', other: '{0} ساعت پیش' },
      },
    },
    minute: {
      displayName: 'دقیقه',
      relativeTime: {
        future: { one: '{0} دقیقه بعد', other: '{0} دقیقه بعد' },
        past: { one: '{0} دقیقه پیش', other: '{0} دقیقه پیش' },
      },
    },
    second: {
      displayName: 'ثانیه',
      relative: { 0: 'اکنون' },
      relativeTime: {
        future: { one: '{0} ثانیه بعد', other: '{0} ثانیه بعد' },
        past: { one: '{0} ثانیه پیش', other: '{0} ثانیه پیش' },
      },
    },
  },
});

export default ReactIntl;

// all exports from node_modules/react-intl/src/react-intl
export const {
  IntlMixin,
  FormattedDate,
  FormattedTime,
  FormattedRelative,
  FormattedNumber,
  FormattedMessage,
  FormattedHTMLMessage,
  __addLocaleData,
} = ReactIntl;
