// Webpack chooses only to insert the async module loader when there is a dynamic import. Unfortunately, the way we
// inject localized JavaScript chunks using AddLocalAssets does not trigger this (only code within the chunk that is
// loaded async does). Some apps, like internal-employees, do not have any dynamic imports. This serves as a ubiquitous
// dynamic import so that Webpack always inserts the async module loader inline into the HTML.
//
// Please do not change this without resolving the above issue.
import('./fakeAsyncModuleToForceWebpackLoader2');

// This is a harmless side-effect to prevent the module from being optimized away.
Date.now();
