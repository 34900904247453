import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';

import { breakpoints } from '@coursera/cds-common';
import type {
  SpacingValue,
  GridSpacing,
  Breakpoint,
} from '@coursera/cds-common';

import generateEmotionClasses from '@core/utils/generateEmotionClasses';

export const classes = generateEmotionClasses('grid', ['item']);

const getSpacingCss = (spacing: SpacingValue) => css`
  margin: -${spacing / 2}px;
  width: calc(100% + ${spacing}px);

  & > .${classes.item} {
    padding: ${spacing / 2}px;
  }
`;

const getSpacingCssForBreakpoint = (
  breakpoint: Breakpoint,
  spacing: SpacingValue
) => css`
  ${breakpoints.up(breakpoint)} {
    ${getSpacingCss(spacing)}
  }
`;

export default (spacing: GridSpacing) => {
  if (typeof spacing === 'number') {
    return getSpacingCss(spacing);
  }

  const styles: Array<SerializedStyles> = [];
  const breakpoints: Array<Breakpoint> = ['xs', 'sm', 'md', 'lg', 'xl'];

  breakpoints.forEach((breakpoint) => {
    const spacingForBreakpoint = spacing[breakpoint];

    if (spacingForBreakpoint) {
      styles.push(getSpacingCssForBreakpoint(breakpoint, spacingForBreakpoint));
    }
  });

  return css`
    ${styles}
  `;
};
