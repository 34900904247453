import { useFocusRing } from '@react-aria/focus';
import clsx from 'clsx';

import { classes } from '@core/cards/common/getCommonCardCss';
import type { LinkProps } from '@core/Link';
import Link from '@core/Link';
import Typography from '@core/Typography2';

export type CardTitleProps = {
  /**
   * Display name for the card title
   */
  name: string;

  /**
   * The tag to use for the title name, depending on the semantic page structure.
   * (e.g. use `h4` if `h3` is already used for a header section)
   * @default h3
   */
  component?: 'h3' | 'h4';

  /**
   * Accessible description that will be read out when a card gets
   * focused by a screenreader.
   */
  ['aria-label']?: string;

  ['aria-labelledby']?: string;

  /**
   * Props for the underlying Link component, such as the `href` to navigate to
   * on card clicks. Or for custom link tracking cases using the `component` prop
   * and `customLinkProps` for additional link props.
   */
  linkProps?: Omit<LinkProps, 'children'>;

  /**
   * Only pass the required link props when using a custom link component such as for tracking.
   */
  customLinkProps?: Record<string, unknown>;

  id?: string;
};

/**
 * Common component to render the main title of the card.
 * The title is always rendered with a link to navigate to on card clicks.
 * In the future, we may have other cards where the title may not
 * be a link at which point this component will be extended to support that.
 */
const CardTitle = (props: CardTitleProps) => {
  const {
    name,
    component = 'h3',
    'aria-label': ariaLabel,
    'aria-labelledby': ariaLabelledBy,
    id,
    linkProps,
    customLinkProps = null,
  } = props;
  const { isFocusVisible, focusProps } = useFocusRing();

  const titleProps = {
    'aria-label': ariaLabel,
    'aria-labelledby': ariaLabelledBy,
    ...linkProps,
    ...customLinkProps,
  };

  return (
    <Link
      className={clsx(classes.titleLink, {
        [classes.linkFocusVisible]: isFocusVisible,
      })}
      id={id}
      {...titleProps}
      {...focusProps}
    >
      <Typography
        className={classes.title}
        component={component}
        variant="subtitleMedium"
      >
        {name}
      </Typography>
    </Link>
  );
};

export default CardTitle;
