/**
 * Preloader is for dependencies that are of the highest priority to be loaded. Right now, this only
 * consists of 2 things:
 * 1. Support for eventing to track page events in SSR before rehydration.
 * 2. Fire an event as soon as preloader is loaded to give us more information on when the HTML parsing
 * happening. We use the performance API to track more granular metrics, but they are lost if the user
 * bounces for whatever reason before a full pageload, so we need to instrument before then.
 */
import multitracker from 'js/app/multitrackerSingleton';
import timing from 'js/lib/timing';

import isServerRendered from 'bundles/ssr/util/isServerRendered';

/**
 * Given an event name and a callback, fire the callback when the event is triggered inbetween
 * the time this preloader is executed and when rehydration is complete and the React app is rendered.
 */
const addEventListenerBetweenPreloaderAndRehydration = (eventName, callback) => {
  window.addEventListener(eventName, callback);
  // 'rendered' is a custom event fired after app rendering in `appStarterFactory`
  window.addEventListener('rendered', () => window.removeEventListener(eventName, callback));
};

const instrumentLinks = () => {
  const prehydrationEventListener = (e) => {
    if (e.target.matches('[data-click-key]')) {
      const eventKey = e.target.getAttribute('data-click-key');
      const eventValue = JSON.parse(e.target.getAttribute('data-click-value')) || {};
      eventValue.isBeforeRehydration = true;
      multitracker.pushV2([eventKey, eventValue]);
    }
  };

  addEventListenerBetweenPreloaderAndRehydration('click', prehydrationEventListener);
};

const instrumentAbandonTracking = () => {
  // Following `Load abandonment` section of https://developers.google.com/web/fundamentals/performance/user-centric-performance-metrics
  const trackLoadingAbandonedVisibilityChange = (e) => {
    const visibilityState = document.visibilityState;
    // "The time origin is a standard time which is considered to be the beginning of the current document's lifetime"
    // See https://developer.mozilla.org/en-US/docs/Web/API/DOMHighResTimeStamp#The_time_origin for more details
    const msFromTimeOrigin = performance && performance.now();
    const event = {
      key: 'web.load.abandoned.visibility_change',
      value: {
        visibilityState,
        msFromTimeOrigin,
      },
    };
    multitracker.get('400').queue.push(['send', event, null /* callback */, true /* forcePing */]);
  };
  addEventListenerBetweenPreloaderAndRehydration('visibilitychange', trackLoadingAbandonedVisibilityChange);

  // Also track `unloads`, which doesn't fire reliably on mobile (see https://www.igvita.com/2015/11/20/dont-lose-user-and-app-state-use-page-visibility/)
  // but is useful to know for how many people bounce on a page load, as visibility change could just imply
  // the user temporarily switched to another tab while waiting for Coursera to load, while unload means
  // the user closed the tab.
  const trackLoadingAbandonedUnload = () => {
    const msFromTimeOrigin = performance && performance.now();
    const event = {
      key: 'web.load.abandoned.unload',
      value: {
        msFromTimeOrigin,
      },
    };
    multitracker.get('400').queue.push(['send', event, null /* callback */, true /* forcePing */]);
  };
  addEventListenerBetweenPreloaderAndRehydration('unload', trackLoadingAbandonedUnload);
};

const instrumentAfterPreload = () => {
  timing.setMark('preloaderExecuted');
  multitracker.get('400').queue.push(['send', 'web.preload.loaded', null /* callback */, true /* forcePing */]);

  if (isServerRendered) {
    instrumentLinks();
    instrumentAbandonTracking();
  }
};

export default {
  instrumentAfterPreload,
};
