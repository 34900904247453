import { css } from '@emotion/react';

const getButtonLoaderCss = css`
  fill: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1.5rem;
  transition: opacity 0.2s ease-in-out;
  stroke-width: 0.75rem;
  stroke: currentColor;
  stroke-dasharray: 300;
  animation: button-loading-animation 2.5s linear infinite;

  @keyframes button-loading-animation {
    0% {
      stroke-dashoffset: 600;
      transform: translate(-50%, -50%) rotate(0deg);
    }

    100% {
      stroke-dashoffset: 60;
      transform: translate(-50%, -50%) rotate(720deg);
    }
  }
`;

export default getButtonLoaderCss;
