import * as React from 'react';
import { withApollo } from 'react-apollo';

import type { CountryCode, LanguageCode } from '__generated__/graphql-types';

import { LoggedOutHomePageContentFieldsFragmentDoc } from 'bundles/front-page/__generated__/contentfulOperations';
import type { LoggedOutHomePageContentFieldsFragment } from 'bundles/front-page/__generated__/contentfulTypes';
import type { FrontPageCollectionsDataState } from 'bundles/front-page/hoc/WithCollectionsData';
import { WithCollectionData } from 'bundles/front-page/hoc/WithCollectionsData';
import {
  WithLocalizedContent,
  getLocalizedQueryOption,
} from 'bundles/internationalization-lib/components/WithLocalizedContent';

type FrontPageContentfulDataState = {
  pageContentLoading?: boolean;
  pageContent?: LoggedOutHomePageContentFieldsFragment | null;
};

export type FrontPageDataState = FrontPageContentfulDataState & FrontPageCollectionsDataState;

type PropsForQuery = {
  isPreview: boolean;
  countryCode: string;
  languageCode: string;
};

type FrontPageDataProviderProps = {
  countryCode: string;
  languageCode: string;
  children: (data: FrontPageDataState) => JSX.Element | null;
} & PropsForQuery;

const LOHP_CONTENT_TYPE = 'loggedOutHomePageContent';
const LOHP_FRAGMENT_FIELDS_NAME = 'LoggedOutHomePageContentFields';

const getWithLocalizedContentProps = ({ isPreview, countryCode, languageCode }: PropsForQuery) => ({
  isPreview,
  contentType: LOHP_CONTENT_TYPE,
  fragmentDoc: LoggedOutHomePageContentFieldsFragmentDoc,
  fragmentFieldsName: LOHP_FRAGMENT_FIELDS_NAME,
  countryCode: countryCode.toUpperCase() as CountryCode,
  languageCode: languageCode as LanguageCode,
});

export const getLOHPLocalizedQueryOption = (Props: PropsForQuery) =>
  getLocalizedQueryOption(getWithLocalizedContentProps(Props));

export const FrontPageDataProvider: React.FC<FrontPageDataProviderProps> = ({
  children,
  countryCode,
  languageCode,
  isPreview,
}) => {
  return (
    <WithLocalizedContent<LoggedOutHomePageContentFieldsFragment>
      {...getWithLocalizedContentProps({ countryCode, languageCode, isPreview })}
    >
      {({ loading, data }) => (
        <WithCollectionData regionSlug={countryCode} language={languageCode}>
          {({ premiumProductCollections, discoveryCollections, collectionsLoading, matches }) => {
            if (loading) {
              return children({
                pageContent: null,
                premiumProductCollections,
                discoveryCollections,
                matches,
                collectionsLoading,
              });
            }

            return children({
              pageContent: data,
              premiumProductCollections,
              discoveryCollections,
              matches,
              collectionsLoading,
            });
          }}
        </WithCollectionData>
      )}
    </WithLocalizedContent>
  );
};

export default withApollo<FrontPageDataProviderProps>(FrontPageDataProvider);
