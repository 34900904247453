import type { TypePolicies, TypePolicy } from '@apollo/client';

import entityKeyFields from 'bundles/page/lib/network/entityKeyFields';

import NaptimeLinkNamespaces from './NaptimeLinkNamespaces.json';
import AssemblerCacheTypes from './__generated__/cache-types.assembler.json';
import GatewayCacheTypes from './__generated__/cache-types.gateway.json';

type KeyFields = TypePolicy['keyFields'];

const NamespaceTypePolicy: TypePolicy = { keyFields: [], merge: true };
const StructTypePolicy: TypePolicy = { keyFields: false, merge: true };
const keyFieldTypePolicy: (keyFields: KeyFields) => TypePolicy = (keyFields) => ({ keyFields, merge: true });

export type EntityKeyFieldsMap = Record<string, KeyFields>;

export const typePolicies: TypePolicies = Object.fromEntries([
  ...AssemblerCacheTypes.namespaces.map((name) => [name, NamespaceTypePolicy]),
  ...AssemblerCacheTypes.structs.map((name) => [name, StructTypePolicy]),
  ...GatewayCacheTypes.namespaces.map((name) => [name, NamespaceTypePolicy]),
  ...GatewayCacheTypes.structs.map((name) => [name, StructTypePolicy]),
  ...NaptimeLinkNamespaces.map((name) => [`Naptime${name}Resource`, NamespaceTypePolicy]),
  ...Object.entries(entityKeyFields).map(([name, keyFields]) => [name, keyFieldTypePolicy(keyFields)]),
]);
