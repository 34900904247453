import { useLocalizedStringFormatter } from '@coursera/cds-common';
import { CloseIcon } from '@coursera/cds-icons';

import Button from '@core/Button';
import type { ButtonProps } from '@core/Button';

import i18nMessages from './i18n';

export type Props = {
  invert?: boolean;
  onClick: ButtonProps['onClick'];
  className?: ButtonProps['className'];
};

const CloseButton = (props: Props) => {
  const { invert = false, ...rest } = props;
  const stringFormatter = useLocalizedStringFormatter(i18nMessages);

  return (
    <Button
      icon={<CloseIcon size="small" />}
      iconPosition="after"
      size="small"
      variant={invert ? 'ghostInvert' : 'ghost'}
      {...rest}
    >
      {stringFormatter.format('close')}
    </Button>
  );
};

export default CloseButton;
