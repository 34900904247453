import * as React from 'react';

import MenuGoalTab from 'bundles/megamenu/components/tabs/MenuGoalTab';
import type { SubMenuData } from 'bundles/megamenu/types/MenuData';

type Props = {
  data?: SubMenuData;
  closeMenu: () => void;
  isMobile?: boolean;
};

const DegreesTab: React.SFC<Props> = ({ data, closeMenu, isMobile }) => {
  if (!data) return null;
  return (
    <MenuGoalTab subMenuData={data} menuType="Degree" tabId="earn-a-degree" closeMenu={closeMenu} isMobile={isMobile} />
  );
};

export default DegreesTab;
