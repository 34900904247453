// This shim exists to provide an imperative API for react-intl's formatMessage. This is only available via the
// IntlMixin in V1, but it is offered as a hook in V5. We are not getting to V5 any time soon, so this provides some of
// the benefit without having to incur the upgrade cost.
//
// We should still pay the upgrade cost at some point though, we can't stay in react-intl V1 forever.
//
// Types for this are against V1's message descriptor format, so it won't be fully compatible with V5.
import * as React from 'react';
import { IntlMixin } from 'react-intl';

import createReactClass from 'create-react-class';

const IntlShimContext = React.createContext<IntlShim | undefined>(undefined);

interface IntlShim {
  formatMessage(message: string, values?: Record<string, string | number | Date>): string;
}

export type IntlShimProviderProps = {
  children: React.ReactNode;
};

// eslint-disable-next-line react/prefer-es6-class
export const IntlShimProvider = createReactClass({
  displayName: 'FormattedMessage',

  mixins: [IntlMixin],

  getContextValue() {
    if (this._contextValue == null) {
      this._contextValue = {
        formatMessage: this.formatMessage,
      };
    }
    return this._contextValue;
  },

  render() {
    return <IntlShimContext.Provider value={this.getContextValue()}>{this.props.children}</IntlShimContext.Provider>;
  },
});

export const useIntl = () => {
  const value = React.useContext(IntlShimContext);
  if (value == null) {
    throw new Error('useIntl() expects to be nested under <IntlShimProvider />');
  }
  return value;
};
