import { css } from '@emotion/react';

import { typography } from '@core/Typography2';
import generateEmotionClasses from '@core/utils/generateEmotionClasses';

import type { AvatarProps } from '.';

export const classes = generateEmotionClasses('Avatar', [
  'base',
  'user',
  'organization',
  'showBorder',
  'initial',
  'initialText',
]);

const sizeBorderRadius: Record<
  NonNullable<AvatarProps['size']>,
  { inner: number; outer: number }
> = {
  24: {
    inner: 2,
    outer: 4,
  },
  36: {
    inner: 4,
    outer: 6,
  },
  64: {
    inner: 8,
    outer: 10,
  },
  120: {
    inner: 16,
    outer: 18,
  },
  200: {
    inner: 24,
    outer: 26,
  },
};

const sizeFontStyleMap: Record<
  NonNullable<AvatarProps['size']>,
  { fontSize: string; lineHeight: string }
> = {
  24: {
    fontSize: '12px',
    lineHeight: '16px',
  },
  36: {
    fontSize: '16px',
    lineHeight: '20px',
  },
  64: {
    fontSize: '32px',
    lineHeight: '40px',
  },
  120: {
    fontSize: '56px',
    lineHeight: '64px',
  },
  200: {
    fontSize: '96px',
    lineHeight: '104px',
  },
};

const styles = ({ size }: { size: NonNullable<AvatarProps['size']> }) => css`
  &.${classes.base} {
    width: ${size}px;
    height: ${size}px;
    user-select: none;
    box-sizing: content-box;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Crop the image to fit without stretching */
    object-position: center center; /* Center the image within the container */
    color: transparent;
  }

  .${classes.initial} {
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${size}px;
    height: ${size}px;
    background-color: var(--cds-color-emphasis-primary-background-xxstrong);
    color: var(--cds-color-neutral-primary-invert);
    text-transform: uppercase;

    ${typography.bodyPrimary}
  }

  .${classes.initialText} {
    font-family: inherit;
    font-size: ${sizeFontStyleMap[size].fontSize};
    line-height: ${sizeFontStyleMap[size].lineHeight};
    font-weight: 600;
    color: var(--cds-color-neutral-primary-invert);
  }

  &.${classes.showBorder} {
    border: 1px solid var(--cds-color-neutral-stroke-primary-weak);
    padding: ${size < 120 ? 2 : 4}px;
  }

  &.${classes.user} {
    border-radius: 50%;
    overflow: hidden;

    img,
    .${classes.initial} {
      border-radius: 50%;
    }
  }

  &.${classes.organization} {
    img,
    .${classes.initial} {
      border-radius: ${sizeBorderRadius[size].inner}px;
    }

    &.${classes.showBorder} {
      border-radius: ${sizeBorderRadius[size].outer}px;
    }
  }
`;

export default styles;
