import getLocale from 'js/lib/locale.client';
import { isAuthenticatedUser } from 'js/lib/user';

import type { Client, Events, Plugin } from '@coursera/event-pulse/core';

/**
 * It is called before page view event and sets the languages to user profile.
 *
 * @param client
 */

function setLanguagesOnPageViewt(client: Client) {
  return async (eventName: keyof Events) => {
    if (eventName !== 'view_page') {
      // We are only setting languages on page view events for now.
      return;
    }
    const locale = getLocale();
    const { language: browserLanguage } = window.navigator;
    const viewedLanguage = locale;
    const profileLanguage = isAuthenticatedUser() ? locale : undefined;

    await client.sendEvent('identify', {
      set: {
        profileLanguage,
        viewedLanguage,
        browserLanguage,
      },
    });
  };
}

const plugin: Plugin = (client) => {
  client.on('beforeSend', setLanguagesOnPageViewt(client));
};

export { plugin };
