import { css } from '@emotion/react';

import { calcCircumference, calcStrokeOffset } from '@core/loaders/utils';
import generateEmotionClasses from '@core/utils/generateEmotionClasses';

import type { Props } from './LoadingSection';

export const classes = generateEmotionClasses('Loader', [
  'overlay',
  'imageContainer',
  'mainCircle',
  'secondaryCircle',
  'tertiaryCircle',
  'backgroundCircle',
  'fillCircle',
  'container',
  'label',
  'status',
  'overlayHidden',
  'invert',
]);

const styles = (props?: Props) => css`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: var(--cds-color-neutral-background-primary);

  .${classes.container} {
    width: fit-content;

    .${classes.imageContainer} {
      width: 6rem;
      transform: rotate(32.5deg);

      circle {
        cx: 50%;
        cy: 50%;
      }
    }

    .${classes.label} {
      margin: var(--cds-spacing-100) 0 0;
    }

    .${classes.status} {
      margin: var(--cds-spacing-50) 0 0;
    }

    .${classes.backgroundCircle} {
      r: 38px;
      stroke: var(--cds-color-neutral-disabled);
      stroke-width: 17;
    }

    .${classes.fillCircle} {
      r: 38px;
      stroke: var(--cds-color-interactive-primary);
      stroke-width: 17;
      stroke-dasharray: ${calcCircumference(38)};
      stroke-dashoffset: ${calcStrokeOffset(props?.value, 38)};
      transition: stroke-dashoffset 0.5s linear;
      transform-origin: center;
      transform: rotate(-120deg);
    }

    .${classes.mainCircle} {
      r: 28px;
      stroke: var(--cds-color-interactive-primary);
      stroke-width: 17px;
      stroke-dasharray: 11.875em;
      stroke-dashoffset: 12em;
      animation: loading-section-animation 2s ease-out infinite;
      animation-delay: 0.2s;
    }

    .${classes.secondaryCircle} {
      r: 28px;
      stroke: var(--cds-color-interactive-primary);
      opacity: 0.5;
      stroke-width: 17px;
      stroke-dasharray: 11.875em;
      stroke-dashoffset: 12em;
      animation: loading-section-animation 2s ease-out infinite;
      animation-delay: 0.1s;
    }

    .${classes.tertiaryCircle} {
      r: 28px;
      stroke: var(--cds-color-blue-700);
      stroke-dasharray: 11.875em;
      stroke-dashoffset: 12em;
      opacity: 0.5;
      stroke-width: 17px;
      animation: loading-section-animation 2s ease-out infinite;
    }

    @keyframes loading-section-animation {
      0% {
        stroke-dashoffset: 12em;
      }

      10% {
        stroke-dashoffset: 12em;
      }

      40% {
        stroke-dashoffset: 26.5em;
      }

      70% {
        stroke-dashoffset: 26.5em;
      }

      90% {
        stroke-dashoffset: 35.7em;
      }

      100% {
        stroke-dashoffset: 35.7em;
      }
    }
  }

  &.${classes.invert} {
    background: var(--cds-color-emphasis-primary-background-xxstrong);

    .${classes.backgroundCircle} {
      stroke: var(--cds-color-neutral-primary-invert);
      opacity: 0.2;
    }

    .${classes.fillCircle} {
      r: 38px;
      stroke: var(--cds-color-neutral-primary-invert);
    }

    .${classes.mainCircle} {
      r: 28px;
      stroke: var(--cds-color-neutral-primary-invert);
    }
  }

  &.${classes.overlayHidden} {
    background: transparent;
  }
`;

export default styles;
