import React from 'react';

import type { DialogColor, DialogVariant } from './styles';

export type DialogContextType = {
  color: DialogColor;
  invert: boolean;
  variant: DialogVariant;
  headingGroupId?: string;
};

const defaultContext: DialogContextType = {
  color: 'white',
  invert: false,
  variant: 'standard',
};

const DialogContext = React.createContext<DialogContextType>(defaultContext);

export const useDialogContext = (): DialogContextType => {
  return React.useContext(DialogContext);
};

type Props = { children?: React.ReactNode } & Omit<DialogContextType, 'invert'>;

export const DialogContextProvider = ({
  color,
  headingGroupId,
  variant,
  children,
}: Props) => {
  const context = React.useMemo(
    () => ({ color, headingGroupId, variant, invert: color !== 'white' }),
    [color, headingGroupId, variant]
  );

  return (
    <DialogContext.Provider value={context}>{children}</DialogContext.Provider>
  );
};
