/** @jsx jsx */

/** @jsxFrag */
import { jsx } from '@emotion/react';

import * as React from 'react';

import { branch, compose, renderComponent, setDisplayName } from 'recompose';

import { Grid, Link, Typography, Typography2, breakpoints, useMediaQuery, useTheme } from '@coursera/cds-core';

import { NotFoundError } from 'bundles/ssr/lib/errors/directives';
import inServerContext from 'bundles/ssr/util/inServerContext';

import _t from 'i18n!nls/common';

import NotFoundSvg from './notFoundSVG';

type NotFoundProps = {
  description?: React.ReactNode;
};
/**
 * A generic NotFound component
 * If the page is server side rendered, throw NotFoundError, which will trigger Edge to serve a 404 Page
 * If page is client side rendered, serve a soft 404
 */
export const NotFound = ({ description }: NotFoundProps) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(breakpoints.down('sm'));
  const padding = isSmallScreen ? 32 : 80;
  return (
    <div css={{ padding: theme.spacing(padding, 16), maxWidth: `1200px`, margin: `0 auto` }}>
      <Grid container justifyContent="center" direction={isSmallScreen ? 'column-reverse' : 'row'}>
        <Grid item xs sm md={5} justifyContent="center" css={{ marginBottom: 'var(--cds-spacing-300)' }}>
          <div css={{ maxWidth: `1200px`, margin: `0 auto` }}>
            {description || (
              <>
                <Typography variant="d2semibold">{_t('What an interesting discovery!')}</Typography>
                <Typography2 component="p" css={{ marginTop: 'var(--cds-spacing-300)' }}>
                  {_t(`You've found a page that doesn't exist! It's never too late to keep learning on Coursera―explore topics to
continue`)}
                </Typography2>
                <Typography2 component="p" css={{ marginTop: 'var(--cds-spacing-300)' }}>
                  {_t('Want to uncover more?')} {_t(' Accelerate your career with')}{' '}
                  <Link href="/degrees">{_t('Online Degrees')}</Link>, {_t('grow together with')}{' '}
                  <Link href="/enterprise">{_t('Coursera for Business')}</Link>, {_t('or explore the')}{' '}
                  <Link href="https://blog.coursera.org/">{_t('Coursera Blog')}</Link>.
                </Typography2>
              </>
            )}
          </div>
        </Grid>
        <Grid
          item
          xs
          sm
          md={5}
          lg={6}
          aria-hidden
          css={{ textAlign: 'center', marginBottom: 'var(--cds-spacing-300)', display: 'flex' }}
        >
          <NotFoundSvg />
        </Grid>
      </Grid>
    </div>
  );
};

function withNotFound({
  errorComponent: ErrorComponent = NotFound,
  test,
}: {
  errorComponent?: React.ComponentType<any> | React.ReactElement<typeof NotFound>;
  test: (x0: any) => boolean;
}): (x0: React.ComponentType<any>) => React.ComponentType<any> {
  return (Component) => {
    const notFoundSSR = () => {
      if (inServerContext) {
        throw new NotFoundError();
      }
      // @ts-expect-error TSMIGRATION
      return <ErrorComponent />;
    };
    return compose(
      branch(test, renderComponent(notFoundSSR)),
      setDisplayName(`withNotFound(${Component.displayName || Component.name})`)
    )(Component);
  };
}

export default withNotFound;
