import * as React from 'react';

import type { Seo_MegaMenu as SeoMegaMenu } from '__generated__/graphql-types';
import { compose } from 'recompose';

import requestUrlInfo from 'js/lib/requestUrlInfo';
import { useLocation } from 'js/lib/useRouter';

import withPremiumProductCollections from 'bundles/browse/components/Domain/withPremiumProductCollections';
import type { Domain } from 'bundles/browse/components/types/MegaMenu';
import type { ProfessionalCertificateProps } from 'bundles/browse/components/types/ProfessionalCertificate';
import { ProductVariantNames } from 'bundles/browse/constants';
import type { DegreeProductMember } from 'bundles/browse/types/degree-list';
import MegaMenuContent from 'bundles/megamenu/components/MegaMenuContent';
import type { mappedMegaMenuAPIData } from 'bundles/megamenu/types/MenuData';
import withHeaderQuery from 'bundles/page-header/components/hoc/withHeaderQuery';
import type { Program } from 'bundles/page/types/Program';
import SeoPathConfigurationQueryWrapper from 'bundles/seo/api/SeoPathConfigurationQueryWrapper';
import { useSeoPageData } from 'bundles/seo/contexts/SeoPageDataContext';

type PropsFromCaller = {
  menuIsOpen: boolean;
  openMenu: () => void;
  closeMenu: () => void;
  anchorElement?: HTMLElement | null;
  currentProgram?: Program;
  renderContentsOnly?: boolean; // If you'd like your own parent container, for lazy loading this component
  showLoadingState?: boolean; // Show a loading icon when content is still fetching data
};

type PropsFromSeoPageData = {
  megaMenuSeoData?: SeoMegaMenu;
};

type PropsToComponent = PropsFromCaller &
  PropsFromSeoPageData & {
    domains: Array<Domain>;
    degreeList?: Array<DegreeProductMember>;
    professionalCertificates: Array<ProfessionalCertificateProps>;
    mastertrackList?: Array<DegreeProductMember>;
    megaMenuAPIData?: mappedMegaMenuAPIData;
  };

export const getMegamenuPremiumProductCollectionsVariables = () => ({
  domains: null,
  productVariants: [
    ProductVariantNames.BachelorsDegree,
    ProductVariantNames.MastersDegree,
    ProductVariantNames.Mastertrack,
    ProductVariantNames.PostgraduateDiploma,
  ],
});

const InnerComponent = compose<PropsToComponent, PropsFromCaller & PropsFromSeoPageData>(
  withHeaderQuery,
  withPremiumProductCollections(getMegamenuPremiumProductCollectionsVariables)
)(MegaMenuContent);

const MegaMenuWithSeoOverrideData = (props: PropsFromCaller) => {
  const { megaMenu } = useSeoPageData();

  return <InnerComponent {...props} megaMenuSeoData={megaMenu} />;
};

const MegaMenuContentOptimized = (props: PropsFromCaller) => {
  const { pathname, search } = useLocation();
  const hostname = requestUrlInfo().hostname;

  return (
    <SeoPathConfigurationQueryWrapper path={hostname + pathname + search}>
      <MegaMenuWithSeoOverrideData {...props} />
    </SeoPathConfigurationQueryWrapper>
  );
};

export default MegaMenuContentOptimized;
