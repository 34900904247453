/**
 *  Imgix is a service that we use to dynamically resize and optimize images
 *  It supports many different transforms,
 *  however to start we're only supporting the ones in QUERY_MAP
 */
import type { ImgHTMLAttributes } from 'react';

import Uri from 'jsuri';

import config from 'js/app/config';
import { prodAssetsRoot } from 'js/constants/cloudfront';
import path from 'js/lib/path';

// Some older code passed src with this prefixed already, so we remove it to
// be consistent. We use regex to be agnostic to CDN domain that Edge may have
// replaced in the HTML stream.
const REDUNDANT_PREFIX = /https:\/\/.+\/api\/utilities\/v1\/imageproxy\//;
// eslint-disable-next-line no-restricted-syntax
const DEVELOPMENT_PREFIX = 'https://assets.dev-coursera.org:9443/';
const ASSET_PREFIX = 'imageAssetProxy.v1';
const STORYBOOK_PREFIX = 'static/media';

export type ImgParams = {
  format?: number | string;
  quality?: number | string;
  blur?: number | string;
  width?: number | string;
  height?: number | string;
  dpr?: number | string;
  mask?: number | string;
  brightness?: number | string;
  fit?: number | string;
  exposure?: number | string;
  auto?: number | string;
  bg?: number | string;
  maxWidth?: number | string;
  invert?: number | string;
  px?: number | string;
  facepad?: number | string;
  crop?: number | string;
  fetchpriority?: ImgHTMLAttributes<HTMLImageElement>['fetchpriority'];

  /**
   * Weird internal prop that you shouldn't use/
   * @deprecated
   * */
  ignoreDpr3SrcSet?: unknown;
};

const QUERY_MAP = Object.freeze({
  format: 'fm',
  quality: 'q',
  blur: 'blur',
  width: 'w',
  height: 'h',
  dpr: 'dpr',
  mask: 'mask',
  brightness: 'bri',
  fit: 'fit',
  exposure: 'exp',
  auto: 'auto',
  bg: 'bg',
  maxWidth: 'max-w',
  invert: 'invert',
  px: 'px',
  facepad: 'facepad',
  crop: 'crop',
  // If extending this, update ImgParams above
});

export type QueryLongKey = keyof typeof QUERY_MAP;
export type QueryShortKey = (typeof QUERY_MAP)[QueryLongKey];

const DEFAULT_OPTIONS = Object.freeze({
  auto: 'format,compress', // http://www.imgix.com/docs/reference/automatic#param-auto

  // Ensure page is fully loaded before checking window. Prevents SSR and CSR mismatch
  dpr: (typeof document !== 'undefined' && document.readyState === 'complete' && window.devicePixelRatio) || 1,
});

function constructUrl(src: string, queryParams: Partial<Record<QueryShortKey, number | string>> = {}) {
  const paramKeys = Object.keys(queryParams) as Array<QueryShortKey>;
  const fullUrl = paramKeys.reduce(
    (uri, key) =>
      uri.addQueryParam(
        key,
        // @ts-expect-error Need exactOptionalPropertyTypes, we don't expect a present key with a value of undefined
        queryParams[key]
      ),
    new Uri(src)
  );

  return fullUrl.toString();
}

function isDevelopmentAsset(src: string) {
  return src.indexOf(DEVELOPMENT_PREFIX) >= 0 || src.indexOf('/dist') === 0 || src.indexOf(STORYBOOK_PREFIX) === 0;
}

function processImage(
  src: string | null | undefined,
  longOptions: Partial<Record<QueryLongKey, number | string>> = {}
) {
  if (!src) {
    return '';
  }

  // dev asset can't be optimized
  if (isDevelopmentAsset(src)) {
    return src;
  }

  let realSrc = src.replace(REDUNDANT_PREFIX, '');

  if (realSrc.indexOf(ASSET_PREFIX) === -1) {
    realSrc = config.url.imageProxyRoot + realSrc;
  }
  const shortOptions: Partial<Record<QueryShortKey, number | string>> = Object.assign({}, DEFAULT_OPTIONS);
  const longKeys = Object.keys(longOptions) as Array<QueryLongKey>;
  longKeys.forEach(function (longKey) {
    const shortKey = QUERY_MAP[longKey];
    if (shortKey) {
      shortOptions[shortKey] = longOptions[longKey];
    }
  });

  return constructUrl(realSrc, shortOptions);
}

function getOptimizedSrcs(src: string, width?: number | string, height?: number | string, imgParams?: ImgParams) {
  // Handle pathological case of missing src. This will show up as missing
  // in the browser, with the alt tag. We do this instead of passing the
  // unoptimized URL so that the developer can see the failure in the
  // render.
  if (!src) return {};

  const isAbsoluteAlready = src.slice(0, 4) === 'http' || src.slice(0, 2) === '//' || isDevelopmentAsset(src);

  const absoluteSrc = isAbsoluteAlready ? src : path.join(prodAssetsRoot, src);

  const options = {
    ...(typeof width !== 'undefined' ? { width } : {}),
    ...(typeof height !== 'undefined' ? { height } : {}),
    ...(typeof imgParams !== 'undefined' ? imgParams : {}),
  };

  // Craft image URLs for each device-pixel-ratio, ignoring the passed in value.
  return {
    dpr1: processImage(absoluteSrc, Object.assign(options, { dpr: 1 })),
    // 2x is HiDPI like Macbook Pro, plus many phones
    dpr2: processImage(absoluteSrc, Object.assign(options, { dpr: 2 })),
    // 3x is supported on iPhone 6 and Nexus 6
    dpr3: processImage(absoluteSrc, Object.assign(options, { dpr: 3 })),
    // Uses configuration for LQIP as suggested by imgix: https://blog.imgix.com/2016/06/01/lqip-your-images
    degraded: processImage(absoluteSrc, Object.assign(options, { blur: 200, px: 16 })),
  };
}

export default {
  processImage,
  getOptimizedSrcs,
};

export { processImage, getOptimizedSrcs };
