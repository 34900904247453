import { css } from '@emotion/react';

import { breakpoints } from '@coursera/cds-common';

import { getPaletteColor } from './colors';
import type { DialogColor, DialogVariant } from './types';

const variants = {
  standard: css`
    padding: var(--cds-spacing-200) var(--cds-spacing-200) var(--cds-spacing-50)
      var(--cds-spacing-200);

    ${breakpoints.down('sm')} {
      padding: var(--cds-spacing-200) var(--cds-spacing-200)
        calc(var(--cds-spacing-50) + 1px) var(--cds-spacing-200);
      margin-bottom: -1px;
    }
  `,

  transactional: css`
    padding: 0;
  `,
};

export const getHeaderCss = ({
  color,
  variant,
}: {
  color: DialogColor;
  variant: DialogVariant;
}) => {
  return css`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    border-radius: var(--cds-border-radius-100) var(--cds-border-radius-100) 0 0;
    background-color: ${getPaletteColor(color)};
    z-index: 1;
    margin-bottom: -0.1px;

    ${breakpoints.down('xs')} {
      border-radius: 0;
    }

    ${variants[variant]}
  `;
};
