import rtlCssJS from 'rtl-css-js';

import Multitracker from 'js/app/multitrackerSingleton';
import user from 'js/lib/user';

// load common translator so we can get the locale
// of the current RequireJS context
// @see http://requirejs.org/docs/api.html#multiversion
import _t from 'i18n!nls/page';

function languageTagToName(
  // TODO: Consider strong-typing language codes
  code: string
): string | undefined {
  switch (code) {
    case 'ab':
      return _t('Abkhaz');
    case 'aa':
      return _t('Afar');
    case 'af':
      return _t('Afrikaans');
    case 'ak':
      return _t('Akan');
    case 'sq':
      return _t('Albanian');
    case 'am':
      return _t('Amharic');
    case 'ar':
      return _t('Arabic');
    case 'ar-EG':
      return _t('Arabic (Egypt)');
    case 'an':
      return _t('Aragonese');
    case 'hy':
      return _t('Armenian');
    case 'as':
      return _t('Assamese');
    case 'av':
      return _t('Avaric');
    case 'ae':
      return _t('Avestan');
    case 'ay':
      return _t('Aymara');
    case 'az':
      return _t('Azerbaijani');
    case 'bm':
      return _t('Bambara');
    case 'ba':
      return _t('Bashkir');
    case 'eu':
      return _t('Basque');
    case 'be':
      return _t('Belarusian');
    case 'bn':
      return _t('Bengali');
    case 'bh':
      return _t('Bihari');
    case 'bi':
      return _t('Bislama');
    case 'bs':
      return _t('Bosnian');
    case 'br':
      return _t('Breton');
    case 'bg':
      return _t('Bulgarian');
    case 'my':
      return _t('Burmese');
    case 'ca':
      return _t('Catalan');
    case 'ch':
      return _t('Chamorro');
    case 'ce':
      return _t('Chechen');
    case 'ny':
      return _t('Chichewa');
    case 'zh':
      return _t('Chinese');
    case 'zh-CN':
      return _t('Chinese (Simplified)');
    case 'zh-TW':
      return _t('Chinese (Traditional)');
    case 'cv':
      return _t('Chuvash');
    case 'kw':
      return _t('Cornish');
    case 'co':
      return _t('Corsican');
    case 'cr':
      return _t('Cree');
    case 'hr':
      return _t('Croatian');
    case 'cs':
      return _t('Czech');
    case 'da':
      return _t('Danish');
    case 'fa-AF':
      return _t('Dari');
    case 'dv':
      return _t('Divehi');
    case 'nl':
      return _t('Dutch');
    case 'dz':
      return _t('Dzongkha');
    case 'en':
      return _t('English');
    case 'en-CA':
      return _t('English (Canada)');
    case 'en-GB':
      return _t('English (United Kingdom)');
    case 'en-IN':
      return _t('English (India)');
    case 'eo':
      return _t('Esperanto');
    case 'et':
      return _t('Estonian');
    case 'ee':
      return _t('Ewe');
    case 'fo':
      return _t('Faroese');
    case 'fj':
      return _t('Fijian');
    case 'fi':
      return _t('Finnish');
    case 'fr':
      return _t('French');
    case 'fr-CA':
      return _t('French (Canada)');
    case 'fr-FR':
      return _t('French (France)');
    case 'ff':
      return _t('Fula');
    case 'gl':
      return _t('Galician');
    case 'ka':
      return _t('Georgian');
    case 'de':
      return _t('German');
    case 'de-DE':
      return _t('German (Germany)');
    case 'el':
      return _t('Greek');
    case 'gn':
      return _t('Guaraní');
    case 'gu':
      return _t('Gujarati');
    case 'ht':
      return _t('Haitian');
    case 'ha':
      return _t('Hausa');
    case 'he':
      return _t('Hebrew');
    case 'hz':
      return _t('Herero');
    case 'hi':
      return _t('Hindi');
    case 'ho':
      return _t('Hiri Motu');
    case 'hu':
      return _t('Hungarian');
    case 'ia':
      return _t('Interlingua');
    case 'id':
      return _t('Indonesian');
    case 'ie':
      return _t('Interlingue');
    case 'ga':
      return _t('Irish');
    case 'ig':
      return _t('Igbo');
    case 'ik':
      return _t('Inupiaq');
    case 'io':
      return _t('Ido');
    case 'is':
      return _t('Icelandic');
    case 'it':
      return _t('Italian');
    case 'iu':
      return _t('Inuktitut');
    case 'ja':
      return _t('Japanese');
    case 'jv':
      return _t('Javanese');
    case 'kl':
      return _t('Kalaallisut');
    case 'kn':
      return _t('Kannada');
    case 'kr':
      return _t('Kanuri');
    case 'ks':
      return _t('Kashmiri');
    case 'kk':
      return _t('Kazakh');
    case 'km':
      return _t('Khmer');
    case 'ki':
      return _t('Kikuyu');
    case 'rw':
      return _t('Kinyarwanda');
    case 'ky':
      return _t('Kyrgyz');
    case 'kv':
      return _t('Komi');
    case 'kg':
      return _t('Kongo');
    case 'ko':
      return _t('Korean');
    case 'ku':
      return _t('Kurdish');
    case 'kj':
      return _t('Kwanyama');
    case 'la':
      return _t('Latin');
    case 'lb':
      return _t('Luxembourgish');
    case 'lg':
      return _t('Ganda');
    case 'li':
      return _t('Limburgish');
    case 'ln':
      return _t('Lingala');
    case 'lo':
      return _t('Lao');
    case 'lt':
      return _t('Lithuanian');
    case 'lu':
      return _t('Luba-Katanga');
    case 'lv':
      return _t('Latvian');
    case 'gv':
      return _t('Manx');
    case 'mk':
      return _t('Macedonian (FYROM)');
    case 'mg':
      return _t('Malagasy');
    case 'ms':
      return _t('Malay');
    case 'ml':
      return _t('Malayalam');
    case 'mt':
      return _t('Maltese');
    case 'mi':
      return _t('Māori');
    case 'mr':
      return _t('Marathi');
    case 'mh':
      return _t('Marshallese');
    case 'mn':
      return _t('Mongolian');
    case 'me':
      return _t('Montenegrin');
    case 'na':
      return _t('Nauru');
    case 'nv':
      return _t('Navajo');
    case 'nb':
      return _t('Norwegian Bokmål');
    case 'nd':
      return _t('North Ndebele');
    case 'ne':
      return _t('Nepali');
    case 'ng':
      return _t('Ndonga');
    case 'nn':
      return _t('Norwegian Nynorsk');
    case 'no':
      return _t('Norwegian');
    case 'ii':
      return _t('Nuosu');
    case 'nr':
      return _t('South Ndebele');
    case 'oc':
      return _t('Occitan');
    case 'oj':
      return _t('Ojibwe');
    case 'cu':
      return _t('Old Church Slavonic');
    case 'om':
      return _t('Oromo');
    case 'or':
      return _t('Oriya');
    case 'os':
      return _t('Ossetian');
    case 'pa':
      return _t('Panjabi');
    case 'pi':
      return _t('Pāli');
    case 'fa':
      return _t('Persian');
    case 'pl':
      return _t('Polish');
    case 'ps':
      return _t('Pashto');
    case 'pt':
      return _t('Portuguese');
    case 'pt-BR':
      return _t('Portuguese (Brazil)');
    case 'pt-PT':
      return _t('Portuguese (Portugal)');
    case 'qu':
      return _t('Quechua');
    case 'rm':
      return _t('Romansh');
    case 'rn':
      return _t('Kirundi');
    case 'ro':
      return _t('Romanian');
    case 'ru':
      return _t('Russian');
    case 'sa':
      return _t('Sanskrit');
    case 'sc':
      return _t('Sardinian');
    case 'sd':
      return _t('Sindhi');
    case 'se':
      return _t('Northern Sami');
    case 'sm':
      return _t('Samoan');
    case 'sg':
      return _t('Sango');
    case 'sr':
      return _t('Serbian');
    case 'gd':
      return _t('Gaelic');
    case 'sn':
      return _t('Shona');
    case 'si':
      return _t('Sinhala');
    case 'sk':
      return _t('Slovak');
    case 'sl':
      return _t('Slovene');
    case 'so':
      return _t('Somali');
    case 'st':
      return _t('Southern Sotho');
    case 'es':
      return _t('Spanish');
    case 'es-ES':
      return _t('Spanish (Spain)');
    case 'es-MX':
      return _t('Spanish (Mexico)');
    case 'su':
      return _t('Sundanese');
    case 'sw':
      return _t('Swahili');
    case 'ss':
      return _t('Swati');
    case 'sv':
      return _t('Swedish');
    case 'ta':
      return _t('Tamil');
    case 'te':
      return _t('Telugu');
    case 'tg':
      return _t('Tajik');
    case 'th':
      return _t('Thai');
    case 'ti':
      return _t('Tigrinya');
    case 'tm':
      return _t('Tetum');
    case 'bo':
      return _t('Tibetan');
    case 'tk':
      return _t('Turkmen');
    case 'tl':
      return _t('Tagalog');
    case 'tn':
      return _t('Tswana');
    case 'to':
      return _t('Tonga');
    case 'tr':
      return _t('Turkish');
    case 'ts':
      return _t('Tsonga');
    case 'tt':
      return _t('Tatar');
    case 'tw':
      return _t('Twi');
    case 'ty':
      return _t('Tahitian');
    case 'ug':
      return _t('Uighur');
    case 'uk':
      return _t('Ukrainian');
    case 'ur':
      return _t('Urdu');
    case 'uz':
      return _t('Uzbek');
    case 've':
      return _t('Venda');
    case 'vi':
      return _t('Vietnamese');
    case 'vo':
      return _t('Volapük');
    case 'wa':
      return _t('Walloon');
    case 'cy':
      return _t('Welsh');
    case 'wo':
      return _t('Wolof');
    case 'fy':
      return _t('Western Frisian');
    case 'xh':
      return _t('Xhosa');
    case 'yi':
      return _t('Yiddish');
    case 'yo':
      return _t('Yoruba');
    case 'za':
      return _t('Zhuang');
    case 'zu':
      return _t('Zulu');
    default:
  }
  return undefined;
}

/**
 * NOTE: below is a list of language tags we currently support as languages for courses
 * see https://github.com/webedx-spark/infra-services/blob/main/libs/models/src/main/scala/org/coursera/languagecode/LanguageCode.scala
 * See TRANSCRIPTION_VENDOR_LANG_CODES for fully supported locales (we have vendor transcription for these)
 */
export const courseLanguageTags: string[] = [
  'af',
  'am',
  'ar',
  'az',
  'bg',
  'bn',
  'bs',
  'ca',
  'ny',
  'cs',
  'da',
  'de',
  'el',
  'es',
  'et',
  'eu',
  'en',
  'fa',
  'fa-AF',
  'fi',
  'fr',
  'he',
  'hi',
  'hr',
  'hu',
  'hy',
  'id',
  'it',
  'ja',
  'jv',
  'ka',
  'kk',
  'km',
  'kn',
  'ko',
  'lt',
  'lv',
  'lo',
  'mk',
  'mn',
  'mr',
  'ms',
  'my',
  'me',
  'ne',
  'nl',
  'no',
  'pl',
  'ps',
  'pt-BR',
  'pt-PT',
  'ro',
  'ru',
  'rw',
  'sk',
  'sl',
  'so',
  'sq',
  'sr',
  'st',
  'sv',
  'sw',
  'ta',
  'te',
  'th',
  'tl',
  'tr',
  'tm',
  'tn',
  'uk',
  'ur',
  'uz',
  'vi',
  'xh',
  'yo',
  'zh-CN',
  'zh-TW',
  'zu',
];

// English, followed by translated languages in alphabetical order
export const supportedLanguageSubdomains: string[] = [
  'en',
  'ar',
  'ar-eg',
  'de',
  'es',
  'fr',
  'id',
  'ja',
  'ko',
  'pt',
  'ru',
  'zh',
  'zh-tw',
];

// special umbrella locales ar_AR, es_LA. Push arabic and spanish locales into these ones.
const facebookSpecialCases: Record<string, string> = {
  ar: 'ar_AR',
  es: 'es_LA',
};

export const cmsSupportedLanguageSubdomains: Record<string, string> = {
  ...supportedLanguageSubdomains.reduce((acc, cur) => {
    acc[cur] = cur;
    return acc;
  }, {} as Record<string, string>),
  en: 'en-US',
  ar: 'ar-SA',
  'ar-eg': 'ar-EG',
  az: 'az',
  de: 'de-DE',
  el: 'el-GR',
  'el-GR': 'el-GR',
  fr: 'fr-FR',
  hu: 'hu-HU',
  id: 'id-ID',
  it: 'it-IT',
  ja: 'ja-JP',
  ko: 'ko-KR',
  'nl-NL': 'nl-NL',
  nl: 'nl-NL',
  pl: 'pl-PL',
  'pl-PL': 'pl-PL',
  sv: 'sv-SE',
  'sv-SE': 'sv-SE',
  th: 'th-TH',
  'th-TH': 'th-TH',
  tr: 'tr-TR',
  'tr-TR': 'tr-TR',
  'zh-tw': 'zh-Hant-TW',
  zh: 'zh-Hans',
};

/**
 * Convert a language code to the IETF language tag
 *
 * @param  {String} code any language code or accept-language encoding
 * @return String user's language code per IETF spec, e.g. en-US
 */
export function toIetfLanguageTag(code: string): string {
  const normalizeLanguageCode = (code || '').replace(/_/g, '-').split(/[;=,]/)[0];
  return normalizeLanguageCode.toLowerCase().replace(/(-[a-z]{1,}$)/, function (match) {
    return match.toUpperCase();
  });
}

/**
 * Get user's [IETF Language Tag](http://en.wikipedia.org/wiki/IETF_language_tag)
 * for the current user from RequireJS
 *
 * @return String user's language code per IETF spec, e.g. en-US
 */
export function getIetfLanguageTag(): string {
  try {
    return toIetfLanguageTag(_t.getLocale());
  } catch (e) {
    // in case of error, default to English and emit tracking event
    Multitracker.push(['user.language.error', { error: e }]);
    return 'en-US';
  }
}

/**
 * Shorten language codes to only contain the 2 character language codes
 * @param  {String} languageTag language tag
 * @return {String}      2 character language code (ISO-631)
 */
export function toLanguageCode(languageTag: string): string {
  return toIetfLanguageTag(languageTag).split('-')[0];
}

/**
 * Get user's language code (ISO-631) from the IETF language co
 * @return {String} User's ISO-631 language code
 */
export function getLanguageCode(): string {
  return toLanguageCode(getIetfLanguageTag());
}

/**
 * Moment requires language codes to be lowercase
 * @return {String} user's language code in IETF in lowercase as required by moment
 */
export function getMomentLanguage(): string {
  const lowerCaseIetfLanguage = getIetfLanguageTag().toLowerCase();
  switch (lowerCaseIetfLanguage) {
    case 'zh-tw':
      return 'zh-tw';
    case 'pt':
    case 'pt-br':
      return 'pt-br';
    default:
      return lowerCaseIetfLanguage.split('-')[0];
  }
}

/**
 * Get the locale string from the language name. E.g. en_US, zh_CN... etc
 * Intended for use with facebook integration localization.
 * As per https://developers.facebook.com/docs/internationalization, the Arabic and Spanish
 * localizations had to be coded as a special case, as facebook uses a non-standard "umbrella
 * locale" for those.
 *
 * @return {String}      locale name
 */
export function getFacebookLocaleString(): string {
  const localeString = getIetfLanguageTag().replace('-', '_');
  const localeLang = localeString.split('_')[0];
  return facebookSpecialCases[localeLang] || localeString;
}

/**
 * Get the locale string from the language name, e.g. en, es, etc.
 * This is primarily intended for use with our current CMS Contentful's APIs as its API takes in particular locale-region combinations.
 *
 * If given a locale that is not supported by Contentful or Coursera, we default to the English locale.
 */
export function getCmsLocaleString(): string {
  const languageCode = getLanguageCode();
  return cmsSupportedLanguageSubdomains[languageCode] || cmsSupportedLanguageSubdomains.en;
}

/**
 * Get the language name corresponding to a language code
 * For more specific language codes in with more components such as zh-tw,
 * this function will attempt to look up the code in decreasing order of
 * specificity, meaning chopping off components from the end 1 at a time,
 * until it either finds a name mapping and returns it, or returns the code
 * itself if there's no mapping
 * @param  {String} code language code
 * @return {String}      language name
 */
export function languageCodeToName(code: string): string {
  const languageCode = toIetfLanguageTag(code);
  const components = languageCode.split('-');
  let language;
  const languageMappingExists = components.some((c, i) => {
    const codeToTest = components.slice(0, components.length - i).join('-');
    language = languageTagToName(toIetfLanguageTag(codeToTest));
    return !!language;
  });

  return languageMappingExists && language != null ? language : code;
}

/**
 * Detect if a language code is a right to left language
 * @param  {String}  language language code
 * @return {Boolean}          whether the language is right to left
 */
export function isRightToLeft(language: string): boolean {
  const rightToLeftLanguages = ['ps', 'fa-af', 'ar', 'he'];
  return rightToLeftLanguages.includes(toLanguageCode(language));
}

export function langDir(language: string): 'ltr' | 'rtl' {
  return isRightToLeft(language) ? 'rtl' : 'ltr';
}

/**
 * Detect if the user is using a right to left language
 * @return {Boolean} whether the user's language is right to left
 */
export function isUserRightToLeft(): boolean {
  return isRightToLeft(getIetfLanguageTag());
}

export function getMobileBadgeLanguageCode(): string {
  const mobileBadgeLanguageCodes = ['en', 'de', 'es', 'fr', 'ja', 'ko', 'pt-br', 'ru', 'zh-cn', 'zh-tw', 'ar'];

  const languageTagLower = getIetfLanguageTag().toLowerCase();
  if (mobileBadgeLanguageCodes.includes(languageTagLower)) {
    return languageTagLower;
  }

  const languageCode = getLanguageCode();
  if (languageCode === 'pt') {
    return 'pt-br';
  } else if (languageCode === 'es') {
    return 'es';
  } else if (languageCode === 'ar') {
    return 'ar';
  } else if (languageCode === 'zh') {
    return 'zh-cn';
  } else {
    return 'en';
  }
}

/*
 * mapping taken from libs/models/src/main/scala/org/coursera/languagecode/TranscriptionVendorLanguageCode.scala
 * with manual mapping of vendor's codes back to ours (via languageCodeToTranscriptionVendorCodeMapping)
 */

const TRANSCRIPTION_VENDOR_LANG_CODES = [
  'ar',
  'zh-tw',
  'zh-cn',
  'cs',
  'da',
  'nl',
  'en',
  'fr',
  'fi',
  'de',
  'el',
  'he',
  'hi',
  'id',
  'it',
  'ja',
  'ko',
  'no',
  'pl',
  'pt-br',
  'ru',
  'es',
  'sk',
  'sv',
  'th',
  'tr',
  'vi',
];

/**
 * whether the language is supported for automatic transcription by our vendors
 * @param {string} langCode
 */
export const isFullySupportedLocale = (langCode: string): boolean =>
  TRANSCRIPTION_VENDOR_LANG_CODES.includes(langCode.toLowerCase());

/**
 * get current user's locale setting when available, default to `en`
 */
export const getUserLanguageCode = (): string => {
  const userLocale = user.get().locale;

  // we don't support the region for English here
  if (!userLocale || userLocale === 'en_US') {
    return 'en';
  }

  // e.g. zh_TW, pt_BR which we do support region here
  return userLocale.replace('_', '-');
};

// make style support rtl if language is rtl
// otherwise, there's no change
export const rtlStyle = <T extends {}>(styles: T, locale: string = _t.getLocale()): T => {
  if (isRightToLeft(locale)) {
    return rtlCssJS(styles);
  } else {
    // There's no need to change anything
    return styles;
  }
};

export const getLanguageDirection = (): 'rtl' | 'ltr' => {
  return isUserRightToLeft() ? 'rtl' : 'ltr';
};

export function isRenderingRightToLeft(): boolean {
  return Boolean(isUserRightToLeft());
}

// a map of translated support page locales
export const supportPageLocale = {
  ar: 'ar',
};

export const getLanguageMatch = (language1: string, language2: string): string | null => {
  // Replace '-' with '_' so that it can be matched easily
  const [generalCode1, specificCode1] = language1.replace(/-/, '_').split(/_/);
  const [generalCode2, specificCode2] = language2.replace(/-/, '_').split(/_/);

  // Compare the general language codes
  if (generalCode1 !== generalCode2) return null;

  // When both arguments have region codes, compare them and return locale if they match
  if (specificCode1 && specificCode2) {
    return specificCode1 === specificCode2 ? `${generalCode1}_${specificCode1}` : null;
  }

  // Return the most specific code that matches
  return specificCode1 && specificCode1 === specificCode2 ? `${generalCode1}_${specificCode1}` : generalCode1;
};
