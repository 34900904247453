// If you update this file, remember to update the server file accordingly
import { memoize } from 'lodash';

import type { Options } from './base';
import type Transport from './base';
import { DEFAULT_BATCH_URL, DEFAULT_URL } from './base';
import BeaconTransport from './beacon';
import FetchTransport from './fetch';
import IframeTransport from './iframe';
import LoggerTransport from './logger';
import PriorityTransport from './priority';
import ScriptTransport from './script';

/**
 * Create a default transport instance.
 *
 * This function memoizes the creation of a default transport instance using a priority transport
 * that includes fetch, beacon, iframe, and script transports. The fetch transport uses the default
 * batch URL, while the other transports use the default URL.
 *
 * @returns A memoized default transport instance.
 */
const createDefaultTransport = memoize(
  (options: Partial<Options>): Transport =>
    new PriorityTransport({
      ...options,
      transports: [FetchTransport, BeaconTransport, IframeTransport, ScriptTransport],
    })
);

export type { Transport };
export {
  BeaconTransport,
  createDefaultTransport,
  DEFAULT_BATCH_URL,
  DEFAULT_URL,
  FetchTransport,
  IframeTransport,
  LoggerTransport,
  PriorityTransport,
  ScriptTransport,
};
