import type { CloseButtonProps } from '@core/dialogs/common/CloseButton';
import { CloseButton } from '@core/dialogs/common/CloseButton';

import { useDialogContext } from './DialogContextProvider';
import { getHeaderCss } from './styles';

export type Props = {
  showCloseAction: boolean;
  onClose?: CloseButtonProps['onClick'];
};

export const Header = (props: Props) => {
  const { showCloseAction, onClose } = props;

  const { invert, color, variant } = useDialogContext();

  return (
    <div css={getHeaderCss({ color, variant })}>
      {showCloseAction && <CloseButton invert={invert} onClick={onClose} />}
    </div>
  );
};
