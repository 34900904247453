// Webpack stats files include the full path of all of the files in a multi-valued entry point, which significantly
// increases the string length, and for some apps like teach-course, overflows the max length in v8. Rather than using a
// multi-entry point with like 10 things, we use two: this one that loads the common preamble, and then the app's
// startApp.
//
// Note: we are using require here so that prettier does not re-order these imports. The order is intentional and very
// important.
require('js/app/webpackPreLoader');
require('js/app/webpackPolyfill');
require('js/app/webpackLoader');
require('bundles/page/lib/brotliPublicPathOverride');
require('bundles/page/lib/react-monkeypatch');
require('js/app/fakeAsyncModuleToForceWebpackLoader1');
