/**
 * Retracked wraps your event recording system in an API optimized for React components.
 *
 * .track(key, values) records an event immediately. (values are optional)
 *
 * .track.handle(key, values) returns a function that records the event when evaluated.
 */
import * as React from 'react';

import * as Sentry from '@sentry/react';
import { cloneDeep, merge } from 'lodash';
import PropTypes from 'prop-types';

import logger from 'js/app/loggerSingleton';
import VisibilityObserver from 'js/lib/VisibilityObserver';
import hoistNonReactStatics from 'js/lib/hoistNonReactStatics';
import { scrubKeyComponentName } from 'js/lib/retrackedUtils';
import { randomUUID as generateUUID } from 'js/lib/uuid';

let _recordEvent = function (fullEventKey, values) {
  throw new Error('Retracked setup() must first be called with an event recording function.');
};

let _actionNames = [];

function setup(recordEventFn, actionNames) {
  _recordEvent = recordEventFn;
  _actionNames = actionNames;
}

/**
 * Clone object, evaluting each property that is a function
 * @param  {Object} map keys map to simple values or functions
 * @return {Object}     Clone of object, with each function value evaluated
 */
function cloneWithPropertyEval(map) {
  const evaluatedMap = {};
  Object.keys(map).forEach((key) => {
    const valOrFunc = map[key];
    evaluatedMap[key] = typeof valOrFunc === 'function' ? valOrFunc() : valOrFunc;
  });
  return evaluatedMap;
}

// TODO(zhaojun): deprecate this makeTracker API after we finalize the newest version
function makeTracker(config = {}) {
  /**
   * Expand the key argument into the full key that would be sent to eventing
   * @param  {String}
   * @return {String} the full key to send to eventing
   */
  function expandKey(eventKey) {
    return config.namespace ? config.namespace + '.' + eventKey : eventKey;
  }

  /**
   * Track an event in the app.
   * @param {String} eventKey name of the event
   * @param {Object} moreValues dictionary of values to attach to the logged event
   * @param {SyntheticEvent} [uiEvent] React's event, passed by track.handle
   */
  function track(eventKey, moreValues, uiEvent) {
    const fullEventKey = expandKey(eventKey);

    const includeValues = cloneWithPropertyEval(config.include || {});

    const values = Object.assign({}, includeValues, moreValues);

    if (uiEvent && uiEvent.currentTarget) {
      // record features of the element interacted upon
      const el = uiEvent.currentTarget;
      if (el.href) {
        values.href = el.href;
      }
    }

    _recordEvent(fullEventKey, values);
  }

  /**
   * Curried form of track
   *
   * @returns {function} that calls event with the same args
   */
  track.handle = function (eventKey, moreValues) {
    return track.bind(null, eventKey, moreValues);
  };

  // create functions like `track.click` that take the client target as the key
  _actionNames.forEach(function (actionName) {
    track[actionName] = function (objectName, moreValues) {
      const eventKey = actionName + '.' + objectName;
      return track.bind(null, eventKey, moreValues);
    };
  });

  /**
   * Expand the objectName argument into the full key that would be sent to eventing.
   *
   * This is used by preloader's instrumentLinks function.
   *
   * @param  {String}
   * @return {String} the full key to send to eventing
   */
  track.clickKey = function (objectName) {
    return expandKey('click.' + objectName);
  };

  return track;
}

function createContainer(Component, config) {
  const baseName = Component.displayName || Component.name;

  class TrackingProvider extends React.Component {
    displayName = baseName + 'TrackingProvider';

    childContextTypes = {
      track: PropTypes.func.isRequired,
    };

    getChildContext() {
      return Object.assign({}, this.context, {
        track: makeTracker(config),
      });
    }

    render() {
      return <Component {...this.props} />;
    }
  }

  return TrackingProvider;
}

const RetrackedContext = React.createContext({});

/**
 * Create the newest generation of tracking container.
 * Please contact @zhaojun or @cliu if you have questions about usage.
 * @param  {Object} A callback of (props, context) => object, and the object will be deeply
 *                  merged to context._eventData
 * @return {Object} The component with tracking data in the context.
 */
function createTrackedContainerImpl(Component, getEventData, withTrackingData) {
  const baseName = Component.displayName || Component.name;

  class TrackingProvider extends React.Component {
    static displayName = baseName + 'TrackingProvider';

    static contextTypes = {
      _eventData: PropTypes.object,
    };

    static childContextTypes = {
      _eventData: PropTypes.object.isRequired,
      _withTrackingData: PropTypes.func,
    };

    // This function merges the eventData and namespace coming from the context of this component
    // with the data coming from the getEventData function passed to the createTrackedContainer HOC
    getMergedEventData() {
      const { _eventData } = this.context;
      // TODO(zhaojun): use deep merge
      const eventDataFromContext = _eventData || {};
      const eventData = getEventData(this.props, this.context);

      return {
        ...eventDataFromContext,
        ...eventData,
        namespace: {
          ...eventDataFromContext.namespace,
          ...eventData.namespace,
        },
      };
    }

    getChildContext() {
      return {
        _eventData: this.getMergedEventData(),

        // Here `this.props` is the own props of the component wrapped by `createTrackedContainer`.
        // We set `this.props` as the first argument of `_withTrackingData` so that when `_withTrackingData` is called,
        // this.props can be accessed in the `withTrackingData` callback.
        // This allows the callback to compute injected data using `this.props`, i.e. the wrapped component's own props.
        _withTrackingData: withTrackingData ? (...args) => withTrackingData(this.props, ...args) : undefined,
      };
    }

    render() {
      // Can't just let `ref` pass through, since `ref` is not a normal prop, but a special prop used by React.
      // So we introduced a new normal prop, `componentRef`, that can be used to pass a ref function to the wrapped
      // component.
      //
      // From the [Forwarding Refs](https://reactjs.org/docs/forwarding-refs.html) documentation:
      //
      // > Regular function or class components don’t receive the `ref` argument, and ref is not available in props either.
      return (
        <RetrackedContext.Provider
          value={{
            eventData: this.getMergedEventData(),
          }}
        >
          <Component {...this.props} ref={this.props.componentRef} />
        </RetrackedContext.Provider>
      );
    }
  }

  hoistNonReactStatics(TrackingProvider, Component);
  return TrackingProvider;
}

/**
 * Create an HoC component that can automatically add tracking data to all events
 * issued by TrackedComponents that are children of the HoC.
 *
 * The HoC component created by the below function does not do any tracking.
 *
 * For example,
 *
 * val OfferingCard = Retracked.createTrackedContainer(
 *   (props) => {
 *     const offering = props.offering;
 *     return {
 *       id: offering.id,
 *       target: offering.link,
 *       offeringType: offering.offeringType,
 *     };
 *   }
 * )(OfferingCard);
 *
 * In a fully rendered React component hierarchy:
 *
 * <OfferingCard>
 *   <SomethingElseOrNothing>
 *     <TrackedLink2 />
 *   </SomethingElseOrNothing>
 * </OfferingCard>
 *
 * When a user click the link, the value of the event issued by TrackedLink2 will
 * have the three fields specified in the createTrackedContainer code, e.g., the following is
 * the part of the value field
 * {
 *   id: ...,
 *   target: ...,
 *   offeringType: ...,
 *   ...
 * }
 *
 * An additional prop, `componentRef`, can be passed to the HOC. This prop will be sent to the
 * wrapped component's `ref` prop, so that a ref to the wrapped component can be captured.
 * Note: In the future, you should be able to use `ref` in the place of `componentRef`, but
 * this feature (ref forwarding) was only introduced in React 16.3.
 *
 * See https://reactjs.org/docs/forwarding-refs.html
 */
function createTrackedContainer(...args) {
  return (Component) => {
    return createTrackedContainerImpl.apply(this, [Component].concat(args));
  };
}

function _isTrackable(contextData) {
  return contextData && contextData.namespace && contextData.namespace.app && contextData.namespace.page;
}

function _getEventKey(contextData, trackingName, action) {
  const { namespace } = contextData;
  const { app, page } = namespace;
  const eventKey = [app, page, action, trackingName].join('.');
  const scrubbedEventKey = [app, page, action, trackingName].map(scrubKeyComponentName).join('.');
  if (eventKey !== scrubbedEventKey) {
    Sentry.captureMessage('event key components contain unexpected chars', {
      extra: { app, page, action, trackingName },
    });
  }
  if (!/^[_0-9a-z.]*$/.test(eventKey)) {
    logger.warn(
      'The event key ' +
        eventKey +
        ' should be `snake_cased`. ' +
        'See https://coursera.atlassian.net/wiki/spaces/EN/pages/46432678/Eventing#Eventing-NamingConvention'
    );
  }
  return eventKey;
}

function _getEventValue(contextData, propsData, trackingName, action) {
  return {
    // TODO(zhaojun): use deep merge
    ...contextData,
    ...propsData,
    namespace: {
      ...contextData.namespace,
      action,
      component: trackingName,
    },
    schema_type: 'FRONTEND',
  };
}

function trackComponent(contextData, propsData, trackingName, action, withTrackingData = undefined) {
  // gracefully handle the cases when the data was not set up correctly (e.g., no app name)
  if (!_isTrackable(contextData) || !trackingName) {
    logger.warn(
      `Retracked can't track with trackingName: ${trackingName}\taction: ${action}\t` +
        `context data: ${JSON.stringify(contextData)}` +
        `\tprops data: ${JSON.stringify(propsData)}`
    );
    return;
  }

  const data = withTrackingData
    ? { ...propsData, ...withTrackingData({ trackingData: propsData, trackingName, action }) }
    : propsData;

  const track = makeTracker();
  const eventKey = _getEventKey(contextData, trackingName, action);
  const eventValue = _getEventValue(contextData, data, trackingName, action);

  track(eventKey, eventValue);
}

/**
 * Create an HoC component that automatically adds visibility events when its child moves in
 * and out of the user's viewport.
 * To use:
 * Create the HoC with the child as the component you want visibility tracking on
 *   (e.g const WithVisibility = Retracked.withVisibilityTracking(MyComponent))
 *   Make sure to re-use the same `WithVisibility` component in all `render` functions, instead of recreating it.
 *   Otherwise `render` will unmount the previous instance and remount because it sees two different Components.
 *
 * If your element is in the viewport but you want to override the visibility handling
 * aria-hidden = 'true' will prevent the view event from firing.
 *
 */

const fullyVisibleObserver = new VisibilityObserver({ threshold: 1 });
const partiallyVisibleObserver = new VisibilityObserver({ threshold: 0 });

function withVisibilityTracking(Component) {
  const baseName = Component.displayName || Component.name;

  class VisibilityTracking extends React.Component {
    displayName = baseName + 'VisibilityTracking';

    static propTypes = {
      // when this component comes into the user's viewport, we fire the event
      // {group}.{page}.view.{trackingName} (this sets the last part)
      trackingName: PropTypes.string.isRequired,

      // Optionally, a viewport event may attach data. We use the same `data` key as the new Tracked Components.
      data: PropTypes.object,

      // If true, visibility is only achieved when the entire component boundary is within viewport.
      requireFullyVisible: PropTypes.bool,
    };

    static contextTypes = {
      _eventData: PropTypes.object,
    };

    static defaultProps = {
      requireFullyVisible: true,
    };

    startVisibilityTrackingTimeout;

    ref = React.createRef();

    visibilityObserver;

    componentDidMount() {
      const { requireFullyVisible } = this.props;
      this.visibilityObserver = requireFullyVisible ? fullyVisibleObserver : partiallyVisibleObserver;

      // starting visibility tracking 1000 ms after tracked element is mounted;
      // when we started the visibility tracking immediately after the element is mounted,
      // we observed that the element to be hidden by a scrolled container becomes visible,
      // generating a visibility impression. It is because the scroller container was rendered slightly
      // after the element is rendered, allowing the element that's supposed to be hidden actually shown
      this.startVisibilityTrackingTimeout = setTimeout(() => this.startVisibilityTracking(), 1000);
    }

    componentWillUnmount() {
      this.stopVisibilityTracking();
    }

    handleVisibility({ target, isIntersecting }) {
      if (!isIntersecting) {
        return;
      }

      const ariaHidden = target.getAttribute('aria-hidden') === 'true';
      if (ariaHidden) {
        return;
      }

      this.trackWithVisibilityMetadataForAction();
      this.stopVisibilityTracking();
    }

    trackWithVisibilityMetadataForAction() {
      const { data, trackingName } = this.props;
      const { _eventData } = this.context;

      const toTrackData = {
        ...data,
        visibilityMetadata: {
          UUID: generateUUID(),
          timestamp: new Date().getTime(),
          // If updating schema, please bump up version to make downstream analysis easier.
          version: 1,
        },
      };

      trackComponent(_eventData, toTrackData, trackingName, 'view');
    }

    startVisibilityTracking() {
      if (this.ref.current) {
        this.visibilityObserver.observe(this.ref.current, this.handleVisibility.bind(this));
      }
    }

    stopVisibilityTracking() {
      clearTimeout(this.startVisibilityTrackingTimeout);
      if (this.ref.current) {
        this.visibilityObserver.unobserve(this.ref.current);
      }
    }

    render() {
      // eslint-disable-next-line no-unused-vars, react/prop-types
      const { requireFullyVisible, 'aria-hidden': ariaHidden, ...componentProps } = this.props;
      return (
        <div ref={this.ref} aria-hidden={ariaHidden}>
          <Component {...componentProps} />
        </div>
      );
    }
  }
  hoistNonReactStatics(VisibilityTracking, Component);
  return VisibilityTracking;
}

/**
 * Hook for calling retrack trackComponent.
 * Returns a function that when call will log the event
 * It needs to have a trackingName, an action and trackingData, and it can be provided either when calling the hook,
 *  or when calling the log function
 * It automatically gets the namespace of the event from the context
 * 
 * 
    const trackMeClicked = useRetracked();
    return (
      <button type="button" onClick={() => trackMeClicked({ trackingName: 'track-me', action: 'click', trackingData: { value: 2 } })}>
        Track me!
      </button>
    );
 */
export const useRetracked = () => {
  const { eventData } = React.useContext(RetrackedContext);
  // We use useCallback to make sure that the function identity returned by useRetracked is stable and won’t change on re-renders
  // This is useful to prevent unnecessary renders
  return React.useCallback((props) => {
    trackComponent(eventData, props.trackingData, props.trackingName, props.action);
  }, []);
};

/**
 * A non-HOC variation of createTrackedContainer
 *
 *
 * This provides default properties to all tracking calls in descendant components.
 *
 * **Note that withTrackingData is not yet supported in this implementation.**
 *
 * @deprecated Please migrate to [Eventing V3](https://coursera.atlassian.net/wiki/spaces/EN/pages/3103391956/Event+Implementation+-+Web+SDK)
 */
export class TrackedContainer extends React.Component {
  static contextTypes = {
    _eventData: PropTypes.object,
  };

  static childContextTypes = {
    _eventData: PropTypes.object.isRequired,
  };

  getMergedEventData() {
    const eventProps = {
      namespace: {
        app: this.props.app,
        page: this.props.page,
      },
      ...(this.props.additionalData || {}),
    };

    const eventDataFromContext = this.context._eventData || {};
    const mutableEventDataFromContext = cloneDeep(eventDataFromContext);

    merge(mutableEventDataFromContext, eventProps);

    return mutableEventDataFromContext;
  }

  /** Provide via legacy context */
  getChildContext() {
    return {
      _eventData: this.getMergedEventData(),
    };
  }

  render() {
    /** Provide via modern context */
    return (
      <RetrackedContext.Provider
        value={{
          eventData: this.getMergedEventData(),
        }}
      >
        {this.props.children}
      </RetrackedContext.Provider>
    );
  }
}

export default {
  setup,
  createContainer,
  makeTracker,
  createTrackedContainer,
  trackComponent,
  withVisibilityTracking,
};
