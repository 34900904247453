import type { DefaultNamespace, Value } from 'bundles/epic/lib/EpicTypes';

class Defaults {
  private map: Map<string, Record<string, Value>> = new Map();

  add(namespace: DefaultNamespace) {
    const parameters = (namespace.parameters ?? [])
      .map((item) => [item.name, item.value] as const)
      .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

    return this.map.set(namespace.name, parameters);
  }

  get(namespace: string, parameter: string): Value | undefined {
    const ns = this.map.get(namespace);

    return ns?.[parameter];
  }
}

const singleton = new Defaults();

export default singleton;
