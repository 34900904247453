import { ApolloLink } from '@apollo/client';

import findPatchedNodes from './findPatchedNodes';
import transformNaptimeToRestQuery from './transformNaptimeToRestQuery';
import transformRestToNaptimeResponse from './transformRestToNaptimeResponse';

function NaptimeTransformLink() {
  return new ApolloLink((operation, forward) => {
    const patchedNodes = operation.query.definitions.reduce(
      (accumulator, definition) => accumulator.concat(findPatchedNodes(definition)),
      []
    );
    if (patchedNodes.length > 0) {
      // Patch query to transform @naptime to @rest and pass it to the next link
      // eslint-disable-next-line no-param-reassign
      operation.query = transformNaptimeToRestQuery(operation.query);

      // When we receive result, we transform it to nodes with @naptime directive
      return forward(operation).map(transformRestToNaptimeResponse(patchedNodes));
    }
    // Skip to next link if no @naptime directive found
    return forward(operation);
  });
}

export default NaptimeTransformLink;
