/**
 * Base class for all stores.
 *
 * This class provides an abstract interface for a key-value store.
 * Subclasses must implement the methods to interact with the store.
 */
abstract class BaseStore {
  /**
   * Clear all entries in the store.
   *
   * This method should remove all key-value pairs from the store.
   */
  abstract clear(): void;

  /**
   * Retrieve the value associated with the given key.
   *
   * @param key - The key whose value needs to be retrieved.
   * @returns The value associated with the key, or undefined if the key does not exist.
   */
  abstract get<TData>(key: string): TData | undefined;

  /**
   * Set the value for the given key.
   *
   * @param key - The key for which the value needs to be set.
   * @param value - The value to be set for the given key.
   */
  abstract set<TData>(key: string, value: TData): void;

  /**
   * Remove the value associated with the given key.
   *
   * @param key - The key whose value needs to be removed.
   */
  abstract remove(key: string): void;
}

export default BaseStore;
