import { randomUUID as uuid } from 'js/lib/uuid';

type Options = {
  history?: [referrer: string, current: string] | [initialReferrer: string, referrer: string, current: string];
  lastActivity?: number;
  sequence?: number;
  ttl?: number;
  uuid?: string;
};

const DEFAULT_TTL = 30 * 60 * 1000; // 30 minutes

/**
 * Class to keep track of the session.
 *
 * This class provides methods to manage session data, including URL history,
 * last activity timestamp, event sequence, and session UUID. It also supports
 * session expiration and serialization to JSON.
 */
class Session {
  /**
   * Represents the URL history of the session. Used to track the referrer and initial referrer.
   * @private
   */
  private declare history:
    | [referrer: string, current: string]
    | [initialReferrer: string, referrer: string, current: string];

  /**
   * The last activity timestamp of the session.
   * @private
   */
  private declare lastActivity: number;

  /**
   * Number representing the sequence of the events sent in the session.
   * @private
   */
  private declare sequence: number;

  /**
   * The time to live (TTL) of the session in milliseconds.
   * @private
   */
  private declare readonly ttl: number;

  /**
   * The UUID of the session.
   */
  declare uuid: string;

  /**
   * Constructor for the Session class.
   *
   * @param options - An optional object containing session initialization parameters.
   * If no parameters are provided, default values are used.
   */
  constructor(options: Options = {}) {
    // If the history already exists and we try to initialize the session again,
    // it means we are visiting a different page, so we keep the initial referrer and start a new history.
    this.history = options.history ?? [window.document.referrer, window.location.href];
    this.lastActivity = options.lastActivity ?? Date.now();
    this.sequence = options.sequence ?? 0;
    this.ttl = options.ttl ?? DEFAULT_TTL;
    // Amplitude needs ~timestamp suffix for a lot of calculations.
    // CRITICAL to keep this format.
    this.uuid = options.uuid ?? `${uuid()}~${Date.now()}`;
  }

  /**
   * Get the initial referrer of the session.
   *
   * @returns The initial referrer URL.
   */
  get initialReferrer(): string {
    return this.history[0];
  }

  /**
   * Get the current referrer of the session.
   *
   * @returns The current referrer URL.
   */
  get referrer(): string {
    return this.history[this.history.length - 2];
  }

  /**
   * Get the current URL of the session.
   *
   * @returns The current URL.
   */
  get url(): string {
    return this.history[this.history.length - 1];
  }

  /**
   * Check if the session is expired.
   *
   * @returns True if the session is expired, otherwise false.
   */
  isExpired(): boolean {
    return Date.now() - this.lastActivity > this.ttl;
  }

  /**
   * Check if the session is new.
   *
   * @returns True if the session is new, otherwise false.
   */
  isNew(): boolean {
    return this.sequence === 0;
  }

  /**
   * Get the next sequence number.
   *
   * @returns The next sequence number.
   */
  getSequence(): number {
    // Increase the sequence number
    this.sequence += 1;

    return this.sequence;
  }

  /**
   * Update the history of the session with the current URL.
   *
   * @param url - The new URL to be added to the session history.
   */
  navigate(url: string): void {
    if (this.history[this.history.length - 1] !== url) {
      // We only keep the last two URLs and the initial URL to track the referrer and initial referrer.
      this.history = [this.history[0], this.history[this.history.length - 1], url];
      //              ^ initial ref    ^ previous URL (last history item)   ^ current URL
    }
  }

  /**
   * Serialize the session to JSON.
   *
   * This method returns a JSON representation of the session, excluding the history.
   *
   * @returns An object containing the session data.
   */
  toJSON(): Omit<Options, 'history'> {
    // We do not store the history because we only use it for client-side navigations.
    // Once the user navigates to a new page, the history is reset.
    return {
      lastActivity: this.lastActivity,
      sequence: this.sequence,
      ttl: this.ttl,
      uuid: this.uuid,
    };
  }

  /**
   * Update the last activity timestamp of the session.
   */
  touch(): void {
    this.lastActivity = Date.now();
  }
}

export type { Options };
export { DEFAULT_TTL };
export default Session;
