import type React from 'react';
import { graphql } from 'react-apollo';

import gql from 'graphql-tag';
import { compose } from 'recompose';

import { apolloFetchPolicy } from 'js/utils/apolloFetchPolicy';

import type { MegaMenuDataQuery as MegaMenuDataQueryType } from 'bundles/megamenu/components/hoc/__generated__/MegaMenuDataQuery';
import type { mappedMegaMenuAPIData, megaMenuAPIData } from 'bundles/megamenu/types/MenuData';

type withMegaMenuDataProps = { megaMenuAPIData?: mappedMegaMenuAPIData };

export const MegaMenuDataQuery = gql`
  query MegaMenuDataQuery {
    ExternallyAccessibleNostosV1Resource {
      getAllProperties(job_name: "megamenu_nostos_job", keys: "megamenu_nostos_key") {
        elements {
          id
          content
        }
      }
    }
  }
`;

export const groupBySectionAndDomain = (rows?: megaMenuAPIData[]) => {
  const acc: mappedMegaMenuAPIData = {};
  rows?.forEach((row) => {
    const { sectionid, domainid } = row;
    if (acc[sectionid]) {
      if (acc[sectionid][domainid]?.length > 0) {
        acc[sectionid][domainid].push(row);
      } else {
        acc[sectionid][domainid] = [row];
      }
    } else {
      acc[sectionid] = { [domainid]: [row] };
    }
  });
  return acc;
};

const withMegaMenuData =
  () =>
  <T extends {}>(BaseComponent: React.ComponentType<T & withMegaMenuDataProps>): React.ComponentType<T> =>
    compose<T & withMegaMenuDataProps, T>(
      graphql<{}, MegaMenuDataQueryType, {}, withMegaMenuDataProps>(MegaMenuDataQuery, {
        options: () => ({
          ...apolloFetchPolicy({ cacheForLoggedOutOnly: true }),
          ssr: false,
        }),
        props: ({ data }) => {
          const rows: megaMenuAPIData[] | undefined =
            data?.ExternallyAccessibleNostosV1Resource?.getAllProperties?.elements[0]?.content?.load_menu;
          // current data does not have grouping by section and domain
          return {
            megaMenuAPIData: rows && groupBySectionAndDomain(rows),
          };
        },
      })
    )(BaseComponent);

export default withMegaMenuData;
