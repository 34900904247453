import epicClient from 'bundles/epic/clients/siteMisc';

import { type GraphQLGatewayLinks, graphqlGatewayLinkEndpoints } from './constants';

/**
 * allows to set a specific link to use for queries by name.
 */
export const graphqlGatewayQueryLinkOverrideFactory =
  (client: typeof epicClient) =>
  (operationName: string, clientName?: string, appName?: string): GraphQLGatewayLinks | string | null => {
    if (clientName && !(clientName in graphqlGatewayLinkEndpoints)) {
      return null;
    }

    const overrides = {
      ...client.get('graphqlGatewayQueryLinkOverride'),
      ...client.get('graphqlGatewayQueryLinkOverrideSecondary'),
    };

    if (overrides[operationName]) {
      if (typeof overrides[operationName] === 'string') {
        return overrides[operationName];
      }

      if (appName && typeof overrides[operationName][appName] === 'string') {
        return overrides[operationName][appName];
      }
    }

    if (overrides.default) {
      if (typeof overrides.default === 'string') return overrides.default;
      if (appName && overrides.default[appName]) {
        return overrides.default[appName];
      }
    }

    return null;
  };

export const graphqlGatewayQueryLinkOverride = graphqlGatewayQueryLinkOverrideFactory(epicClient);
