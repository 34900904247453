import { type ComponentType } from 'react';
import type { IndexRoute, RouteProps } from 'react-router';

import logger from 'js/app/loggerSingleton';

import inServerContext from 'bundles/ssr/util/inServerContext';

// TODO Once on react-router@3, remove the second half (it's the same as the first)
type GetComponent = Required<RouteProps>['getComponent'] & Required<IndexRoute.IndexRouteProps>['getComponent'];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ImportPromise = () => Promise<{ default: ComponentType<any> } | ComponentType<any>>;
type BuildOnRoute = { moduleName: string };

const hasDefaultExport = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  module: { default: ComponentType<any> } | ComponentType<any>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): module is { default: ComponentType<any> } => {
  return typeof module === 'object' && 'default' in module;
};

const prefersReducedMotion = () => !inServerContext && window.matchMedia('(prefers-reduced-motion)').matches;

const createAsyncHandlerWithPromise =
  (importModulePromiseFactory: ImportPromise, viewTransition?: boolean): GetComponent =>
  // This is the
  // [getComponents](https://github.com/reactjs/react-router/blob/bd9f53f/docs/API.md#getcomponentsnextstate-callback)
  // callback from `react-router`
  (_nextState, cb) => {
    const asyncImport = () =>
      importModulePromiseFactory()
        .then((module) => cb(null, hasDefaultExport(module) ? module.default : module))
        .catch((err) => {
          logger.info('Dynamic route loading failed', err);
          cb(err, () => null);
        });

    if (inServerContext || !document.startViewTransition || !viewTransition || prefersReducedMotion()) {
      asyncImport();
      return;
    }

    try {
      document.startViewTransition(asyncImport);
    } catch (err) {
      logger.info('document transistion failed', err);
    }
  };

type Options = {
  viewTransition?: boolean;
};

const isImportPromise = (mod: ImportPromise | BuildOnRoute): mod is ImportPromise => {
  return typeof mod === 'function' && mod.length === 0;
};

const loadOnRoute = (module: ImportPromise | BuildOnRoute, { viewTransition }: Options = {}): GetComponent => {
  if (!isImportPromise(module)) {
    throw new Error(
      'Should not pass in a raw import(...) statement to `loadOnRoute` as ' +
        'it will not lazily load the code! ' +
        'Please pass in a promise factory like `() => import(...) instead.'
    );
  }

  return createAsyncHandlerWithPromise(module, viewTransition);
};

export const loadOnRouteWithTransition = (module: ImportPromise | BuildOnRoute): GetComponent => {
  return loadOnRoute(module, { viewTransition: true });
};

export default loadOnRoute;
