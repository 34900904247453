import { css } from '@emotion/react';

const megaMenuZIndex = 3000;
const headerHeight = 65;

export const styles = {
  megaMenuOverlay: () => css`
    position: fixed;
    background-color: rgb(0 0 0 / 70%);
    top: ${headerHeight}px;
    left: 0;
    z-index: 3000;
    width: 100vw;
    height: calc(100% - ${headerHeight}px);
    display: block;
  `,
  megaMenuOverlayHidden: () => css`
    display: none;
  `,
  megaMenuContainer: () => css`
    position: absolute;
    top: -20px;
  `,
  megaMenu: () => css`
    position: relative;
    margin-top: 11px;
    padding: 0;
    z-index: ${megaMenuZIndex};
    background-color: var(--cds-color-grey-25);
    border: 1px solid #e3e3e3;
    box-shadow: none;
    display: flex;
  `,
  loadingContainer: () => css`
    margin-top: 11px;
    padding: 14px 0 0;
    z-index: ${megaMenuZIndex};
    border-top: 1px solid #e3e3e3;
  `,
  loadingIcon: () => css`
    margin-top: 4rem;
  `,
};

export default styles;
