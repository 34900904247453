import { memoize } from 'lodash';

import Device from './device';
import { createDefaultStore } from './store';
import type { Store } from './store';

type Options = {
  store: Store;
};

/**
 * Class to manage device instances and provide device-related functionalities.
 *
 * This class is responsible for creating, restoring, and updating device instances.
 * It also handles the persistence of device data using a provided store.
 */
class DeviceProvider {
  /**
   * The current device instance.
   * @private
   */
  private declare readonly instance: Device;

  /**
   * The store used to persist device data.
   * @private
   */
  private declare store: Store;

  /**
   * Constructor for the DeviceProvider class.
   *
   * @param options - An object containing a store for device data.
   */
  constructor(options: Options) {
    this.store = options.store;

    // Restore or create a new device
    const previous = this.store.get<Partial<Device>>('device');
    this.instance = new Device({ ...previous });
  }

  /**
   * Get the current device instance.
   *
   * @returns The current device instance.
   */
  get(): Device {
    return this.instance;
  }

  /**
   * Update the device data and persist it to the store.
   *
   * This method updates the device data and then persists it to the store.
   */
  touch(): void {
    // Store the new device
    this.store.set('device', this.instance.toJSON());
  }
}

/**
 * Create a default device provider instance.
 *
 * This function memoizes the creation of a default device provider instance
 * using a default store.
 *
 * @returns A memoized default device provider instance.
 */
const createDefaultDeviceProvider = memoize(() => new DeviceProvider({ store: createDefaultStore() }));

export { createDefaultDeviceProvider };
export default DeviceProvider;
