import { css } from '@emotion/react';

import clsx from 'clsx';

import { classes } from '@core/cards/ProductCard/getProductCardCss';
import Tag from '@core/Tag';

type Props = {
  tags: string[];

  /**
   * Whether tags should overlay on top of other content in the layout.
   * e.g. for <CardPreviewGrid/> the tags lay on top of the preview image.
   * @default false
   */
  shouldOverlay?: boolean;
};

// override default Tag styles confirmed by Search team experiments to perform better.
const styles = css`
  background-color: var(--cds-color-neutral-background-primary) !important;
  color: var(--cds-color-neutral-primary) !important;
  border-color: var(--cds-color-neutral-stroke-primary) !important;
`;

const CardStatusTags = ({ tags, shouldOverlay = false }: Props) => {
  if (tags.length === 0) {
    return null;
  }

  return (
    <div
      className={clsx(classes.statusTags, {
        [classes.statusTagsSimple]: !shouldOverlay,
        [classes.statusTagsOverlay]: shouldOverlay,
      })}
    >
      {tags.map((tag) => (
        <Tag key={tag} css={styles} priority="secondary" variant="status">
          {tag as string}
        </Tag>
      ))}
    </div>
  );
};

export default CardStatusTags;
