import { useState, useCallback } from 'react';

import { useLocalizedStringFormatter } from '@coursera/cds-common';

import { CardAvatar, CardInteractiveArea } from '@core/cards/common';
import { classes } from '@core/cards/ProductCard/getProductCardCss';
import i18nMessages from '@core/cards/ProductCard/i18n';
import { Tooltip } from '@core/Tooltip';
import Typography from '@core/Typography2';

export type ProductCardPartner = {
  name: string;
  logoUrl?: string;
};

type Props = {
  partners: ProductCardPartner[];
  id?: string;
};

/**
 * Renders the product partners with their avatars + names with a tooltip
 */
const CardPartnerInfo = (props: Props) => {
  const stringFormatter = useLocalizedStringFormatter(i18nMessages);

  const { partners, id } = props;
  const [showTooltip, setShowTooltip] = useState(false);

  const handleOpen = useCallback(() => {
    setShowTooltip(true);
  }, []);

  const handleClose = useCallback(() => {
    setShowTooltip(false);
  }, []);

  return (
    <div className={classes.partnerInfo} id={id}>
      <CardInteractiveArea>
        <Tooltip
          arrow
          open={showTooltip}
          placement="top"
          title={partners.map((partner) => partner.name).join(', ')}
          onClose={handleClose}
          onOpen={handleOpen}
        >
          <div className={classes.partners}>
            {partners.map((partner) => (
              <div key={partner.name} className={classes.partnerLogos}>
                <CardAvatar
                  imgProps={{ src: partner.logoUrl }}
                  initial={partner.name}
                />
              </div>
            ))}

            <Typography
              className={classes.partnerNames}
              color="supportText"
              component="p"
              variant="bodySecondary"
            >
              {partners.length === 1
                ? partners[0].name
                : stringFormatter.format('multiple_educators')}
            </Typography>
          </div>
        </Tooltip>
      </CardInteractiveArea>
    </div>
  );
};

export default CardPartnerInfo;
