import type { Events } from '@coursera/event-pulse/core';

import { high as client } from './client';

type Options = Events['identify'];

/**
 * This function allows you to control user properties with operators.
 *
 * @see https://github.com/webedx-spark/eventing-schemas/blob/main/schemas/eventingv3/schema_definitions/identify.schema.json
 *
 * @param options
 */
function identify(options: Options) {
  return client.sendEvent('identify', options);
}

export default identify;
