import * as Sentry from '@sentry/react';

import logger from 'js/app/loggerSingleton';

import type { Events } from '@coursera/event-pulse/core';

type Page = Partial<Events['view_page']['page']>;

let memory: Page | undefined;

function get(): Page {
  if (!memory) {
    // TODO(@adrian): Replace this log with the future @coursera/log

    logger.error('[Eventing]: Page options have not been set');

    const error = new Error('[Eventing]: Page options have not been set');
    Sentry.captureException(error);
  }

  // We need to safely return the page even if it is undefined
  return memory ?? {};
}

function set(value: Page): void {
  memory = value;
}

export { get, set };
