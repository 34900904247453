import type { FetchResult, Operation } from '@apollo/client';

import requestUrlSearchParams from 'js/lib/requestUrlSearchParams';

import { AVAILABLE_SPACES, getClientName } from 'bundles/cms/utils/SpaceUtils';

export const getCmsGraphqlContextBySlug = (spaceSlug: string, isPreview: boolean) => {
  return { clientName: getClientName(spaceSlug, isPreview) };
};

// default to GROWTH for legacy usecase and growth space
export const getGrowthCmsGraphqlContext = (isPreview: boolean) =>
  getCmsGraphqlContextBySlug(AVAILABLE_SPACES.growth, isPreview);

// ignore replacing strings with these keys to prevent broken page
const exceptionKeys = [
  'id',
  '__typename',
  'slug',
  'regionSlug',
  'languageCode',
  'image',
  'url',
  'uri',
  'promoBannerId',
  'sys',
  'page',
  'nodeType',
  'type',
  'embedType',
  'localizedCountryCode',
  'localizedLanguageCode',
  'defaultRegion',
  'applyButtonUrl',
  'layout',
  'layoutOptions',
  'theme',
  'formIdEnglish',
  'proofPointsDisplayStyle',
  'primaryCourseraPlusProduct',
  'secondaryCourseraPlusProduct',
  'heroDesignChoice',
  'pretranslated',
];

// Helper function to replace JSON from localization system
function replaceStringOrStringifiedJson(str: string) {
  try {
    // Check if the string is a valid JSON
    const json = JSON.parse(str);

    // If it is, replace strings in the parsed JSON
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const replacedJson = replaceStringsWithGibberish(json);

    // Convert the replaced JSON back to a string
    return JSON.stringify(replacedJson);
  } catch (e) {
    // If the string is not a valid JSON, replace all non-whitespace characters in the string with 'c'
    return str.replaceAll(/[^\s]/g, '𝘤');
  }
}

// Helper function to recursively replace all strings in an object
export function replaceStringsWithGibberish(
  obj: Record<string, unknown> | unknown[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Record<string, any> | null | undefined {
  if (obj === null) {
    return null;
  }

  // Clone obj to avoid mutating the original
  const clonedObj = Array.isArray(obj) ? [...obj] : { ...obj };

  if (Array.isArray(clonedObj)) {
    // If clonedObj is an array, recursively replace strings in each item
    clonedObj.forEach((item, index) => {
      if (typeof item === 'string') {
        clonedObj[index] = replaceStringOrStringifiedJson(item);
      } else if (typeof item === 'object') {
        // Recursively replace strings in the nested object or array
        clonedObj[index] = replaceStringsWithGibberish(item as Record<string, unknown> | unknown[]);
      }
    });
  } else {
    // If clonedObj is an object, replace strings in each property
    for (const key in clonedObj) {
      if (!exceptionKeys.includes(key)) {
        if (typeof clonedObj[key] === 'string') {
          clonedObj[key] = replaceStringOrStringifiedJson(clonedObj[key] as string);
        } else if (typeof clonedObj[key] === 'object') {
          // Recursively replace strings in the nested object or array
          clonedObj[key] = replaceStringsWithGibberish(clonedObj[key] as Record<string, unknown> | unknown[]);
        }
      }
    }
  }
  return clonedObj;
}

export function generateGibberishInterceptorForContentful(operation: Operation) {
  const { clientName } = operation.getContext();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (response: FetchResult<Record<string, any>, Record<string, any>, Record<string, any>>) => {
    const showCopySource = requestUrlSearchParams().get('showCopySource') === 'true';
    const isLocalizationSystem = operation?.operationName === 'GetLocalizedData';
    if (showCopySource && response?.data && (clientName?.includes('contentful') || isLocalizationSystem)) {
      response.data = replaceStringsWithGibberish(response?.data);
    }
    return response;
  };
}

export default {
  getCmsGraphqlContextBySlug,
  replaceStringsWithGibberish,
};
