import React from 'react';

import { Modal } from '@core/Modal';
import type { ModalProps } from '@core/Modal';

export type Props = ModalProps & {
  classes?: { dialog?: string };
};

const BaseDialog = React.forwardRef(function BaseDialog(
  props: Props,
  ref: React.Ref<HTMLDivElement>
) {
  const {
    children,
    classes,
    'aria-label': ariaLabel,
    'aria-labelledby': ariaLabelledBy,
    'aria-describedby': ariaDescribedBy,
    role = 'dialog',
    ...modalProps
  } = props;

  return (
    <Modal ref={ref} {...modalProps}>
      <div
        aria-modal
        aria-describedby={ariaDescribedBy}
        aria-label={ariaLabel}
        aria-labelledby={ariaLabelledBy}
        className={classes?.dialog}
        role={role}
      >
        {children}
      </div>
    </Modal>
  );
});

export default BaseDialog;
