/*
This regex matches a subfolder URL pathname e.g /de-DE/courses or /de-DE/learn/machine-learning.
The first part of the URL path tests for a valid subfolder locale using the validator regex here - https://github.com/webedx-spark/edge/blob/0376332549d0163113b275ee2a8de4d9542fe923/scripts/i18nSubfolders.ts#L6
*/
export const subfolderPathnameWithLocaleRegex = /^\/(([a-z]{2})-?([A-Z]{2})?)\/([a-zA-Z0-9_-]+(\/[a-zA-Z0-9_-]+)*)$/;

type ReturnValue = {
  language: string;
  locale: string | null;
  languageAndLocale: string;
  restOfPath: string;
};

export const urlPathLocaleSubfolderPatternMatcher = (urlPath?: string): ReturnValue | null => {
  if (!urlPath) return null;
  const matches = urlPath.match(subfolderPathnameWithLocaleRegex);

  if (matches && matches.length >= 3) {
    return {
      language: matches[2],
      locale: matches[3] || null,
      languageAndLocale: matches[1],
      restOfPath: matches[4],
    };
  }
  return null;
};

export const doesUrlPathMatchLocaleSubfolderPattern = (urlPath: string) => {
  return subfolderPathnameWithLocaleRegex.test(urlPath);
};
