import * as Sentry from '@sentry/browser-bridge';

import { captureException, captureMessage, setExtra, setExtras, setTag, setTags } from 'js/lib/SentryProxyMethods';
import user from 'js/lib/user';

if (user.get().authenticated) {
  Sentry?.setUser({
    id: user.get().external_id,
    isSuperuser: user.isSuperuser(),
  });
}

export * from '@sentry/browser-bridge';
export { captureException, captureMessage, setExtra, setExtras, setTag, setTags };
