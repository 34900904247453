import type { DialogColor } from './types';

export const getPaletteColor = (color: DialogColor): string => {
  const paletteMap: Record<DialogColor, string> = {
    blue: 'var(--cds-color-callouts-background-primary)',
    darkBlue: 'var(--cds-color-callouts-background-primary-strong)',
    darkPurple: 'var(--cds-color-callouts-secondary)',
    white: 'var(--cds-color-neutral-background-primary)',
  };

  return paletteMap[color];
};
