import Q from 'q';

import Multitracker from 'js/app/multitrackerSingleton';
import Cookie from 'js/lib/cookie';
import memoize from 'js/lib/memoize';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'js/l... Remove this comment to see the full error message
import thirdParties from 'js/lib/thirdParties';
import timing from 'js/lib/timing';

import GrowthExperiments from 'bundles/epic/clients/Growth';
import constants from 'bundles/event-tracking/constants';
import AdsTracker from 'bundles/event-tracking/tracker';
import userJson from 'bundles/user-account/common/user';

const DATA_LAYER = constants.googleTagManager.dataLayerName;
const GTAG = constants.googleTagManager.gtagName;

declare global {
  interface Window {
    [GTAG]: (...args: unknown[]) => unknown;
    [DATA_LAYER]?: unknown[];
  }
}

function GoogleTagManager(...args: $TSFixMe[]) {
  // @ts-expect-error ts-migrate(2683) FIXME: 'this' implicitly has type 'any' because it does n... Remove this comment to see the full error message
  AdsTracker.apply(this, args);
}

function trackEvent(eventName: string, params?: Record<string, unknown>) {
  const multiTrackerEventKey = 'adsTracker.googleTagManager.' + eventName;
  Multitracker.pushV2([multiTrackerEventKey, params]);
}

// @ts-expect-error ts-migrate(7009) FIXME: 'new' expression, whose target lacks a construct s... Remove this comment to see the full error message
GoogleTagManager.prototype = new AdsTracker();

GoogleTagManager.prototype.ensureLibLoaded = memoize(function () {
  const deferred = Q.defer();

  timing.time('googleTagManager');
  window[DATA_LAYER] = window[DATA_LAYER] || [];
  window[DATA_LAYER]?.push({
    'gtm.start': new Date().getTime(),
    sessionId: Cookie.get('__204u') || '1234',
    event: 'gtm.js',
  });
  window[GTAG] = function (...args) {
    window[DATA_LAYER]?.push(args);
  };
  const useCopyGTMContainerUrl = GrowthExperiments.preview('useCopyGTMContainerForTesting');
  thirdParties.loadScript(
    useCopyGTMContainerUrl ? constants.googleTagManager.copyContainerUrl : constants.googleTagManager.url,
    function () {
      timing.timeEnd('googleTagManager');
      deferred.resolve(window[DATA_LAYER]);
    },
    function () {
      trackEvent('error');
    }
  );

  return deferred.promise;
});

GoogleTagManager.prototype.send = function (params: $TSFixMe) {
  return this.ensureLibLoaded().then(function (dataLayer: $TSFixMe) {
    return dataLayer.push(params);
  });
};

GoogleTagManager.prototype.prepareParams = function (eventName: $TSFixMe, additionalData: $TSFixMe) {
  return Object.assign(
    {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'id' does not exist on type '{} | UserDat... Remove this comment to see the full error message
      userId: (userJson && userJson.id) || '',
      event: eventName,
    },
    additionalData
  );
};

GoogleTagManager.prototype.trackSignup = function (userId: $TSFixMe) {
  trackEvent('signup');
  return this.track('Signup', { userId });
};

GoogleTagManager.prototype.trackEnroll = function ({
  userId,
  productId,
  productCategory,
  productName,
  productSlug,
}: {
  userId: number;
  productId?: string;
  productCategory: string;
  productName?: string;
  productSlug?: string;
}) {
  trackEvent('enroll');
  return this.track('Enroll', { userId, productId, productCategory, productName, productSlug });
};

GoogleTagManager.prototype.trackDegreeEOICompletion = function (
  degreeSlug: $TSFixMe,
  surveyResponseId: $TSFixMe,
  isQualified: $TSFixMe
) {
  trackEvent('degree_eoi_completion');

  const trackingInfo = {
    degreeSlug,
  };

  if (surveyResponseId) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'surveyResponseId' does not exist on type... Remove this comment to see the full error message
    trackingInfo.surveyResponseId = surveyResponseId;
  }

  if (isQualified) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'isQualified' does not exist on type '{ d... Remove this comment to see the full error message
    trackingInfo.isQualified = isQualified;
  }

  return this.track('DegreeEOICompletion', trackingInfo);
};

GoogleTagManager.prototype.trackDegreeApplyNow = function (
  degreeSlug: string,
  productId?: string,
  productName?: string,
  productCategory?: string
) {
  trackEvent('degree_apply_now');

  const trackingInfo = {
    degreeSlug,
    ...(productId && { productId }),
    ...(productName && { productName }),
    ...(productCategory && { productCategory }),
  };

  return this.track('DegreeApplyNow', trackingInfo);
};

GoogleTagManager.prototype.trackScheduleACall = function (degreeSlug: $TSFixMe) {
  trackEvent('schedule_a_call');

  const trackingInfo = {
    degreeSlug,
  };

  return this.track('ScheduleACall', trackingInfo);
};

GoogleTagManager.prototype.trackEnterpriseContractPayment = function (data: $TSFixMe) {
  trackEvent('wes_payment');
  return this.track('WESPayment', data);
};

GoogleTagManager.prototype.trackAddToCart = function (data: { userId: string; productId: string }) {
  trackEvent('add_to_cart');

  return this.track('AddToCart', data);
};

GoogleTagManager.prototype._trackPaymentEvent = function (
  type: $TSFixMe,
  userId: $TSFixMe,
  paymentsInfoVal: $TSFixMe,
  orderId: $TSFixMe
) {
  const paymentsInfo = [].concat(paymentsInfoVal);
  const promises = paymentsInfo.map(
    ({ id, value, name, specializationId, currency, isFirstTimePayer, isFinaidCart, subscriptionCycle }) => {
      const specialization = {
        SpecializationId:
          specializationId ||
          ((type === 'specialization' || type === 'specializationSubscription' || type === 'specializationPrepaid') &&
            id) ||
          'Non-Spec',
      };
      const course = type === 'course' ? { CourseId: id } : {};
      const subscription = subscriptionCycle ? { SubscriptionCycle: subscriptionCycle } : {};

      const data = Object.assign(
        {
          Value: parseFloat(value || 0),
          Type: type,
          Name: name,
          Currency: currency,
          FirstTimePayer: isFirstTimePayer,
          IsFinaidCart: isFinaidCart,
        },
        specialization,
        course,
        subscription
      );

      trackEvent('payment', { ...data, userId, cartId: orderId });

      return this.track('Payment', data);
    }
  );
  return Q.allSettled(promises);
};

GoogleTagManager.prototype.trackS12NBulkPayment = function (...args: $TSFixMe[]) {
  trackEvent('bulkpay');
  return this._trackPaymentEvent('specialization', ...args);
};

GoogleTagManager.prototype.trackS12NSubscriptionPayment = function (...args: $TSFixMe[]) {
  trackEvent('s12n_subscription_payment');
  return this._trackPaymentEvent('specializationSubscription', ...args);
};

GoogleTagManager.prototype.trackS12NVCPayment = function (...args: $TSFixMe[]) {
  trackEvent('s12n_course_purchase');
  return this._trackPaymentEvent('course', ...args);
};

GoogleTagManager.prototype.trackVCPayment = function (...args: $TSFixMe[]) {
  trackEvent('single_course_purchase');
  return this._trackPaymentEvent('course', ...args);
};

GoogleTagManager.prototype.trackGuidedProjectPayment = function (...args: $TSFixMe[]) {
  trackEvent('guided_project_purchase');
  return this._trackPaymentEvent('guidedProject', ...args);
};

GoogleTagManager.prototype.trackProjectPayment = function (...args: $TSFixMe[]) {
  trackEvent('project_purchase');
  return this._trackPaymentEvent('project', ...args);
};

GoogleTagManager.prototype.trackCourseraLiteSubscriptionPayment = function (...args: $TSFixMe[]) {
  trackEvent('coursera_lite_subscription_purchase');
  return this._trackPaymentEvent('courseraLiteSubscription', ...args);
};

GoogleTagManager.prototype.trackCourseraPlusSubscriptionPayment = function (...args: $TSFixMe[]) {
  trackEvent('coursera_plus_subscription_purchase');
  return this._trackPaymentEvent('courseraPlusSubscription', ...args);
};

GoogleTagManager.prototype.trackCourseraPlusPrepaidPayment = function (...args: $TSFixMe[]) {
  trackEvent('coursera_plus_prepaid_purchase');
  return this._trackPaymentEvent('courseraPlusPrepaid', ...args);
};

GoogleTagManager.prototype.trackMasterTrackPayment = function (...args: $TSFixMe[]) {
  trackEvent('master_track_purchase');
  return this._trackPaymentEvent('masterTrack', ...args);
};

GoogleTagManager.prototype.trackS12NPrepaidPayment = function (...args: $TSFixMe[]) {
  trackEvent('s12n_prepaid_purchase');
  return this._trackPaymentEvent('specializationPrepaid', ...args);
};

GoogleTagManager.prototype.trackEmailMeInfo = function (
  slug: string,
  productId: string,
  productName: string,
  productCategory: string
) {
  trackEvent('email_me_info');

  const trackingData = {
    slug,
    productId,
    productName,
    productCategory,
  };

  return this.track('EmailMeInfo', trackingData);
};

GoogleTagManager.prototype.trackEnrollButtonClick = function ({
  userId,
  productId,
  productCategory,
  productName,
  productSlug,
}: {
  userId?: number;
  productId?: string;
  productCategory?: string;
  productName?: string;
  productSlug?: string;
}) {
  trackEvent('enrollButtonClick');
  return this.track('EnrollButtonClick', { userId, productId, productCategory, productName, productSlug });
};

GoogleTagManager.prototype.trackS12nEnrollModalButtonClick = function ({
  userId,
  productId,
}: {
  userId?: number;
  productId?: string;
}) {
  trackEvent('s12nEnrollModalButtonClick');
  return this.track('S12nEnrollModalButtonClick', { userId, productId });
};

export default GoogleTagManager;
