export type { BaseQueryOptions as QueryOpts } from '@apollo/client/react';
export type { ChildProps } from '@apollo/client/react/hoc';
export type { DataProps } from '@apollo/client/react/hoc';
export type { DataValue } from '@apollo/client/react/hoc';
export type { ExecutionResult } from 'graphql';
export type { FetchResult } from '@apollo/client';
export type { MutateProps } from '@apollo/client/react/hoc';
export type { MutationFunction as MutationFn } from '@apollo/client/react';
export type { MutationResult } from '@apollo/client/react';
export type { OperationOption } from '@apollo/client/react/hoc';
export type { OperationVariables } from '@apollo/client';
export type { OptionProps } from '@apollo/client/react/hoc';
export type { QueryResult } from '@apollo/client';
export type { WithApolloClient } from '@apollo/client/react/hoc';
export { ApolloConsumer } from '@apollo/client';
export { ApolloProvider } from '@apollo/client';
export { Mutation } from '@apollo/client/react/components';
export { Query } from '@apollo/client/react/components';
export { graphql } from '@apollo/client/react/hoc';
export { withApollo } from '@apollo/client/react/hoc';

// REMOVED:
//
// QueryOpts (mapped to BaseQueryOptions)
// MutationFn (mapped to MutationFunction)
