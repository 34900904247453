import type { FetchPolicy } from 'apollo-client';

import user from 'js/lib/user';

import inServerContext from 'bundles/ssr/util/inServerContext';

type FetchPolicyOptions = {
  // This is useful for queries that call APIs that have
  // some implicit non-determinism, such as depending on
  // some kind of user context in the backend without being
  // a query parameter.
  cacheForLoggedOutOnly?: boolean;
};

/**
 * Utility to configure fetchPolicy for apollo queries.
 */
export const apolloFetchPolicy = ({ cacheForLoggedOutOnly = true }: FetchPolicyOptions = {}): {
  fetchPolicy: FetchPolicy;
} => {
  let fetchPolicy: FetchPolicy = 'cache-first';
  if (inServerContext) {
    // @ts-expect-error cache-and-network has been removed from FetchPolicy as of Apollo 2.6.0
    fetchPolicy = cacheForLoggedOutOnly && user.isAuthenticatedUser() ? 'cache-and-network' : 'cache-first';
  }
  return {
    fetchPolicy,
  };
};
