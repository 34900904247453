import type React from 'react';

import type { EmotionCache } from '@emotion/cache';
import { CacheProvider } from '@emotion/react';

type Props = {
  children?: React.ReactNode;
  value: EmotionCache;
};

/**
 * Provide custom cache for emotion based on locale. The RTL cache includes
 * the RTL plugin https://github.com/styled-components/stylis-plugin-rtl
 * for automatically flipping rules for right-to-left locales.
 */
const EmotionCacheProvider = ({ value, children }: Props) => (
  <CacheProvider value={value}>{children}</CacheProvider>
);

export default EmotionCacheProvider;
