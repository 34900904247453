import React from 'react';

import { Fade as MuiFade, Slide as MuiSlide } from '@material-ui/core';
import type { FadeProps as MuiFadeProps } from '@material-ui/core/Fade/Fade';
import type { SlideProps as MuiSlideProps } from '@material-ui/core/Slide/Slide';

type TransitionProps = Pick<MuiFadeProps, 'children' | 'timeout' | 'in'>;

export type Props = TransitionProps & {
  variant: 'fade-in' | 'slide-up' | 'slide-left' | 'slide-right';
};

const slideDirectionMap: Partial<
  Record<Props['variant'], MuiSlideProps['direction']>
> = {
  'slide-up': 'up',
  'slide-left': 'left',
  'slide-right': 'right',
};

const ModalTransition = React.forwardRef(function Transition(
  props: Props,
  ref: React.Ref<HTMLDivElement>
) {
  const { children, variant, ...rest } = props;
  const transitionProps = { ref, appear: true, ...rest };

  const direction = slideDirectionMap[variant];

  if (direction) {
    return (
      <MuiSlide direction={direction} {...transitionProps}>
        {children}
      </MuiSlide>
    );
  }

  return <MuiFade {...transitionProps}>{children}</MuiFade>;
});

export default ModalTransition;
