import {
  getKnownSubdomainFromHost,
  getLegacySubdomainFromHost,
} from 'bundles/internationalization-lib/constants/countrySubdomains';

const get = (): string | undefined => {
  const maybeHostname: string | undefined = window?.location?.hostname;
  return getKnownSubdomainFromHost(maybeHostname);
};

const getLegacySubdomainCountryCode = (): string | undefined => {
  const maybeHostname: string | undefined = window?.location?.hostname;
  return getLegacySubdomainFromHost(maybeHostname);
};

const isInternationalSubdomain = (): boolean => !!(get() || getLegacySubdomainCountryCode());

export default {
  get,
  isInternationalSubdomain,
};
