import * as React from 'react';

import type { ServerRouterData } from 'bundles/page/types/router';

export default React.createContext<ServerRouterData>({
  location: {
    pathname: '',
    search: '',
    query: {},
  },
});
