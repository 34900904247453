import React from 'react';

import type { ComponentPropsWithoutRef } from '@coursera/cds-common';

import { getStickyScrollCss } from './stickyScrollCss';

export type StickyType = 'headingGroup' | 'actions' | 'none';

type Props = {
  children?: React.ReactNode;
} & ComponentPropsWithoutRef<'div'>;

export const ScrollContainer = ({ children, ...rest }: Props) => {
  return (
    <div css={getStickyScrollCss()} {...rest} data-testid="scroll-container">
      {children}
    </div>
  );
};

const StickyHeadingGroup = ({ children, ...rest }: Props) => {
  const [headingGroup, ...scrollableContent] = React.Children.toArray(children);

  return (
    <>
      {headingGroup}
      <ScrollContainer {...rest}>{scrollableContent}</ScrollContainer>
    </>
  );
};

const StickyActions = ({ children, ...rest }: Props) => {
  const childArray = React.Children.toArray(children);
  const scrollableContent = childArray.slice(0, -1);
  const actions = childArray[childArray.length - 1];

  return (
    <>
      <ScrollContainer {...rest}>{scrollableContent}</ScrollContainer>
      {actions}
    </>
  );
};

const stickyComponentsMap: Record<StickyType, React.ComponentType> = {
  actions: StickyActions,
  headingGroup: StickyHeadingGroup,
  none: ScrollContainer,
};

export const StickyScroll = ({
  sticky,
  ...rest
}: { sticky: StickyType } & Props) => {
  const Component = stickyComponentsMap[sticky];

  return <Component {...rest} />;
};
