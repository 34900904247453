export type GraphQLGatewayLinks = 'gatewayGql' | 'devGatewayGql' | 'graphqlProxyGql' | 'gatewayWrapperGql';

/**
 * GraphQL Gateway endpoints. Keys are identifiers for queries to use a
 * specific endpoint and the values are the paths for the API endpoint.
 */
export const graphqlGatewayLinkEndpoints: Record<GraphQLGatewayLinks, string> = {
  gatewayGql: '/graphql-gateway',
  // used for development/testing purposes
  devGatewayGql: '/graphql-gateway-testing',
  // endpoint for migrating queries from assembler to the gateway
  graphqlProxyGql: '/graphql-proxy',
  // endpoint for a compatibility shim wrapping the gateway schema so that it can accept assembler queries
  gatewayWrapperGql: '/graphql-gateway-wrapper',
};

export const XCourseraSchemaVersion = 'X-Coursera-Schema-Version';
