import type React from 'react';
import { useEffect, useRef } from 'react';

import type { MenuSection } from 'bundles/megamenu/types/MenuData';

function useArrowKeys(divRef: React.RefObject<HTMLElement>, sectionItems: MenuSection[], isMobile?: boolean) {
  const newIndex = useRef(0);

  useEffect(() => {
    const divElement = divRef.current;
    const listItems = divElement?.querySelectorAll('a');
    const handleKeyDown = (event: KeyboardEvent) => {
      if (listItems) {
        switch (event.key) {
          case 'ArrowUp':
            event.preventDefault();
            if (newIndex.current === 0) {
              newIndex.current = listItems.length - 1;
            } else {
              newIndex.current -= 1;
            }
            break;

          case 'ArrowDown':
            event.preventDefault();
            if (newIndex.current === listItems.length - 1) {
              newIndex.current = 0;
            } else {
              newIndex.current += 1;
            }
            break;
          case 'Tab':
            newIndex.current = 0;
            break;
          default:
            break;
        }
        listItems[0].tabIndex = 0;
        listItems[newIndex.current]?.focus();
      }
    };
    if (divElement && !isMobile) {
      divElement.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      if (divElement) {
        divElement.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, [divRef, isMobile, sectionItems]); // SectionItems changes after initial render so need it as a dependecy
}

export default useArrowKeys;
