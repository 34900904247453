import type { SpacingValue, SpacingOptions } from '@common/types';

const spacingValueToTokenMap: Record<SpacingValue, string> = {
  0: '0px',
  4: 'var(--cds-spacing-50)',
  8: 'var(--cds-spacing-100)',
  12: 'var(--cds-spacing-150)',
  16: 'var(--cds-spacing-200)',
  24: 'var(--cds-spacing-300)',
  32: 'var(--cds-spacing-400)',
  48: 'var(--cds-spacing-600)',
  64: 'var(--cds-spacing-800)',
  80: 'var(--cds-spacing-1000)',
  112: 'var(--cds-spacing-1400)',
};

export const createSpacing: SpacingOptions = (val: SpacingValue) =>
  spacingValueToTokenMap[val];
export default createSpacing;
