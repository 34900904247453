import type React from 'react';

import { ThemeProvider } from '@emotion/react';

import type { Theme } from '@common/types';

type Props = {
  children?: React.ReactNode;
  theme: Theme;
};

const EmotionThemeProvider = ({ theme, children }: Props) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default EmotionThemeProvider;
