import { css } from '@emotion/react';

import { Colors, typography } from '@coursera/cds-core';

import { MAIN_MENU_WIDTH } from 'bundles/megamenu/constants/constants';

export const styles = {
  megaMenuMain: () => css`
    z-index: 2;
    font-size: 14px;
    margin: 0;
    position: relative;
    min-width: ${MAIN_MENU_WIDTH}px;
    padding: 0;
    padding-bottom: 128px;
    max-height: calc(100vh - 85px); /* 65px is header height + 20px padding */
    overflow: auto;
  `,
  ulWrapper: () => css`
    width: 100%;
    list-style-type: none;
    padding: 0;
  `,
  menuItem: () => css`
    background: none;
    border: none;
    display: block;
    width: ${MAIN_MENU_WIDTH}px;
    color: #1f1f1f;
    padding: 5px 24px;
    text-align: left; /* for ie */
    position: relative;
    cursor: default;
    text-decoration: none;

    :hover {
      text-decoration: none;
      text-align: left; /* for ie */
    }

    :focus {
      text-decoration: none;
      text-align: left; /* for ie */
    }
  `,
  menuItemWrapperHidden: () => css`
    min-height: unset;
    height: 0;
    padding: 0;
    margin: 0;
  `,
  menuItemSelected: () => css`
    background-color: white;
    box-shadow: 0 2px 2px rgb(0 0 0 / 10%);
    color: ${Colors.blue[600]};
  `,
  sectionTitle: () => css`
    ${typography.h2semibold}
    padding: 5px 24px;
    padding-top: 24px;
    margin-bottom: 10px;
  `,
  exploreAllWrapper: () => css`
    width: ${MAIN_MENU_WIDTH}px;
    padding: 0 24px;
    margin-top: 32px;
    margin-bottom: 50px;
    list-style-type: none;
  `,
  exploreAllButton: () => css`
    background: transparent !important;

    :hover {
      background: ${Colors.blue[600]} !important;
      color: ${Colors.white} !important;
    }

    :focus {
      background: ${Colors.blue[600]} !important;
      color: ${Colors.white} !important;
    }
  `,
  menuList: () => css`
    margin: 0;
    padding: 0;
  `,
  listItem: () => css`
    list-style: none;
  `,
};

export default styles;
