import * as React from 'react';
import type { ErrorInfo } from 'react';

import * as Sentry from '@sentry/react';

import Error500 from 'bundles/page/components/Error500';

type State = {
  hasError: boolean;
};

type Props = {
  ErrorComponent: React.ComponentType;
};

class ErrorBoundaryWithLogging extends React.Component<Props, State> {
  state = {
    hasError: false,
  };

  static defaultProps = {
    // Copied from https://github.com/webedx-spark/infra-services/blob/049c1bee225804c19dbf3993ff3b17b00c705c05/playcour/playcour/src/main/scala/org/coursera/playcour/util/ErrorMessageHelper.scala#L9-L32
    // Keeping this simple (in-line styles, no translation) to avoid potential errors in the error path (which would defeat the purpose).
    ErrorComponent: Error500,
  };

  // for React 16.6+
  static getDerivedStateFromError() {
    return {
      hasError: true,
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    Sentry.captureException(error, {
      extra: { errorInfo },
      tags: { source: 'ErrorBoundaryWithLogging' },
    });
  }

  render() {
    const { children, ErrorComponent } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return <ErrorComponent />;
    }

    return children;
  }
}

export default ErrorBoundaryWithLogging;
