import type React from 'react';
import { useState, useEffect } from 'react';

export type Props = {
  children: React.ReactElement;
  waitBeforeShowInMilliSeconds?: number;
};

/**
 * Use the Delayed component to render contents after a set amount of milliseconds.
 * Use-case example:
 * When using `aria-live`, announcements are only made when the content within the region changes.
 * Consequently, content present when the region is added to DOM may not be announced.
 * To ensure proper annoucement, you should first render the region and then add the desired content.
 */
const Delayed = (props: Props) => {
  const { children, waitBeforeShowInMilliSeconds = 100 } = props;
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(true);
    }, waitBeforeShowInMilliSeconds);
    return () => clearTimeout(timer);
  }, [waitBeforeShowInMilliSeconds]);

  return show ? children : null;
};

export default Delayed;
