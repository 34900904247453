export const CONTENTFUL_BASE_URL = 'https://graphql.contentful.com/content/v1/spaces/';

export const AVAILABLE_SPACES = {
  growth: 'GROWTH',
  enterprise: 'ENTERPRISE',
  marketing: 'MARKETING',
};

export const AVAILABLE_SPACE_SLUGS = Object.values(AVAILABLE_SPACES);

export type SpaceConfig = {
  SPACE_ID: string;
  SPACE_NAME: string;
  DELIVERY_ACCESS_TOKEN: string;
  PREVIEW_ACCESS_TOKEN: string;
};

export const CONTENTFUL_SPACE_CONFIG: Record<string, SpaceConfig> = {
  PILOT: {
    SPACE_ID: 'gwyaud6pg98q',
    SPACE_NAME: 'Pilot',
    DELIVERY_ACCESS_TOKEN: 'ad708450802bef259f47619e93630d2ae35ea68d93271cadfaeb4af5473ff4ed',
    PREVIEW_ACCESS_TOKEN: 'fc50706390666d97bcdbfa31a14eca76a8fec86970232cfdbb4418cb731b012a',
  },
  GROWTH: {
    SPACE_ID: 'wp1lcwdav1p1',
    SPACE_NAME: 'Growth',
    DELIVERY_ACCESS_TOKEN: '50d6818b7e5fa21880fa56d502309520c5c2287007c3aac773783c9581c99bdc',
    PREVIEW_ACCESS_TOKEN: '00330f0f3bf5496e88ff658258316532e60af32db9b60f3063f5f2a6c1129ba7',
  },
  ENTERPRISE: {
    SPACE_ID: '2pudprfttvy6',
    SPACE_NAME: 'Enterprise',
    DELIVERY_ACCESS_TOKEN: 'p9p1X6U0NXjqneV0r5vej-DUSzxmD2Y77Nn0YGNpFqI',
    PREVIEW_ACCESS_TOKEN: 'uoajc1fRV-cK6QUQXrlN17-dyXWr6iw5Dfz673jnmps',
  },
  MARKETING: {
    SPACE_ID: '00atxywtfxvd',
    SPACE_NAME: 'Marketing',
    DELIVERY_ACCESS_TOKEN: 'K50YJaxTSVSLS1b4Ces8ikhu9eWbISWVslwi4OSMdxo',
    PREVIEW_ACCESS_TOKEN: 'WetSovmgucQ7T9hQWQx44I5XRfViAA9wLM9dsslDNVI',
  },
};

export const getClientName = (slug: string, isPreview?: boolean) => {
  let name = CONTENTFUL_SPACE_CONFIG[slug].SPACE_NAME;
  if (isPreview) name += 'Preview';
  return `contentful${name}Gql`;
};

export default { CONTENTFUL_BASE_URL, AVAILABLE_SPACE_SLUGS, CONTENTFUL_SPACE_CONFIG, getClientName };
