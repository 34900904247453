/* eslint-disable camelcase */
import type { PossibleTypesMap } from '@apollo/client';

import possibleTypesAssembler from './__generated__/possible-types.assembler.json';
import possibleTypesContentful from './__generated__/possible-types.contentful.json';
import possibleTypesGateway from './__generated__/possible-types.gateway.json';

// All naptime-link unions MUST be explicitly configured here. The __typename values are constructed in here [1] and
// follow the format:
//
//   Naptime[ResourceNameV#]_path_includingUnionTypeNames_to_field_unionTypeName
//
// They are very verbose and must match EXACTLY for it to work, otherwise you will get gibberish/nothing back form
// Apollo. Avoid using fragments and unions with naptime-link. Avoid using naptime-link.
//
// [1]: static/bundles/page/lib/network/apollo-link-naptime/transformRestToNaptimeResponse.js
const possibleTypesNaptimeLink = {
  possibleTypes: {
    NaptimeOnDemandAssignmentPresentationsV1_phases: [
      'NaptimeOnDemandAssignmentPresentationsV1_phases_singleFormPhase',
      'NaptimeOnDemandAssignmentPresentationsV1_phases_reviewPhase',
    ],
    NaptimeOnDemandAssignmentPresentationsV1_Task_validationErrors_error_reason: [
      'NaptimeOnDemandAssignmentPresentationsV1_Task_validationErrors_error_reason_invalidCml',
      'NaptimeOnDemandAssignmentPresentationsV1_Task_validationErrors_error_reason_tooLong',
      'NaptimeOnDemandAssignmentPresentationsV1_Task_validationErrors_error_reason_generic',
      'NaptimeOnDemandAssignmentPresentationsV1_Task_validationErrors_error_reason_invalidUrl',
      'NaptimeOnDemandAssignmentPresentationsV1_Task_validationErrors_error_reason_empty',
      'NaptimeOnDemandAssignmentPresentationsV1_Task_validationErrors_error_reason_missingReviewPart',
    ],
    NaptimeDegreesV1_headerBackgroundColor: ['NaptimeDegreesV1_headerBackgroundColor_linearGradient2Member'],
    NaptimeProgramMembershipsV2_externalUserData: [
      'NaptimeProgramMembershipsV2_externalUserData_genericExternalUserData',
    ],
  },
};

export const possibleTypes: PossibleTypesMap = {
  ...possibleTypesNaptimeLink.possibleTypes,
  ...possibleTypesContentful.possibleTypes,
  ...possibleTypesAssembler.possibleTypes,
  ...possibleTypesGateway.possibleTypes,
};
