/**
 * This component works similarly to HTML <img> but runs the image through
 * [imgix](http://www.imgix.com/) to dynamically optimize for the requesting device.
 *
 * Note that even in dev it will only be able to serve images that have been landed
 * in prod. As such, if you are developing, you may want to make one revision to land
 * the assets and then begin work making the UI. With the assets in prod, the images
 * will resolve.
 *
 * Note also that once you land an image asset in prod, it can never change, due to our
 * aggressive caching. If you need to change an asset, you'll have to give it a new name.
 * Therefore if you need to iterate on the images with your code, serve them locally first
 * using a regular <img> tag. Save the use of <Imgix> and prod pushing until the image
 * assets have been finalized.
 *
 * If you have ideas for how to improve this process, please suggest in #frontend-dev.
 */
import * as React from 'react';

import imgix from 'js/lib/imgix';
import type { ImgParams } from 'js/lib/imgix';

export type Props = {
  src: string; // resolved against prodAssetsRoot
  alt: string; // for accessibility
  role?: string; // for accessibility
  maxWidth?: number | string;
  maxHeight?: number | string;
  width?: number | string;
  height?: number | string;
  imgParams?: ImgParams; // REF http://www.imgix.com/docs/reference
  className?: string;
  onLoad?: () => void;
  style?: React.CSSProperties;
  imgRef?: () => void;
  degradeForSsr?: boolean; // directive for SSR to render an image with a lower quality placeholder
  loading?: React.ImgHTMLAttributes<HTMLImageElement>['loading'];
  fetchpriority?: React.ImgHTMLAttributes<HTMLImageElement>['fetchpriority'];
};

type State = {
  componentDidMount: boolean;
};

class Imgix extends React.PureComponent<Props, State> {
  static DECORATIVE = '';

  state = {
    componentDidMount: false,
  };

  componentDidMount() {
    this.setState(() => ({ componentDidMount: true }));
  }

  render() {
    const {
      src,
      className,
      maxWidth,
      maxHeight,
      style,
      alt,
      onLoad,
      role,
      imgParams,
      degradeForSsr,
      imgRef,
      width,
      height,
      loading,
      fetchpriority,
    } = this.props;
    const { componentDidMount } = this.state;

    const srcs = imgix.getOptimizedSrcs(src, maxWidth, maxHeight, imgParams);
    // The dpr=1 value is omitted because it is implied by the `src` attribute.
    // The dpr=3 case is rare, but because the URL is so similar it costs little
    // over a gzipped connection.
    const srcSet = srcs.dpr2 && srcs.dpr3 && `${srcs.dpr2} 2x${imgParams?.ignoreDpr3SrcSet ? '' : `, ${srcs.dpr3} 3x`}`;

    if (degradeForSsr && !componentDidMount) {
      return (
        <img
          src={srcs.degraded}
          className={className}
          style={{ maxWidth, maxHeight, ...style }}
          width={width}
          height={height}
          alt={alt}
          onLoad={onLoad}
          ref={imgRef}
          role={role}
          loading={loading}
        />
      );
    }

    return (
      <img
        src={srcs.dpr1}
        srcSet={srcSet}
        className={className}
        style={{ maxWidth, maxHeight, ...style }}
        width={width}
        height={height}
        alt={alt}
        onLoad={onLoad}
        ref={imgRef}
        role={role}
        loading={loading}
        fetchpriority={fetchpriority}
      />
    );
  }
}

/**
 * Used by caller to set an alt attribute to blank, and make explicit that the alt
 * attribute is not applicable for this <img> tag.
 *
 * From http://en.wikipedia.org/wiki/Alt_attribute :
 *
 * The W3C recommends that images that convey no information, but are purely
 * decorative, be specified in CSS rather than in the HTML markup.
 * However, it may sometimes be necessary to include a decorative image as an
 * HTML img tag. In this case, if the image truly does not add to the content,
 * then a blank alt attribute should be included in the form of alt="".
 * This makes the page navigable for users of screen readers or non-graphical
 * browsers. If (in breach of the standard) no alt attribute has been
 * supplied, then browsers that cannot display the image will still display
 * something there, e.g. the URL of the image, or a fixed text string.
 */
Imgix.DECORATIVE = '';

export default Imgix;
