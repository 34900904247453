import type { BaseComponentProps } from '@coursera/cds-common';

import { classes } from '@core/cards/common/getCommonCardCss';

type Props = {
  children: React.ReactNode;
} & BaseComponentProps<'div'>;

/**
 * To be used for cards that have a `<CardTitle>`. Defines the clickable area for a card, any clicks inside this area
 * will be treated the same as a click on the link of `<CardTitle>`.
 *
 * If there are custom elements inside this card area that need to be interactive,
 * they can be "raised" up by wrapping in a `<CardInteractiveArea>`.
 * See [eng notes](__storybookUrl__/components-data-display-product-card--default#eng-notes) for more info.
 */
const CardClickArea = ({ children, ...rest }: Props) => {
  return (
    <div className={classes.clickArea} {...rest}>
      {children}
    </div>
  );
};

export default CardClickArea;
