import { classes } from '@core/cards/ProductCard/getProductCardCss';
import type { ProductCardImage } from '@core/cards/ProductCard/types';

type Props = ProductCardImage;

/**
 * Renders the preview for the list variant
 */
const CardPreviewList = (props: Props) => {
  const { imageSrc, renderImage } = props;

  return (
    <div className={classes.listPreviewImage}>
      {renderImage?.()}
      {!renderImage && <img aria-hidden="true" src={imageSrc} />}
    </div>
  );
};
export default CardPreviewList;
