import type Events from '@coursera/event-pulse/core/events';
import type Session from '@coursera/event-pulse/core/session';

type onEvents = {
  beforeSend?: <K extends keyof Events>(eventName: K, data: Events[K]) => void;
  sent?: <K extends keyof Events>(eventName: K, data: Events[K]) => void;
  sessionStart?: (session: Session) => void;
};

// unknown gives some errors in the extending classes
// eslint-disable-next-line @typescript-eslint/no-explicit-any
class Emitter<S extends Record<string, (...args: any[]) => void>> {
  private callbacks: { [key in keyof S]?: S[key][] } = {};

  clear() {
    this.callbacks = {};
  }

  on<K extends keyof S, C extends S[K]>(eventName: K, callback: C) {
    if (!this.callbacks[eventName]) {
      this.callbacks[eventName] = [];
    }

    this.callbacks[eventName]?.push(callback);
  }

  off<K extends keyof S, C extends S[K]>(eventName: K, callback: C) {
    if (!this.callbacks[eventName]) {
      return;
    }

    this.callbacks[eventName] = this.callbacks[eventName]?.filter((cb) => cb !== callback);
  }

  trigger<K extends keyof S>(eventName: K, ...args: Parameters<S[K]>) {
    this.callbacks[eventName]?.forEach((callback) => callback(...args));
  }
}

export type { onEvents };
export default Emitter;
