import { useQuery } from '@apollo/client';

import DiscoveryCollectionsQuery from 'bundles/browse/components/DiscoveryCollectionsQuery.graphql';
import type {
  DiscoveryCollectionsQuery as Query,
  DiscoveryCollectionsQueryVariables as Variables,
} from 'bundles/browse/components/__generated__/DiscoveryCollectionsQuery';
import type { DiscoveryCollection } from 'bundles/browse/components/types/DiscoveryCollections';

export type DiscoveryProductCollectionsData = {
  loadingCollections: boolean;
  collections: Array<DiscoveryCollection | null>;
};

const useDiscoveryCollectionsData = ({
  contextId,
  contextType,
  skip = false,
}: {
  contextId: string;
  contextType: string;
  skip?: boolean;
}): DiscoveryProductCollectionsData => {
  const { loading, data } = useQuery<Query, Variables>(DiscoveryCollectionsQuery, {
    variables: {
      contextId,
      contextType,
    },
    notifyOnNetworkStatusChange: true,
    ssr: false,
    skip,
    errorPolicy: 'all' as const,
    context: { clientName: 'gatewayGql' },
  });

  const collections = data?.DiscoveryCollections?.queryCollections || [];

  return {
    loadingCollections: loading,
    collections,
  };
};

export default useDiscoveryCollectionsData;
