import { useCallback } from 'react';

type Options = {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  isDisabled?: boolean;
};

/**
 * Creates event handler for button click event.
 * Note: onClick event for button is also fired when "Enter" or "Space" key is pressed
 * @param options
 */
const useButtonPress = (options: Options) => {
  const { onClick, isDisabled } = options;

  return useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      // React throws a warning without this.
      // https://reactjs.org/docs/legacy-event-pooling.html
      event.persist();
      if (isDisabled) {
        event.preventDefault();
        event.stopPropagation();
      } else {
        onClick?.(event);
      }
    },
    [onClick, isDisabled]
  );
};

export default useButtonPress;
