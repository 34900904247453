import { css } from '@emotion/react';

import generateEmotionClasses from '@core/utils/generateEmotionClasses';

export const classes = generateEmotionClasses('AccordionRoot', [
  'focusContainer',
  'container',
  'standard',
  'silent',
  'focus',
  'hover',
]);

export const getAccordionRootCss = () => {
  return css`
    margin: 0;
    padding: 0;

    .${classes.focusContainer} {
      position: relative;
      margin: 0;
      padding: 2px;
    }

    .${classes.container} {
      background-color: var(--cds-color-neutral-background-primary);
      border-radius: var(--cds-border-radius-50);
    }

    .${classes.standard} {
      border: 1px solid var(--cds-color-neutral-stroke-primary);
    }

    .${classes.silent} {
      border: 1px solid transparent;
    }

    .${classes.hover} {
      border: 1px solid var(--cds-color-interactive-primary-hover);
    }

    .${classes.focus} {
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: var(--cds-border-radius-50);
        box-shadow: 0 0 0 1px var(--cds-color-interactive-stroke-primary-focus),
          0 0 0 2px var(--cds-color-interactive-background-primary-hover-weak);
        outline: 1px solid transparent;
      }
    }
  `;
};
