import { classes } from '@core/cards/common/getCommonCardCss';

export type CardPreviewProps = {
  /**
   * Preview image url
   */
  src?: string;
  /**
   * Custom renderer for when the preview image has a custom need
   * such as lazy loading, or tracking.
   *
   * Accessibility: `aria-hidden=true` or an empty `alt` must be added for
   * custom images to hide decorative images from screenreaders
   * (https://www.w3.org/WAI/tutorials/images/decorative/)
   *
   * @example renderImage={() => <LazyImg src={src} loading={isLoading} alt="" />}
   */
  renderImage?: () => React.ReactNode;
};

/**
 * Common component to render a preview image for a card.
 *
 * The preview is meant purely for decorative purposes so when using `renderImage`
 * for custom images, make sure to set an empty `alt` or `aria-hidden="true"` on
 * the underlying img tag to keep things a11y-friendly (https://www.w3.org/WAI/tutorials/images/decorative/)
 */
const CardPreview = (props: CardPreviewProps) => {
  const { src, renderImage } = props;
  const hasImage = !!src || !!renderImage;

  if (!hasImage) {
    return null;
  }

  return (
    <div className={classes.previewImage}>
      {renderImage?.()}
      {!renderImage && <img aria-hidden="true" src={src} />}
    </div>
  );
};

export default CardPreview;
