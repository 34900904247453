import { css } from '@emotion/react';

import generateEmotionClasses from '@core/utils/generateEmotionClasses';

export const classes = generateEmotionClasses('Skeleton', [
  'root',
  'withChildren',
  'fitContent',
  'heightAuto',
  'circle',
  'rect',
  'text',
]);

const getSkeletonCss = css`
  &.${classes.root} {
    display: block;
    background-color: var(--cds-color-neutral-disabled);
    height: 1.2em;
  }

  &.${classes.withChildren} > * {
    visibility: hidden;
  }

  &.${classes.fitContent} {
    max-width: fit-content;
  }

  &.${classes.heightAuto} {
    height: auto;
  }

  &.${classes.text} {
    margin-top: 0;
    margin-bottom: 0;
    height: auto;
    transform-origin: 0 60%;
    transform: scale(1, 0.6);
    border-radius: var(--cds-border-radius-50);

    &:empty::before {
      content: '\\00a0';
    }
  }

  &.${classes.circle} {
    border-radius: 50%;
  }
`;

export default getSkeletonCss;
