import { createContext } from 'react';

import type { ActionContext } from 'js/lib/ActionContext';

export const FluxibleContext = createContext<ActionContext>({
  executeAction: () => {
    throw new Error(
      'Fluxible context was not initialized! If you see this error in a unit test, ensure you are mocking out the useFluxibleAction() hook.'
    );
  },
  getStore: () => {
    throw new Error(
      'Fluxible context was not initialized! If you see this error in a unit test, ensure you are mocking out the useFluxibleStore() hook.'
    );
  },
  dispatch: () => {
    throw new Error('Fluxible context was not initialized!');
  },
});
