import type { InjectedRouter } from 'js/lib/connectToRouter';
import { getLanguageCode } from 'js/lib/language';

export const switchAuthModalTo = (authMode: string, router: $TSFixMe) => {
  if (!authMode) {
    return;
  }
  const { location: { query = {}, pathname = '' } = {}, params = {} } = router;
  router.push({
    pathname,
    params,
    query: { ...query, authMode },
  });
};

export const getRegionSlug = (pathname?: string): string => {
  const fallbackSlug = 'us';
  if (!pathname) {
    return fallbackSlug;
  }

  const potentialRegionSlug = pathname.substring(1);
  return potentialRegionSlug.length === 0 || potentialRegionSlug === 'frontpage' ? fallbackSlug : potentialRegionSlug;
};

export const getInternationalizationVariables = (pathname?: string): { countryCode: string; languageCode: string } => {
  const potentialVariables = pathname?.substring(1);

  if (!potentialVariables || potentialVariables?.length === 0 || potentialVariables === 'frontpage') {
    return {
      countryCode: 'us',
      languageCode: 'global',
    };
  }

  if (potentialVariables.includes('-')) {
    const [languageCode, countryCode] = potentialVariables.split('-');
    return {
      countryCode,
      languageCode,
    };
  }

  return {
    countryCode: potentialVariables,
    languageCode: getLanguageCode(),
  };
};

export const handleConsumerCTAClick = (router: InjectedRouter, authMode: string) => {
  router.replace({
    pathname: router?.location?.pathname || '/',
    query: { ...(router?.location?.query || {}), authMode },
  });
};
