import { css } from '@emotion/react';

import generateEmotionClasses from '@core/utils/generateEmotionClasses';

export const classes = generateEmotionClasses('buttonBase', ['focusVisible']);

const getButtonBaseCss = css`
  border-radius: var(--cds-border-radius-50);

  &:focus {
    outline: none;
  }

  &.${classes.focusVisible} {
    /* windows high contrast mode */
    outline: 1px solid transparent;
    outline-offset: 2px;

    &::after {
      content: '';
      position: absolute;
      top: -2px;
      left: -2px;
      right: -2px;
      bottom: -2px;
      border-radius: var(--cds-border-radius-50);
      box-shadow: 0 0 0 1px var(--cds-color-interactive-stroke-primary-focus),
        0 0 0 2px var(--cds-color-interactive-stroke-primary-focus-invert);
    }
  }

  @media (forced-colors: active) {
    /* Use a border instead, since box-shadow is forced to 'none' in forced-colors mode, ButtonText is just system color */
    border: 1px ButtonText solid;
  }
`;

export default getButtonBaseCss;
