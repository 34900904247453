import { RestLink } from 'apollo-link-rest';
import camelCase from 'lodash/camelCase';

// Add camelCase fieldNameNormalizer by default. This is needed for fields like `courses.v1`
// because GraphQL does not allow dots in field names.
class NaptimeRestLink extends RestLink {
  constructor(options) {
    super({
      ...options,
      customFetch: options.customFetch || fetch,
      fieldNameNormalizer: options.fieldNameNormalizer || ((key) => camelCase(key)),
    });
  }
}

export default NaptimeRestLink;
