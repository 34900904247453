/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import user from 'js/lib/user';

import { Link, Provider, Typography, Typography2, breakpoints } from '@coursera/cds-core';
import { ClockOutlineIcon, HelpIcon, ReloadIcon } from '@coursera/cds-icons';

import Logotype from 'bundles/page/components/CourseraLogo';

import _t from 'i18n!nls/page';

import Icon from './Icon';

const styles = {
  content: css`
    background: var(--cds-color-grey-25);
    display: grid;
    flex-direction: column;
    gap: var(--cds-spacing-100) var(--cds-spacing-800);
    height: 100vh;
    justify-content: center;
    left: 0;
    padding: var(--cds-spacing-400) var(--cds-spacing-400);
    position: absolute;
    top: 0;
    width: 100vw;

    ${breakpoints.up('lg')} {
      grid-template: repeat(2, auto) / repeat(2, auto);
      align-items: center;
    }
  `,

  header: css`
    align-self: end;
  `,

  help: css`
    align-self: start;
    margin-top: var(--cds-spacing-400);
  `,

  image: css`
    display: none;
    grid-row: span 2;

    ${breakpoints.up('lg')} {
      display: block;
    }
  `,

  list: css`
    list-style: none;
    margin: var(--cds-spacing-150) var(--cds-spacing-150) 0 0;
    padding-left: var(--cds-spacing-200);

    li {
      align-items: center;
      display: flex;
      gap: var(--cds-spacing-100);
      margin-top: var(--cds-spacing-100);
    }
  `,

  logotype: css`
    height: var(--cds-spacing-200);
  `,

  nav: css`
    align-items: center;
    background: var(--cds-color-white-0);
    border-bottom: 1px solid var(--cds-color-neutral-stroke-primary-weak);
    display: flex;
    height: var(--cds-spacing-800);
    justify-content: center;
    position: relative;
    z-index: 1;
  `,

  title: css`
    align-items: center;
    display: flex;
  `,
};

const Error500 = () => (
  <Provider locale={user.get()?.locale ?? 'en'}>
    <nav css={styles.nav}>
      <Link href="/">
        <Logotype css={styles.logotype} />
      </Link>
    </nav>
    <main css={styles.content}>
      <header css={styles.header}>
        <Typography css={styles.title} variant="d2">
          {_t('Oops! Something went wrong.')}
        </Typography>
      </header>

      <picture css={styles.image}>
        <Icon />
      </picture>

      <section css={styles.help}>
        <Typography2 component="p">{_t('This page encountered an error. If the problem persists:')}</Typography2>
        <Typography2 component="p" variant="bodySecondary">
          <ul css={styles.list}>
            <li>
              <ReloadIcon size="small" />
              {_t('Refresh the page.')}
            </li>
            <li>
              <ClockOutlineIcon size="small" />
              {_t('Try again later.')}
            </li>
            <li>
              <HelpIcon size="small" />
              <Link href="https://www.coursera.support/">{_t('Contact Coursera Support.')}</Link>
            </li>
          </ul>
        </Typography2>
      </section>
    </main>
  </Provider>
);

export default Error500;
