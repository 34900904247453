import Q from 'q';

import constants from 'bundles/event-tracking/constants';

function AdsTracker() {
  // @ts-expect-error ts-migrate(2683) FIXME: 'this' implicitly has type 'any' because it does n... Remove this comment to see the full error message
  this.logger = console;
}

AdsTracker.prototype.send = function (content: $TSFixMe) {
  return Q(content);
};

AdsTracker.prototype.prepareParams = function (params: $TSFixMe) {
  return params;
};

AdsTracker.prototype.track = function (...args: $TSFixMe[]) {
  const params = this.prepareParams(...args);
  if (constants.config.environment !== 'development') {
    // give the tracking call a max timeout
    return Q.timeout(this.send(params), constants.timeout);
  } else {
    return Q(params);
  }
};

AdsTracker.prototype.throwError = function (err: $TSFixMe) {
  if (constants.config.environment !== 'development') {
    this.logger.error(err);
  } else {
    throw new Error(err);
  }
};

/**
 * Track Signup Event
 * @param  {String} userId user id
 * @return {Promise}       Promise of the result of the tracking call
 */
AdsTracker.prototype.trackSignup = function (userId: $TSFixMe) {
  return Q();
};

/**
 * Track Enroll Event
 * @param  {String} userId user id
 * @return {Promise}       Promise of the result of the tracking call
 */
AdsTracker.prototype.trackEnroll = function (userId: $TSFixMe) {
  return Q();
};

/**
 * Track Degree EOI Completion
 * @param  {String} degreeSlug degree slug
 * @return {Promise}       Promise of the result of the tracking call
 */
AdsTracker.prototype.trackDegreeEOICompletion = function (degreeSlug: $TSFixMe) {
  return Q();
};

/**
 * Track Degree Apply Now
 * @param  {String} degreeSlug degree slug
 * @return {Promise}       Promise of the result of the tracking call
 */
AdsTracker.prototype.trackDegreeApplyNow = function (
  degreeSlug: string,
  productId: string,
  productName: string,
  productCategory: string
) {
  return Q();
};

/**
 * Track Schedule A Call clicks
 * @param  {String} degreeSlug degree slug
 * @return {Promise}       Promise of the result of the tracking call
 */
AdsTracker.prototype.trackScheduleACall = function (degreeSlug: $TSFixMe) {
  return Q();
};

/**
 * Track User Action Event
 * @param  {String} action type of action
 * @param  {String} userId user id
 * @return {Promise}       Promise of the result of the tracking call
 */
AdsTracker.prototype.trackAction = function (action: $TSFixMe, userId: $TSFixMe) {
  return Q();
};

/**
 * Track Specialization Bulk Payment
 * @param  {String} userId       user id
 * @param  {Object} paymentsInfo  Payment info containing the following fields:
 *                               `id` - unique id that represents the product
 *                               `value` - value paid in cents
 * @param  {String} orderId      Order id for the transaciton
 * @return {Promise}             Promise of the result of the tracking call
 */
AdsTracker.prototype.trackS12NBulkPayment = function (userId: $TSFixMe, paymentsInfo: $TSFixMe, orderId: $TSFixMe) {
  return Q();
};

/**
 * Track Specialization Subscription Payment
 * @param  {String} userId       user id
 * @param  {Object} paymentsInfo  Payment info containing the following fields:
 *                               `id` - unique id that represents the product
 *                               `value` - value paid in cents
 * @param  {String} orderId      Order id for the transaciton
 * @return {Promise}             Promise of the result of the tracking call
 */
AdsTracker.prototype.trackS12NSubscriptionPayment = function (
  userId: $TSFixMe,
  paymentsInfo: $TSFixMe,
  orderId: $TSFixMe
) {
  return Q();
};

/**
 * Track Specialization Prepaid Payment
 * @param  {String} userId       user id
 * @param  {Object} paymentsInfo  Payment info containing the following fields:
 *                               `id` - unique id that represents the product
 *                               `value` - value paid in cents
 * @param  {String} orderId      Order id for the transaciton
 * @return {Promise}             Promise of the result of the tracking call
 */
AdsTracker.prototype.trackS12NPrepaidPayment = function (userId: $TSFixMe, paymentsInfo: $TSFixMe, orderId: $TSFixMe) {
  return Q();
};

/**
 * Track VC Payment for courses that are part of a specialization
 * @param  {String} userId       user id
 * @param  {Object} paymentsInfo  Payment info containing the following fields:
 *                               `id` - unique id that represents the product
 *                               `value` - value paid in cents
 * @param  {String} orderId      Order id for the transaciton
 * @return {Promise}             Promise of the result of the tracking call
 */
AdsTracker.prototype.trackS12NVCPayment = function (userId: $TSFixMe, paymentsInfo: $TSFixMe, orderId: $TSFixMe) {
  return Q();
};

/**
 * Track VC Payment for courses that are not part of a specialization
 * @param  {String} userId       user id
 * @param  {Object} paymentsInfo  Payment info containing the following fields:
 *                               `id` - unique id that represents the product
 *                               `value` - value paid in cents
 * @param  {String} orderId      Order id for the transaciton
 * @return {Promise}             Promise of the result of the tracking call
 */
AdsTracker.prototype.trackVCPayment = function (userId: $TSFixMe, paymentsInfo: $TSFixMe, orderId: $TSFixMe) {
  return Q();
};

/**
 * Track Enterprise contract payment
 * @param  {String} userId       user id
 * @param  {Object} paymentsInfo  Payment info containing the following fields:
 *                               `id` - unique id that represents the product
 *                               `value` - value paid in cents
 * @param  {String} orderId      Order id for the transaciton
 * @return {Promise}             Promise of the result of the tracking call
 */
AdsTracker.prototype.trackEnterpriseContractPayment = function (
  userId: $TSFixMe,
  paymentsInfo: $TSFixMe,
  orderId: $TSFixMe
) {
  return Q();
};

/**
 * Track Email Me Info Button click
 * @param  {String} slug       program slug
 * @param  {String} productId       unique id that represents the product (certificateId, mastertrackId or degreeId)
 * @param  {String} productName      product name
 * @param  {String} productCategory      product category (degree, certificates, course, guided project, specialization, etc)
 * @return {Promise}             Promise of the result of the tracking call
 */
AdsTracker.prototype.trackEmailMeInfo = function (
  slug: string,
  productId: string,
  productName: string,
  productCategory: string
) {
  return Q();
};
/**
 * Track add to cart
 * @param  {String} userId  user id
 * @param  {String} productId unique id that represents the product (courseId or s12nId)
 * @return {Promise}
 */
AdsTracker.prototype.trackAddToCart = function ({ userId, productId }: { userId: string; productId: string }) {
  return Q();
};

/**
 * Track enroll button click
 * @param  {Number} userId user id
 * @param  {String} productId product id
 * @param  {String} productCategory product category
 * @param  {String} productName product name
 * @param  {String} prodcutSlug product slug
 * @return {Promise}
 */
AdsTracker.prototype.trackEnrollButtonClick = function ({
  userId,
  productId,
  productCategory,
  productName,
  productSlug,
}: {
  userId?: number;
  productId?: string;
  productCategory?: string;
  productName?: string;
  productSlug?: string;
}) {
  return Q();
};

/**
 * Track s12n enroll modal button click
 * @param  {Number} userId user id
 * @param  {String} productId product id
 * @return {Promise}
 */
AdsTracker.prototype.trackS12nEnrollModalButtonClick = function ({
  userId,
  productId,
}: {
  userId?: number;
  productId?: string;
}) {
  return Q();
};

export default AdsTracker;
