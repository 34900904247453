import React from 'react';

import type { BaseComponentProps } from '@coursera/cds-common';

import Typography from '@core/Typography2';
import type { TypographyProps } from '@core/Typography2';

import { getBaseHeadingGroupCss } from './baseHeadingGroupCss';

type BaseProps = BaseComponentProps<'div'>;

export type Props = BaseProps & {
  /**
   * The text to display as the dialog heading text
   */
  heading: React.ReactText;

  /**
   * The text to display as the dialog heading support text
   */
  supportText?: React.ReactText;

  /**
   * The color for the heading and support text.
   */
  color?: TypographyProps['color'];
};

const BaseHeadingGroup = React.forwardRef(function BaseHeadingGroup(
  props: Props,
  ref: React.Ref<HTMLDivElement>
) {
  const { heading, supportText, color = 'body', ...rest } = props;

  return (
    <div ref={ref} css={getBaseHeadingGroupCss()} {...rest}>
      <Typography color={color} component="h2" variant="titleSmall">
        {heading}
      </Typography>

      {supportText && (
        <Typography color={color} component="p">
          {supportText}
        </Typography>
      )}
    </div>
  );
});

export default BaseHeadingGroup;
