import { memoize } from 'lodash';

import type { Options as SessionOptions } from './session';
import Session, { DEFAULT_TTL } from './session';
import { createDefaultStore } from './store';
import type { Store } from './store';

type Config = Pick<SessionOptions, 'ttl'>;

type OwnOptions = {
  store: Store;
};

type Options = Config & OwnOptions;

/**
 * Class to manage session instances and provide session-related functionalities.
 *
 * This class is responsible for creating, resetting, and updating session instances.
 * It also handles the persistence of session data using a provided store.
 */
class SessionProvider {
  /**
   * Configuration options for the session provider.
   * @private
   */
  private declare readonly config: Config;

  /**
   * The current session instance.
   * @private
   */
  private declare instance: Session;

  /**
   * The store used to persist session data.
   * @private
   */
  private declare store: Store;

  /**
   * Constructor for the SessionProvider class.
   *
   * @param options - An object containing configuration options and a store for session data.
   */
  constructor(options: Options) {
    this.config = { ttl: options.ttl ?? DEFAULT_TTL };
    this.store = options.store;

    // Restore the previous session if any
    const previous = this.store.get<Partial<Session>>('session');

    this.instance = new Session({
      lastActivity: -(options.ttl ?? DEFAULT_TTL),
      ttl: options.ttl,
      ...previous,
    });
  }

  /**
   * Get the current session instance.
   *
   * @returns The current session instance.
   */
  get(): Session {
    return this.instance;
  }

  /**
   * Reset the current session instance.
   *
   * This method creates a new session instance while preserving the referrer and current URL.
   */
  reset(): void {
    this.instance = new Session({
      history: [this.instance.referrer, this.instance.url],
      ttl: this.config.ttl,
    });
  }

  /**
   * Update the session with the current URL and last interaction timestamp.
   *
   * This method updates the session's navigation history and last activity timestamp,
   * and then persists the session data to the store.
   */
  touch(): void {
    // Update the history of the navigation
    this.instance.navigate(window.location.href);

    // Update the last interaction
    this.instance.touch();

    // Record the state of the session
    this.store.set('session', this.instance.toJSON());
  }
}

/**
 * Create a default session provider instance.
 *
 * This function memoizes the creation of a default session provider instance
 * using a default store.
 *
 * @returns A memoized default session provider instance.
 */
const createDefaultSessionProvider = memoize(() => new SessionProvider({ store: createDefaultStore() }));

export { createDefaultSessionProvider };
export default SessionProvider;
