import { CERTIFICATE_SECTION_TYPE, DUAL_COLUMN_CONTENT_TYPE } from 'bundles/megamenu/constants/constants';

import _t from 'i18n!nls/megamenu';

export const getMockedMoreCertificateData = () => ({
  sectionName: _t('More Certificate Programs'),
  sectionType: CERTIFICATE_SECTION_TYPE,
  contentType: DUAL_COLUMN_CONTENT_TYPE,
  sectionItems: [
    {
      url: `/certificates/launch-your-career`,
      name: _t('Launch your career'),
    },
    {
      url: `/certificates/advance-your-career`,
      name: _t('Advance your career'),
    },
    {
      url: `/certificates/prepare-for-a-certification`,
      name: _t('Prepare for a certification'),
    },
  ],
});

export const certificateViewAllLinkByDomain: { [key: string]: string } = {
  'computer-science': `/certificates/computer-science-it`,
  'information-technology': `/certificates/computer-science-it`,
  'data-science': `/certificates/data-science`,
  business: `/certificates/business`,
};
