/*
  Note that animation has been disabled for skeletons for the following reasons:
    1. Non-animated skeletons are more inclusive to motion-sensitive users
        who prefer reduced motion.
    2. The skeleton itself already provides system loading feedback to the user,
        animation is not additive to the overall experience.

  If neeeded in the future, we can easily add in the wave animation from the
  figma spec: https://www.figma.com/file/rq1qg3g5dv3Sk9ETCLDS6b/%F0%9F%9A%A7-Skeleton?node-id=2517%3A4730
*/

import React from 'react';

import clsx from 'clsx';

import getSkeletonCss, { classes } from './getSkeletonCss';

export type Props = {
  /**
   * The type of content that will be rendered.
   * @default text
   */
  variant?: 'text' | 'rect' | 'circle';

  /**
   * Optional children to infer width and height from.
   */
  children?: React.ReactNode;

  /**
   * Height of the skeleton.
   * Useful when you don't want to adapt the skeleton to a text element but for instance a card.
   */
  height?: number | string;

  /**
   * Width of the skeleton.
   * Useful when the skeleton is inside an inline element with no width of its own.
   */
  width?: number | string;

  /**
   * The component used for the root node.
   * Either a string to use a HTML element or a component.
   * @default span
   */
  component?: React.ElementType;

  /**
   * Defines additional classes that will be added to the root element.
   */
  className?: string;
};

/**
 * The skeleton is a visual indicator of system loading status. It provides a
 * low-fidelity version of the interface elements as a placeholder before the
 * fully loaded content.
 * * Use when system loading duration will take an indeterminate amount of time.
 * * Do NOT use skeleton shapes to replace action components like input fields,
 *   checkboxes, or radios to reduce confusion with placeholders for avatars and
 *   groups of content.
 *
 * See [Props](__storybookUrl__/utility-skeleton--default#props)
 *
 * @example
 * 1. <Skeleton variant="circle" />
 *
 * 2. <Typography variant="titleLarge" component="h1">
 *      {loading
 *        ? <Skeleton variant="text" />
 *        : someAsyncText
 *      }
 *    </Typography>
 */
const Skeleton = React.forwardRef<HTMLElement, Props>(function Skeleton(
  {
    component: Component = 'span',
    height,
    variant = 'text',
    width,
    children,
    className = '',
    ...rest
  },
  ref
) {
  const hasChildren = Boolean(children);

  return (
    <Component
      ref={ref}
      className={clsx(classes.root, className, classes[variant], {
        [classes.withChildren]: hasChildren,
        [classes.fitContent]: hasChildren && !width,
        [classes.heightAuto]: hasChildren && !height,
      })}
      css={getSkeletonCss}
      {...rest}
      aria-hidden={true} // see documentation for notes on a11y best practices
      style={{
        width,
        height,
      }}
    >
      {children}
    </Component>
  );
});

export default Skeleton;
