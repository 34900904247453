import * as React from 'react';
import { useContext } from 'react';

import type {
  Seo_FooterCategory as SeoFooterCategory,
  Seo_MegaMenu as SeoMegaMenu,
  Seo_MetaTags as SeoMetaTags,
} from '__generated__/graphql-types';

import type { SeoPathConfigurationQuery_EqpDataFragment as SeoEqpData } from 'bundles/seo/api/__generated__/SeoPathConfigurationQuery';
import type { GlobalFooterLinksForCategory } from 'bundles/seo/types';

type SeoPageDataContextType = {
  subfooter?: SeoFooterCategory[];
  globalFooterLinksForCategory?: GlobalFooterLinksForCategory;
  metaTags?: SeoMetaTags;
  megaMenu?: SeoMegaMenu;
  eqpData?: SeoEqpData | null;
};

const SeoPageDataContext = React.createContext<SeoPageDataContextType>({});

export const useSeoPageData = () => useContext(SeoPageDataContext);

export const SeoPageDataContextProvider: React.FC<SeoPageDataContextType> = ({
  subfooter,
  globalFooterLinksForCategory,
  metaTags,
  megaMenu,
  eqpData,
  children,
}) => {
  return (
    <SeoPageDataContext.Provider value={{ subfooter, globalFooterLinksForCategory, metaTags, megaMenu, eqpData }}>
      {children}
    </SeoPageDataContext.Provider>
  );
};
