import useLocale from '@common/i18n/useLocale';
import { getLocaleWithoutRegion } from '@common/i18n/utils';
import type {
  LocalizedString,
  LocalizedStringFormatter,
} from '@internationalized/string';
import { useLocalizedStringFormatter } from '@react-aria/i18n';

type LocalizedStrings<K extends string, T extends LocalizedString> = {
  [lang: string]: {
    [P in K]?: T;
  };
};

export default <K extends string = string, T extends LocalizedString = string>(
  strings: LocalizedStrings<K, T>
): LocalizedStringFormatter<K, T> => {
  const { locale } = useLocale();

  // if locale does not exist in the translated strings,
  // attempt to strip region and compare
  if (!strings[locale]) {
    const localeWithoutRegion = getLocaleWithoutRegion(locale);

    if (strings[localeWithoutRegion]) {
      strings[locale] = strings[localeWithoutRegion];
    } else {
      // if locale without region does not exist in the translated strings,
      // default to english
      strings[locale] = strings['en'];
    }
  }

  // @ts-expect-error the locales may have missing strings
  return useLocalizedStringFormatter(strings);
};
