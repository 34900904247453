// setup locale for Moment lib
import moment from 'moment';

import { getMomentLanguage } from 'js/lib/language';

const setupMoment = () => {
  moment.locale(getMomentLanguage());
};

export default setupMoment;
