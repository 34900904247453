import { css } from '@emotion/react';

import { breakpoints } from '@coursera/cds-common';

import type { DialogColor } from './types';

export const getContentCss = ({ color }: { color: DialogColor }) => {
  const paddingTop = color !== 'white' ? 'var(--cds-spacing-300)' : 0;

  return css`
    padding: ${paddingTop} var(--cds-spacing-600) 0 var(--cds-spacing-600);

    ${breakpoints.down('xs')} {
      padding-right: var(--cds-spacing-200);
      padding-left: var(--cds-spacing-200);
    }
  `;
};
