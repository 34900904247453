import { default as Actions } from './Actions';
import { default as Content } from './Content';
import { default as DialogRoot } from './Dialog';
import { default as HeadingGroup } from './HeadingGroup';

export type { Props as DialogProps } from './Dialog';
export type { Props as DialogHeadingGroupProps } from './HeadingGroup';
export type { Props as DialogContentProps } from './Content';
export type { Props as DialogActionsProps } from './Actions';

type DialogRootType = typeof DialogRoot;

/**
 * Wraps the building blocks of the dialog into a single namespace.
 *
 * @see Dialog
 */
export interface DialogType extends DialogRootType {
  /**
   * The dialog heading group.
   *
   * Required by `Dialog`.
   *
   * See
   * [Props](__storybookUrl__/components-surfaces-dialog--default#props) for details.
   *
   * @see Dialog
   * @see Dialog.Content
   * @see Dialog.Actions
   */
  HeadingGroup: typeof HeadingGroup;

  /**
   * Container for the the dialog content.
   *
   * Required by `Dialog`.
   *
   * See
   * [Props](__storybookUrl__/components-surfaces-dialog--default#props) for details.
   *
   * @see Dialog
   * @see Dialog.HeadingGroup
   * @see Dialog.Actions
   */
  Content: typeof Content;

  /**
   * The dialog actions container for components that interact with the dialog state.
   *
   * Required by `Dialog`.
   *
   * See
   * [Props](__storybookUrl__/components-surfaces-dialog--default#props) for details.
   *
   * @see Dialog
   * @see Dialog.HeadingGroup
   * @see Dialog.Content
   */
  Actions: typeof Actions;
}

/**
 * Dialogs create direct communication between the user and the system that appear
 * over the page. They do so by disabling the main page content until the user
 * explicitly interacts with the dialog.
 *
 * Anatomy:
 * ```
 * <Dialog>
 *   <Dialog.HeadingGroup />
 *   <Dialog.Content />
 *   <Dialog.Actions />
 * </Dialog>
 * ```
 *
 * See
 * [Props](__storybookUrl__/components-surfaces-dialog--default#props) for details.
 *
 * @see Dialog.HeadingGroup
 * @see Dialog.Content
 * @see Dialog.Actions
 */
export const Dialog: DialogType = Object.assign(DialogRoot, {
  HeadingGroup,
  Content,
  Actions,
});
