// React Apollo link that catch errors from previous links in SSR environment.
//
// REST Link fires error if it receives error from API endpoint and if this
// error is unhandled in SSR it breaks server. This link catches errors and
// pass it as an empty result to the next link to avoid this. It also prints
// error to console.error for debugging. In browser environment it does nothing.
import { ApolloLink, Observable } from '@apollo/client';
import * as Sentry from '@sentry/react';

import isSSR from './lib/isSSR';

const CatchLink = new ApolloLink((operation, forward) => {
  return new Observable((observer) => {
    const subscription = forward(operation).subscribe({
      next: observer.next.bind(observer),
      complete: observer.complete.bind(observer),
      error: (error) => {
        if (isSSR()) {
          console.error(error); // eslint-disable-line no-console
          observer.next({ data: {} });
        } else {
          Sentry.captureException(error);
          observer.error(error);
        }
      },
    });

    return () => {
      if (subscription) subscription.unsubscribe();
    };
  });
});

export default CatchLink;
