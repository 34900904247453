import type React from 'react';
import { graphql } from 'react-apollo';

import gql from 'graphql-tag';

import { apolloFetchPolicy } from 'js/utils/apolloFetchPolicy';

import type {
  getDegreeListsV3ByDomainQuery,
  getDegreeListsV3ByDomainQueryVariables,
} from 'bundles/browse/components/Domain/__generated__/getDegreeListsV3ByDomainQuery';
import { PRODUCT_TYPE_NAMES } from 'bundles/browse/constants';
import type { DegreeProductMember, Partner } from 'bundles/browse/types/degree-list';
import { buildDegreeUrlAbsolute, buildMastertrackUrlAbsolute } from 'bundles/common/utils/urlUtils';
import { lohpDegreeListsV3Query } from 'bundles/front-page/common/Queries';
import { lohpDegreeListsV3Options } from 'bundles/front-page/hoc/WithCollectionsData';

type InputProps = {};
export type ChildProps = {
  degreeList?: DegreeProductMember[];
  mastertrackList?: DegreeProductMember[];
  degreeListsV3Loading?: boolean;
};
type GraphqlVariables = getDegreeListsV3ByDomainQueryVariables;
type GraphqlData = getDegreeListsV3ByDomainQuery;
type GraphqlChildProps = ChildProps;

const buildProgramAbsoluteUrl = (slug: string, programType: string) => {
  if (programType === PRODUCT_TYPE_NAMES.DEGREE) {
    return buildDegreeUrlAbsolute(slug);
  } else {
    return buildMastertrackUrlAbsolute(slug);
  }
};

export const getDegreeProductWithLinkAndPartners = (
  degreeProduct: DegreeProductMember,
  productType: string,
  partners: Array<Partner | null>
) => {
  return {
    ...degreeProduct,
    type: productType,
    link: buildProgramAbsoluteUrl(degreeProduct.slug, productType),
    partner: partners?.find((partner) => degreeProduct?.partnerIds[0].toString() === partner?.id),
  };
};

export const getDegreeListsV3ByDomain = gql`
  query getDegreeListsV3ByDomainQuery($domains: [String!], $productTypes: [String!], $productVariants: [String!]) {
    DegreeListsV3Resource {
      getList(domains: $domains, productTypes: $productTypes, productVariants: $productVariants) {
        elements {
          id
          degreeProducts {
            ... on DegreeListsV3_catalogDegreeMember {
              catalogDegree {
                name
                shortName
                marketingName
                slug
                bannerImage
                catalogAvailabilityStatus
                partnerIds
                domainIds
                degreeCategoryIds
              }
            }
            ... on DegreeListsV3_catalogMastertrackMember {
              catalogMastertrack {
                name
                shortName
                slug
                bannerImage
                catalogAvailabilityStatus
                partnerIds
                domainIds
              }
            }
          }
          degreePartners {
            elements {
              id
              shortName
              name
              squareLogo
              primaryColor
            }
          }
          mastertrackPartners {
            elements {
              id
              shortName
              name
              squareLogo
              primaryColor
            }
          }
        }
      }
    }
  }
`;

export const productVariantsWithoutPostgradDiplomas = [
  'BachelorsDegree',
  'MastersDegree',
  'MasterTrack',
  'UniversityCertificate',
];

export const defaultVariables = {
  domains: null,
  productTypes: ['DEGREE', 'MASTERTRACK'],
  productVariants: productVariantsWithoutPostgradDiplomas,
};

export const defaultOptions = {
  ...apolloFetchPolicy({ cacheForLoggedOutOnly: false }),
  query: getDegreeListsV3ByDomain,
  variables: defaultVariables,
  errorPolicy: 'all' as const,
};

const withDegreeListsV3 =
  <P extends InputProps>(
    getVariablesFromProps: (props: P) => getDegreeListsV3ByDomainQueryVariables & { skip?: boolean }
  ) =>
  (BaseComponent: React.ComponentType<ChildProps & P>) =>
    graphql<P, GraphqlData, GraphqlVariables, GraphqlChildProps>(getDegreeListsV3ByDomain, {
      skip: (props) => getVariablesFromProps(props)?.skip ?? false,
      options: (props) => {
        const { skip, ...restProps } = getVariablesFromProps(props);
        return {
          ...defaultOptions,
          variables: {
            ...defaultVariables,
            ...restProps,
          },
        };
      },
      props: ({ data }): ChildProps => {
        const degreeListV3 = data?.DegreeListsV3Resource?.getList?.elements[0];

        const degreeProducts = degreeListV3?.degreeProducts || [];
        const degreePartners = degreeListV3?.degreePartners?.elements || [];
        const mastertrackPartners = degreeListV3?.mastertrackPartners?.elements || [];

        const degreeList: DegreeProductMember[] = [];
        const mastertrackList: DegreeProductMember[] = [];

        degreeProducts?.forEach((degreeProduct) => {
          if ('catalogDegree' in degreeProduct)
            degreeList.push(
              getDegreeProductWithLinkAndPartners(
                degreeProduct.catalogDegree,
                PRODUCT_TYPE_NAMES.DEGREE,
                degreePartners
              )
            );
          else if ('catalogMastertrack' in degreeProduct)
            mastertrackList.push(
              getDegreeProductWithLinkAndPartners(
                degreeProduct.catalogMastertrack,
                PRODUCT_TYPE_NAMES.MASTERTRACK,
                mastertrackPartners
              )
            );
        });
        return { degreeList, mastertrackList, degreeListsV3Loading: data?.loading };
      },
    })(BaseComponent);

export const defaultLOHPDegreeListsV3Options = {
  query: lohpDegreeListsV3Query,
  ...lohpDegreeListsV3Options?.(),
};

export default withDegreeListsV3;
