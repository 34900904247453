import type { MutableRefObject } from 'react';
import { useCallback, useEffect, useMemo, useRef } from 'react';

import { memoize } from 'lodash';

import type { EventData, Events } from '@coursera/event-pulse/sdk';

import useTracker from './useTracker';

type Options = {
  fullyVisible?: boolean;
  initialDelay?: number;
};

/**
 * This hook provides a ref that can be used to track visibility events. The ref should be attached to the element that
 * you want to track visibility for.
 *
 * The hook will track the visibility event when the element is visible in the viewport. You can use the `fullyVisible`
 * option to track the event only when the element is fully visible in the viewport.
 * The hook will track the visibility event after `initialDelay` milliseconds after the element is mounted.
 */
function useVisibilityTracker<T extends HTMLElement, K extends keyof Events = keyof Events>(
  eventName: K,
  data: EventData<K>,
  dependencies: unknown[] = [],
  { fullyVisible = false, initialDelay = 1000 }: Options = {}
): MutableRefObject<T | null> {
  const elementRef = useRef<T | null>(null);
  const timeoutRef = useRef<number>();

  const track = useTracker(eventName, data);

  const memoTrack = useMemo(() => memoize(track), [track]);

  const handleVisibilityChange = useCallback(
    (entries) => {
      const { isIntersecting } = entries[0];
      if (!isIntersecting) return;

      memoTrack();
    },
    [memoTrack]
  );

  const observer = useMemo(() => {
    return typeof window !== 'undefined' && 'IntersectionObserver' in window
      ? new IntersectionObserver(handleVisibilityChange, {
          threshold: fullyVisible ? 1 : 0,
        })
      : false;
  }, [handleVisibilityChange, fullyVisible]);

  useEffect(() => {
    // starting visibility tracking initialDelay ms after tracked element is mounted;
    // when we started the visibility tracking immediately after the element is mounted,
    // we observed that the element to be hidden by a scrolled container becomes visible,
    // generating a visibility impression. It is because the scroller container was rendered slightly
    // after the element is rendered, allowing the element that's supposed to be hidden actually shown
    timeoutRef.current = window.setTimeout(() => {
      if (elementRef.current && observer) {
        observer.observe(elementRef?.current);
      }
    }, initialDelay);

    return () => {
      clearTimeout(timeoutRef.current);

      if (observer) {
        observer.disconnect();
      }
    };
    // Similar to other hooks, we use an array of dependencies defined by the user to re-run the effect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [observer, initialDelay, ...dependencies]);

  return elementRef;
}

export default useVisibilityTracker;
