import React from 'react';

import FocusLock from 'react-focus-lock';

export type Props = {
  children?: React.ReactNode;

  /**
   * Reference to a focusable element that should receive initial focus when the
   * component opens. By default, the first focusable element inside the component
   * will receive focus.
   */
  initialFocusRef?: React.RefObject<HTMLElement>;

  /**
   * Reference to a focusable element that should receive focus when the component
   * closes. By default, focus will return to the element that had focus when the
   * component was opened.
   */
  returnFocusRef?: React.RefObject<HTMLElement>;

  /**
   * By default, the component will shift and lock focus to itself when it opens,
   * and return it to the last focused element on close. This ensures a good
   * keyboard interaction experience.
   *
   * If set, this property will disable the built-in focus lock. It should only
   * be used when custom control of the focus lock is needed, and an alternative
   * focus control mechanism should be provided.
   *
   * @hidden
   */
  disableFocusLock?: boolean;

  /**
   * When enabled tries to focus first interactive element
   * @default true
   */
  autoFocus?: boolean;

  className?: string;
};

const useSetFocus = (focusRef?: React.RefObject<HTMLElement>) => {
  return React.useCallback(
    // Wait until next tick to set focus to allow React lifecycle methods to complete first
    // @see https://github.com/theKashey/react-focus-lock#unmounting-and-focus-management
    () => setTimeout(() => focusRef?.current?.focus(), 0),
    [focusRef]
  );
};

const FocusTrap = (props: Props) => {
  const {
    children,
    initialFocusRef,
    returnFocusRef,
    disableFocusLock,
    className,
    autoFocus = true,
  } = props;

  return (
    <FocusLock
      noFocusGuards
      returnFocus
      autoFocus={autoFocus}
      className={className}
      disabled={disableFocusLock}
      onActivation={useSetFocus(initialFocusRef)}
      onDeactivation={useSetFocus(returnFocusRef)}
    >
      {children}
    </FocusLock>
  );
};
export default FocusTrap;
