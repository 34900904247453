import React from 'react';

import { ButtonBase as MuiButtonBase } from '@material-ui/core';
import type { ButtonBaseProps } from '@material-ui/core';

import type { OverrideProps, OverridableComponent } from '@coursera/cds-common';

import useButtonPress from '@core/utils/useButtonPress';

import getButtonBaseCss, { classes } from './getButtonBaseCss';

type BaseProps = {
  /**
   * Override or extend the styles applied to the component.
   * @ignore
   * @internal
   */
  classes?: { focusVisible?: string; root?: string };
  /**
   * The content of the component.
   */
  children?: React.ReactNode;
  /**
   * If `true`, the button will be disabled.
   * If button has to be focusable please use `aria-disabled`
   */
  disabled?: boolean;

  /**
   * If `true`, the button will be semantically disabled.
   * Use this prop instead `disabled` if the button has to stay in the focus order and be available for screen readers.
   */
  'aria-disabled'?: React.AriaAttributes['aria-disabled'];

  /**
   * The `tabIndex` attribute indicates that the element can be focused,
   * and where it participates in sequential keyboard navigation (usually with the `Tab` key, hence the name).
   */
  tabIndex?: number;

  /**
   * Callback fired when the component is focused using keyboard.
   * It can be used to add specific styles to the component when it's focused by keyboard.
   */
  onFocusVisible?: ButtonBaseProps['onFocusVisible'];
};

export interface ButtonBaseTypeMap<D extends React.ElementType = 'button'> {
  props: BaseProps;
  defaultComponent: D;
}

export type Props<
  D extends React.ElementType = ButtonBaseTypeMap['defaultComponent']
> = OverrideProps<ButtonBaseTypeMap<D>, D> & { component?: React.ElementType };

/**
 * Base button component with minimum styling for the focused state
 *
 */
const ButtonBase: OverridableComponent<ButtonBaseTypeMap> = React.forwardRef<
  HTMLButtonElement,
  Props
>(function ButtonBase(props, ref) {
  const { classes: buttonBaseClasses, onClick, ...rest } = props;

  const handleClick = useButtonPress({
    onClick,
    isDisabled:
      rest['aria-disabled'] === 'true' ||
      rest['aria-disabled'] === true ||
      rest.disabled,
  });

  return (
    <MuiButtonBase
      ref={ref}
      disableRipple
      disableTouchRipple
      classes={{
        focusVisible: classes.focusVisible,
        ...buttonBaseClasses,
      }}
      css={getButtonBaseCss}
      onClick={handleClick}
      {...rest}
    />
  );
});

export default ButtonBase;
