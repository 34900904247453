import React from 'react';

import { useId } from '@coursera/cds-common';
import type { ForwardRefComponent } from '@coursera/cds-common';

import { BaseDialog } from '@core/dialogs/common/BaseDialog';
import type { CommonDialogProps } from '@core/dialogs/common/types';
import { DialogContextProvider } from '@core/dialogs/Dialog/DialogContextProvider';
import { StickyScroll } from '@core/StickyScroll';

import { Header } from './Header';
import type { DialogColor, DialogVariant, DialogWidth } from './styles';
import { dialogClasses, getDialogCss } from './styles';

type DialogType = ForwardRefComponent<HTMLDivElement, Props>;

export type Props = CommonDialogProps & {
  /**
   * Use the subcomponents to compose a Dialog:
   *
   * ```
   * <Dialog>
   *   <Dialog.HeadingGroup/>
   *   <Dialog.Content />
   *   <Dialog.Actions />
   * </Dialog>
   * ```
   *
   * @see Dialog.HeadingGroup
   * @see Dialog.Content
   * @see Dialog.Actions
   */
  children: React.ReactNode | React.ReactNode[];

  /**
   * Background color to apply to the header and the heading group.
   *
   * @default white
   */
  color?: DialogColor;

  /**
   * Controls the variant of the dialog.
   * Standard dialog will allow the user to exit using escape key, background overlay or close button.
   * Transactional dialog requires user to take an action and does not provide an option to exit without choosing to confirm or reject.
   *
   * @default standard
   */
  variant?: DialogVariant;

  /**
   * Sets the desired width behavior of the dialog.
   *
   * @default medium
   */
  width?: DialogWidth;
};

const getHeadingGroupId = (node: React.ReactNode, baseId?: string) => {
  const configuredId = React.isValidElement(node) ? node.props.id : undefined;
  return configuredId || `${baseId}-headingGroup`;
};

const Dialog: DialogType = React.forwardRef(function Dialog(
  props: Props,
  ref: React.Ref<HTMLDivElement>
) {
  const {
    children,
    color = 'white',
    sticky = 'none',
    variant = 'standard',
    width = 'medium',
    id: baseId,
    onClose,
    'aria-labelledby': labelledBy,
    ...baseDialogProps
  } = props;

  const id = useId(baseId);

  const [headingGroup] = React.Children.toArray(children);
  const headingGroupId = getHeadingGroupId(headingGroup, id);

  const isTransactionalDialog = props.variant === 'transactional';
  const handleOnClose = isTransactionalDialog ? undefined : onClose;

  return (
    <BaseDialog
      ref={ref}
      aria-labelledby={labelledBy || headingGroupId}
      classes={{ dialog: dialogClasses.dialog }}
      css={getDialogCss({ width })}
      id={id}
      transition="fade-in"
      onClose={handleOnClose}
      {...baseDialogProps}
    >
      <DialogContextProvider
        color={color}
        headingGroupId={headingGroupId}
        variant={variant}
      >
        <Header
          showCloseAction={!isTransactionalDialog}
          onClose={handleOnClose}
        />

        <StickyScroll sticky={sticky}>{children}</StickyScroll>
      </DialogContextProvider>
    </BaseDialog>
  );
});

export default Dialog;
