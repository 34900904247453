import * as React from 'react';
import { useContext } from 'react';

import hoistNonReactStatics from 'js/lib/hoistNonReactStatics';

import UserAgentApiContext from 'bundles/page/contexts/UserAgentApiContext';

const withUserAgentFromContext =
  () =>
  <P extends Record<string, unknown>>(WrappedComponent: React.ComponentType<P>) => {
    const componentName = WrappedComponent.displayName || WrappedComponent.name;

    const HOCWithUserAgentApi = (props: $TSFixMe) => {
      const userAgent = useContext(UserAgentApiContext);

      return <WrappedComponent {...{ ...props, userAgent }} />;
    };

    HOCWithUserAgentApi.displayName = `withUserAgentFromContext(${componentName})`;

    hoistNonReactStatics(HOCWithUserAgentApi, WrappedComponent);

    return HOCWithUserAgentApi;
  };

export default withUserAgentFromContext;
