import type { Events as AllEvents } from '@coursera/event-pulse/core';

import { low as client } from './client';
import mutex from './mutex';
import * as memory from './pageMemory';

type KeysWithPage<T> = {
  [K in keyof T]: 'page' | 'user_properties' extends keyof T[K] ? K : never;
}[keyof T];

type Events = Omit<Pick<AllEvents, KeysWithPage<AllEvents>>, 'view_page'>;

type EventData<K extends keyof Events> = Omit<Events[K], 'page' | 'user_properties'>;
type EventOptions<K extends keyof Events> = Events[K]['user_properties'];

/**
 * Track an event. Page information will be automatically added to the event.
 *
 * @param eventName The name of the event. If the event name contains eventingv3. prefix, you don't need to include it.
 * @param data The event data.
 * @param options If the event includes user properties, they can be passed in here.
 */
async function track<K extends keyof Events>(
  eventName: K,
  data: EventData<K>,
  options: EventOptions<K> = {}
): Promise<void> {
  // Wait for the mutex to be released before sending the event
  // This will block events until the view_page event is sent
  // @see static/bundles/page/lib/event-pulse/sdk/trackPageView.ts

  await mutex(() => {
    // We don't want to block other requests while waiting for the mutex,
  });

  const page = memory.get();

  // eslint-disable-next-line camelcase
  return client.sendEvent(eventName, { ...data, page, user_properties: options } as AllEvents[K]);
}

export type { Events, EventData, EventOptions };

export default track;
