import * as Sentry from '@sentry/browser-bridge';

const captureException: typeof Sentry['captureException'] = (...args) => {
  return Sentry?.captureException?.(...args);
};

const captureMessage: typeof Sentry['captureMessage'] = (...args) => {
  return Sentry?.captureMessage?.(...args);
};

const setExtra: typeof Sentry['setExtra'] = (...args) => {
  return Sentry?.setExtra?.(...args);
};

const setExtras: typeof Sentry['setExtras'] = (...args) => {
  return Sentry?.setExtras?.(...args);
};

const setTag: typeof Sentry['setTag'] = (...args) => {
  return Sentry?.setTag?.(...args);
};

const setTags: typeof Sentry['setTags'] = (...args) => {
  return Sentry?.setTags?.(...args);
};

export { captureException, captureMessage, setExtra, setExtras, setTag, setTags };
