/* eslint-disable camelcase,no-restricted-syntax */
import type { AppConfig } from './types';

const baseConfig = {
  gapi_eventing_data: {
    project_number: '274249572679',
    api_key: '',
    client_id: '274249572679-al8nc3ttcavj0n91due232a3h6s3r9vk.apps.googleusercontent.com',
  },
  bigquery: {
    dataset: 'mydataset',
    table_prefix: 'livedata_',
  },
  dir: {
    home: '/',
  },
};

const domain = 'https://www.coursera.org';
const cloudfrontDistribution = 'https://d3njjcbhbojbot.cloudfront.net';

const config: AppConfig = {
  ...baseConfig,
  debug: false,
  compress: true,
  optimize: true,
  environment: 'production',
  log: 'error',
  url: {
    api: '/maestro/api/',
    maestro: '/maestro/api/',
    base: `${domain}/`,
    // www-origin is needed for certain calls to avoid Cloudfront issues
    // See https://coursera.slack.com/archives/C70F5CPJS/p1585606476030900
    origin: 'https://www-origin.coursera.org/',
    cloudfront_api: 'https://d1hpa2gdx2lr6r.cloudfront.net/maestro/api/',
    accounts: 'https://accounts.coursera.org/',
    assets: `${cloudfrontDistribution}/web/`,
    assets_domain: 'https://d3njjcbhbojbot.cloudfront.net',
    resource_assets: 'https://coursera_assets.s3.amazonaws.com/',
    app_assets: `${cloudfrontDistribution}/web/`,
    app_chunks: `${cloudfrontDistribution}/webapps/`,
    imageProxyRoot: `${cloudfrontDistribution}/api/utilities/v1/imageproxy/`,
    imageProxyApiRoot: `${cloudfrontDistribution}/api/utilities/v1/imageproxy/`,
    imageRoot: 'https://coursera_assets.s3.amazonaws.com/images/',

    domain,
    originSubdomain: 'https://www-origin.coursera.org',
    appChunks: `${cloudfrontDistribution}/webapps`,
    appAssets: `${cloudfrontDistribution}/web`,
    imageProxy: `${cloudfrontDistribution}/api/utilities/v1/imageproxy`,
  },
};

export default config;
