import * as React from 'react';
import { useContext } from 'react';

import UserAgent from 'js/lib/useragent';

import type { UserAgentApi } from 'bundles/page/types';

const UserAgentContext = React.createContext<UserAgentApi>(new UserAgent('default'));

export function useUserAgent() {
  return useContext(UserAgentContext);
}

export default UserAgentContext;
