import type { AvatarProps } from '@core/Avatar';
import { Avatar } from '@core/Avatar';

type Props = Omit<AvatarProps, 'variant' | 'size' | 'showBorder'>;

/**
 * Common component to render Avatar
 */
const CardAvatar = ({ ...props }: Props) => {
  return <Avatar {...props} showBorder size={24} variant="organization" />;
};

export default CardAvatar;
