import { useCallback } from 'react';

import type { EventData, EventOptions, Events } from '@coursera/event-pulse/sdk';
import { track } from '@coursera/event-pulse/sdk';

/**
 * This hooks provides a function that can be used to track events.
 *
 * There are two ways to use this hook:
 *
 * 1. Pass in the event name, data, and options as arguments to the hook. And use the returned function to track the event.
 * 2. Pass nothing to the hook. And use the returned function to track the event.
 *
 * The first way is useful when you want to track the same event multiple times with same data and options. Because it is
 * using `useCallback` internally, the returned function will be memoized. So it is safe to use it in the dependency array.
 *
 * The second way is useful when you want to track the different events with different data and options.
 */
function useTracker(): <K extends keyof Events>(eventName: K, data: EventData<K>, options?: EventOptions<K>) => void;
function useTracker<K extends keyof Events>(eventName: K, data: EventData<K>, options?: EventOptions<K>): () => void;
function useTracker<K extends keyof Events>(
  eventName?: K,
  data?: EventData<K>,
  options?: EventOptions<K>
  // For this overloaded function types (any below) does not mean any output is fine, but rather that we cannot type it
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any {
  return useCallback(
    (e: K, d: EventData<K>, o: EventOptions<K>) => {
      // Casting to `EventData<K>` is needed because page could be undefined as we don't want to fail if the page provider
      // is not available.
      return track(eventName ?? e, (data ?? d) as EventData<K>, options ?? o);
    },
    // Due to data and options being and object, any time an object is passed as parameter this callback is generated
    // in every render
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [eventName, JSON.stringify(data), JSON.stringify(options)]
  );
}

export { EventData, EventOptions, Events };
export default useTracker;
