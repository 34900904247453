import type React from 'react';

import { classes } from '@core/cards/ProductCard/getProductCardCss';

type Props = {
  children: NonNullable<React.ReactNode>;
};

/**
 * Placeholder slot for custom content to render at the bottom of the card.
 * Use this for admin/authoring use cases, such as selection or deletion of cards.
 */
const CardAdminContent = ({ children }: Props) => {
  return <div className={classes.adminContent}>{children}</div>;
};

export default CardAdminContent;
