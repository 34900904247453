import { classes } from '@core/cards/ProductCard/getProductCardCss';

type Props = {
  children: NonNullable<React.ReactNode>;
};

const CardHeader = ({ children }: Props) => {
  return <div className={classes.header}>{children}</div>;
};

export default CardHeader;
