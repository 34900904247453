import * as React from 'react';

import { ApolloClient, ApolloLink, ApolloProvider, InMemoryCache, createHttpLink, split } from '@apollo/client';
import type { NormalizedCacheObject } from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import URI from 'jsuri';

import Cookie from 'js/lib/cookie';
import { getIetfLanguageTag } from 'js/lib/language';

import { generateGibberishInterceptorForContentful } from 'bundles/cms/utils/CmsUtils';
import {
  AVAILABLE_SPACE_SLUGS,
  CONTENTFUL_BASE_URL,
  CONTENTFUL_SPACE_CONFIG,
  getClientName,
} from 'bundles/cms/utils/SpaceUtils';
import type { SpaceConfig } from 'bundles/cms/utils/SpaceUtils';
import AuthRedirectLink from 'bundles/page/lib/network/AuthRedirectLink';
// @ts-expect-error TS7016 Untyped import http://go.dkandu.me/strict-ts-migration#TS7016
import NaptimeLink from 'bundles/page/lib/network/apollo-link-naptime';

import { XCourseraSchemaVersion, graphqlGatewayLinkEndpoints } from './constants';
import type { GraphQLGatewayLinks } from './constants';
import { graphqlGatewayQueryLinkOverride } from './graphqlGatewayQueryLinkOverride';
import { possibleTypes } from './possibleTypes';
import { typePolicies } from './typePolicies';

/**
 * We're encapsulating this module so that these module dependencies (which only exist in node_modules)
 * can be stubbed out when executed in RequireJS.
 * You must use Webpack in order to utilize the ApolloClient.
 */

declare const COURSERA_APP_NAME: string;
declare const COURSERA_APP_VERSION: string;
declare global {
  interface Window {
    __APOLLO_STATE__: NormalizedCacheObject;
  }
}

const operationNameHeader = 'operation-name';

type PatchedRequestInit = Omit<RequestInit, 'headers'> & { headers: Record<string, string> };

// This custom fetch and the following middleware append the operation name
// as a query parameter, mainly for visibility in edge access logs.
const fetchWithOperationName = (uri: string, options: PatchedRequestInit) => {
  const operationName = options.headers[operationNameHeader];
  const uriWithOpName = new URI(uri).addQueryParam('opname', operationName).toString();
  return fetch(uriWithOpName, options);
};

const XCourseraApolloTransformNotFound = 'x-coursera-apollo-transform-notfound';

const fetchWithTransformNotFound = async (request: RequestInfo, init: RequestInit) => {
  // Coerce this. It can come as either string[][], Record<string, string>, Headers, or undefined.
  const headers = new Headers(init.headers);
  const shouldTransform404 = headers.has(XCourseraApolloTransformNotFound);

  // apollo-rest-link silently converts 404 responses to {data:null}. This is okay for queries, but disastrous for
  // mutations. We'll allow select mutations to opt-into a work-around that escapes this disastrous behavior by
  // converting the response code to 400 Bad Request.
  if (shouldTransform404) {
    // Don't actually send this header, it's just a trick since we can't access context from fetch
    headers.delete(XCourseraApolloTransformNotFound);
    const res = await fetch(request, { ...init, headers });
    if (res.status === 404) {
      return new Response(res.body, { status: 400, statusText: res.statusText, headers: res.headers });
    }
    return res;
  }

  return fetch(request, init);
};

const headersMiddleware = new ApolloLink((operation, forward) => {
  const { schemaVersion, transformNotFoundIntoBadRequest } = operation.getContext();
  operation.setContext(({ headers = {} }) => {
    const newHeaders = {
      ...headers,
      'accept-language': getIetfLanguageTag(),
      //  A string name of the query if it is named, otherwise it is null. e.g. MembershipsQuery
      [operationNameHeader]: operation.operationName,
    };

    if (schemaVersion) Object.assign(newHeaders, { [XCourseraSchemaVersion]: schemaVersion });

    // We cannot access context from customFetch, so proxy this property through HTTP headers.
    if (transformNotFoundIntoBadRequest) {
      Object.assign(newHeaders, {
        [XCourseraApolloTransformNotFound]: '1',
      });
    }

    return { headers: newHeaders };
  });
  return forward ? forward(operation) : null;
});

/**
 * Add the CSRF3 token to a graphql request if it appears to need the token.
 */
const csrf3TokenMiddleware = new ApolloLink((operation, forward) => {
  const csrf3Token = Cookie.get('CSRF3-Token');
  if (csrf3Token) {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        'X-CSRF3-Token': csrf3Token,
      },
    }));
  }
  return forward ? forward(operation) : null;
});

export const createApolloClient = (): ApolloClient<{}> => {
  const graphqlLink = new BatchHttpLink({
    uri: '/graphqlBatch',
    credentials: 'same-origin',
    headers: {
      'R2-APP-VERSION': COURSERA_APP_VERSION,
      'X-Coursera-Application': COURSERA_APP_NAME,
      'X-Coursera-Version': COURSERA_APP_VERSION,
      'apollographql-client-name': COURSERA_APP_NAME,
      'apollographql-client-version': COURSERA_APP_VERSION,
    },
    fetch: fetchWithOperationName as typeof fetch,
  });

  const naptimeLink = new NaptimeLink({
    uri: '/api/',
    credentials: 'same-origin',
    customFetch: fetchWithTransformNotFound,
  });

  const createGatewayHttpLink = (uri: string): ApolloLink => {
    const options = {
      uri,
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'apollographql-client-name': COURSERA_APP_NAME,
        'apollographql-client-version': COURSERA_APP_VERSION,
      },
      fetch: fetchWithOperationName as typeof fetch,
    };

    const httpLink = createHttpLink(options);
    const batchHttpLink = new BatchHttpLink(options);

    return ApolloLink.from([
      csrf3TokenMiddleware,
      headersMiddleware,
      AuthRedirectLink,
      naptimeLink,
      split((op) => op.getContext().disableBatching === true, httpLink, batchHttpLink),
    ]);
  };

  const gatewayLinks: Partial<Record<GraphQLGatewayLinks, ApolloLink>> = {};
  for (const [k, v] of Object.entries(graphqlGatewayLinkEndpoints) as [GraphQLGatewayLinks, string][]) {
    gatewayLinks[k] = createGatewayHttpLink(v);
  }

  const createContentfulLink = (CONFIG: SpaceConfig, isPreview?: boolean) =>
    createHttpLink({
      uri: `${CONTENTFUL_BASE_URL}${CONFIG.SPACE_ID}`,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${isPreview ? CONFIG.PREVIEW_ACCESS_TOKEN : CONFIG.DELIVERY_ACCESS_TOKEN}`,
      },
    });

  const contentfulSpaceLinks: Record<string, ApolloLink> = Object.assign(
    {},
    ...AVAILABLE_SPACE_SLUGS.map((spaceSlug: string) => {
      const CONFIG = CONTENTFUL_SPACE_CONFIG[spaceSlug];
      return {
        [getClientName(spaceSlug)]: createContentfulLink(CONFIG),
        [getClientName(spaceSlug, true)]: createContentfulLink(CONFIG, true),
      };
    })
  );

  // supporting legacy link
  const legacyContentfulConfig = CONTENTFUL_SPACE_CONFIG.GROWTH;
  const legacyContentfulLink = createContentfulLink(legacyContentfulConfig, false);
  const legacyContentfulPreviewLink = createContentfulLink(legacyContentfulConfig, true);

  const cache = new InMemoryCache({ possibleTypes, typePolicies });

  // Make sure the key in this object is the same as the context you pass in
  // the GraphQL query
  const defaultLink = ApolloLink.from([
    csrf3TokenMiddleware,
    headersMiddleware,
    AuthRedirectLink,
    naptimeLink,
    graphqlLink,
  ]);

  const allLinks: Record<string, ApolloLink> = {
    default: defaultLink,
    contentfulGql: legacyContentfulLink,
    contentfulPreviewGql: legacyContentfulPreviewLink,
    ...contentfulSpaceLinks,
    ...gatewayLinks,
  };

  // Needed to send the GraphQL request to the respective URL
  const linkFromOperation = new ApolloLink((operation, forward) => {
    const { clientName } = operation.getContext();
    const linkOverride = graphqlGatewayQueryLinkOverride(operation.operationName, clientName, COURSERA_APP_NAME);
    const linkToUse = (linkOverride && allLinks[linkOverride]) || allLinks[clientName] || defaultLink;

    // Debugging feature for translations https://coursera.atlassian.net/wiki/spaces/EN/pages/3230924825/Debugging+Translations
    return linkToUse.request(operation, forward)?.map(generateGibberishInterceptorForContentful(operation)) || null;
  });

  return new ApolloClient({
    cache: cache.restore(window.__APOLLO_STATE__),
    connectToDevTools: true,
    link: linkFromOperation,
  });
};

export const provideApolloContext = (apolloClient: ApolloClient<{}>, type: React.ComponentClass<{}>) => {
  return function ApolloClientProvider() {
    return <ApolloProvider client={apolloClient}>{React.createElement(type)}</ApolloProvider>;
  };
};
