import React from 'react';

import clsx from 'clsx';

import { useLocalizedStringFormatter } from '@coursera/cds-common';
import { ErrorIcon } from '@coursera/cds-icons';

import Delayed from '@core/Delayed';
import i18nMessages from '@core/loaders/i18n';
import Typography from '@core/Typography2';
import { clamp } from '@core/utils';
import VisuallyHidden from '@core/VisuallyHidden';

import getLoadingSectionCss, { classes } from './getLoadingSectionCss';

export type Props = {
  /**
   * Label for the loading section
   */
  label?: string;
  /**
   * When true, makes the underlying overlay transparent.
   * @default false
   */
  hideOverlay?: boolean;
  /**
   * Invert the color scheme. Use when displaying over dark backgrounds
   * @default false
   */
  inverted?: boolean;
  /**
   * When true, displays an error icon.
   * @default false
   */
  hasError?: boolean;
  /**
   * Current progress in percentage. Min value = 0 and max value = 100.
   */
  value?: number;
} & React.ComponentPropsWithRef<'div'>;

/**
 * LoadingSection notifies the user that either a page or large area of content is loading.
 *
 * See [Props](__storybookUrl__/components-feedback-loading--default#props)
 */
const LoadingSection = React.forwardRef(function LoadingSection(
  props: Props,
  ref: React.Ref<HTMLDivElement>
) {
  const stringFormatter = useLocalizedStringFormatter(i18nMessages);
  const {
    hasError = false,
    label = stringFormatter.format('loading'),
    inverted = false,
    value: rawValue,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    hideOverlay = false,
    className,
    ...rest
  } = props;

  const fontColor = inverted ? 'invertBody' : 'body';
  const isDeterminate = Number.isFinite(rawValue) && !hasError;
  const isInDeterminate = !Number.isFinite(rawValue) && !hasError;
  const value = isDeterminate
    ? clamp(rawValue as NonNullable<number>)
    : undefined;

  const loading = (
    <div
      ref={ref}
      className={clsx(className, {
        [classes.overlayHidden]: hideOverlay,
        [classes.invert]: inverted,
      })}
      css={getLoadingSectionCss({ ...props, value })}
      role="status"
      {...rest}
    >
      <div className={classes.container}>
        {/*  Failed state  */}
        {hasError && (
          <ErrorIcon
            color={inverted ? 'invert' : 'error'}
            css={{ width: '64px', height: '64px' }}
          />
        )}
        {/* Determinate state */}
        {isDeterminate && (
          <svg
            aria-hidden
            className={classes.imageContainer}
            data-testid="determinate-svg"
            fill="none"
            viewBox="0 0 100 100"
          >
            <circle className={classes.backgroundCircle}></circle>
            <circle className={classes.fillCircle} />
          </svg>
        )}
        {/* Indeterminate state */}
        {isInDeterminate && (
          <svg
            aria-hidden
            className={classes.imageContainer}
            data-testid="indeterminate-svg"
            fill="none"
            viewBox="0 0 74 74"
          >
            <circle className={classes.tertiaryCircle} />
            <circle className={classes.secondaryCircle} />
            <circle className={classes.mainCircle} />
          </svg>
        )}
        <Delayed>
          <Typography
            aria-hidden={!!props['aria-label']}
            className={classes.label}
            color={fontColor}
            component="h2"
            variant="subtitleLarge"
          >
            {hasError && (
              <VisuallyHidden>{stringFormatter.format('error')}</VisuallyHidden>
            )}
            {label}
          </Typography>
        </Delayed>
        {isDeterminate && (
          <Typography
            className={classes.status}
            color={fontColor}
            component="p"
            variant="bodySecondary"
          >
            {stringFormatter.format('completion_status', {
              value: value || '0',
            })}
          </Typography>
        )}
      </div>
    </div>
  );

  return loading;
});

export default LoadingSection;
