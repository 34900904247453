import UserAgentInfo from 'js/lib/useragent';

const agentString =
  typeof navigator === 'undefined'
    ? 'Prerender' // rendering isomorphic inside Node, can treat as prerender.io
    : navigator.userAgent; // in browser, use its agent string

// NOTE if you Object.freeze() this, some tests will break because they need to modify the singleton
const userAgent = new UserAgentInfo(agentString);

userAgent.isMobile = userAgent.isMobile || userAgent.isAndroid || userAgent.isIOS;

export default userAgent;
