import clsx from 'clsx';

import { classes } from '@core/cards/ProductCard/getProductCardCss';
import Grid from '@core/Grid';
import Skeleton from '@core/Skeleton';

/**
 * Skeleton version of the grid card variant
 */
const CardGridSkeleton = () => {
  return (
    <div className={clsx(classes.grid, classes.gridSkeleton)}>
      <div>
        <Skeleton className={classes.gridSkeletonPreview} variant="rect" />
      </div>
      <div className={classes.gridSkeletonHeader}>
        <Skeleton height={16} variant="rect" width="50%" />
        <Skeleton height={16} variant="rect" width="90%" />
      </div>
      <div className={classes.gridSkeletonBody}>
        <Skeleton height={16} variant="rect" width="90%" />
        <Skeleton height={16} variant="rect" width="70%" />
      </div>
      <Grid
        container
        alignItems="center"
        className={classes.gridSkeletonFooter}
      >
        <Skeleton height={24} variant="text" width="25%" />
        <Skeleton height={24} variant="text" width="25%" />
        <Skeleton height={24} variant="text" width="25%" />
      </Grid>
    </div>
  );
};

export default CardGridSkeleton;
