import { simplifyQueryValue } from 'js/lib/queryUtils';

import type CartsV2 from 'bundles/naptimejs/resources/carts.v2';

export type DigitProps = {
  maximumFractionDigits?: number;
  minimumFractionDigits?: number;
};

export const amountToDigitsProps = (amount?: number | null): DigitProps => {
  return amount && typeof amount === 'number' && amount % 1 > 0
    ? { maximumFractionDigits: 2, minimumFractionDigits: 2 }
    : {};
};

export const isSubscriptionFreeTrial = ({ cart }: { cart?: CartsV2 }): boolean => {
  if (!cart) {
    return false;
  }

  const response =
    (cart.isSpecializationSubscription || cart.isCourseraPlusSubscription || cart.isCourseraLiteSubscription) &&
    cart.hasFreeTrial;
  return !!response;
};

type GenericQuery = { [key: string]: string | string[] | null | undefined } | undefined;

const parseQueryId = (id: string | undefined): string | undefined => {
  // use regex match to strip any trailing Coursera header tag and non-numeric inputs
  return id?.replace(/[0-9]{4}Coursera/, '')?.match(/([0-9]*)/)?.[0];
};

export const getCartIdFromQuery = (query: GenericQuery): string | undefined =>
  parseQueryId(simplifyQueryValue(query?.cartId));
export const getPreEnrollmentIdFromQuery = (query: GenericQuery): string | undefined =>
  simplifyQueryValue(query?.preEnrollmentId);

export default {
  amountToDigitsProps,
  isSubscriptionFreeTrial,
  getCartIdFromQuery,
};
