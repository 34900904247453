import type { Provider } from 'react';
import { createContext, useContext } from 'react';

import config from 'js/app/config';
import logger from 'js/app/loggerSingleton';

/**
 * This function creates a tracking store that can be used to store any value.
 *
 * For example, you can use it to store the current page information or the current course information.
 */
function createTrackerStore<T extends {}>(
  { storeName }: { storeName?: string } = { storeName: 'Store' }
): [Provider<T | undefined>, () => T | undefined] {
  const Context = createContext<T | undefined>(undefined);

  /**
   * This hook returns the current value of the store.
   */
  function useTrackerStore(): T | undefined {
    const store = useContext(Context);

    if (!store && config.environment === 'development') {
      logger.error(`use${storeName} must be used within a ${storeName} Provider.`);
    }

    return store;
  }

  return [Context.Provider, useTrackerStore];
}

export default createTrackerStore;
