import clsx from 'clsx';

import { breakpoints } from '@coursera/cds-common';

import { CardTitle } from '@core/cards/common';
import { classes } from '@core/cards/ProductCard/getProductCardCss';
import { useMediaQuery } from '@core/utils';

import CardBody from './card/CardBody';
import CardContent from './card/CardContent';
import CardFooter from './card/CardFooter';
import CardHeader from './card/CardHeader';
import CardPartnerInfo from './card/CardPartnerInfo';
import CardPreviewList from './card/CardPreviewList';
import CardStatusTags from './card/CardStatusTags';
import type { ProductCardProps } from './types';

type Props = {
  'aria-label': string;
  titleId: string;
  partnerInfoId?: string;
} & ProductCardProps;

/**
 * List variant of the ProductCard
 */
const ProductListCard = (props: Props) => {
  const {
    previewImageSrc,
    statusTags,
    partners,
    title,
    'aria-label': ariaLabel,
    body,
    footer,
    titleId,
    partnerInfoId,
    renderPreviewImage,
    alwaysSmallImage,
  } = props;

  const isLgUpMedia = useMediaQuery(breakpoints.up('lg'));

  const shouldShowPreview =
    !!previewImageSrc || typeof renderPreviewImage === 'function';

  const shouldOverlayTags =
    isLgUpMedia && shouldShowPreview && !alwaysSmallImage;

  return (
    <div
      className={clsx(classes.listCard, {
        [classes.alwaysSmallImage]: alwaysSmallImage,
      })}
    >
      {statusTags && (
        <CardStatusTags shouldOverlay={shouldOverlayTags} tags={statusTags} />
      )}
      <div className={classes.listContentContainer}>
        {shouldShowPreview && (
          <CardPreviewList
            imageSrc={previewImageSrc}
            renderImage={renderPreviewImage}
          />
        )}
        <CardContent>
          <CardHeader>
            {partners && (
              <CardPartnerInfo id={partnerInfoId} partners={partners} />
            )}
            {title && (
              <CardTitle
                aria-label={ariaLabel}
                customLinkProps={title.customLinkProps}
                id={titleId}
                linkProps={title.linkProps}
                name={title.name}
              />
            )}
          </CardHeader>
          {body && <CardBody>{body}</CardBody>}
          {footer && <CardFooter>{footer}</CardFooter>}
        </CardContent>
      </div>
    </div>
  );
};

export default ProductListCard;
