import clsx from 'clsx';

import { classes } from '@core/cards/ProductCard/getProductCardCss';
import Grid from '@core/Grid';
import Skeleton from '@core/Skeleton';

/**
 * Skeleton version of the list card variant
 */
const CardListSkeleton = ({
  alwaysSmallImage,
}: {
  alwaysSmallImage?: boolean;
}) => {
  return (
    <div
      className={clsx(classes.listSkeleton, {
        [classes.alwaysSmallImage]: alwaysSmallImage,
      })}
    >
      <div className={classes.listSkeletonPreviewContainer}>
        <Skeleton className={classes.listSkeletonPreview} variant="rect" />
      </div>
      <div className={classes.listSkeletonContent}>
        <Skeleton
          className={classes.listSkeletonSection}
          height={14}
          variant="rect"
          width="50%"
        />
        <Skeleton
          className={classes.listSkeletonSection}
          height={14}
          variant="rect"
          width="100%"
        />
        <Skeleton
          className={classes.listSkeletonSection}
          height={14}
          variant="rect"
          width="100%"
        />
        <Grid className={classes.listSkeletonFooter}>
          <Skeleton height={20} variant="text" width="25%" />
          <Skeleton height={20} variant="text" width="25%" />
          <Skeleton height={20} variant="text" width="25%" />
        </Grid>
      </div>
    </div>
  );
};

export default CardListSkeleton;
