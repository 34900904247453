import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

import cdsBreakpoints from '@common/theme/createBreakpoints';

/**
 * Static breakpoints
 */
const breakpoints = createBreakpoints(cdsBreakpoints);

export default breakpoints;
