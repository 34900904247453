import _t from 'i18n!nls/megamenu';

// export from https://docs.google.com/spreadsheets/d/1PiE1pT3oz_fQx3_FwlicoY3dbUcDiubDi4yTSS0Ood8/edit#gid=0
type CertificateMetadata = {
  type: string;
  metadata?: string[];
};

export const getFullCertificateMetadata = (): Record<string, CertificateMetadata> => ({
  'ibm-data-engineer': {
    type: 'certificate',
    metadata: [_t('No prerequisites'), _t('Self-paced')],
  },
  'ibm-technical-support': {
    type: 'certificate',
    metadata: [_t('No prerequisites'), _t('Self-paced')],
  },
  'meta-front-end-developer': {
    type: 'certificate',
    metadata: [_t('No prerequisites'), _t('Self-paced')],
  },
  'meta-ios-developer': {
    type: 'certificate',
    metadata: [_t('No prerequisites'), _t('Self-paced')],
  },
  'intuit-bookkeeping': {
    type: 'certificate',
    metadata: [_t('No prerequisites'), _t('Self-paced')],
  },
  'google-digital-marketing-ecommerce': {
    type: 'certificate',
    metadata: [_t('No prerequisites'), _t('Self-paced')],
  },
  'facebook-marketing-analytics': {
    type: 'certificate',
    metadata: [_t('No prerequisites'), _t('Self-paced')],
  },
  'meta-database-engineer': {
    type: 'certificate',
    metadata: [_t('No prerequisites'), _t('Self-paced')],
  },
  'ibm-data-science': {
    type: 'certificate',
    metadata: [_t('No prerequisites'), _t('Self-paced')],
  },
  'certificado-profesional-analista-de-datos-de-ibm': {
    type: 'certificate',
    metadata: [_t('No prerequisites'), _t('Self-paced')],
  },
  'certificado-profesional-de-ciencia-de-datos-de-ibm': {
    type: 'certificate',
    metadata: [_t('No prerequisites'), _t('Self-paced')],
  },
  'google-it-support': {
    type: 'certificate',
    metadata: [_t('No prerequisites'), _t('Self-paced')],
  },
  'soporte-de-tecnologias-de-informacion-google': {
    type: 'certificate',
    metadata: [_t('No prerequisites'), _t('Self-paced')],
  },
  'suporte-em-ti-do-google': {
    type: 'certificate',
    metadata: [_t('No prerequisites'), _t('Self-paced')],
  },
  'ibm-data-analyst': {
    type: 'certificate',
    metadata: [_t('No prerequisites'), _t('Self-paced')],
  },
  'ibm-full-stack-cloud-developer': {
    type: 'certificate',
    metadata: [_t('No prerequisites'), _t('Self-paced')],
  },
  'ibm-cybersecurity-analyst': {
    type: 'certificate',
    metadata: [_t('No prerequisites'), _t('Self-paced')],
  },
  'sales-development-representative': {
    type: 'certificate',
    metadata: [_t('No prerequisites'), _t('Self-paced')],
  },
  'facebook-social-media-marketing': {
    type: 'certificate',
    metadata: [_t('No prerequisites'), _t('Self-paced')],
  },
  'applied-project-management': {
    type: 'certificate',
    metadata: [_t('Certification preparation')],
  },
  'sas-programming': {
    type: 'certificate',
    metadata: [_t('Certification preparation'), _t('Self-paced')],
  },
  'sas-advanced-programmer': {
    type: 'certificate',
    metadata: [_t('Certification preparation'), _t('Self-paced')],
  },
  'arizona-state-university-tesol': {
    type: 'certificate',
    metadata: [_t('Certification preparation'), _t('Self-paced')],
  },
  'cloud-engineering-gcp': {
    type: 'certificate',
    metadata: [_t('Certification preparation'), _t('Self-paced')],
  },
  'icpm-certified-supervisor': {
    type: 'certificate',
    metadata: [_t('Certification preparation'), _t('Self-paced')],
  },
  'sre-devops-engineer-google-cloud': {
    type: 'certificate',
    metadata: [_t('Certification preparation'), _t('Self-paced')],
  },
  'certified-artificial-intelligence-practitioner': {
    type: 'certificate',
    metadata: [_t('Certification preparation'), _t('Self-paced')],
  },
  'google-cloud-security': {
    type: 'certificate',
    metadata: [_t('Certification preparation'), _t('Self-paced')],
  },
  'google-cloud-networking': {
    type: 'certificate',
    metadata: [_t('Certification preparation'), _t('Self-paced')],
  },
  'gcp-cloud-architect-jp': {
    type: 'certificate',
    metadata: [_t('Certification preparation'), _t('Self-paced')],
  },
  'gcp-data-engineering': {
    type: 'certificate',
    metadata: [_t('Certification preparation'), _t('Self-paced')],
  },
  'gcp-data-engineering-jp': {
    type: 'certificate',
    metadata: [_t('Certification preparation'), _t('Self-paced')],
  },
  'gcp-cloud-architect': {
    type: 'certificate',
    metadata: [_t('Certification preparation'), _t('Self-paced')],
  },
  'certified-ethical-emerging-technologist': {
    type: 'certificate',
    metadata: [_t('Certification preparation'), _t('Self-paced')],
  },
  'tensorflow-in-practice': {
    type: 'certificate',
    metadata: [_t('Certification preparation'), _t('Self-paced')],
  },
  'sas-visual-business-analytics': {
    type: 'certificate',
    metadata: [_t('Certification preparation'), _t('Self-paced')],
  },
  'ibm-z-mainframe': {
    type: 'certificate',
    metadata: [_t('Self-paced')],
  },
  'applied-artifical-intelligence-ibm-watson-ai': {
    type: 'certificate',
    metadata: [_t('Self-paced')],
  },
  'google-it-automation': {
    type: 'certificate',
    metadata: [_t('Self-paced')],
  },
  'bases-de-inteligencia-artificial-para-todos': {
    type: 'certificate',
    metadata: [_t('Self-paced')],
  },
  'ai-engineer': {
    type: 'certificate',
    metadata: [_t('Self-paced')],
  },
  'ibm-machine-learning': {
    type: 'certificate',
    metadata: [_t('Self-paced')],
  },
  'google-data-analytics': {
    type: 'certificate',
    metadata: [_t('No prerequisites'), _t('Self-paced')],
  },
  'google-project-management': {
    type: 'certificate',
    metadata: [_t('No prerequisites'), _t('Self-paced')],
  },
  'google-ux-design': {
    type: 'certificate',
    metadata: [_t('No prerequisites'), _t('Self-paced')],
  },
  'data-science-machine-learning-iitr': {
    type: 'certificate',
    metadata: [_t('Live Sessions'), _t('Expert feedback')],
  },
  'digital-transformation-iitbombay': {
    type: 'certificate',
    metadata: [_t('Live Sessions'), _t('Expert feedback')],
  },
  'power-electronics-motors-ev-iitbombay': {
    type: 'certificate',
    metadata: [_t('Live Sessions'), _t('Expert feedback')],
  },
  'software-engineering-asu': {
    type: 'mastertrack',
    metadata: [_t('Expert feedback'), _t('Credit eligible')],
  },
  'cybersecurity-asu': {
    type: 'mastertrack',
    metadata: [_t('Expert feedback'), _t('Credit eligible')],
  },
  'big-data-asu': {
    type: 'mastertrack',
    metadata: [_t('Degree credit'), _t('Expert feedback')],
  },
  'ai-machine-learning-asu': {
    type: 'mastertrack',
    metadata: [_t('Expert feedback'), _t('Credit eligible')],
  },
  'principios-ingenieria-software-automatizada-agil-uniandes': {
    type: 'mastertrack',
    metadata: [_t('Expert feedback'), _t('Credit eligible')],
  },
  'innovation-management-entrepreneurship-hec': {
    type: 'mastertrack',
    metadata: [_t('Degree credit'), _t('Expert feedback')],
  },
  'iiot-boulder': {
    type: 'mastertrack',
    metadata: [_t('Expert feedback'), _t('Credit eligible')],
  },
  'power-electronics-boulder': {
    type: 'mastertrack',
    metadata: [_t('Expert feedback'), _t('Credit eligible')],
  },
  'machine-learning-analytics-chicago': {
    type: 'mastertrack',
    metadata: [_t('Expert feedback'), _t('Credit eligible')],
  },
  'instructional-design-illinois': {
    type: 'mastertrack',
    metadata: [_t('Expert feedback'), _t('Credit eligible')],
  },
  'supply-chain-excellence-rutgers': {
    type: 'mastertrack',
    metadata: [_t('Expert feedback'), _t('Credit eligible')],
  },
  'construction-engineering-management-umich': {
    type: 'mastertrack',
    metadata: [_t('Expert feedback'), _t('Credit eligible')],
  },
  'sustainability-development-umich': {
    type: 'mastertrack',
    metadata: [_t('Expert feedback'), _t('Credit eligible')],
  },
  'social-work-umich': {
    type: 'mastertrack',
    metadata: [_t('Expert feedback'), _t('Credit eligible')],
  },
  'global-leadership-hr-management-macquarie': {
    type: 'mastertrack',
    metadata: [_t('Expert feedback'), _t('Credit eligible')],
  },
  'health-informatics-yale': {
    type: 'mastertrack',
    metadata: [_t('Expert feedback'), _t('Credit eligible')],
  },
  'digital-marketing-illinois': {
    type: 'mastertrack',
    metadata: [_t('Expert feedback'), _t('Credit eligible')],
  },
  'strategic-management-leadership-illinois': {
    type: 'mastertrack',
    metadata: [_t('Expert feedback'), _t('Credit eligible')],
  },
  'finance-analysis-modeling-macquarie': {
    type: 'mastertrack',
    metadata: [_t('Expert feedback'), _t('Credit eligible')],
  },
  'blockchain-duke': {
    type: 'university certificate',
    metadata: [_t('Expert feedback'), _t('Live sessions')],
  },
  'graduate-english-proficiency-asu-cert': {
    type: 'university certificate',
    metadata: [_t('Expert feedback'), _t('Live sessions')],
  },
});

export const getCertificateMetadata = (certificateSlug?: string): string[] => {
  return (certificateSlug && getFullCertificateMetadata()[certificateSlug]?.metadata) || [];
};

export default getCertificateMetadata;
