import { getDegreeProductWithLinkAndPartners } from 'bundles/browse/components/Domain/withDegreeListsV3';
import type { DomainGetAllQuery_DomainsV1Resource as DomainGetAllQueryData } from 'bundles/browse/components/__generated__/DomainGetAllQuery';
import type { ProfessionalCertificatesData_ProfessionalCertsV1Resource as ProfessionalCertificatesData } from 'bundles/browse/components/__generated__/ProfessionalCertificatesData';
import type { ProfessionalCertificateProps } from 'bundles/browse/components/types/ProfessionalCertificate';
import { PRODUCT_TYPE_NAMES } from 'bundles/browse/constants';
import type { DegreeProductMember } from 'bundles/browse/types/degree-list';
import { buildProfessionalCertificateUrlAbsolute } from 'bundles/common/utils/urlUtils';
import type { MegaMenuDataQuery_ExternallyAccessibleNostosV1Resource as MegaMenuDataQueryType } from 'bundles/megamenu/components/hoc/__generated__/MegaMenuDataQuery';
import type { MegaMenuSkillDataQuery_ExternallyAccessibleNostosV1Resource as MegaMenuSkillDataQueryType } from 'bundles/megamenu/components/hoc/__generated__/MegaMenuSkillDataQuery';
import { groupBySectionAndDomain } from 'bundles/megamenu/components/hoc/withMegaMenuData';
import { mapToMegaMenuData } from 'bundles/megamenu/components/hoc/withMegaMenuSkillData';
import { MEGAMENU_SECTION_ID_POPULAR_SKILLS } from 'bundles/megamenu/constants/constants';

export function parseDomains(DomainsV1Resource: DomainGetAllQueryData) {
  const domains = (DomainsV1Resource && DomainsV1Resource.domains && DomainsV1Resource.domains.elements) || [];
  return domains;
}

export function parseDegreeLists(DegreeListsV3Resource: $TSFixMe) {
  const degreeListV3Element = DegreeListsV3Resource?.getList?.elements[0];
  const degreeProducts = degreeListV3Element?.degreeProducts;
  const degreePartners = degreeListV3Element?.degreePartners?.elements;
  const mastertrackPartners = degreeListV3Element?.mastertrackPartners?.elements;

  const degreeList: DegreeProductMember[] = [];
  const mastertrackList: DegreeProductMember[] = [];

  // @ts-expect-error
  degreeProducts?.forEach((degreeProduct) => {
    const catalogDegree = degreeProduct.catalogDegree;
    const catalogMastertrack = degreeProduct.catalogMastertrack;
    if (catalogDegree)
      degreeList.push(getDegreeProductWithLinkAndPartners(catalogDegree, PRODUCT_TYPE_NAMES.DEGREE, degreePartners));
    else if (catalogMastertrack)
      mastertrackList.push(
        getDegreeProductWithLinkAndPartners(catalogMastertrack, PRODUCT_TYPE_NAMES.MASTERTRACK, mastertrackPartners)
      );
  });
  return { degreeList, mastertrackList };
}

export function parseProfessionalCertificates(ProfessionalCertsV1Resource: ProfessionalCertificatesData) {
  const certs = ProfessionalCertsV1Resource?.getAll?.elements;
  const professionalCertificates = certs
    ?.map((cert) => {
      if (!cert) return null;
      return { ...cert, link: buildProfessionalCertificateUrlAbsolute(cert.slug) };
    })
    .filter((cert): cert is ProfessionalCertificateProps => cert !== null);

  return professionalCertificates;
}

export function parseMegaMenuData(
  ExternallyAccessibleNostosV1Resource: MegaMenuDataQueryType,
  MegamenuPopularSkills: MegaMenuSkillDataQueryType
) {
  const rows = ExternallyAccessibleNostosV1Resource?.getAllProperties?.elements[0]?.content?.load_menu;
  const megaMenuData = groupBySectionAndDomain(rows);

  const elements = MegamenuPopularSkills?.getAllProperties?.elements;
  const popularSkillsData = elements && mapToMegaMenuData(elements);

  if (!megaMenuData || !popularSkillsData) return undefined;

  megaMenuData[MEGAMENU_SECTION_ID_POPULAR_SKILLS] = popularSkillsData;

  return megaMenuData;
}
