import React from 'react';

import clsx from 'clsx';

import { useLocalizedStringFormatter, IconContext } from '@coursera/cds-common';
import type { IconProps, BaseComponentProps } from '@coursera/cds-common';

import Typography from '@core/Typography2';
import VisuallyHidden from '@core/VisuallyHidden';

import getTagCss, { classes } from './getTagCss';
import i18nMessages from './i18n';
import type { VisualProps } from './types';

type TagColor = VisualProps['color'];

const statusColors: TagColor[] = [
  'default',
  'highlight1',
  'success',
  'warning',
  'error',
];

const categoryColors: TagColor[] = ['default', 'highlight1', 'highlight2'];

const getTranslationKey = (
  variant: 'category' | 'status',
  color: TagColor,
  wrongColor: boolean
) => {
  if (wrongColor || variant === 'category') {
    return 'category';
  }

  if (color === 'default' || color === 'highlight1' || color === 'highlight2') {
    return 'status';
  }

  return color;
};

export type Props = BaseComponentProps<'span'> &
  Partial<VisualProps> & {
    /**
     * An optional icon to give added visual feedback.
     */
    icon?: React.ReactElement<IconProps>;

    /**
     * Displayed label.
     */
    children: React.ReactText;
  };

/**
 * Tags are compact, static components that are used to provide users with status feedback or show the relationship of content of single or multiple categories.
 *
 * See [Props](__storybookUrl__/components-data-display-tag--default#props)
 */
const Tag = React.forwardRef<HTMLSpanElement, Props>(function Tag(
  {
    children,
    variant = 'category',
    priority = 'primary',
    color = 'default',
    icon,
    className,
    'aria-label': ariaLabel,
    ...rest
  },
  ref
) {
  const stringFormatter = useLocalizedStringFormatter(i18nMessages);

  const hasWrongColor =
    (variant === 'category' && !categoryColors.includes(color)) ||
    (variant === 'status' && !statusColors.includes(color));

  if (hasWrongColor && __DEV__) {
    console.warn(
      `<Tag variant="${variant}" /> can't be used with "${color}" color. Falling back to default.`
    );
  }

  return (
    <span
      ref={ref}
      className={clsx(
        classes[variant],
        classes[priority],
        classes[hasWrongColor ? 'default' : color],
        !!icon && classes.hasIcon,
        className
      )}
      css={getTagCss}
      data-testid="tag-root"
      {...rest}
    >
      {icon && (
        <IconContext.Provider
          value={{
            size: 'small',
          }}
        >
          {icon}
        </IconContext.Provider>
      )}
      <VisuallyHidden>
        {ariaLabel
          ? ariaLabel
          : stringFormatter.format(
              getTranslationKey(variant, color, hasWrongColor),
              {
                textLabel: children,
              }
            )}
      </VisuallyHidden>
      <Typography
        aria-hidden
        color="inherit"
        component="span"
        variant={variant === 'status' ? 'subtitleMedium' : 'bodySecondary'}
      >
        {children}
      </Typography>
    </span>
  );
});

export default Tag;
