import type {
  CountrySubdomainInfo,
  CourseraCountryId,
  LegacyCountrySubdomainInfo,
  LegacyCourseraCountryId,
  OtherSubdomainInfo,
} from 'bundles/internationalization-lib/types';

export const otherSubdomainInfo: OtherSubdomainInfo = {
  www: [
    {
      type: 'language',
      languageCode: 'en',
      courseraLocale: 'en',
    },
  ],
};

/**
 * Currently supported subdomains and related info.
 */
export const subdomainCountryInfo: CountrySubdomainInfo = {
  de: [
    {
      type: 'language',
      languageCode: 'de',
      courseraLocale: 'de',
    },
  ],
  in: [
    {
      type: 'region',
      countryName: 'India',
      countryCode: 'in',
      languageCode: 'en',
      courseraLocale: 'en-in',
    },
  ],
  mx: [
    {
      type: 'region',
      countryName: 'Mexico',
      countryCode: 'mx',
      languageCode: 'es',
      courseraLocale: 'es-mx',
    },
  ],
  gb: [
    {
      type: 'region',
      countryName: 'Great Britain',
      countryCode: 'gb',
      languageCode: 'en',
      courseraLocale: 'en-gb',
    },
  ],
  jp: [
    {
      type: 'region',
      countryName: 'Japan',
      countryCode: 'jp',
      languageCode: 'ja',
      courseraLocale: 'ja-jp',
    },
  ],
  tw: [
    {
      type: 'region',
      countryName: 'Taiwan',
      countryCode: 'tw',
      languageCode: 'zh-tw',
      courseraLocale: 'zh-tw',
    },
  ],
  cn: [
    {
      type: 'region',
      countryName: 'China',
      countryCode: 'cn',
      languageCode: 'zh',
      courseraLocale: 'zh-cn',
    },
  ],
  kr: [
    {
      type: 'region',
      countryName: 'Korea',
      countryCode: 'kr',
      languageCode: 'ko',
      courseraLocale: 'ko-kr',
    },
  ],
  ca: [
    {
      type: 'region',
      countryName: 'Canada',
      countryCode: 'ca',
      languageCode: 'en',
      courseraLocale: 'en-ca',
    },
  ],
};

/**
 * This contains subdomains that are language codes. Will transition over to country code subdomains.
 * Make sure locales are unique per domain. Ex. "en" should only existing on "www".
 */
export const legacySubdomainCountryInfo: LegacyCountrySubdomainInfo = {
  de: [
    {
      type: 'language',
      languageCode: 'de',
      courseraLocale: 'de',
    },
  ],
  es: [
    {
      type: 'language',
      languageCode: 'es',
      courseraLocale: 'es',
    },
  ],
  fr: [
    {
      type: 'language',
      languageCode: 'fr',
      courseraLocale: 'fr',
    },
  ],
  gb: [
    {
      type: 'region',
      countryName: 'Great Britain',
      countryCode: 'gb',
      languageCode: 'en',
      courseraLocale: 'en-gb',
    },
  ],
  in: [
    {
      type: 'region',
      countryName: 'India',
      countryCode: 'in',
      languageCode: 'en',
      courseraLocale: 'en-in',
    },
  ],
  mx: [
    {
      type: 'region',
      countryName: 'Mexico',
      countryCode: 'mx',
      languageCode: 'es',
      courseraLocale: 'es-mx',
    },
  ],
  pt: [
    {
      type: 'region',
      countryName: 'Brazil',
      countryCode: 'br',
      languageCode: 'pt',
      courseraLocale: 'pt-br',
    },
    {
      type: 'language',
      languageCode: 'pt',
      courseraLocale: 'pt',
    },
  ],
  ru: [
    {
      type: 'language',
      languageCode: 'ru',
      courseraLocale: 'ru',
    },
  ],
};

export const allSubdomainInfo = { ...legacySubdomainCountryInfo, ...subdomainCountryInfo, ...otherSubdomainInfo };

export function isCourseraCountryId(id: string): id is CourseraCountryId {
  return !!(subdomainCountryInfo as Record<string, unknown>)[id];
}

export function isLegacyCourseraCountryId(id: string): id is LegacyCourseraCountryId {
  return !!(legacySubdomainCountryInfo as Record<string, unknown>)[id];
}

export function getKnownSubdomainFromHost(host?: string): CourseraCountryId | undefined {
  const maybeSubdomain = host?.split('.')?.[0];
  if (maybeSubdomain && isCourseraCountryId(maybeSubdomain)) {
    return maybeSubdomain;
  }
  return undefined;
}

export function getLegacySubdomainFromHost(host?: string): LegacyCourseraCountryId | undefined {
  const maybeSubdomain = host?.split('.')?.[0];
  if (maybeSubdomain && isLegacyCourseraCountryId(maybeSubdomain)) {
    return maybeSubdomain;
  }
  return undefined;
}
