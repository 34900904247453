export default {
  COURSE: {
    pathname: 'learn',
  },
  SPECIALIZATIONS: {
    pathname: 'specializations',
  },
  PROFESSIONAL_CERTIFICATES: {
    pathname: 'professional-certificates',
  },
  GOOGLE_CERTIFICATES: {
    pathname: 'google-certificates',
  },
  PROJECTS: {
    pathname: 'projects',
  },
  LEARNING_PATH: {
    pathname: 'learning-path',
  },
} as const;
