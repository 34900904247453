import 'js/app/polyfills';
import 'js/constants/userAgent';
import initializeHeartbeatTracking from 'js/lib/heartbeat';
import timing from 'js/lib/timing';

if (!SKIP_MARKETING_SCRIPTS) {
  // execute on module load
  require('bundles/event-tracking/safeEnableAdsTracking').default();
}

initializeHeartbeatTracking();

timing.setMark('loaderExecuted');
