import { useLocalizedStringFormatter } from '@coursera/cds-common';

import i18nMessages from '@core/Button/i18n';
import Delayed from '@core/Delayed';
import VisuallyHidden from '@core/VisuallyHidden';

import getButtonLoaderCss from './getButtonLoaderCss';

/**
 * Loading animation for Button
 */

type Props = {
  isFocused?: boolean;
};

const ButtonLoader = (props: Props) => {
  const { isFocused } = props;
  const stringFormatter = useLocalizedStringFormatter(i18nMessages);

  const ariaLive = isFocused ? 'polite' : 'off';

  return (
    <span aria-live={ariaLive}>
      <Delayed>
        <VisuallyHidden>{stringFormatter.format('loading')}</VisuallyHidden>
      </Delayed>
      <svg
        aria-hidden
        css={getButtonLoaderCss}
        data-testid="cds-button-loading-indicator"
        viewBox="0 0 100 100"
      >
        <circle cx="50" cy="50" r="40" />
      </svg>
    </span>
  );
};

export default ButtonLoader;
