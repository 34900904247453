import React from 'react';

import type { ForwardRefComponent } from '@coursera/cds-common';

import type { CommonActionsProps } from '@core/dialogs/common/types';

import { getActionsCss } from './styles';

type ActionsType = ForwardRefComponent<HTMLDivElement, Props>;

export type Props = CommonActionsProps;

const styles = getActionsCss();

const Actions: ActionsType = React.forwardRef(function Actions(
  props: Props,
  ref: React.Ref<HTMLDivElement>
) {
  const { children, ...rest } = props;

  return (
    <div ref={ref} css={styles} {...rest}>
      {children}
    </div>
  );
});

export default Actions;
