/* eslint-disable @typescript-eslint/no-explicit-any, camelcase */

/**
 * This file is autogenerated, please don't edit it manually.
 * Use this command to regenerate it if you modified Epic experiments:
 * yarn epic:types
 */

/**
 * Epic experiments namespaces with their possible keys.
 * Autogenerated from static/bundles/epic/data/defaults/
 */
import client from 'bundles/epic/client';
import DegreesDiscovery from 'bundles/epic/data/defaults/DegreesDiscovery.json';
import type { Tags } from 'bundles/epic/lib/EpicTypes';

type Namespace = {
  destinationDegreeList: any;
  pgdipsMarketedAsDegrees: any;
  heroBannerUnification: 'control' | 'A' | 'B' | 'C';
  degreeAuthoringToolRollout: boolean;
  replaceMTonLIHP: boolean;
  LOHPpathwayS12nGraduallyRollout: boolean;
  throttledDestinationDegreeList: any;
};

const NAMESPACE = 'DegreesDiscovery';

// Adding safe optional chaining to avoid massive changes in unit tests.
client.addDefaults?.([DegreesDiscovery as $TSFixMe]);

const DegreesDiscoveryEpicClient = {
  get<K extends keyof Namespace>(key: K, tags?: Tags): Namespace[K] {
    return client.get(NAMESPACE, key, tags);
  },

  preview<K extends keyof Namespace>(key: K, tags?: Tags, defaults?: Namespace): Namespace[K] {
    return client.preview(NAMESPACE, key, tags);
  },
};

export default DegreesDiscoveryEpicClient;
