import { useEffect, useState } from 'react';

/**
 * Manually detect image load errors.
 * React has an issue with onError in SSR environment: https://github.com/facebook/react/issues/15446
 * This hook defers image load to client-side with useEffect to workaround it.
 */
const useHasImageLoadError = (options: { src?: string; srcSet?: string }) => {
  const { src, srcSet } = options;

  // set default to true for progressive enhancement,
  // so that we always render something until image is being fetched
  const [hasError, setHasError] = useState(true);

  useEffect(() => {
    if (!src && !srcSet) {
      setHasError(true);
      return;
    }

    setHasError(false);

    let mounted = true;
    const image = new Image();

    if (src) {
      image.src = src;
    }

    if (srcSet) {
      image.srcset = srcSet;
    }

    /* istanbul ignore next */
    image.onerror = () => {
      if (!mounted) {
        // this would occur when Avatar unmounts before onerror is invoked
        return;
      }

      setHasError(true);
    };

    return () => {
      mounted = false;
    };
  }, [src, srcSet]);

  return hasError;
};

export default useHasImageLoadError;
