import { css } from '@emotion/react';

import { typography } from '@core/Typography2';
import generateEmotionClasses from '@core/utils/generateEmotionClasses';

import type { Props as LinkProps } from './Link';

export const classes = generateEmotionClasses('link', ['focusVisible']);

export const getBeforeIconCss = css`
  vertical-align: text-top;
  margin-right: var(--cds-spacing-100);
`;

export const getAfterIconCss = css`
  vertical-align: text-top;
  margin-left: var(--cds-spacing-100);
`;

const getLinkCss = (props: LinkProps) => css`
  vertical-align: baseline;
  display: ${props.standalone
    ? props.iconPosition === 'before'
      ? 'inline-flex'
      : 'inline-block'
    : 'inline'};
  color: ${props.invert
    ? 'var(--cds-color-neutral-primary-invert)'
    : 'var(--cds-color-interactive-primary)'};
  padding: ${props.standalone ? 'var(--cds-spacing-150) 0px' : 0};
  text-decoration: ${props.variant === 'standard' ? 'underline' : 'none'};

  &:hover {
    color: ${props.invert
      ? 'var(--cds-color-neutral-primary-invert)'
      : 'var(--cds-color-interactive-primary-hover)'};
    text-decoration: ${props.variant === 'standard' ? 'none' : 'underline'};
  }

  &:focus {
    outline: none;
  }

  &:visited:not(.${classes.focusVisible}) {
    color: ${props.invert
      ? 'var(--cds-color-neutral-primary-invert)'
      : props.enableVisitedState
      ? 'var(--cds-color-interactive-primary-pressed)'
      : 'var(--cds-color-interactive-primary)'};
  }

  ${typography[props.typographyVariant as keyof typeof typography]};

  &.${classes.focusVisible} {
    outline: 1px solid transparent;
    outline-offset: 2px;
    border-radius: var(--cds-border-radius-50);
    text-decoration: underline;
    color: ${props.invert
      ? 'var(--cds-color-neutral-primary-invert)'
      : 'var(--cds-color-interactive-primary-hover)'};
    box-shadow: 0 0 0 1px var(--cds-color-interactive-stroke-primary-focus),
      0 0 0 2px var(--cds-color-interactive-stroke-primary-focus-invert);
  }
`;

export default getLinkCss;
