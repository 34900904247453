import React from 'react';

import clsx from 'clsx';

import type { BaseComponentProps } from '@coursera/cds-common';

import { getAccordionRootCss, classes } from './accordionRootCss';

export type AccordionVariant = 'standard' | 'silent';

export type Props = {
  variant: AccordionVariant;
  focused: boolean;
  hovering: boolean;
} & BaseComponentProps<'div'>;

const styles = getAccordionRootCss();

const AccordionRoot = React.forwardRef(function AccordionRoot(
  props: Props,
  ref: React.Ref<HTMLDivElement>
) {
  const { focused, hovering, id, children, variant, ...rest } = props;

  return (
    <div ref={ref} css={styles} {...rest}>
      <div className={classes.focusContainer}>
        <div
          className={clsx(classes.container, {
            [classes.standard]: variant === 'standard',
            [classes.silent]: variant === 'silent',
            [classes.focus]: focused,
            [classes.hover]: hovering,
          })}
          id={id}
        >
          {children}
        </div>
      </div>
    </div>
  );
});

export default AccordionRoot;
