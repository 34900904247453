import cookie from 'js/lib/cookie';
import user from 'js/lib/user';
import UserAgentInfo from 'js/lib/useragent';

import type { AppInfo } from 'bundles/page/contexts/AppInfoContext';
import requestCountryCode from 'bundles/phoenix/template/models/requestCountryCode';

declare global {
  interface Window {
    locale?: string;
    Navigator: Navigator;
    appName?: string;
  }
}

const get = (): AppInfo => {
  const userAgent = new UserAgentInfo(window.navigator.userAgent);
  return {
    appName: typeof window !== 'undefined' ? window?.appName : undefined,
    userAgent,
    csrfToken: cookie ? cookie.get('CSRF3-Token') : '<MISSING>',
    requestCountryCode,
    userData: user.get(),
  };
};

export default { get };
