/**
 * @deprecated Use `typography2` utility, which supports new variants instead.
 */
const typography = {
  inherit: {
    font: 'inherit',
    letterSpacing: 'inherit',
  },
  d1semibold: {
    font: 'var(--cds-typography-display1)',
    letterSpacing: 'var(--cds-letter-spacing-display1)',
  },
  d1: {
    font: 'var(--cds-typography-display1-weak)',
    letterSpacing: 'var(--cds-letter-spacing-display1)',
  },
  d2semibold: {
    font: 'var(--cds-typography-display2)',
    letterSpacing: 'var(--cds-letter-spacing-display2)',
  },
  d2: {
    font: 'var(--cds-typography-display2-weak)',
    letterSpacing: 'var(--cds-letter-spacing-display2)',
  },
  h1semibold: {
    font: 'var(--cds-typography-title1)',
    letterSpacing: 'var(--cds-letter-spacing-title1)',
  },
  h1: {
    font: 'var(--cds-typography-title1-weak)',
    letterSpacing: 'var(--cds-letter-spacing-title1)',
  },
  h2semibold: {
    font: 'var(--cds-typography-title2)',
    letterSpacing: 'var(--cds-letter-spacing-title2)',
  },
  h2: {
    font: 'var(--cds-typography-title2-weak)',
    letterSpacing: 'var(--cds-letter-spacing-title2)',
  },
  h3semibold: {
    font: 'var(--cds-typography-title3)',
    letterSpacing: 'var(--cds-letter-spacing-title3)',
  },
  h3bold: {
    font: 'var(--cds-typography-title3-strong)',
    letterSpacing: 'var(--cds-letter-spacing-title3)',
  },
  h4bold: {
    font: 'var(--cds-typography-title4)',
    letterSpacing: 'var(--cds-letter-spacing-title4)',
  },
  body1: {
    font: 'var(--cds-typography-body1)',
    letterSpacing: 'var(--cds-letter-spacing-body1)',
  },
  body2: {
    font: 'var(--cds-typography-body2)',
    letterSpacing: 'var(--cds-letter-spacing-body2)',
  },
};

export default typography;
