import * as React from 'react';
import { useContext } from 'react';

import PropTypes from 'prop-types';

import type { ActionContext } from 'js/lib/ActionContext';

type NaptimeContextAdapterType = {
  executeAction: ActionContext['executeAction'];
  getStore: ActionContext['getStore'];
};

const NaptimeAdapterContext = React.createContext<NaptimeContextAdapterType | undefined>(undefined);

export function useNaptimeContext() {
  const context = useContext(NaptimeAdapterContext);
  if (context === undefined) {
    throw new Error(
      '`useNaptimeContext` was used outside of `NaptimeAdapterContext.Provider`. Ensure your component is nested in the app under `<NaptimeContextAdapter>`. For unit tests, wrap your component with `<MockedUseNaptimeProvider>`.'
    );
  }
  return context;
}

/**
 * A context provider that provides the legacy Fluxible context (which Naptime
 * uses under the hood) using modern React Context. This allows custom hooks
 * (and hookified components) to access the Naptime API directly without
 * needing HOCs.
 */
export default function NaptimeContextAdapter(
  { children }: { children: React.ReactNode },
  context: NaptimeContextAdapterType
) {
  return <NaptimeAdapterContext.Provider value={context}>{children}</NaptimeAdapterContext.Provider>;
}

NaptimeContextAdapter.contextTypes = {
  executeAction: PropTypes.func.isRequired,
  getStore: PropTypes.func.isRequired,
};
