type IntersectionCallback = (entry: IntersectionObserverEntry) => void;

class VisibilityObserver {
  intersectionObserver;

  targetMap = new Map<Element, IntersectionCallback>();

  constructor(options: IntersectionObserverInit) {
    if (typeof window !== 'undefined' && 'IntersectionObserver' in window) {
      this.intersectionObserver = new IntersectionObserver(this.handleVisibilityCheck, options);
      document.addEventListener('visibilitychange', this.handlePageVisibility);
    }
  }

  observe(target: Element, callback: IntersectionCallback) {
    this.targetMap.set(target, callback);
    if (!document.hidden) {
      this.intersectionObserver?.observe(target);
    }
  }

  unobserve(target: Element) {
    this.targetMap.delete(target);
    this.intersectionObserver?.unobserve(target);
  }

  disconnect() {
    this.intersectionObserver?.disconnect();
    document.removeEventListener('visibilitychange', this.handlePageVisibility);
  }

  handleVisibilityCheck = (entries: IntersectionObserverEntry[]) => {
    if (document.hidden) {
      return;
    }

    entries.forEach((entry) => {
      const callback = this.targetMap.get(entry.target);
      if (callback) {
        callback(entry);
      }
    });
  };

  // unobserve targets when the page is no longer visible and reobserve when visible again
  // this ensures eventing is triggered as soon as the page is visible and the targets are intersecting
  handlePageVisibility = () => {
    const targets = Array.from(this.targetMap.keys());
    if (document.hidden) {
      targets.forEach((target) => this.intersectionObserver?.unobserve(target));
    } else {
      targets.forEach((target) => this.intersectionObserver?.observe(target));
    }
  };
}

export default VisibilityObserver;
