/* eslint-disable @typescript-eslint/no-explicit-any, camelcase */

/**
 * This file is autogenerated, please don't edit it manually.
 * Use this command to regenerate it if you modified Epic experiments:
 * yarn epic:types
 */

/**
 * Epic experiments namespaces with their possible keys.
 * Autogenerated from static/bundles/epic/data/defaults/
 */
import client from 'bundles/epic/client';
import GrowthPremium from 'bundles/epic/data/defaults/GrowthPremium.json';
import type { Tags } from 'bundles/epic/lib/EpicTypes';

type Namespace = {
  showMasterTracksAndUcertsOnHomepage: boolean;
  showDegreeDeadline: boolean;
  showCertRecommendationsOnCertPages: boolean;
  paintedDoorDegree: 'CompSci1' | 'CompSci2' | 'DataSci1' | 'DataSci2';
  paintedDoorDegreeRelaunch: 'CompSci1' | 'CompSci2' | 'DataSci1' | 'DataSci2';
  paintedDoorDegreeRollout: boolean;
  DLPV2Rollout: boolean;
  MasterTrackUcertsLPRollout: boolean;
};

const NAMESPACE = 'GrowthPremium';

// Adding safe optional chaining to avoid massive changes in unit tests.
client.addDefaults?.([GrowthPremium as $TSFixMe]);

const GrowthPremiumEpicClient = {
  get<K extends keyof Namespace>(key: K, tags?: Tags): Namespace[K] {
    return client.get(NAMESPACE, key, tags);
  },

  preview<K extends keyof Namespace>(key: K, tags?: Tags, defaults?: Namespace): Namespace[K] {
    return client.preview(NAMESPACE, key, tags);
  },
};

export default GrowthPremiumEpicClient;
