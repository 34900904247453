import type { BaseComponentProps } from '@coursera/cds-common';

import { classes } from '@core/cards/common/getCommonCardCss';

type Props = {
  children: React.ReactNode;
} & BaseComponentProps<'div'>;

/**
 * Common component to make any custom element(s) inside the card content area
 * to be interactive with pointing devices.
 *
 * Since the `<CardClickArea>` makes the entire card
 * content as one big link, we may want certain child elements to be "raised" outside in order
 * to be clicked on (e.g. custom links or buttons)
 *
 * See [eng notes](__storybookUrl__/components-data-display-product-card--default#eng-notes) for more info.
 *
 * @example
 *
 *   <CustomReviews>
 *     Some non-interactive content here.
 *     <CardInteractiveArea>
 *       <a href="/custom-link" target="_blank">
 *         Link inside the card content that should
 *         be clickable instead of the main card link.
 *       </a>
 *     </CardInteractiveArea>
 *   </CustomReviews>
 */
const CardInteractiveArea = ({ children }: Props) => {
  return <div className={classes.interactiveArea}>{children}</div>;
};

export default CardInteractiveArea;
