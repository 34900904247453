import type { Typography, TypographyStyle, Theme } from '@common/types';

const isLeftToRight = (theme: Theme): boolean => theme.direction === 'ltr';

const fontFamily = (theme: Theme): React.CSSProperties['fontFamily'] => {
  if (isLeftToRight(theme)) {
    return 'var(--cds-font-family-source-sans-pro)';
  }

  return 'var(--cds-font-family-boutros-coursera)';
};

type FontWeightType = 'regular' | 'semibold' | 'bold';

export const fontWeight = (
  theme: Theme,
  type: FontWeightType = 'regular'
): string => {
  switch (type) {
    case 'regular':
      return 'var(--cds-font-weight-400)';
    case 'semibold':
      return isLeftToRight(theme)
        ? 'var(--cds-font-weight-600)'
        : 'var(--cds-font-weight-700)';
    case 'bold':
      return 'var(--cds-font-weight-700)';
  }
};

const letterSpacing = (theme: Theme): string | number =>
  isLeftToRight(theme)
    ? 'var(--cds-letter-spacing-125)'
    : 'var(--cds-letter-spacing-0)';

const d1 = (
  theme: Theme,
  type: FontWeightType = 'regular'
): TypographyStyle => ({
  fontSize: 'var(--cds-font-size-display1-lg)',
  lineHeight: 'var(--cds-line-height-display1-lg)',
  fontFamily: fontFamily(theme),
  fontWeight: fontWeight(theme, type),
  letterSpacing: isLeftToRight(theme)
    ? 'var(--cds-letter-spacing-display1)'
    : 'var(--cds-letter-spacing-0)',

  [theme.breakpoints.down('sm')]: {
    fontSize: 'var(--cds-font-size-display1-sm)',
    lineHeight: 'var(--cds-line-height-display1-sm)',
  },

  [theme.breakpoints.down('xs')]: {
    fontSize: 'var(--cds-font-size-display1-xs)',
    lineHeight: 'var(--cds-line-height-display1-xs)',
  },
});

const d2 = (
  theme: Theme,
  type: FontWeightType = 'regular'
): TypographyStyle => ({
  fontSize: 'var(--cds-font-size-display2-lg)',
  lineHeight: 'var(--cds-line-height-display2-lg)',
  fontFamily: fontFamily(theme),
  letterSpacing: letterSpacing(theme),
  fontWeight: fontWeight(theme, type),

  [theme.breakpoints.down('sm')]: {
    fontSize: 'var(--cds-font-size-display2-sm)',
    lineHeight: 'var(--cds-line-height-display2-sm)',
  },

  [theme.breakpoints.down('xs')]: {
    fontSize: 'var(--cds-font-size-display2-xs)',
    lineHeight: 'var(--cds-line-height-display2-xs)',
  },
});

const h1 = (
  theme: Theme,
  type: FontWeightType = 'regular'
): TypographyStyle => ({
  fontSize: 'var(--cds-font-size-title1-lg)',
  lineHeight: 'var(--cds-line-height-title1-lg)',
  fontFamily: fontFamily(theme),
  fontWeight: fontWeight(theme, type),
  letterSpacing: letterSpacing(theme),

  [theme.breakpoints.down('xs')]: {
    fontSize: 'var(--cds-font-size-title1-xs)',
    lineHeight: 'var(--cds-line-height-title1-xs)',
  },
});

const h2 = (
  theme: Theme,
  type: FontWeightType = 'regular'
): TypographyStyle => ({
  fontSize: 'var(--cds-font-size-title2)',
  lineHeight: 'var(--cds-line-height-title2)',
  fontFamily: fontFamily(theme),
  fontWeight: fontWeight(theme, type),
  letterSpacing: letterSpacing(theme),
});

export const h3 = (
  theme: Theme,
  type: FontWeightType = 'regular'
): TypographyStyle => ({
  fontSize: 'var(--cds-font-size-title3)',
  lineHeight: 'var(--cds-line-height-title3)',
  fontFamily: fontFamily(theme),
  fontWeight: fontWeight(theme, type),
  letterSpacing: letterSpacing(theme),
});

export const h4 = (
  theme: Theme,
  type: FontWeightType = 'regular'
): TypographyStyle => ({
  fontSize: 'var(--cds-font-size-title4)',
  lineHeight: 'var(--cds-line-height-title4)',
  fontFamily: fontFamily(theme),
  fontWeight: fontWeight(theme, type),
  letterSpacing: letterSpacing(theme),
});

const body1 = (theme: Theme): TypographyStyle => ({
  letterSpacing: 0,
  fontSize: 'var(--cds-font-size-body1)',
  lineHeight: 'var(--cds-line-height-body1)',
  fontFamily: fontFamily(theme),
  fontWeight: fontWeight(theme),
});

const body2 = (theme: Theme): TypographyStyle => ({
  letterSpacing: 0,
  fontSize: 'var(--cds-font-size-body2)',
  lineHeight: 'var(--cds-line-height-body2)',
  fontFamily: fontFamily(theme),
  fontWeight: fontWeight(theme),
});

export default (theme: Theme): Typography => ({
  fontFamily: fontFamily(theme),
  fontWeight: 'var(--cds-font-weight-400)',

  inherit: {
    fontSize: 'inherit',
    fontWeight: 'inherit',
    fontFamily: 'inherit',
    lineHeight: 'inherit',
    letterSpacing: 'inherit',
  },

  d1: d1(theme),
  d1semibold: d1(theme, 'semibold'),

  d2: d2(theme),
  d2semibold: d2(theme, 'semibold'),

  h1: h1(theme),
  h1semibold: h1(theme, 'semibold'),

  h2: h2(theme),
  h2semibold: h2(theme, 'semibold'),

  h3semibold: h3(theme, 'semibold'),
  h3bold: h3(theme, 'bold'),

  h4bold: h4(theme, 'bold'),

  body1: body1(theme),
  body2: body2(theme),

  pxToRem: theme.typography.pxToRem,
});
