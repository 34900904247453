import gql from 'graphql-tag';

const RhymeProjectFragment = gql`
  fragment RhymeProjectFragment on CourseTypeMetadataV1_rhymeProjectMember {
    ... on CourseTypeMetadataV1_rhymeProjectMember {
      rhymeProject {
        typeNameIndex
      }
    }
  }
`;

const LightweightCourseFragment = gql`
  fragment LightweightCourseFragment on CoursesV1 {
    id
    slug
    name
    photoUrl
    s12nIds
    premiumExperienceVariant
    level
    workload
    primaryLanguages
    partners {
      elements {
        id
        name
        squareLogo
        classLogo
        logo
      }
    }
    courseTypeMetadata {
      id
      courseTypeMetadata {
        ...RhymeProjectFragment
      }
    }
  }
  ${RhymeProjectFragment}
`;

const LightweightS12nFragment = gql`
  fragment LightweightS12nFragment on OnDemandSpecializationsV1 {
    name
    id
    slug
    logo
    courseIds
    partners {
      elements {
        id
        name
        squareLogo
        classLogo
        logo
      }
    }
    metadata {
      headerImage
      level
    }
    productVariant
  }
`;

export const CollectionsFragment = gql`
  ${LightweightCourseFragment}
  ${LightweightS12nFragment}
  fragment CollectionsFragment on BrowseCollectionsV1Connection {
    elements {
      label
      id
      linkedCollectionPageMetadata {
        url
        label
      }
      debug
      entries {
        id
        courseId
        onDemandSpecializationId
        resourceName
        score
        isPartOfCourseraPlus
        isFreeCertificate
      }
      courses {
        elements {
          ...LightweightCourseFragment
        }
      }
      s12ns {
        elements {
          ...LightweightS12nFragment
        }
      }
    }
  }
`;

export const lohpDegreeListsV3Query = gql`
  query LOHPDegreeListsV3Query {
    DegreeListsV3Resource {
      getList(domains: null, productTypes: ["DEGREE", "MASTERTRACK"], limit: 12) {
        elements {
          id
          degreeProducts {
            ... on DegreeListsV3_catalogDegreeMember {
              catalogDegree {
                name
                shortName
                marketingName
                slug
                bannerImage
                catalogAvailabilityStatus
                partnerIds
                domainIds
                degreeCategoryIds
              }
            }
            ... on DegreeListsV3_catalogMastertrackMember {
              catalogMastertrack {
                name
                shortName
                slug
                bannerImage
                catalogAvailabilityStatus
                partnerIds
                domainIds
              }
            }
          }
          degreePartners {
            elements {
              id
              shortName
              name
              squareLogo
              primaryColor
            }
          }
        }
      }
    }
  }
`;

export const ProductQuery = gql`
  query ProductQuery($slug: String!) {
    XdpV1Resource {
      slug(productType: "SPECIALIZATION", slug: $slug) {
        elements {
          id
          xdpMetadata {
            __typename
            ... on XdpV1_sdpMetadataMember {
              sdpMetadata {
                id
                slug
                level
                name
                skills
                s12nAndCourseSkills
                productVariant
                coBrandingEnabled
                certificateLogo
                partners {
                  id
                  name
                  shortName
                  squareLogo
                }
                monthsToCompleteOverride
                ratings {
                  averageFiveStarRating
                  ratingCount
                }
                courses {
                  id
                  material {
                    weeks {
                      modules {
                        id
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
