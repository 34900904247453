import * as replay from '@amplitude/session-replay-browser';

import * as cookie from 'js/lib/cookie';

import type { Plugin } from '@coursera/event-pulse/core';

import FrontendInfraExperiments from 'bundles/epic/clients/FrontendInfra';

import session from './session';

const API_KEY = '9f370ddd4287ab67b08ab58273ddf40c';

function factory() {
  return replay.init(API_KEY, {
    // Sadly, we are not using the device ID from our client
    deviceId: cookie.get('__204u') ?? '',
    // Session for Amplitude is only the timestamp when the session started
    // Our uuid is a combination of the timestamp and a random uuid
    // @see static/bundles/page/lib/event-pulse/core/session.ts
    sessionId: Number(session.get().uuid.split('~')[1]),
    // 1% sample rate
    // This could be overridden by the remote config
    sampleRate: 0.01,
  });
}

const plugin: Plugin = (client) => {
  let singleton: ReturnType<typeof factory> | undefined;

  client.middleware(async (eventName, data) => {
    if (!FrontendInfraExperiments.get('replayPOC')) {
      return data;
    }

    if (!singleton) {
      singleton = factory();
    }

    await singleton.promise;

    return eventName === 'view_page' ? { ...data, ...replay.getSessionReplayProperties() } : data;
  });
};

export default plugin;
