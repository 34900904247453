/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import MegaMenuSection from 'bundles/megamenu/components/sections/MegaMenuSection';
import {
  CERTIFICATE_SECTION_TYPE,
  CLP_SECTION_TYPE,
  DEGREES_SECTION_TYPE,
  GET_STARTED_SECTION_TYPE,
  POPULAR_SKILLS_SECTION_TYPE,
} from 'bundles/megamenu/constants/constants';
import type { MegaMenuSections as MegaMenuSectionsType } from 'bundles/megamenu/types/MegaMenuSections';
import type { MenuSection } from 'bundles/megamenu/types/MenuData';

const styles = {
  sectionsContainer: () => css`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    padding-top: 15px;
    margin: 0 -50px;
  `,
  mobileSectionsContainer: () => css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100vw;
    margin: 0 -20px;
  `,
};

const MegaMenuSections: React.SFC<MegaMenuSectionsType> = ({ sectionItems, isMobile, tabName, tabId }) => {
  const degreesSectionItems = sectionItems.filter((section) => section.sectionType === DEGREES_SECTION_TYPE);
  const certificateSectionItems = sectionItems.filter((section) => section.sectionType === CERTIFICATE_SECTION_TYPE);
  const leftSectionItems = sectionItems.filter(
    (section) => section.sectionType === GET_STARTED_SECTION_TYPE || section.sectionType === POPULAR_SKILLS_SECTION_TYPE
  );
  const clpSectionItems = sectionItems.filter((section) => section.sectionType === CLP_SECTION_TYPE);

  return (
    <div className="rc-MegaMenuSections" css={isMobile ? styles.mobileSectionsContainer : styles.sectionsContainer}>
      {degreesSectionItems.map((section) => (
        <MegaMenuSection
          key={section.sectionName}
          section={section}
          isMobile={isMobile}
          tabName={tabName}
          tabId={tabId}
        />
      ))}
      {certificateSectionItems.map((section) => (
        <MegaMenuSection
          key={section.sectionName}
          section={section}
          isMobile={isMobile}
          tabName={tabName}
          tabId={tabId}
        />
      ))}
      {leftSectionItems.map((section: MenuSection) => (
        <MegaMenuSection
          key={section.sectionName}
          section={section}
          isMobile={isMobile}
          tabName={tabName}
          tabId={tabId}
        />
      ))}
      {clpSectionItems.map((section) => (
        <MegaMenuSection
          key={section.sectionName}
          section={section}
          isMobile={isMobile}
          tabName={tabName}
          tabId={tabId}
        />
      ))}
    </div>
  );
};

export default MegaMenuSections;
