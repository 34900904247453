import gql from 'graphql-tag';

const DomainFragment = gql`
  fragment DomainFragment on DomainsV1 {
    description
    displayColor
    id
    name
    slug
    keywords
    backgroundImageUrl
    subdomains {
      elements {
        ...SubdomainFragment
      }
    }
  }
`;

const SubdomainFragment = gql`
  fragment SubdomainFragment on SubdomainsV1 {
    id
    domainId
    slug
    name
    description
  }
`;

const DomainSubdomainQuery = gql`
  query DomainSubdomainQuery(
    $isDomainSlugDefined: Boolean!
    $domainSlug: String!
    $isSubdomainSlugDefined: Boolean!
    $subdomainSlug: String!
  ) {
    DomainsV1Resource @include(if: $isDomainSlugDefined) {
      slug(slug: $domainSlug) {
        elements {
          ...DomainFragment
        }
      }
    }
    SubdomainsV1Resource @include(if: $isSubdomainSlugDefined) {
      slug(slug: $subdomainSlug) {
        elements {
          ...SubdomainFragment
        }
      }
    }
  }
  ${DomainFragment}
  ${SubdomainFragment}
`;

const DomainGetAllQuery = gql`
  query DomainGetAllQuery {
    DomainsV1Resource {
      domains: getAll {
        elements {
          id
          topic
          slug
          name
          description
          backgroundImageUrl
          subdomains {
            elements {
              id
              slug
              topic
              name
              domainId
              description
            }
          }
        }
      }
    }
  }
`;

const SubdomainGetAllQuery = gql`
  query SubdomainGetAllQuery {
    SubdomainsV1Resource {
      subdomains: getAll {
        elements {
          id
          slug
          domainId
          name
          description
        }
      }
    }
  }
`;

export { DomainGetAllQuery, DomainSubdomainQuery, SubdomainGetAllQuery, DomainFragment, SubdomainFragment };
