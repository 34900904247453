import * as React from 'react';
import { useContext } from 'react';

import type { NavButtonType } from 'bundles/page-header/components/mobile/constants';
import type { NewCPlusEntryPointsEligibilityStatus } from 'bundles/page-header/hooks/useNewCPlusEntryPointsEligibilityCheck';

export type PageHeaderContextType = {
  isSimplifiedPageHeader?: boolean;
  // This is required for some legacy components to update styling in the composable page header framework.
  isComposablePageHeader?: boolean;
  subNavigationLinks?: React.ReactNode;
  hasUnifiedMetaNav?: boolean;
  mobileSubNavigationLinks?: NavButtonType[];
  cPlusEntryPointsData?: NewCPlusEntryPointsEligibilityStatus;
};

const PageHeaderContext = React.createContext<PageHeaderContextType>({});

export const useMobileSubNavigationLinks = () => {
  return useContext(PageHeaderContext).mobileSubNavigationLinks;
};

export const useIsSimplifiedPageHeader = () => {
  return useContext(PageHeaderContext).isSimplifiedPageHeader;
};

export const useHasMetaNav = () => {
  return useContext(PageHeaderContext).hasUnifiedMetaNav;
};

export const usePageHeader = () => {
  return useContext(PageHeaderContext);
};

export default PageHeaderContext;
