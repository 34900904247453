import { randomUUID as uuid } from 'js/lib/uuid';

type Options = {
  uuid?: string;
};

type ScreenSize = {
  height: number;
  width: number;
};

/**
 * Class to keep track of the device.
 *
 * This class provides methods to retrieve device-specific information such as
 * the UUID and screen size. It also supports serialization to JSON.
 */
class Device {
  /**
   * The UUID of the device.
   *
   * This UUID is either provided in the options or generated using the `uuid` function.
   */
  declare readonly uuid: string;

  /**
   * Constructor for the Device class.
   *
   * @param options - An optional object containing the UUID of the device.
   * If no UUID is provided, a new one will be generated.
   */
  constructor(options: Options = {}) {
    this.uuid = options.uuid ?? uuid();
  }

  /**
   * Get the screen size of the device.
   *
   * This method retrieves the height and width of the device's screen.
   *
   * @returns An object containing the height and width of the screen.
   */
  get screenSize(): ScreenSize {
    return {
      height: window.screen.height,
      width: window.screen.width,
    };
  }

  /**
   * Get the viewport size of the device.
   *
   * This method retrieves the height and width of the device's viewport.
   * The viewport size is the visible area of the web page.
   *
   * @returns An object containing the height and width of the viewport.
   */
  get viewportSize(): ScreenSize {
    return {
      height: window.innerHeight,
      width: window.innerWidth,
    };
  }

  /**
   * Serialize the device to JSON.
   *
   * This method returns a JSON representation of the device, including its UUID.
   *
   * @returns An object containing the UUID of the device.
   */
  toJSON() {
    return { uuid: this.uuid };
  }
}

export default Device;
