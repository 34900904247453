import { classes } from '@core/cards/ProductCard/getProductCardCss';

type Props = {
  children: NonNullable<React.ReactNode>;
};

const CardContent = ({ children }: Props) => {
  return <div className={classes.content}>{children}</div>;
};

export default CardContent;
