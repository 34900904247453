/**
 * Sanitizes a slug by replacing backslashes and other special characters with escaped versions of themselves.
 *
 * @param {string} slug The slug to sanitize.
 * @returns {string} The sanitized slug.
 */
function sanitizeString(value: string) {
  const regex = /[\\\n\r\t'"\u2028\u2029]/g;

  return value.replace(regex, (match) => {
    switch (match) {
      case '\\':
        return '\\\\';
      case '\n':
        return '\\n';
      case '\r':
        return '\\r';
      case '\t':
        return '\\t';
      case "'":
        return "\\'";
      case '"':
        return '\\"';
      case '\u2028':
        return '\\u2028';
      case '\u2029':
        return '\\u2029';
      default:
        return '';
    }
  });
}

export default sanitizeString;
