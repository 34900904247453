import { css } from '@emotion/react';

import generateEmotionClasses from '@core/utils/generateEmotionClasses';

export const classes = generateEmotionClasses('Modal', [
  'backdrop',
  'container',
]);

export const getModalCss = () => {
  return css`
    .${classes.container} {
      height: 100%;
      width: 100%;
    }

    .${classes.backdrop} {
      background-color: rgb(99 99 99 / 50%);
    }
  `;
};
