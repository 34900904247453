import config from 'js/app/config';

import {
  BeaconTransport,
  Client,
  FetchTransport,
  IframeTransport,
  PriorityTransport,
  ScriptTransport,
} from '@coursera/event-pulse/core';

import localStorage from 'bundles/common/utils/localStorageEx';

// The rule is silly and does not understand that this is not localStorage from the browser
import { plugin as campaign } from './campaign';
import device from './device';
import { plugin as language } from './language';
// Not the right place
// This is a side effect and should be clear what it does. This is a POC and should be moved to a better place for production.
import replay from './replay';
import session from './session';

const isDevelopment = config.environment === 'development';
// eslint-disable-next-line no-restricted-syntax
const localBypass = localStorage.getItem('__eventing_local_debug__', JSON.parse, false);

const debug = isDevelopment && !localBypass;

// High priority events
const high = new Client({
  debug,
  device,
  session,
  transport: new PriorityTransport({
    transports: [BeaconTransport, FetchTransport, IframeTransport, ScriptTransport],
  }),
});

const control = new Client({
  debug,
  device,
  session,
  transport: new PriorityTransport({
    transports: [BeaconTransport, FetchTransport, IframeTransport, ScriptTransport],
  }),
  version: '2.1.0-control',
});

const experiment = new Client({
  debug,
  device,
  session,
  transport: new PriorityTransport({
    transports: [BeaconTransport, FetchTransport, IframeTransport, ScriptTransport],
  }),
  version: '2.1.0-experiment',
});

// Low priority events
const low = new Client({
  debug,
  device,
  session,
  transport: new PriorityTransport({
    transports: [FetchTransport, BeaconTransport, IframeTransport, ScriptTransport],
  }),
});

// Add analytics for both clients
[control, high, experiment, low].forEach((client) => {
  client.register(campaign);
  client.register(language);
  client.register(replay);
});

export { control, experiment, high, low };
